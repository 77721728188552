import styled, { theme } from "../../theme";
import Box, { BoxProps } from "../primitives/box";

export type BodyProps = BoxProps;

export const ModalBody = styled(Box)<BodyProps>`
  border-bottom-color: ${theme.colors.neutral.light};
  flex: 1;
  overflow: auto;
`;

ModalBody.displayName = "ModalBody";

ModalBody.defaultProps = {
  px: 3,
  py: 4,
  maxWidth: "36rem",
};
export default ModalBody;
