import styled from "../../theme";
import Flex from "./flex";

export const Grid = styled(Flex)`
  display: grid;
`;

Grid.displayName = "Grid";

export default Grid;
