import { css } from "@emotion/core";
import {
  Tab as ReactAriaTab,
  TabList as ReactAriaTabList,
  TabPanel as ReactAriaTabPanel,
  Tabs as ReactAriaTabs,
  type TabPanelProps as ReactAriaTabPanelProps,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcFormattedMessage } from "./nc-formatted-message";
import { NcLoadingIndicator } from "./nc-loading-indicator";

const styles = {
  tabs: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: ${ncTheme.borderRadius.medium};
    flex-grow: 1;
    min-height: 0%;

    &[data-orientation="vertical"] {
      flex-direction: row;
    }

    /* global outline reset override */
    &:focus-visible,
    &[data-focused="true"] {
      html & {
        outline: none;
      }
    }
  `,
  list: css`
    display: flex;

    [data-orientation="horizontal"] & {
      gap: ${ncTheme.spacing(2)};
      padding-inline: ${ncTheme.spacing(2)};
    }

    &[data-orientation="vertical"] {
      flex-direction: column;
      gap: ${ncTheme.spacing(2)};
      margin-right: -${ncTheme.borderWidth.fine};
    }
  `,
  panel: {
    base: css`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: ${ncTheme.spacing(4)};
      border-radius: ${ncTheme.borderRadius.medium};
      background-color: ${ncTheme.colors.light};
      outline-style: none;
      overflow-y: auto;

      &[data-focus-visible] {
        outline-style: auto;
        ${ncTheme.utilities.outlineStyles}
        z-index: 1;
      }

      [data-orientation="vertical"] & {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    `,
    loading: css`
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: ${ncTheme.colors.brand};
    `,
    variant: {
      default: css`
        border: ${ncTheme.border(ncTheme.colors.ui)};
      `,
      subPanel: css`
        [data-orientation="horizontal"] & {
          border-top: ${ncTheme.border(ncTheme.colors.ui)};
        }
        [data-orientation="vertical"] & {
          border-left: ${ncTheme.border(ncTheme.colors.ui)};
        }
      `,
    },
  },
  tab: css`
    overflow: hidden;
    display: flex;
    background-color: ${ncTheme.colors.light};
    position: relative;
    cursor: pointer;
    font-weight: bold;
    border-radius: ${ncTheme.borderRadius.small};
    outline-style: none;
    border: ${ncTheme.border(ncTheme.colors.ui)};
    justify-content: space-between;
    color: inherit;
    text-decoration: none;
    white-space: normal;

    &[data-selected] {
      color: ${ncTheme.colors.main};

      &::before {
        content: "";
        position: absolute;
        top: 0;
        background-color: ${ncTheme.colors.active};
      }
    }

    &[data-hovered]:not([data-selected], [data-disabled]) {
      background-color: ${ncTheme.colors.main};
      color: ${ncTheme.colors.light};
    }

    &[data-disabled] {
      color: ${ncTheme.colors.disabled};
      font-weight: ${ncTheme.fontWeight.standard};
      background-color: ${ncTheme.colors.uiLight};
    }

    &[data-focus-visible] {
      ${ncTheme.utilities.outlineStyles}
    }

    [data-orientation="horizontal"] & {
      padding: ${ncTheme.spacing(3)};
      flex-basis: 100%;
      margin-bottom: -${ncTheme.borderWidth.fine};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &[data-selected] {
        border-bottom: ${ncTheme.border(ncTheme.colors.light)};

        &::before {
          left: 0;
          right: 0;
          height: ${ncTheme.spacing(1)};
        }
      }
    }

    [data-orientation="vertical"] & {
      padding: ${ncTheme.spacing(4)};
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &[data-selected] {
        border-right: none;

        &::before {
          left: 0;
          bottom: 0;
          width: ${ncTheme.spacing(1)};
        }
      }
    }
  `,
};

const NcTabs = ({ ...props }) => <ReactAriaTabs data-nc="NcTabs" css={styles.tabs} {...props} />;

const TabList = ({ ...props }) => (
  <ReactAriaTabList data-nc="NcTabs.TabList" {...props} css={styles.list} />
);

const Tab = ({ ...props }) => <ReactAriaTab data-nc="NcTabs.Tab" css={styles.tab} {...props} />;

interface NcTabPanelProps extends ReactAriaTabPanelProps {
  variant?: keyof typeof styles.panel.variant;
  error?: boolean | string;
  empty?: boolean | string;
  isLoading?: boolean;
}

type NcTabPanelState = "LOADING" | "LOADED" | "EMPTY" | "ERROR";

function getPanelState({
  isLoading,
  empty,
  error,
}: Omit<NcTabPanelProps, "children">): NcTabPanelState {
  if (isLoading) return "LOADING";
  if (empty) return "EMPTY";
  if (error) return "ERROR";
  return "LOADED";
}

const TabPanel = ({
  children,
  isLoading,
  empty,
  error,
  variant = "default",
  id,
  ...props
}: NcTabPanelProps) => {
  const state = getPanelState({ isLoading, empty, error });
  if (state === "LOADED") {
    return (
      <ReactAriaTabPanel
        data-nc="NcTabs.TabPanel"
        css={[styles.panel.base, styles.panel.variant[variant]]}
        id={id}
        {...props}
      >
        {children}
      </ReactAriaTabPanel>
    );
  }
  return (
    <ReactAriaTabPanel
      data-nc="NcTabs.TabPanel"
      css={[styles.panel.base, styles.panel.variant[variant]]}
      id={id}
    >
      {state === "LOADING" && (
        <div css={styles.panel.loading}>
          <NcLoadingIndicator />
        </div>
      )}
      {state === "ERROR" && <TabPanelError error={error} />}
      {state === "EMPTY" && <TabPanelEmpty empty={empty} />}
    </ReactAriaTabPanel>
  );
};

const TabPanelError = ({ error }: { error: NcTabPanelProps["error"] }) => {
  const { t } = useI18n();
  const errorText = typeof error === "string" ? error : t("error_default");
  return <NcFormattedMessage variant="danger">{errorText}</NcFormattedMessage>;
};

const TabPanelEmpty = ({ empty }: { empty: NcTabPanelProps["empty"] }) => {
  const { t } = useI18n();
  const emptyText = typeof empty === "string" ? empty : t("empty_default");
  return <NcFormattedMessage variant="secondary">{emptyText}</NcFormattedMessage>;
};

NcTabs.TabList = TabList;
NcTabs.Tab = Tab;
NcTabs.TabPanel = TabPanel;

export { NcTabs };
