import { NcCheckbox, NcHeading, NcLink } from "@noted/noted-components";
import { useRef, useState } from "react";

import { useI18n } from "~/hooks/use-i18n";
import { useUserPersonalisationSettings } from "~/hooks/use-user-personalisation-settings";

import { useClientSearchQuery } from "../dashboard-queries";
import SearchBox from "./search-box";
import SearchPopoverLayout from "./search-popover-layout";
import SearchResults from "./search-results";

const DEFAULT_OPTIONS = {
  size: 6,
  activeonly: false,
  searchAddresses: false,
  sortattr: "first_name,last_name",
  sortdir: "ASC",
};

const KEY_INCLUDE_INACTIVE = "dashboard_search_include_inactive";
const KEY_SEARCH_ADDRESSES = "dashboard_search_search_addresses";

const ClientSearch = () => {
  const { userPersonalisationSettings, setUserPersonalisationSettings } =
    useUserPersonalisationSettings();
  const { t } = useI18n("org");
  const [searchTerm, setSearchTerm] = useState("");
  const [includeInactive, setIncludeInactive] = useState<boolean>(
    userPersonalisationSettings[KEY_INCLUDE_INACTIVE] === true
  );
  const [searchAddresses, setSearchAddresses] = useState<boolean>(
    userPersonalisationSettings[KEY_SEARCH_ADDRESSES] === true
  );
  const resultsRef = useRef<HTMLUListElement>(null);

  const updateIncludeInactive = (value: boolean) => {
    setIncludeInactive(value);
    setUserPersonalisationSettings(KEY_INCLUDE_INACTIVE, value);
  };

  const updateSearchAddresses = (value: boolean) => {
    setSearchAddresses(value);
    setUserPersonalisationSettings(KEY_SEARCH_ADDRESSES, value);
  };

  const {
    data: searchResults,
    isLoading,
    error,
  } = useClientSearchQuery({
    searchTerm: searchTerm,
    page: 0,
    options: {
      ...DEFAULT_OPTIONS,
      activeonly: !includeInactive,
      searchAddresses: searchAddresses,
    },
  });

  const popoverOpen = isLoading || !!error || !!searchResults;

  const handleKeyboardNavOut = (event: KeyboardEvent) => {
    if (popoverOpen && resultsRef.current) {
      event.stopPropagation();
      event.preventDefault();
      const firstItem = resultsRef?.current?.firstChild as HTMLElement;
      (firstItem?.firstChild as HTMLElement)?.focus();
    }
  };

  return (
    <div className="relative z-[1] w-full items-center 2xl:mb-0 2xl:flex 2xl:gap-3">
      <NcHeading level={2} styleAs={5} className="mb-1 2xl:mb-0">
        {t("org:dashboard.clients.label")}
      </NcHeading>
      <SearchPopoverLayout popoverOpen={popoverOpen} closeAction={() => setSearchTerm("")}>
        {{
          search: (
            <SearchBox
              id="clientSearch"
              labelKey="org:dashboard.clients.search.label"
              onChange={setSearchTerm}
              onKeyboardNavOut={handleKeyboardNavOut}
              aria-haspopup
              aria-expanded={popoverOpen}
              aria-controls="dashboard-search-results"
              defaultValue={searchTerm}
            />
          ),
          controls: (
            <div className="z-0 grid w-full items-center gap-x-4 gap-y-3 pt-2 sm:flex sm:flex-wrap 2xl:pt-0">
              <NcCheckbox
                defaultSelected={includeInactive}
                onChange={value => {
                  updateIncludeInactive(value);
                }}
              >
                {t("org:dashboard.clients.search.include_inactive")}
              </NcCheckbox>

              <NcCheckbox
                defaultSelected={searchAddresses}
                onChange={value => {
                  updateSearchAddresses(value);
                }}
              >
                {t("org:dashboard.clients.search.include_addresses")}
              </NcCheckbox>

              <NcLink href="/n/clients" className="md:ml-auto">
                {t("org:dashboard.clients.search.advanced")}
              </NcLink>
            </div>
          ),
          results: (
            <ul
              role="listbox"
              aria-label={t("org:dashboard.clients.search.label")}
              className="z-[1] w-full"
              id="dashboard-search-results"
              data-testid="dashboard-search-results"
              ref={resultsRef}
            >
              <SearchResults
                error={!!error}
                loading={isLoading}
                searchResults={searchResults?.results}
              />
            </ul>
          ),
        }}
      </SearchPopoverLayout>
    </div>
  );
};

export default ClientSearch;
