import styled from "../../theme";
import Flex from "../primitives/flex";

export const Overlay = styled(Flex)<{ active: boolean }>`
  position: fixed;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: ${props => (props.active ? 1 : 0)};
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 250ms ease 250ms;
  pointer-events: ${props => (props.active ? "initial" : "none")};
  touch-action: ${props => (props.active ? "initial" : "none")};
  z-index: 2;
`;

Overlay.displayName = "ModalOverlay";

export default Overlay;
