import {
  NcBadge,
  NcFormattedMessage,
  NcIconEllipsis,
  NcListBox,
  NcPanel,
} from "@noted/noted-components";
import { useId } from "react-aria";

import { useI18n } from "~/hooks/use-i18n";

import { UnsignedRecord, useUnsignedRecordsQuery } from "./dashboard-queries";
import { LetterIcon, NoRecordsIcon, NoteIcon, RecordCourseIcon, SessionIcon } from "./icons";

const PanelEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-2 p-8">
      <NoRecordsIcon opacity="0.5" width="6rem" />
      <NcFormattedMessage variant="secondary">
        {t("org:dashboard.unsigned_records.empty")}
      </NcFormattedMessage>
    </div>
  );
};

function getRecordLink(record: UnsignedRecord) {
  if (record.client) {
    return `/n/clients/${record.client.id}/records/consultation/${record.id}`;
  }
  if (record.group) {
    return `/n/groups/${record.group.id}/sessions/${record.id}`;
  }
  return "";
}

function getRecordIcon(recordType: string) {
  const iconProps = {
    width: "1.5rem",
  };
  switch (recordType) {
    case "NOTE":
      return <NoteIcon {...iconProps} />;
    case "LETTER":
      return <LetterIcon {...iconProps} />;
    case "SESSION":
      return <SessionIcon {...iconProps} />;
    default:
      return <RecordCourseIcon {...iconProps} />;
  }
}

const UnsignedRecordsPanel = () => {
  const { t } = useI18n("org");
  const panelId = useId();

  const { data: allUnsignedRecords, isLoading, error } = useUnsignedRecordsQuery();

  const unsignedRecords = allUnsignedRecords?.slice(0, 500);
  const moreUnsignedRecords = allUnsignedRecords?.length !== unsignedRecords?.length;

  return (
    <NcPanel>
      <NcPanel.Header>
        <NcPanel.Heading id={panelId} level={2} className="mr-auto">
          {t("org:dashboard.unsigned_records.heading")}
        </NcPanel.Heading>
        {unsignedRecords && (
          <NcBadge variant="dangerLight">
            {unsignedRecords.length}
            {moreUnsignedRecords && "+"}
          </NcBadge>
        )}
      </NcPanel.Header>
      <NcPanel.Body
        className="p-0"
        isLoading={isLoading}
        error={
          error
            ? t("common:error_loading_refresh", {
                component: t("org:dashboard.unsigned_records.name"),
              })
            : undefined
        }
      >
        {unsignedRecords?.length ? (
          <NcListBox aria-labelledby={panelId} variant="borderless">
            {unsignedRecords.map((record: UnsignedRecord) => {
              const { id, client, group } = record;
              return (
                <NcListBox.Item
                  key={id}
                  href={getRecordLink(record)}
                  textValue={`${client?.firstName} ${client?.lastName} ${group?.name}`}
                >
                  <div className="flex items-center gap-2">
                    {getRecordIcon(record.type)}
                    <div data-testid="unsigned-record-name">
                      {client?.firstName ?? ""} {client?.lastName} {group?.name}
                    </div>
                  </div>
                </NcListBox.Item>
              );
            })}
            {moreUnsignedRecords && (
              <NcListBox.Item textValue={t("org:dashboard.unsigned_records.more_hidden")}>
                <NcIconEllipsis />
              </NcListBox.Item>
            )}
          </NcListBox>
        ) : (
          <PanelEmpty />
        )}
      </NcPanel.Body>
    </NcPanel>
  );
};

export default UnsignedRecordsPanel;
