import { InputHTMLAttributes } from "react";

import styled, { theme } from "../theme";

const RadioButton = styled.input`
  appearance: none;
  background: ${theme.colors.neutral.lightest};
  border: 1px solid ${theme.colors.neutral.medium};
  border-radius: 50%;
  box-shadow: 0 0 0 0.15em ${theme.colors.neutral.lightest} inset;
  cursor: pointer;
  display: flex;
  height: 1.2em;
  margin-right: ${theme.space[4]};
  position: relative;
  transition:
    background 0.2s ease-out,
    box-shadow 0.2s ease-out;
  width: 1.2em;

  &:hover {
    background: ${theme.colors.neutral.light};
    box-shadow: 0 0 0 0.15em ${theme.colors.neutral.light} inset;
  }

  &[checked] {
    background: radial-gradient(
      ${theme.colors.primary.dark} 60%,
      ${theme.colors.neutral.lightest} 60%
    );
  }
`;

export const InputRadio = (props: InputHTMLAttributes<HTMLInputElement>) => (
  <RadioButton type="radio" {...props} />
);
