import { createContext, ReactNode, useMemo, useState } from "react";

export interface INavigationContext {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const NavigationContext = createContext<INavigationContext>({
  open: false,
  setOpen: () => undefined,
});

export const NavigationState = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const value = useMemo(() => ({ open, setOpen }), [open]);

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};
