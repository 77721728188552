import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { customFetcher } from "~/graphql-hooks/custom-fetcher";
import * as Types from "~/graphql-hooks/types";

export type FeaturesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FeaturesQuery = { __typename?: "Query" } & {
  availableFeatures: { __typename?: "AvailableFeatureQueryPayload" } & {
    features: Array<
      { __typename?: "AvailableFeature" } & Pick<Types.AvailableFeature, "permissionName">
    >;
  };
  editableFeatures: { __typename?: "FeatureQueryPayload" } & {
    features: Array<
      { __typename?: "Feature" } & Pick<
        Types.Feature,
        "id" | "permissionName" | "name" | "description" | "enabled"
      >
    >;
  };
};

export type AuthenticateMutationVariables = Types.Exact<{
  input: Types.AuthenticationInput;
}>;

export type AuthenticateMutation = { __typename?: "Mutation" } & {
  authenticate?: Types.Maybe<
    { __typename?: "AuthenticationResult" } & Pick<
      Types.AuthenticationResult,
      | "result"
      | "lastLogin"
      | "shouldChangePasswordHint"
      | "token"
      | "version"
      | "lockoutSeconds"
      | "roles"
    >
  >;
};

export const FeaturesDocument = `
    query features {
  availableFeatures {
    features {
      permissionName
    }
  }
  editableFeatures {
    features {
      id
      permissionName
      name
      description
      enabled
    }
  }
}
    `;

export const useFeaturesQuery = <TData = FeaturesQuery, TError = unknown>(
  variables?: FeaturesQueryVariables,
  options?: Omit<UseQueryOptions<FeaturesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<FeaturesQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<FeaturesQuery, TError, TData>({
    queryKey: variables === undefined ? ["features"] : ["features", variables],
    queryFn: customFetcher<FeaturesQuery, FeaturesQueryVariables>(FeaturesDocument, variables),
    ...options,
  });
};

useFeaturesQuery.getKey = (variables?: FeaturesQueryVariables) =>
  variables === undefined ? ["features"] : ["features", variables];

export const AuthenticateDocument = `
    mutation authenticate($input: AuthenticationInput!) {
  authenticate(input: $input) {
    result
    lastLogin
    shouldChangePasswordHint
    token
    version
    lockoutSeconds
    roles
  }
}
    `;

export const useAuthenticateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AuthenticateMutation,
    TError,
    AuthenticateMutationVariables,
    TContext
  >
) => {
  return useMutation<AuthenticateMutation, TError, AuthenticateMutationVariables, TContext>({
    mutationKey: ["authenticate"],
    mutationFn: (variables?: AuthenticateMutationVariables) =>
      customFetcher<AuthenticateMutation, AuthenticateMutationVariables>(
        AuthenticateDocument,
        variables
      )(),
    ...options,
  });
};
