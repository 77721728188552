import { config } from "@noted/configuration";
import { useEffect } from "react";

import { useAccount } from "~/account/account.context";
import { useFeatures } from "~/account/features.context";

function createIntercomScriptlet(id: string) {
  // this is the script taken directly from the Intercom Installation guide
  // we just need to inject the correct app id based on the environment
  return `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${id}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
}

/**
 * Injects the Intercom script into the page.  The script will be configured for the Intercom
 * environment indicated by the passed #key.
 *
 * @param key The Intercom environment key to use.
 * @see @noted/configuration
 */
export function intercomScriptInjector(key: string) {
  const script = document.createElement("script");
  script.innerHTML = createIntercomScriptlet(key);
  document.head.appendChild(script);
}

/**
 * Boots the Intercom widget with the current user details.  Requires that the Intercom script is
 * injected into the page and that the users information has been loaded to work correctly.  If
 * Intercom is not configured for the environment then this component will do nothing.
 *
 * @see intercomScriptInjector
 * @see useAccount
 * @see @noted/configuration
 */
export function Intercom() {
  const { account, organisation } = useAccount();
  const { availableFeatures } = useFeatures();

  useEffect(() => {
    if (!config.intercomId) return;
    if (!account || !organisation) return;

    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: config.intercomId,
      hide_default_launcher: true,

      // user details
      ...account.thirdParty.intercom,
      name: account.firstName ? `${account.firstName} ${account.lastName}` : "",
      username: account.username,
      email: account.email,
      phone: account.phone,
      features_enabled: availableFeatures.map(f => f.permissionName).join(","),
      company_id: organisation.id,

      // company,
      company: {
        id: organisation.id,
        name: organisation.tradingName || organisation.legalName,
        phone: organisation.phone,
        email: organisation.email,
        has_acc_billing_enabled: organisation.healthLinkKeystoreStored,
      },
    });

    return () => {
      window.Intercom("shutdown");
    };
  }, [config.intercomId, organisation, account, availableFeatures]);
  return null;
}
