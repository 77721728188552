import { css } from "@emotion/core";
import { type SVGProps } from "react";

const styles = css`
  display: inline-block;
  height: 1.1em;
`;

export interface NcIconProps extends SVGProps<SVGSVGElement> {
  alt?: string;
}

export const NcIcon = ({ children, alt, fill = "currentColor", ...other }: NcIconProps) => {
  const role = alt && alt.length > 0 ? "img" : "presentation";
  const title = alt && alt.length > 0 ? <title>{alt}</title> : <></>;
  return (
    <svg data-nc="NcIcon" css={styles} fill={fill} {...other} role={role}>
      {title}
      {children}
    </svg>
  );
};
