import Box from "../primitives/box";

/**
 * @deprecated Use Form from `/shared/ui` instead.
 */
export const Form = Box.withComponent("form");

Form.displayName = "Form";

Form.defaultProps = {
  width: "100%",
  noValidate: true,
  autoComplete: "off",
};

export default Form;
