import { css } from "@emotion/core";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import type {
  RadioGroupProps as ReactAriaRadioGroupProps,
  RadioProps as ReactAriaRadioProps,
} from "react-aria-components";
import { Radio as ReactAriaRadio, RadioGroup as ReactAriaRadioGroup } from "react-aria-components";
import { useFormContext } from "react-hook-form";

import { ncTheme } from "../../nc-theme";
import type { FieldProps } from "./nc-field";
import { NcField } from "./nc-field";
import { checkboxFieldStyles } from "./nc-field-checkbox";

interface FieldRadioProps
  extends FieldProps,
    Omit<ReactAriaRadioGroupProps, "name" | "value" | "onBlur" | "onChange" | "validate"> {
  onChange?: (value: unknown) => void;
  options: {
    label: string;
    value: string;
  }[];
}

interface RadioProps extends ReactAriaRadioProps {
  children: ReactNode;
}

interface RadioGroupProps extends ReactAriaRadioGroupProps {
  children: ReactNode;
}

const styles = {
  wrap: css`
    ${checkboxFieldStyles.wrap};
  `,
  Radio: css`
    ${checkboxFieldStyles.checkbox};
    border-radius: ${ncTheme.borderRadius.rounded};
  `,
  label: css`
    ${checkboxFieldStyles.label};

    [data-disabled] > & {
      cursor: not-allowed;
    }
  `,
  group: css`
    ${checkboxFieldStyles.group};
  `,
};

const Radio = forwardRef<HTMLLabelElement, RadioProps>(({ children, ...props }, ref) => {
  return (
    <ReactAriaRadio data-nc="NcRadio" ref={ref} css={styles.wrap} {...props}>
      <div css={styles.Radio}></div>
      <div css={styles.label}>{children}</div>
    </ReactAriaRadio>
  );
});

const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(({ children, ...props }, ref) => {
  return (
    <ReactAriaRadioGroup data-nc="NcRadioGroup" ref={ref} css={styles.group} {...props}>
      {children}
    </ReactAriaRadioGroup>
  );
});

export const NcFieldRadio = ({
  label,
  labelNode,
  name,
  description,
  options,
  ...props
}: FieldRadioProps) => {
  const {
    register,
    formState: { defaultValues },
    setValue,
  } = useFormContext();

  const handleChange = (value: unknown) => {
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    props.onChange?.(value);
  };

  return (
    <RadioGroup
      data-nc="NcFieldRadio"
      defaultValue={defaultValues?.[name]}
      name={name}
      {...props}
      onChange={handleChange}
    >
      <NcField {...{ label: labelNode || label, description, isRequired: props.isRequired }}>
        {options.map(option => (
          <Radio key={option.value} value={option.value} {...register(name)}>
            {option.label}
          </Radio>
        ))}
      </NcField>
    </RadioGroup>
  );
};
