import { ReactNode } from "react";

import { useAccount } from "~/account/account.context";
import { useAuth } from "~/auth-provider";

import { LoadingPage } from "./waiting-component";

export const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { account, organisation } = useAccount();
  const { isAuthenticated } = useAuth();

  return isAuthenticated && account && organisation ? <>{children}</> : <LoadingPage />;
};
