import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconMagnify = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <circle cx={10} cy={10} r={8} stroke="currentColor" strokeWidth={2.5} />
    <path
      fill="currentColor"
      d="M18.237 15.763 17 14.525 14.525 17l1.238 1.237zm1.526 6.474a1.75 1.75 0 1 0 2.474-2.474zm-4-4 4 4 2.474-2.474-4-4z"
    />
  </NcIcon>
);
export { NcIconMagnify };
