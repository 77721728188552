import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { customFetcher } from "~/graphql-hooks/custom-fetcher";
import * as Types from "~/graphql-hooks/types";

export type GetResourcesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetResourcesQuery = { __typename?: "Query" } & {
  workers?: Types.Maybe<
    { __typename?: "UserQueryPayload" } & {
      results: Array<
        { __typename?: "User" } & Pick<Types.User, "id" | "name"> & {
            workingHours?: Types.Maybe<
              Array<
                { __typename?: "WorkingHours" } & Pick<
                  Types.WorkingHours,
                  "id" | "weekday" | "working" | "startTime" | "endTime"
                >
              >
            >;
            services?: Types.Maybe<
              Array<
                { __typename?: "Service" } & Pick<
                  Types.Service,
                  "id" | "duration" | "name" | "requiredWorkers" | "requiredSpaces"
                >
              >
            >;
          }
      >;
    }
  >;
  spaces?: Types.Maybe<
    Array<
      { __typename?: "Location" } & Pick<Types.Location, "id" | "name"> & {
          services?: Types.Maybe<
            Array<
              { __typename?: "Service" } & Pick<
                Types.Service,
                "id" | "duration" | "name" | "requiredWorkers" | "requiredSpaces"
              >
            >
          >;
        }
    >
  >;
};

export type CreateBlockedTimeMutationVariables = Types.Exact<{
  input: Types.CreateResourceAdjustmentPeriodInput;
}>;

export type CreateBlockedTimeMutation = { __typename?: "Mutation" } & {
  createResourceAdjustmentPeriod: { __typename?: "ResourceAdjustmentPeriodPayload" } & {
    resourceAdjustmentPeriod?: Types.Maybe<
      { __typename?: "ResourceAdjustmentPeriod" } & Pick<
        Types.ResourceAdjustmentPeriod,
        "id" | "type" | "from" | "to" | "notes"
      > & {
          space?: Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>;
          worker?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>;
          createdBy: { __typename?: "User" } & Pick<Types.User, "id" | "name">;
        }
    >;
  };
};

export type UpdateBlockedTimeMutationVariables = Types.Exact<{
  input: Types.UpdateResourceAdjustmentPeriodInput;
}>;

export type UpdateBlockedTimeMutation = { __typename?: "Mutation" } & {
  updateResourceAdjustmentPeriod: { __typename?: "ResourceAdjustmentPeriodPayload" } & {
    resourceAdjustmentPeriod?: Types.Maybe<
      { __typename?: "ResourceAdjustmentPeriod" } & Pick<
        Types.ResourceAdjustmentPeriod,
        "id" | "type" | "from" | "to" | "notes"
      > & {
          space?: Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>;
          worker?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>;
          createdBy: { __typename?: "User" } & Pick<Types.User, "id" | "name">;
        }
    >;
  };
};

export type DeleteBlockedTimeMutationVariables = Types.Exact<{
  id: Types.Scalars["Long"]["input"];
}>;

export type DeleteBlockedTimeMutation = { __typename?: "Mutation" } & {
  deleteResourceAdjustmentPeriod?: Types.Maybe<
    { __typename?: "DeleteResourceAdjustmentPeriodPayload" } & Pick<
      Types.DeleteResourceAdjustmentPeriodPayload,
      "success"
    >
  >;
};

export type UpcomingAppointmentsForClientQueryVariables = Types.Exact<{
  clientId: Types.Scalars["Long"]["input"];
  telehealthEnabled: Types.Scalars["Boolean"]["input"];
}>;

export type UpcomingAppointmentsForClientQuery = { __typename?: "Query" } & {
  upcomingAppointmentsForClient?: Types.Maybe<
    Array<
      { __typename?: "Appointment" } & Types.MakeOptional<
        Pick<
          Types.Appointment,
          | "id"
          | "name"
          | "from"
          | "duration"
          | "notes"
          | "cancelled"
          | "paid"
          | "confirmed"
          | "arrived"
          | "completed"
          | "telehealth"
        >,
        "telehealth"
      > & {
          service: { __typename?: "Service" } & Pick<Types.Service, "id" | "name" | "color">;
          workers?: Types.Maybe<
            Array<Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>>
          >;
          spaces?: Types.Maybe<
            Array<Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>>
          >;
          client: { __typename?: "Client" } & Pick<Types.Client, "id" | "name" | "email" | "level">;
          telehealthRoom?: Types.Maybe<
            { __typename?: "TelehealthRoom" } & Pick<Types.TelehealthRoom, "id" | "token">
          >;
        }
    >
  >;
};

export type GetClientDetailsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars["Long"]["input"]>;
}>;

export type GetClientDetailsQuery = { __typename?: "Query" } & {
  client?: Types.Maybe<
    { __typename?: "Client" } & Pick<
      Types.Client,
      | "id"
      | "name"
      | "firstName"
      | "lastName"
      | "dateOfBirth"
      | "phone"
      | "secondaryPhone"
      | "email"
    >
  >;
};

export type AppointmentFragmentFragment = { __typename?: "Appointment" } & Types.MakeOptional<
  Pick<
    Types.Appointment,
    | "id"
    | "from"
    | "duration"
    | "notes"
    | "name"
    | "cancelled"
    | "paid"
    | "confirmed"
    | "arrived"
    | "completed"
    | "telehealth"
  >,
  "telehealth"
> & {
    service: { __typename?: "Service" } & Pick<Types.Service, "id" | "name" | "color">;
    workers?: Types.Maybe<
      Array<Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>>
    >;
    spaces?: Types.Maybe<
      Array<Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>>
    >;
    client: { __typename?: "Client" } & Pick<
      Types.Client,
      "id" | "name" | "firstName" | "lastName" | "email" | "level"
    >;
    telehealthRoom?: Types.Maybe<
      { __typename?: "TelehealthRoom" } & Pick<Types.TelehealthRoom, "id" | "token">
    >;
  };

export type CancelAppointmentMutationVariables = Types.Exact<{
  input: Types.CancelAppointmentInput;
  telehealthEnabled: Types.Scalars["Boolean"]["input"];
}>;

export type CancelAppointmentMutation = { __typename?: "Mutation" } & {
  cancelAppointment: { __typename?: "AppointmentPayload" } & {
    appointment: { __typename?: "Appointment" } & Types.MakeOptional<
      Pick<
        Types.Appointment,
        | "id"
        | "from"
        | "duration"
        | "notes"
        | "name"
        | "cancelled"
        | "paid"
        | "confirmed"
        | "arrived"
        | "completed"
        | "telehealth"
      >,
      "telehealth"
    > & {
        service: { __typename?: "Service" } & Pick<Types.Service, "id" | "name" | "color">;
        workers?: Types.Maybe<
          Array<Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>>
        >;
        spaces?: Types.Maybe<
          Array<Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>>
        >;
        client: { __typename?: "Client" } & Pick<
          Types.Client,
          "id" | "name" | "firstName" | "lastName" | "email" | "level"
        >;
        telehealthRoom?: Types.Maybe<
          { __typename?: "TelehealthRoom" } & Pick<Types.TelehealthRoom, "id" | "token">
        >;
      };
  };
};

export type DeleteAppointmentMutationVariables = Types.Exact<{
  id: Types.Scalars["Long"]["input"];
}>;

export type DeleteAppointmentMutation = { __typename?: "Mutation" } & {
  deleteAppointment: { __typename?: "SuccessPayload" } & Pick<Types.SuccessPayload, "success">;
};

export type UpdateAppointmentWithWorkerAndLocationsMutationVariables = Types.Exact<{
  input: Types.UpdateAppointmentInput;
  telehealthEnabled: Types.Scalars["Boolean"]["input"];
}>;

export type UpdateAppointmentWithWorkerAndLocationsMutation = { __typename?: "Mutation" } & {
  updateAppointment?: Types.Maybe<
    { __typename?: "UpdateAppointmentPayload" } & Pick<
      Types.UpdateAppointmentPayload,
      "success" | "message"
    > & {
        appointment?: Types.Maybe<
          { __typename?: "Appointment" } & Types.MakeOptional<
            Pick<
              Types.Appointment,
              | "id"
              | "from"
              | "duration"
              | "notes"
              | "name"
              | "cancelled"
              | "paid"
              | "confirmed"
              | "arrived"
              | "completed"
              | "telehealth"
            >,
            "telehealth"
          > & {
              service: { __typename?: "Service" } & Pick<Types.Service, "id" | "name" | "color">;
              workers?: Types.Maybe<
                Array<Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>>
              >;
              spaces?: Types.Maybe<
                Array<
                  Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>
                >
              >;
              client: { __typename?: "Client" } & Pick<
                Types.Client,
                "id" | "name" | "firstName" | "lastName" | "email" | "level"
              >;
              telehealthRoom?: Types.Maybe<
                { __typename?: "TelehealthRoom" } & Pick<Types.TelehealthRoom, "id" | "token">
              >;
            }
        >;
        workerCollisions?: Types.Maybe<
          Array<
            Types.Maybe<
              { __typename?: "WorkerCollision" } & Pick<Types.WorkerCollision, "id" | "name">
            >
          >
        >;
        locationCollisions?: Types.Maybe<
          Array<
            Types.Maybe<
              { __typename?: "LocationCollision" } & Pick<Types.LocationCollision, "id" | "name">
            >
          >
        >;
      }
  >;
};

export type UpdateOrganisationTimezoneMutationVariables = Types.Exact<{
  organisation: Types.OrganisationInput;
}>;

export type UpdateOrganisationTimezoneMutation = { __typename?: "Mutation" } & {
  updateOrganisationTimezone?: Types.Maybe<
    { __typename?: "OrganisationPayload" } & {
      organisation: { __typename?: "Organisation" } & Pick<Types.Organisation, "timezone">;
    }
  >;
};

export type AppFragmentFragment = { __typename?: "Appointment" } & Types.MakeOptional<
  Pick<
    Types.Appointment,
    | "id"
    | "from"
    | "duration"
    | "notes"
    | "name"
    | "cancelled"
    | "paid"
    | "confirmed"
    | "arrived"
    | "completed"
    | "telehealth"
  >,
  "telehealth"
> & {
    service: { __typename?: "Service" } & Pick<Types.Service, "id" | "name" | "color">;
    workers?: Types.Maybe<
      Array<Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>>
    >;
    spaces?: Types.Maybe<
      Array<Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>>
    >;
    client: { __typename?: "Client" } & Pick<Types.Client, "id" | "name" | "email" | "level">;
    telehealthRoom?: Types.Maybe<
      { __typename?: "TelehealthRoom" } & Pick<Types.TelehealthRoom, "id" | "token">
    >;
    events?: Types.Maybe<
      Array<
        { __typename?: "AppointmentEvent" } & Pick<
          Types.AppointmentEvent,
          "id" | "eventType" | "eventDate" | "created"
        > & { createdBy?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">> }
      >
    >;
  };

export type ArriveAtAppointmentMutationVariables = Types.Exact<{
  input: Types.ArriveAtAppointmentInput;
  telehealthEnabled: Types.Scalars["Boolean"]["input"];
}>;

export type ArriveAtAppointmentMutation = { __typename?: "Mutation" } & {
  arriveAtAppointment: { __typename?: "AppointmentPayload" } & {
    appointment: { __typename?: "Appointment" } & Types.MakeOptional<
      Pick<
        Types.Appointment,
        | "id"
        | "from"
        | "duration"
        | "notes"
        | "name"
        | "cancelled"
        | "paid"
        | "confirmed"
        | "arrived"
        | "completed"
        | "telehealth"
      >,
      "telehealth"
    > & {
        service: { __typename?: "Service" } & Pick<Types.Service, "id" | "name" | "color">;
        workers?: Types.Maybe<
          Array<Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>>
        >;
        spaces?: Types.Maybe<
          Array<Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>>
        >;
        client: { __typename?: "Client" } & Pick<Types.Client, "id" | "name" | "email" | "level">;
        telehealthRoom?: Types.Maybe<
          { __typename?: "TelehealthRoom" } & Pick<Types.TelehealthRoom, "id" | "token">
        >;
        events?: Types.Maybe<
          Array<
            { __typename?: "AppointmentEvent" } & Pick<
              Types.AppointmentEvent,
              "id" | "eventType" | "eventDate" | "created"
            > & {
                createdBy?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>;
              }
          >
        >;
      };
  };
};

export type CompleteAppointmentMutationVariables = Types.Exact<{
  input: Types.CompleteAppointmentInput;
  telehealthEnabled: Types.Scalars["Boolean"]["input"];
}>;

export type CompleteAppointmentMutation = { __typename?: "Mutation" } & {
  completeAppointment: { __typename?: "AppointmentPayload" } & {
    appointment: { __typename?: "Appointment" } & Types.MakeOptional<
      Pick<
        Types.Appointment,
        | "id"
        | "from"
        | "duration"
        | "notes"
        | "name"
        | "cancelled"
        | "paid"
        | "confirmed"
        | "arrived"
        | "completed"
        | "telehealth"
      >,
      "telehealth"
    > & {
        service: { __typename?: "Service" } & Pick<Types.Service, "id" | "name" | "color">;
        workers?: Types.Maybe<
          Array<Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>>
        >;
        spaces?: Types.Maybe<
          Array<Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>>
        >;
        client: { __typename?: "Client" } & Pick<Types.Client, "id" | "name" | "email" | "level">;
        telehealthRoom?: Types.Maybe<
          { __typename?: "TelehealthRoom" } & Pick<Types.TelehealthRoom, "id" | "token">
        >;
        events?: Types.Maybe<
          Array<
            { __typename?: "AppointmentEvent" } & Pick<
              Types.AppointmentEvent,
              "id" | "eventType" | "eventDate" | "created"
            > & {
                createdBy?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>;
              }
          >
        >;
      };
  };
};

export type PayAppointmentMutationVariables = Types.Exact<{
  input: Types.PayAppointmentInput;
  telehealthEnabled: Types.Scalars["Boolean"]["input"];
}>;

export type PayAppointmentMutation = { __typename?: "Mutation" } & {
  payAppointment: { __typename?: "AppointmentPayload" } & {
    appointment: { __typename?: "Appointment" } & Types.MakeOptional<
      Pick<
        Types.Appointment,
        | "id"
        | "from"
        | "duration"
        | "notes"
        | "name"
        | "cancelled"
        | "paid"
        | "confirmed"
        | "arrived"
        | "completed"
        | "telehealth"
      >,
      "telehealth"
    > & {
        service: { __typename?: "Service" } & Pick<Types.Service, "id" | "name" | "color">;
        workers?: Types.Maybe<
          Array<Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>>
        >;
        spaces?: Types.Maybe<
          Array<Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>>
        >;
        client: { __typename?: "Client" } & Pick<Types.Client, "id" | "name" | "email" | "level">;
        telehealthRoom?: Types.Maybe<
          { __typename?: "TelehealthRoom" } & Pick<Types.TelehealthRoom, "id" | "token">
        >;
        events?: Types.Maybe<
          Array<
            { __typename?: "AppointmentEvent" } & Pick<
              Types.AppointmentEvent,
              "id" | "eventType" | "eventDate" | "created"
            > & {
                createdBy?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>;
              }
          >
        >;
      };
  };
};

export type SchedulingStateQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SchedulingStateQuery = { __typename?: "Query" } & {
  schedulingState: { __typename?: "SchedulingStatePayload" } & Pick<
    Types.SchedulingStatePayload,
    "hasTimezone" | "totalServices" | "totalBookableServices"
  > & {
      servicesMissingResources: Array<
        { __typename?: "Service" } & Pick<Types.Service, "id" | "name">
      >;
      servicesRequiringWorkingHours: Array<
        { __typename?: "Service" } & Pick<Types.Service, "id" | "name">
      >;
      workersRequiringWorkingHours: Array<
        { __typename?: "User" } & Pick<Types.User, "id" | "name">
      >;
    };
};

export type AppointmentQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars["Long"]["input"]>;
}>;

export type AppointmentQuery = { __typename?: "Query" } & {
  appointment: { __typename?: "Appointment" } & {
    events?: Types.Maybe<
      Array<
        { __typename?: "AppointmentEvent" } & Pick<
          Types.AppointmentEvent,
          "id" | "eventType" | "eventDate" | "created"
        > & { createdBy?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">> }
      >
    >;
  };
};

export type ClientPersonalDetailsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars["Long"]["input"]>;
}>;

export type ClientPersonalDetailsQuery = { __typename?: "Query" } & {
  client?: Types.Maybe<
    { __typename?: "Client" } & Pick<
      Types.Client,
      "id" | "name" | "firstName" | "lastName" | "dateOfBirth" | "phone" | "email"
    >
  >;
};

export type ResendAppointmentEmailMutationVariables = Types.Exact<{
  input: Types.AppointmentEmailInput;
}>;

export type ResendAppointmentEmailMutation = { __typename?: "Mutation" } & {
  resendAppointmentEmail: { __typename?: "SuccessPayload" } & Pick<Types.SuccessPayload, "success">;
};

export type AdjustmentPeriodsQueryVariables = Types.Exact<{
  input: Types.DateRangeInput;
}>;

export type AdjustmentPeriodsQuery = { __typename?: "Query" } & {
  resourceAdjustmentPeriodsBetween: Array<
    { __typename?: "ResourceAdjustmentPeriod" } & Pick<
      Types.ResourceAdjustmentPeriod,
      "id" | "type" | "from" | "to" | "notes"
    > & {
        space?: Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>;
        worker?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>;
        createdBy: { __typename?: "User" } & Pick<Types.User, "id" | "name">;
      }
  >;
};

export type UpdateResourceAdjustmentPeriodMutationVariables = Types.Exact<{
  input: Types.UpdateResourceAdjustmentPeriodInput;
}>;

export type UpdateResourceAdjustmentPeriodMutation = { __typename?: "Mutation" } & {
  updateResourceAdjustmentPeriod: { __typename?: "ResourceAdjustmentPeriodPayload" } & {
    resourceAdjustmentPeriod?: Types.Maybe<
      { __typename?: "ResourceAdjustmentPeriod" } & Pick<
        Types.ResourceAdjustmentPeriod,
        "id" | "type" | "from" | "to" | "notes"
      > & {
          space?: Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>;
          worker?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>;
          createdBy: { __typename?: "User" } & Pick<Types.User, "id" | "name">;
        }
    >;
  };
};

export type CreateClientMutationVariables = Types.Exact<{
  input: Types.CreateClientInput;
}>;

export type CreateClientMutation = { __typename?: "Mutation" } & {
  createClient?: Types.Maybe<
    { __typename?: "ClientPayload" } & {
      client?: Types.Maybe<
        { __typename?: "Client" } & Pick<
          Types.Client,
          | "id"
          | "name"
          | "firstName"
          | "lastName"
          | "dateOfBirth"
          | "email"
          | "phone"
          | "secondaryPhone"
          | "preferredName"
          | "medicalServiceId"
          | "clientId"
        > & {
            addresses?: Types.Maybe<
              Array<
                Types.Maybe<
                  { __typename?: "Address" } & Pick<
                    Types.Address,
                    "street1" | "street2" | "suburb" | "city" | "postcode" | "country"
                  >
                >
              >
            >;
          }
      >;
    }
  >;
};

export type ClientDuplicateQueryVariables = Types.Exact<{
  input: Types.CreateClientInput;
}>;

export type ClientDuplicateQuery = { __typename?: "Query" } & {
  duplicates: { __typename?: "ClientDuplicatesPayload" } & {
    duplicates: Array<
      { __typename?: "Client" } & Pick<
        Types.Client,
        "id" | "name" | "firstName" | "lastName" | "dateOfBirth" | "email"
      >
    >;
  };
};

export type CreateAdjustmentPeriodMutationVariables = Types.Exact<{
  input: Types.CreateResourceAdjustmentPeriodInput;
}>;

export type CreateAdjustmentPeriodMutation = { __typename?: "Mutation" } & {
  createResourceAdjustmentPeriod: { __typename?: "ResourceAdjustmentPeriodPayload" } & {
    resourceAdjustmentPeriod?: Types.Maybe<
      { __typename?: "ResourceAdjustmentPeriod" } & Pick<Types.ResourceAdjustmentPeriod, "id">
    >;
    conflictingPeriods?: Types.Maybe<
      Array<
        { __typename?: "ResourceAdjustmentPeriod" } & Pick<
          Types.ResourceAdjustmentPeriod,
          "id" | "type" | "from" | "to" | "notes"
        > & {
            space?: Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>;
            worker?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>;
            createdBy: { __typename?: "User" } & Pick<Types.User, "id">;
          }
      >
    >;
  };
};

export type UpdateAdjustmentPeriodMutationVariables = Types.Exact<{
  input: Types.UpdateResourceAdjustmentPeriodInput;
}>;

export type UpdateAdjustmentPeriodMutation = { __typename?: "Mutation" } & {
  updateResourceAdjustmentPeriod: { __typename?: "ResourceAdjustmentPeriodPayload" } & {
    resourceAdjustmentPeriod?: Types.Maybe<
      { __typename?: "ResourceAdjustmentPeriod" } & Pick<Types.ResourceAdjustmentPeriod, "id">
    >;
    conflictingPeriods?: Types.Maybe<
      Array<
        { __typename?: "ResourceAdjustmentPeriod" } & Pick<
          Types.ResourceAdjustmentPeriod,
          "id" | "type" | "from" | "to" | "notes"
        > & {
            space?: Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>;
            worker?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>;
            createdBy: { __typename?: "User" } & Pick<Types.User, "id">;
          }
      >
    >;
  };
};

export type DeleteResourceAdjustmentPeriodMutationVariables = Types.Exact<{
  id: Types.Scalars["Long"]["input"];
}>;

export type DeleteResourceAdjustmentPeriodMutation = { __typename?: "Mutation" } & {
  deleteResourceAdjustmentPeriod?: Types.Maybe<
    { __typename?: "DeleteResourceAdjustmentPeriodPayload" } & Pick<
      Types.DeleteResourceAdjustmentPeriodPayload,
      "success"
    >
  >;
};

export type TimeslotsQueryVariables = Types.Exact<{
  input: Types.TimeslotInput;
}>;

export type TimeslotsQuery = { __typename?: "Query" } & {
  timeslots: Array<
    { __typename?: "Timeslot" } & Pick<
      Types.Timeslot,
      "from" | "workerIds" | "spaceIds" | "clientId" | "serviceId"
    >
  >;
};

export type AppointmentFragment = { __typename?: "Appointment" } & Pick<
  Types.Appointment,
  | "id"
  | "from"
  | "duration"
  | "notes"
  | "name"
  | "cancelled"
  | "paid"
  | "confirmed"
  | "arrived"
  | "completed"
> & {
    service: { __typename?: "Service" } & Pick<Types.Service, "id" | "name" | "color">;
    workers?: Types.Maybe<
      Array<Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>>
    >;
    spaces?: Types.Maybe<
      Array<Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>>
    >;
    client: { __typename?: "Client" } & Pick<
      Types.Client,
      "id" | "name" | "firstName" | "lastName" | "email" | "level"
    >;
  };

export type WorkersAndTheirWorkingHoursFragment = { __typename?: "UserQueryPayload" } & {
  results: Array<
    { __typename?: "User" } & Pick<Types.User, "id" | "name"> & {
        workingHours?: Types.Maybe<
          Array<
            { __typename?: "WorkingHours" } & Pick<
              Types.WorkingHours,
              "id" | "weekday" | "working" | "startTime" | "endTime"
            >
          >
        >;
      }
  >;
};

export type ResourceAdjustmentPeriodFragment = { __typename?: "ResourceAdjustmentPeriod" } & Pick<
  Types.ResourceAdjustmentPeriod,
  "id" | "type" | "from" | "to" | "notes"
> & {
    space?: Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>;
    worker?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>;
    createdBy: { __typename?: "User" } & Pick<Types.User, "id" | "name">;
  };

export type GetCalendarItemsQueryVariables = Types.Exact<{
  input: Types.DateRangeInput;
  telehealthEnabled: Types.Scalars["Boolean"]["input"];
}>;

export type GetCalendarItemsQuery = { __typename?: "Query" } & {
  appointmentsForPeriod?: Types.Maybe<
    Array<
      Types.Maybe<
        { __typename?: "Appointment" } & Types.MakeOptional<
          Pick<
            Types.Appointment,
            | "telehealth"
            | "id"
            | "from"
            | "duration"
            | "notes"
            | "name"
            | "cancelled"
            | "paid"
            | "confirmed"
            | "arrived"
            | "completed"
          >,
          "telehealth"
        > & {
            telehealthRoom?: Types.Maybe<
              { __typename?: "TelehealthRoom" } & Pick<Types.TelehealthRoom, "id" | "token">
            >;
            service: { __typename?: "Service" } & Pick<Types.Service, "id" | "name" | "color">;
            workers?: Types.Maybe<
              Array<Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>>
            >;
            spaces?: Types.Maybe<
              Array<Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>>
            >;
            client: { __typename?: "Client" } & Pick<
              Types.Client,
              "id" | "name" | "firstName" | "lastName" | "email" | "level"
            >;
          }
      >
    >
  >;
  resourceAdjustmentPeriodsBetween: Array<
    { __typename?: "ResourceAdjustmentPeriod" } & Pick<
      Types.ResourceAdjustmentPeriod,
      "id" | "type" | "from" | "to" | "notes"
    > & {
        space?: Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>;
        worker?: Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>;
        createdBy: { __typename?: "User" } & Pick<Types.User, "id" | "name">;
      }
  >;
  workers?: Types.Maybe<
    { __typename?: "UserQueryPayload" } & {
      results: Array<
        { __typename?: "User" } & Pick<Types.User, "id" | "name"> & {
            workingHours?: Types.Maybe<
              Array<
                { __typename?: "WorkingHours" } & Pick<
                  Types.WorkingHours,
                  "id" | "weekday" | "working" | "startTime" | "endTime"
                >
              >
            >;
          }
      >;
    }
  >;
  spaces?: Types.Maybe<Array<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>>;
};

export type CreateAppointmentMutationVariables = Types.Exact<{
  input: Types.CreateAppointmentInput;
  telehealthEnabled: Types.Scalars["Boolean"]["input"];
}>;

export type CreateAppointmentMutation = { __typename?: "Mutation" } & {
  createAppointment?: Types.Maybe<
    { __typename?: "CreateAppointmentPayload" } & Pick<
      Types.CreateAppointmentPayload,
      "success" | "message"
    > & {
        appointment?: Types.Maybe<
          { __typename?: "Appointment" } & Types.MakeOptional<
            Pick<
              Types.Appointment,
              | "telehealth"
              | "id"
              | "from"
              | "duration"
              | "notes"
              | "name"
              | "cancelled"
              | "paid"
              | "confirmed"
              | "arrived"
              | "completed"
            >,
            "telehealth"
          > & {
              telehealthRoom?: Types.Maybe<
                { __typename?: "TelehealthRoom" } & Pick<Types.TelehealthRoom, "id" | "token">
              >;
              service: { __typename?: "Service" } & Pick<Types.Service, "id" | "name" | "color">;
              workers?: Types.Maybe<
                Array<Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>>
              >;
              spaces?: Types.Maybe<
                Array<
                  Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>
                >
              >;
              client: { __typename?: "Client" } & Pick<
                Types.Client,
                "id" | "name" | "firstName" | "lastName" | "email" | "level"
              >;
            }
        >;
        workerCollisions?: Types.Maybe<
          Array<
            Types.Maybe<
              { __typename?: "WorkerCollision" } & Pick<Types.WorkerCollision, "id" | "name">
            >
          >
        >;
        locationCollisions?: Types.Maybe<
          Array<
            Types.Maybe<
              { __typename?: "LocationCollision" } & Pick<Types.LocationCollision, "id" | "name">
            >
          >
        >;
      }
  >;
};

export type UpdateAppointmentMutationVariables = Types.Exact<{
  input: Types.UpdateAppointmentInput;
  telehealthEnabled: Types.Scalars["Boolean"]["input"];
}>;

export type UpdateAppointmentMutation = { __typename?: "Mutation" } & {
  updateAppointment?: Types.Maybe<
    { __typename?: "UpdateAppointmentPayload" } & Pick<
      Types.UpdateAppointmentPayload,
      "success" | "message"
    > & {
        appointment?: Types.Maybe<
          { __typename?: "Appointment" } & Types.MakeOptional<
            Pick<
              Types.Appointment,
              | "telehealth"
              | "id"
              | "from"
              | "duration"
              | "notes"
              | "name"
              | "cancelled"
              | "paid"
              | "confirmed"
              | "arrived"
              | "completed"
            >,
            "telehealth"
          > & {
              telehealthRoom?: Types.Maybe<
                { __typename?: "TelehealthRoom" } & Pick<Types.TelehealthRoom, "id" | "token">
              >;
              service: { __typename?: "Service" } & Pick<Types.Service, "id" | "name" | "color">;
              workers?: Types.Maybe<
                Array<Types.Maybe<{ __typename?: "User" } & Pick<Types.User, "id" | "name">>>
              >;
              spaces?: Types.Maybe<
                Array<
                  Types.Maybe<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>
                >
              >;
              client: { __typename?: "Client" } & Pick<
                Types.Client,
                "id" | "name" | "firstName" | "lastName" | "email" | "level"
              >;
            }
        >;
        workerCollisions?: Types.Maybe<
          Array<
            Types.Maybe<
              { __typename?: "WorkerCollision" } & Pick<Types.WorkerCollision, "id" | "name">
            >
          >
        >;
        locationCollisions?: Types.Maybe<
          Array<
            Types.Maybe<
              { __typename?: "LocationCollision" } & Pick<Types.LocationCollision, "id" | "name">
            >
          >
        >;
      }
  >;
};

export type UpdateClientMutationVariables = Types.Exact<{
  input: Types.UpdateClientInput;
}>;

export type UpdateClientMutation = { __typename?: "Mutation" } & {
  updateClient?: Types.Maybe<
    { __typename?: "ClientPayload" } & {
      client?: Types.Maybe<
        { __typename?: "Client" } & Pick<
          Types.Client,
          "id" | "name" | "firstName" | "lastName" | "dateOfBirth" | "phone" | "email"
        >
      >;
    }
  >;
};

export type GetServicesQueryVariables = Types.Exact<{
  telehealthEnabled: Types.Scalars["Boolean"]["input"];
}>;

export type GetServicesQuery = { __typename?: "Query" } & {
  services?: Types.Maybe<
    Array<
      { __typename?: "Service" } & Types.MakeOptional<
        Pick<
          Types.Service,
          | "id"
          | "name"
          | "description"
          | "duration"
          | "price"
          | "color"
          | "requiredWorkers"
          | "requiredSpaces"
          | "defaultTelehealth"
        >,
        "defaultTelehealth"
      > & {
          workers?: Types.Maybe<
            Array<{ __typename?: "User" } & Pick<Types.User, "id" | "name" | "email">>
          >;
          spaces?: Types.Maybe<
            Array<{ __typename?: "Location" } & Pick<Types.Location, "id" | "name">>
          >;
        }
    >
  >;
};

export const AppointmentFragmentFragmentDoc = `
    fragment AppointmentFragment on Appointment {
  id
  from
  duration
  notes
  name
  cancelled
  paid
  confirmed
  arrived
  completed
  service {
    id
    name
    color
  }
  workers {
    id
    name
  }
  spaces {
    id
    name
  }
  client {
    id
    name
    firstName
    lastName
    email
    level
  }
  telehealth @include(if: $telehealthEnabled)
  telehealthRoom @include(if: $telehealthEnabled) {
    id
    token
  }
}
    `;
export const AppFragmentFragmentDoc = `
    fragment AppFragment on Appointment {
  id
  from
  duration
  notes
  name
  cancelled
  paid
  confirmed
  arrived
  completed
  service {
    id
    name
    color
  }
  workers {
    id
    name
  }
  spaces {
    id
    name
  }
  client {
    id
    name
    email
    level
  }
  telehealth @include(if: $telehealthEnabled)
  telehealthRoom @include(if: $telehealthEnabled) {
    id
    token
  }
  events {
    id
    eventType
    eventDate
    createdBy {
      id
      name
    }
    created
  }
}
    `;
export const AppointmentFragmentDoc = `
    fragment Appointment on Appointment {
  id
  from
  duration
  notes
  name
  cancelled
  paid
  confirmed
  arrived
  completed
  service {
    id
    name
    color
  }
  workers {
    id
    name
  }
  spaces {
    id
    name
  }
  client {
    id
    name
    firstName
    lastName
    email
    level
  }
}
    `;
export const WorkersAndTheirWorkingHoursFragmentDoc = `
    fragment WorkersAndTheirWorkingHours on UserQueryPayload {
  results {
    id
    name
    workingHours {
      id
      weekday
      working
      startTime
      endTime
    }
  }
}
    `;
export const ResourceAdjustmentPeriodFragmentDoc = `
    fragment ResourceAdjustmentPeriod on ResourceAdjustmentPeriod {
  id
  space: location {
    id
    name
  }
  worker {
    id
    name
  }
  type
  from
  to
  notes
  createdBy {
    id
    name
  }
}
    `;
export const GetResourcesDocument = `
    query getResources {
  workers {
    results {
      id
      name
      workingHours {
        id
        weekday
        working
        startTime
        endTime
      }
      services {
        id
        duration
        name
        requiredWorkers
        requiredSpaces
      }
    }
  }
  spaces: locations {
    id
    name
    services {
      id
      duration
      name
      requiredWorkers
      requiredSpaces
    }
  }
}
    `;

export const useGetResourcesQuery = <TData = GetResourcesQuery, TError = unknown>(
  variables?: GetResourcesQueryVariables,
  options?: Omit<UseQueryOptions<GetResourcesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetResourcesQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<GetResourcesQuery, TError, TData>({
    queryKey: variables === undefined ? ["getResources"] : ["getResources", variables],
    queryFn: customFetcher<GetResourcesQuery, GetResourcesQueryVariables>(
      GetResourcesDocument,
      variables
    ),
    ...options,
  });
};

useGetResourcesQuery.getKey = (variables?: GetResourcesQueryVariables) =>
  variables === undefined ? ["getResources"] : ["getResources", variables];

export const CreateBlockedTimeDocument = `
    mutation createBlockedTime($input: CreateResourceAdjustmentPeriodInput!) {
  createResourceAdjustmentPeriod(input: $input) {
    resourceAdjustmentPeriod {
      id
      space: location {
        id
        name
      }
      worker {
        id
        name
      }
      type
      from
      to
      notes
      createdBy {
        id
        name
      }
    }
  }
}
    `;

export const useCreateBlockedTimeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateBlockedTimeMutation,
    TError,
    CreateBlockedTimeMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateBlockedTimeMutation,
    TError,
    CreateBlockedTimeMutationVariables,
    TContext
  >({
    mutationKey: ["createBlockedTime"],
    mutationFn: (variables?: CreateBlockedTimeMutationVariables) =>
      customFetcher<CreateBlockedTimeMutation, CreateBlockedTimeMutationVariables>(
        CreateBlockedTimeDocument,
        variables
      )(),
    ...options,
  });
};

export const UpdateBlockedTimeDocument = `
    mutation updateBlockedTime($input: UpdateResourceAdjustmentPeriodInput!) {
  updateResourceAdjustmentPeriod(input: $input) {
    resourceAdjustmentPeriod {
      id
      space: location {
        id
        name
      }
      worker {
        id
        name
      }
      type
      from
      to
      notes
      createdBy {
        id
        name
      }
    }
  }
}
    `;

export const useUpdateBlockedTimeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateBlockedTimeMutation,
    TError,
    UpdateBlockedTimeMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateBlockedTimeMutation,
    TError,
    UpdateBlockedTimeMutationVariables,
    TContext
  >({
    mutationKey: ["updateBlockedTime"],
    mutationFn: (variables?: UpdateBlockedTimeMutationVariables) =>
      customFetcher<UpdateBlockedTimeMutation, UpdateBlockedTimeMutationVariables>(
        UpdateBlockedTimeDocument,
        variables
      )(),
    ...options,
  });
};

export const DeleteBlockedTimeDocument = `
    mutation deleteBlockedTime($id: Long!) {
  deleteResourceAdjustmentPeriod(id: $id) {
    success
  }
}
    `;

export const useDeleteBlockedTimeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteBlockedTimeMutation,
    TError,
    DeleteBlockedTimeMutationVariables,
    TContext
  >
) => {
  return useMutation<
    DeleteBlockedTimeMutation,
    TError,
    DeleteBlockedTimeMutationVariables,
    TContext
  >({
    mutationKey: ["deleteBlockedTime"],
    mutationFn: (variables?: DeleteBlockedTimeMutationVariables) =>
      customFetcher<DeleteBlockedTimeMutation, DeleteBlockedTimeMutationVariables>(
        DeleteBlockedTimeDocument,
        variables
      )(),
    ...options,
  });
};

export const UpcomingAppointmentsForClientDocument = `
    query upcomingAppointmentsForClient($clientId: Long!, $telehealthEnabled: Boolean!) {
  upcomingAppointmentsForClient(clientId: $clientId) {
    id
    name
    from
    duration
    notes
    cancelled
    paid
    confirmed
    arrived
    completed
    service {
      id
      name
      color
    }
    workers {
      id
      name
    }
    spaces {
      id
      name
    }
    client {
      id
      name
      email
      level
    }
    telehealth @include(if: $telehealthEnabled)
    telehealthRoom @include(if: $telehealthEnabled) {
      id
      token
    }
  }
}
    `;

export const useUpcomingAppointmentsForClientQuery = <
  TData = UpcomingAppointmentsForClientQuery,
  TError = unknown,
>(
  variables: UpcomingAppointmentsForClientQueryVariables,
  options?: Omit<UseQueryOptions<UpcomingAppointmentsForClientQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<UpcomingAppointmentsForClientQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<UpcomingAppointmentsForClientQuery, TError, TData>({
    queryKey: ["upcomingAppointmentsForClient", variables],
    queryFn: customFetcher<
      UpcomingAppointmentsForClientQuery,
      UpcomingAppointmentsForClientQueryVariables
    >(UpcomingAppointmentsForClientDocument, variables),
    ...options,
  });
};

useUpcomingAppointmentsForClientQuery.getKey = (
  variables: UpcomingAppointmentsForClientQueryVariables
) => ["upcomingAppointmentsForClient", variables];

export const GetClientDetailsDocument = `
    query getClientDetails($id: Long) {
  client(id: $id) {
    id
    name
    firstName
    lastName
    dateOfBirth
    phone
    secondaryPhone
    email
  }
}
    `;

export const useGetClientDetailsQuery = <TData = GetClientDetailsQuery, TError = unknown>(
  variables?: GetClientDetailsQueryVariables,
  options?: Omit<UseQueryOptions<GetClientDetailsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetClientDetailsQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<GetClientDetailsQuery, TError, TData>({
    queryKey: variables === undefined ? ["getClientDetails"] : ["getClientDetails", variables],
    queryFn: customFetcher<GetClientDetailsQuery, GetClientDetailsQueryVariables>(
      GetClientDetailsDocument,
      variables
    ),
    ...options,
  });
};

useGetClientDetailsQuery.getKey = (variables?: GetClientDetailsQueryVariables) =>
  variables === undefined ? ["getClientDetails"] : ["getClientDetails", variables];

export const CancelAppointmentDocument = `
    mutation cancelAppointment($input: CancelAppointmentInput!, $telehealthEnabled: Boolean!) {
  cancelAppointment(input: $input) {
    appointment {
      ...AppointmentFragment
    }
  }
}
    ${AppointmentFragmentFragmentDoc}`;

export const useCancelAppointmentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CancelAppointmentMutation,
    TError,
    CancelAppointmentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CancelAppointmentMutation,
    TError,
    CancelAppointmentMutationVariables,
    TContext
  >({
    mutationKey: ["cancelAppointment"],
    mutationFn: (variables?: CancelAppointmentMutationVariables) =>
      customFetcher<CancelAppointmentMutation, CancelAppointmentMutationVariables>(
        CancelAppointmentDocument,
        variables
      )(),
    ...options,
  });
};

export const DeleteAppointmentDocument = `
    mutation deleteAppointment($id: Long!) {
  deleteAppointment(id: $id) {
    success
  }
}
    `;

export const useDeleteAppointmentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteAppointmentMutation,
    TError,
    DeleteAppointmentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    DeleteAppointmentMutation,
    TError,
    DeleteAppointmentMutationVariables,
    TContext
  >({
    mutationKey: ["deleteAppointment"],
    mutationFn: (variables?: DeleteAppointmentMutationVariables) =>
      customFetcher<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>(
        DeleteAppointmentDocument,
        variables
      )(),
    ...options,
  });
};

export const UpdateAppointmentWithWorkerAndLocationsDocument = `
    mutation updateAppointmentWithWorkerAndLocations($input: UpdateAppointmentInput!, $telehealthEnabled: Boolean!) {
  updateAppointment(input: $input) {
    appointment {
      ...AppointmentFragment
    }
    workerCollisions {
      id
      name
    }
    locationCollisions {
      id
      name
    }
    success
    message
  }
}
    ${AppointmentFragmentFragmentDoc}`;

export const useUpdateAppointmentWithWorkerAndLocationsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateAppointmentWithWorkerAndLocationsMutation,
    TError,
    UpdateAppointmentWithWorkerAndLocationsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateAppointmentWithWorkerAndLocationsMutation,
    TError,
    UpdateAppointmentWithWorkerAndLocationsMutationVariables,
    TContext
  >({
    mutationKey: ["updateAppointmentWithWorkerAndLocations"],
    mutationFn: (variables?: UpdateAppointmentWithWorkerAndLocationsMutationVariables) =>
      customFetcher<
        UpdateAppointmentWithWorkerAndLocationsMutation,
        UpdateAppointmentWithWorkerAndLocationsMutationVariables
      >(UpdateAppointmentWithWorkerAndLocationsDocument, variables)(),
    ...options,
  });
};

export const UpdateOrganisationTimezoneDocument = `
    mutation updateOrganisationTimezone($organisation: OrganisationInput!) {
  updateOrganisationTimezone(input: $organisation) {
    organisation {
      timezone
    }
  }
}
    `;

export const useUpdateOrganisationTimezoneMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateOrganisationTimezoneMutation,
    TError,
    UpdateOrganisationTimezoneMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateOrganisationTimezoneMutation,
    TError,
    UpdateOrganisationTimezoneMutationVariables,
    TContext
  >({
    mutationKey: ["updateOrganisationTimezone"],
    mutationFn: (variables?: UpdateOrganisationTimezoneMutationVariables) =>
      customFetcher<
        UpdateOrganisationTimezoneMutation,
        UpdateOrganisationTimezoneMutationVariables
      >(UpdateOrganisationTimezoneDocument, variables)(),
    ...options,
  });
};

export const ArriveAtAppointmentDocument = `
    mutation arriveAtAppointment($input: ArriveAtAppointmentInput!, $telehealthEnabled: Boolean!) {
  arriveAtAppointment(input: $input) {
    appointment {
      ...AppFragment
    }
  }
}
    ${AppFragmentFragmentDoc}`;

export const useArriveAtAppointmentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ArriveAtAppointmentMutation,
    TError,
    ArriveAtAppointmentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ArriveAtAppointmentMutation,
    TError,
    ArriveAtAppointmentMutationVariables,
    TContext
  >({
    mutationKey: ["arriveAtAppointment"],
    mutationFn: (variables?: ArriveAtAppointmentMutationVariables) =>
      customFetcher<ArriveAtAppointmentMutation, ArriveAtAppointmentMutationVariables>(
        ArriveAtAppointmentDocument,
        variables
      )(),
    ...options,
  });
};

export const CompleteAppointmentDocument = `
    mutation completeAppointment($input: CompleteAppointmentInput!, $telehealthEnabled: Boolean!) {
  completeAppointment(input: $input) {
    appointment {
      ...AppFragment
    }
  }
}
    ${AppFragmentFragmentDoc}`;

export const useCompleteAppointmentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CompleteAppointmentMutation,
    TError,
    CompleteAppointmentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CompleteAppointmentMutation,
    TError,
    CompleteAppointmentMutationVariables,
    TContext
  >({
    mutationKey: ["completeAppointment"],
    mutationFn: (variables?: CompleteAppointmentMutationVariables) =>
      customFetcher<CompleteAppointmentMutation, CompleteAppointmentMutationVariables>(
        CompleteAppointmentDocument,
        variables
      )(),
    ...options,
  });
};

export const PayAppointmentDocument = `
    mutation payAppointment($input: PayAppointmentInput!, $telehealthEnabled: Boolean!) {
  payAppointment(input: $input) {
    appointment {
      ...AppFragment
    }
  }
}
    ${AppFragmentFragmentDoc}`;

export const usePayAppointmentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PayAppointmentMutation,
    TError,
    PayAppointmentMutationVariables,
    TContext
  >
) => {
  return useMutation<PayAppointmentMutation, TError, PayAppointmentMutationVariables, TContext>({
    mutationKey: ["payAppointment"],
    mutationFn: (variables?: PayAppointmentMutationVariables) =>
      customFetcher<PayAppointmentMutation, PayAppointmentMutationVariables>(
        PayAppointmentDocument,
        variables
      )(),
    ...options,
  });
};

export const SchedulingStateDocument = `
    query schedulingState {
  schedulingState {
    hasTimezone
    totalServices
    totalBookableServices
    servicesMissingResources {
      id
      name
    }
    servicesRequiringWorkingHours {
      id
      name
    }
    workersRequiringWorkingHours {
      id
      name
    }
  }
}
    `;

export const useSchedulingStateQuery = <TData = SchedulingStateQuery, TError = unknown>(
  variables?: SchedulingStateQueryVariables,
  options?: Omit<UseQueryOptions<SchedulingStateQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<SchedulingStateQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<SchedulingStateQuery, TError, TData>({
    queryKey: variables === undefined ? ["schedulingState"] : ["schedulingState", variables],
    queryFn: customFetcher<SchedulingStateQuery, SchedulingStateQueryVariables>(
      SchedulingStateDocument,
      variables
    ),
    ...options,
  });
};

useSchedulingStateQuery.getKey = (variables?: SchedulingStateQueryVariables) =>
  variables === undefined ? ["schedulingState"] : ["schedulingState", variables];

export const AppointmentDocument = `
    query appointment($id: Long) {
  appointment(id: $id) {
    events {
      id
      eventType
      eventDate
      createdBy {
        id
        name
      }
      created
    }
  }
}
    `;

export const useAppointmentQuery = <TData = AppointmentQuery, TError = unknown>(
  variables?: AppointmentQueryVariables,
  options?: Omit<UseQueryOptions<AppointmentQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<AppointmentQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<AppointmentQuery, TError, TData>({
    queryKey: variables === undefined ? ["appointment"] : ["appointment", variables],
    queryFn: customFetcher<AppointmentQuery, AppointmentQueryVariables>(
      AppointmentDocument,
      variables
    ),
    ...options,
  });
};

useAppointmentQuery.getKey = (variables?: AppointmentQueryVariables) =>
  variables === undefined ? ["appointment"] : ["appointment", variables];

export const ClientPersonalDetailsDocument = `
    query clientPersonalDetails($id: Long) {
  client(id: $id) {
    id
    name
    firstName
    lastName
    dateOfBirth
    phone
    email
  }
}
    `;

export const useClientPersonalDetailsQuery = <TData = ClientPersonalDetailsQuery, TError = unknown>(
  variables?: ClientPersonalDetailsQueryVariables,
  options?: Omit<UseQueryOptions<ClientPersonalDetailsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<ClientPersonalDetailsQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<ClientPersonalDetailsQuery, TError, TData>({
    queryKey:
      variables === undefined ? ["clientPersonalDetails"] : ["clientPersonalDetails", variables],
    queryFn: customFetcher<ClientPersonalDetailsQuery, ClientPersonalDetailsQueryVariables>(
      ClientPersonalDetailsDocument,
      variables
    ),
    ...options,
  });
};

useClientPersonalDetailsQuery.getKey = (variables?: ClientPersonalDetailsQueryVariables) =>
  variables === undefined ? ["clientPersonalDetails"] : ["clientPersonalDetails", variables];

export const ResendAppointmentEmailDocument = `
    mutation resendAppointmentEmail($input: AppointmentEmailInput!) {
  resendAppointmentEmail(input: $input) {
    success
  }
}
    `;

export const useResendAppointmentEmailMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ResendAppointmentEmailMutation,
    TError,
    ResendAppointmentEmailMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ResendAppointmentEmailMutation,
    TError,
    ResendAppointmentEmailMutationVariables,
    TContext
  >({
    mutationKey: ["resendAppointmentEmail"],
    mutationFn: (variables?: ResendAppointmentEmailMutationVariables) =>
      customFetcher<ResendAppointmentEmailMutation, ResendAppointmentEmailMutationVariables>(
        ResendAppointmentEmailDocument,
        variables
      )(),
    ...options,
  });
};

export const AdjustmentPeriodsDocument = `
    query adjustmentPeriods($input: DateRangeInput!) {
  resourceAdjustmentPeriodsBetween(input: $input) {
    id
    space: location {
      id
      name
    }
    worker {
      id
      name
    }
    type
    from
    to
    notes
    createdBy {
      id
      name
    }
  }
}
    `;

export const useAdjustmentPeriodsQuery = <TData = AdjustmentPeriodsQuery, TError = unknown>(
  variables: AdjustmentPeriodsQueryVariables,
  options?: Omit<UseQueryOptions<AdjustmentPeriodsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<AdjustmentPeriodsQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<AdjustmentPeriodsQuery, TError, TData>({
    queryKey: ["adjustmentPeriods", variables],
    queryFn: customFetcher<AdjustmentPeriodsQuery, AdjustmentPeriodsQueryVariables>(
      AdjustmentPeriodsDocument,
      variables
    ),
    ...options,
  });
};

useAdjustmentPeriodsQuery.getKey = (variables: AdjustmentPeriodsQueryVariables) => [
  "adjustmentPeriods",
  variables,
];

export const UpdateResourceAdjustmentPeriodDocument = `
    mutation updateResourceAdjustmentPeriod($input: UpdateResourceAdjustmentPeriodInput!) {
  updateResourceAdjustmentPeriod(input: $input) {
    resourceAdjustmentPeriod {
      id
      space: location {
        id
        name
      }
      worker {
        id
        name
      }
      type
      from
      to
      notes
      createdBy {
        id
        name
      }
    }
  }
}
    `;

export const useUpdateResourceAdjustmentPeriodMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateResourceAdjustmentPeriodMutation,
    TError,
    UpdateResourceAdjustmentPeriodMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateResourceAdjustmentPeriodMutation,
    TError,
    UpdateResourceAdjustmentPeriodMutationVariables,
    TContext
  >({
    mutationKey: ["updateResourceAdjustmentPeriod"],
    mutationFn: (variables?: UpdateResourceAdjustmentPeriodMutationVariables) =>
      customFetcher<
        UpdateResourceAdjustmentPeriodMutation,
        UpdateResourceAdjustmentPeriodMutationVariables
      >(UpdateResourceAdjustmentPeriodDocument, variables)(),
    ...options,
  });
};

export const CreateClientDocument = `
    mutation createClient($input: CreateClientInput!) {
  createClient(input: $input) {
    client {
      id
      name
      firstName
      lastName
      dateOfBirth
      email
      phone
      secondaryPhone
      preferredName
      medicalServiceId
      clientId
      addresses {
        street1
        street2
        suburb
        city
        postcode
        country
      }
    }
  }
}
    `;

export const useCreateClientMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateClientMutation,
    TError,
    CreateClientMutationVariables,
    TContext
  >
) => {
  return useMutation<CreateClientMutation, TError, CreateClientMutationVariables, TContext>({
    mutationKey: ["createClient"],
    mutationFn: (variables?: CreateClientMutationVariables) =>
      customFetcher<CreateClientMutation, CreateClientMutationVariables>(
        CreateClientDocument,
        variables
      )(),
    ...options,
  });
};

export const ClientDuplicateDocument = `
    query clientDuplicate($input: CreateClientInput!) {
  duplicates(input: $input) {
    duplicates {
      id
      name
      firstName
      lastName
      dateOfBirth
      email
    }
  }
}
    `;

export const useClientDuplicateQuery = <TData = ClientDuplicateQuery, TError = unknown>(
  variables: ClientDuplicateQueryVariables,
  options?: Omit<UseQueryOptions<ClientDuplicateQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<ClientDuplicateQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<ClientDuplicateQuery, TError, TData>({
    queryKey: ["clientDuplicate", variables],
    queryFn: customFetcher<ClientDuplicateQuery, ClientDuplicateQueryVariables>(
      ClientDuplicateDocument,
      variables
    ),
    ...options,
  });
};

useClientDuplicateQuery.getKey = (variables: ClientDuplicateQueryVariables) => [
  "clientDuplicate",
  variables,
];

export const CreateAdjustmentPeriodDocument = `
    mutation createAdjustmentPeriod($input: CreateResourceAdjustmentPeriodInput!) {
  createResourceAdjustmentPeriod(input: $input) {
    resourceAdjustmentPeriod {
      id
    }
    conflictingPeriods {
      id
      space: location {
        id
        name
      }
      worker {
        id
        name
      }
      type
      from
      to
      notes
      createdBy {
        id
      }
    }
  }
}
    `;

export const useCreateAdjustmentPeriodMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateAdjustmentPeriodMutation,
    TError,
    CreateAdjustmentPeriodMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateAdjustmentPeriodMutation,
    TError,
    CreateAdjustmentPeriodMutationVariables,
    TContext
  >({
    mutationKey: ["createAdjustmentPeriod"],
    mutationFn: (variables?: CreateAdjustmentPeriodMutationVariables) =>
      customFetcher<CreateAdjustmentPeriodMutation, CreateAdjustmentPeriodMutationVariables>(
        CreateAdjustmentPeriodDocument,
        variables
      )(),
    ...options,
  });
};

export const UpdateAdjustmentPeriodDocument = `
    mutation updateAdjustmentPeriod($input: UpdateResourceAdjustmentPeriodInput!) {
  updateResourceAdjustmentPeriod(input: $input) {
    resourceAdjustmentPeriod {
      id
    }
    conflictingPeriods {
      id
      space: location {
        id
        name
      }
      worker {
        id
        name
      }
      type
      from
      to
      notes
      createdBy {
        id
      }
    }
  }
}
    `;

export const useUpdateAdjustmentPeriodMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateAdjustmentPeriodMutation,
    TError,
    UpdateAdjustmentPeriodMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateAdjustmentPeriodMutation,
    TError,
    UpdateAdjustmentPeriodMutationVariables,
    TContext
  >({
    mutationKey: ["updateAdjustmentPeriod"],
    mutationFn: (variables?: UpdateAdjustmentPeriodMutationVariables) =>
      customFetcher<UpdateAdjustmentPeriodMutation, UpdateAdjustmentPeriodMutationVariables>(
        UpdateAdjustmentPeriodDocument,
        variables
      )(),
    ...options,
  });
};

export const DeleteResourceAdjustmentPeriodDocument = `
    mutation deleteResourceAdjustmentPeriod($id: Long!) {
  deleteResourceAdjustmentPeriod(id: $id) {
    success
  }
}
    `;

export const useDeleteResourceAdjustmentPeriodMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteResourceAdjustmentPeriodMutation,
    TError,
    DeleteResourceAdjustmentPeriodMutationVariables,
    TContext
  >
) => {
  return useMutation<
    DeleteResourceAdjustmentPeriodMutation,
    TError,
    DeleteResourceAdjustmentPeriodMutationVariables,
    TContext
  >({
    mutationKey: ["deleteResourceAdjustmentPeriod"],
    mutationFn: (variables?: DeleteResourceAdjustmentPeriodMutationVariables) =>
      customFetcher<
        DeleteResourceAdjustmentPeriodMutation,
        DeleteResourceAdjustmentPeriodMutationVariables
      >(DeleteResourceAdjustmentPeriodDocument, variables)(),
    ...options,
  });
};

export const TimeslotsDocument = `
    query timeslots($input: TimeslotInput!) {
  timeslots(input: $input) {
    from
    workerIds
    spaceIds
    clientId
    serviceId
  }
}
    `;

export const useTimeslotsQuery = <TData = TimeslotsQuery, TError = unknown>(
  variables: TimeslotsQueryVariables,
  options?: Omit<UseQueryOptions<TimeslotsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<TimeslotsQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<TimeslotsQuery, TError, TData>({
    queryKey: ["timeslots", variables],
    queryFn: customFetcher<TimeslotsQuery, TimeslotsQueryVariables>(TimeslotsDocument, variables),
    ...options,
  });
};

useTimeslotsQuery.getKey = (variables: TimeslotsQueryVariables) => ["timeslots", variables];

export const GetCalendarItemsDocument = `
    query getCalendarItems($input: DateRangeInput!, $telehealthEnabled: Boolean!) {
  appointmentsForPeriod(input: $input) {
    ...Appointment
    telehealth @include(if: $telehealthEnabled)
    telehealthRoom @include(if: $telehealthEnabled) {
      id
      token
    }
  }
  resourceAdjustmentPeriodsBetween(input: $input) {
    ...ResourceAdjustmentPeriod
  }
  workers {
    ...WorkersAndTheirWorkingHours
  }
  spaces: locations {
    id
    name
  }
}
    ${AppointmentFragmentDoc}
${ResourceAdjustmentPeriodFragmentDoc}
${WorkersAndTheirWorkingHoursFragmentDoc}`;

export const useGetCalendarItemsQuery = <TData = GetCalendarItemsQuery, TError = unknown>(
  variables: GetCalendarItemsQueryVariables,
  options?: Omit<UseQueryOptions<GetCalendarItemsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetCalendarItemsQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<GetCalendarItemsQuery, TError, TData>({
    queryKey: ["getCalendarItems", variables],
    queryFn: customFetcher<GetCalendarItemsQuery, GetCalendarItemsQueryVariables>(
      GetCalendarItemsDocument,
      variables
    ),
    ...options,
  });
};

useGetCalendarItemsQuery.getKey = (variables: GetCalendarItemsQueryVariables) => [
  "getCalendarItems",
  variables,
];

export const CreateAppointmentDocument = `
    mutation createAppointment($input: CreateAppointmentInput!, $telehealthEnabled: Boolean!) {
  createAppointment(input: $input) {
    appointment {
      ...Appointment
      telehealth @include(if: $telehealthEnabled)
      telehealthRoom @include(if: $telehealthEnabled) {
        id
        token
      }
    }
    workerCollisions {
      id
      name
    }
    locationCollisions {
      id
      name
    }
    success
    message
  }
}
    ${AppointmentFragmentDoc}`;

export const useCreateAppointmentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateAppointmentMutation,
    TError,
    CreateAppointmentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateAppointmentMutation,
    TError,
    CreateAppointmentMutationVariables,
    TContext
  >({
    mutationKey: ["createAppointment"],
    mutationFn: (variables?: CreateAppointmentMutationVariables) =>
      customFetcher<CreateAppointmentMutation, CreateAppointmentMutationVariables>(
        CreateAppointmentDocument,
        variables
      )(),
    ...options,
  });
};

export const UpdateAppointmentDocument = `
    mutation updateAppointment($input: UpdateAppointmentInput!, $telehealthEnabled: Boolean!) {
  updateAppointment(input: $input) {
    appointment {
      ...Appointment
      telehealth @include(if: $telehealthEnabled)
      telehealthRoom @include(if: $telehealthEnabled) {
        id
        token
      }
    }
    workerCollisions {
      id
      name
    }
    locationCollisions {
      id
      name
    }
    success
    message
  }
}
    ${AppointmentFragmentDoc}`;

export const useUpdateAppointmentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateAppointmentMutation,
    TError,
    UpdateAppointmentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateAppointmentMutation,
    TError,
    UpdateAppointmentMutationVariables,
    TContext
  >({
    mutationKey: ["updateAppointment"],
    mutationFn: (variables?: UpdateAppointmentMutationVariables) =>
      customFetcher<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(
        UpdateAppointmentDocument,
        variables
      )(),
    ...options,
  });
};

export const UpdateClientDocument = `
    mutation updateClient($input: UpdateClientInput!) {
  updateClient(input: $input) {
    client {
      id
      name
      firstName
      lastName
      dateOfBirth
      phone
      email
    }
  }
}
    `;

export const useUpdateClientMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateClientMutation,
    TError,
    UpdateClientMutationVariables,
    TContext
  >
) => {
  return useMutation<UpdateClientMutation, TError, UpdateClientMutationVariables, TContext>({
    mutationKey: ["updateClient"],
    mutationFn: (variables?: UpdateClientMutationVariables) =>
      customFetcher<UpdateClientMutation, UpdateClientMutationVariables>(
        UpdateClientDocument,
        variables
      )(),
    ...options,
  });
};

export const GetServicesDocument = `
    query getServices($telehealthEnabled: Boolean!) {
  services {
    id
    name
    description
    duration
    price
    color
    requiredWorkers
    requiredSpaces
    workers {
      id
      name
      email
    }
    spaces {
      id
      name
    }
    defaultTelehealth @include(if: $telehealthEnabled)
  }
}
    `;

export const useGetServicesQuery = <TData = GetServicesQuery, TError = unknown>(
  variables: GetServicesQueryVariables,
  options?: Omit<UseQueryOptions<GetServicesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetServicesQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<GetServicesQuery, TError, TData>({
    queryKey: ["getServices", variables],
    queryFn: customFetcher<GetServicesQuery, GetServicesQueryVariables>(
      GetServicesDocument,
      variables
    ),
    ...options,
  });
};

useGetServicesQuery.getKey = (variables: GetServicesQueryVariables) => ["getServices", variables];
