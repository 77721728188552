import Flex from "../primitives/flex";

export const Main = Flex.withComponent("main");

Main.displayName = "Main";

Main.defaultProps = {
  height: "100%",
  width: "100%",
  overflow: "auto",
  id: "main",
};

export default Main;
