import "./index.css";
import "./i18n-config";

import { config } from "@noted/configuration";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import AuthProvider from "~/auth-provider";
import { intercomScriptInjector } from "~/third-parties/intercom";

import { NetworkLayer } from "./account/network-layer";
import App from "./App";
import { NavigationState } from "./layout/navigation.context";
import { ToastProvider } from "./shared/components/alerts/toast-provider";
import { AuthTimeoutChecker } from "./shared/components/auth-timeout/auth-timeout-checker";
import safeLocalStorage from "./shared/safe-local-storage";
import ThemeProvider from "./shared/theme/theme-provider";

if (config.sentryEnvironment) {
  Sentry.init({
    dsn: "https://87e353e6099a423db7dc896e75a4f1b6@sentry.io/1728956",
    environment: config.sentryEnvironment,
    beforeBreadcrumb(breadcrumb) {
      return breadcrumb.category === "console" ? null : breadcrumb;
    },
    release: `krios-${import.meta.env.VITE_COMMIT_SHA}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

if (config.intercomId) {
  intercomScriptInjector(config.intercomId);
}

Object.defineProperty(window, "localStorage", {
  value: safeLocalStorage,
  configurable: true,
  enumerable: true,
  writable: true,
});

const params = new URLSearchParams(window.location.search);
const session = params.get("s");
if (session) {
  // we have a session in the URL ...
  // Set Noted ID login preference for performing automatic login screen redirect
  window.localStorage.setItem("usesNotedIdLogin", "true");
  // extract the session value, store it and redirect to the root with no session value
  window.localStorage.setItem("ngStorage-session", `"${session}"`);
  window.location.href = "/";
} else {
  if (window.location.pathname === "/login") {
    window.localStorage.removeItem("ngStorage-session");
    window.localStorage.removeItem("ngStorage-token");
  }

  // no session in the URL, just render the app
  createRoot(document.getElementById("root") as HTMLElement).render(
    <ThemeProvider>
      <ToastProvider>
        <NavigationState>
          <BrowserRouter>
            <NetworkLayer>
              <AuthProvider>
                <App />
                <AuthTimeoutChecker />
              </AuthProvider>
            </NetworkLayer>
          </BrowserRouter>
        </NavigationState>
      </ToastProvider>
    </ThemeProvider>
  );
}
