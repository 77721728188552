import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { z } from "zod";

import { restFetcher, restPoster } from "~/graphql-hooks/custom-fetcher";
import { useI18n } from "~/hooks/use-i18n";
import { useToast } from "~/shared/components/alerts/toast-provider";

import { appointmentSchema } from "./appointments/types";
import { Reminder, ReminderSchema } from "./reminders/types";

const CLIENT_SEARCH_STALE_TIME = 10 * 1000; // Cache results for 10 seconds when re-searching

const dashboardDataRefetchSettings = {
  staleTime: 10 * 1000, // Cache data for 10 seconds if refocusing window or returning to page
  keepPreviousData: true, // Keep previous data until the new data is fetched
  refetchOnWindowFocus: true, // refetch stale queries when the window regains focus
  refetchOnMount: true, // or the app programmatically returns to the dashboard
};

// Unsigned Records
// -----------------------
export const CLIENT_UNSIGNED_ENDPOINT = "records/unsigned";

const unsignedRecordSchema = z.object({
  id: z.number(),
  type: z.string(),
  recordType: z.string(),
  client: z
    .object({ id: z.number(), firstName: z.string().optional(), lastName: z.string().optional() })
    .optional(),
  group: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
});

export type UnsignedRecord = z.infer<typeof unsignedRecordSchema>;

const unsignedRecordsResponse = z.array(unsignedRecordSchema);

type UnsignedRecordsResponse = z.infer<typeof unsignedRecordsResponse>;

export function useUnsignedRecordsQuery() {
  return useQuery({
    queryKey: [CLIENT_UNSIGNED_ENDPOINT],
    queryFn: async () => {
      const resp = await restFetcher<UnsignedRecordsResponse>(`/v1/${CLIENT_UNSIGNED_ENDPOINT}`);
      return unsignedRecordsResponse.parse(resp);
    },
    ...dashboardDataRefetchSettings,
  });
}

// Updated Records
// -----------------------

const updatedRecordSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  patientId: z.number(),
  recordsAdded: z.number(),
});

export const CLIENT_UPDATES_ENDPOINT = "users/clientupdates";

export const updatedRecordsResponse = z.array(updatedRecordSchema);

export type UpdatedRecordsResponse = z.infer<typeof updatedRecordsResponse>;

export function useUpdatedRecordsQuery() {
  return useQuery({
    queryKey: [CLIENT_UPDATES_ENDPOINT],
    queryFn: async () => {
      const resp = await restFetcher<UpdatedRecordsResponse>(`/v1/${CLIENT_UPDATES_ENDPOINT}`);
      return updatedRecordsResponse.parse(resp);
    },
    ...dashboardDataRefetchSettings,
  });
}

// Reminders
// -----------------------

export const GET_ALL_REMINDERS_ENDPOINT = "reminder/getAll";

export const allRemindersResponse = z.array(ReminderSchema);
export type AllRemindersResponse = z.infer<typeof allRemindersResponse>;

export function useAllRemindersQuery() {
  return useQuery({
    queryKey: [GET_ALL_REMINDERS_ENDPOINT],
    queryFn: async () => {
      const resp = await restFetcher<AllRemindersResponse>(`/v1/${GET_ALL_REMINDERS_ENDPOINT}`);
      return allRemindersResponse.parse(resp);
    },
    ...dashboardDataRefetchSettings,
  });
}

export function useUpdateReminderMutation() {
  const queryClient = useQueryClient();
  const { enqueueError, enqueueSuccess } = useToast();
  const { t } = useI18n("org");
  return useMutation({
    mutationFn: (body: Reminder) => restPoster(`/v1/reminder/update`, body),
    onError: () => {
      enqueueError(t("org:dashboard.reminders.edit.error"));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_ALL_REMINDERS_ENDPOINT] });
      enqueueSuccess(t("org:dashboard.reminders.edit.success"));
    },
  });
}

export function useCompleteReminderMutation() {
  const queryClient = useQueryClient();
  const { enqueueError, enqueueSuccess } = useToast();
  const { t } = useI18n("org");
  return useMutation({
    mutationFn: (body: { id: number }) => restPoster(`/v1/reminder/resolve`, body),
    onError: () => {
      enqueueError(t("org:dashboard.reminders.actions.complete.error"));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_ALL_REMINDERS_ENDPOINT] });
      enqueueSuccess(t("org:dashboard.reminders.actions.complete.success"));
    },
  });
}

export function useDeleteReminderMutation() {
  const queryClient = useQueryClient();
  const { enqueueError, enqueueSuccess } = useToast();
  const { t } = useI18n("org");
  return useMutation({
    mutationFn: (body: { id: number }) => restPoster(`/v1/reminder/delete`, body),
    onError: () => {
      enqueueError(t("org:dashboard.reminders.delete.error"));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_ALL_REMINDERS_ENDPOINT] });
      enqueueSuccess(t("org:dashboard.reminders.delete.success"));
    },
  });
}

// Appointments
// -----------------------

export const APPOINTMENTS_ENDPOINT = "appointments";

export const appointmentsResponse = z.array(appointmentSchema);

export type AppointmentsResponse = z.infer<typeof appointmentsResponse>;

export function useAppointmentsQuery() {
  return useQuery({
    queryKey: [APPOINTMENTS_ENDPOINT],
    queryFn: async () => {
      const resp = await restFetcher<AppointmentsResponse>(`/v1/${APPOINTMENTS_ENDPOINT}`);
      return appointmentsResponse.parse(resp);
    },
    ...dashboardDataRefetchSettings,
  });
}

// Invites
// -----------------------

export const INVITES_PENDING_ENDPOINT = "client-invites/pending";

const inviteSchema = z.object({
  id: z.number(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string(),
});

export type Invite = z.infer<typeof inviteSchema>;

export const invitesResponse = z.array(inviteSchema);

export type InvitesResponse = z.infer<typeof invitesResponse>;

export function usePendingInvitesQuery() {
  return useQuery({
    queryKey: [INVITES_PENDING_ENDPOINT],
    queryFn: async () => {
      const resp = await restFetcher<InvitesResponse>(`/v1/${INVITES_PENDING_ENDPOINT}`);
      return invitesResponse.parse(resp);
    },
    ...dashboardDataRefetchSettings,
  });
}

usePendingInvitesQuery.getKey = () => [INVITES_PENDING_ENDPOINT];

export const INVITES_REVIEW_ENDPOINT = "client-invites/review";

export function useReviewInvitesQuery() {
  return useQuery({
    queryKey: [INVITES_REVIEW_ENDPOINT],
    queryFn: async () => {
      const resp = await restFetcher<InvitesResponse>(`/v1/${INVITES_REVIEW_ENDPOINT}`);
      return invitesResponse.parse(resp);
    },
    ...dashboardDataRefetchSettings,
  });
}

// Client Search
// -----------------------

export const ARTEMIS_CLIENT_SEARCH_ENDPOINT = "clientsearch";

const clientSearchResultSchemaArtemis = z.object({
  id: z.number(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  preferredName: z.string().optional(),
  phone: z.string().optional(),
  email: z.string().optional(),
  address: z.string().optional(),
  clientId: z.string().optional(),
  medicalServiceId: z.string().optional(),
  dateOfBirth: z.string().optional(),
  currentStatus: z.object({ active: z.boolean() }).optional(),
  securePatientId: z.number().optional(),
  accessLevel: z.string().optional(),
  active: z.boolean().optional(),
});

const clientSearchResponseArtemis = z.object({
  page: z.number(),
  results: z.array(clientSearchResultSchemaArtemis),
  pages: z.number(),
  count: z.number(),
  total: z.number(),
});

type ClientSearchResponseArtemis = z.infer<typeof clientSearchResponseArtemis>;

// This is the unified view of the two Client Search API responses, Artemis and Legacy.
// The API calls are come back as the "ClientSearchResponseArtemis" and "ClientSearchResponse" types
// and are transformed into this type so the UI components do not need to understand that differences
// of the two API outputs.
// Key differences between this and the source format are:
//   1. Only fields used by the UI components are included.
//   2. dateOfBirth field is converted from a Date/String to a DateTime for consistences.
//   3. The active/isActive fields in the currentStatus object are unified to be currentStatus.active.
export type ClientSearchResult = {
  id: number;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  preferredName?: string;
  phone?: string;
  email?: string;
  address?: string;
  medicalServiceId?: string;
  dateOfBirth?: DateTime;
  currentStatus: { active: boolean };
};

type ClientSearchResultPages = {
  page: number;
  results: ClientSearchResult[];
};

interface ClientSearchQueryParams {
  searchTerm: string;
  page: number;
  options: {
    size: number;
    activeonly: boolean;
    searchAddresses: boolean;
    sortattr: string;
    sortdir: string;
  };
}

export function useClientSearchQuery({
  searchTerm,
  page,
  options,
}: ClientSearchQueryParams): UseQueryResult<ClientSearchResultPages, unknown> {
  const searchParams = new URLSearchParams({
    searchterm: searchTerm,
    page: `${page}`,
    size: `${options.size}`,
    activeonly: `${options.activeonly}`,
    searchAddresses: `${options.searchAddresses}`,
    sortattr: options.sortattr,
    sortdir: options.sortdir,
  });

  // Artemis Search
  const endpoint = `/v1/${ARTEMIS_CLIENT_SEARCH_ENDPOINT}?${searchParams.toString()}`;
  return useQuery({
    queryKey: [endpoint],
    queryFn: async () => {
      const resp = await restFetcher<ClientSearchResponseArtemis>(endpoint);
      return clientSearchResponseArtemis.parse(resp);
    },
    select: data => {
      return {
        ...data,
        results: data.results.map(r => {
          return {
            ...r,
            dateOfBirth: !r.dateOfBirth
              ? undefined
              : DateTime.fromFormat(r.dateOfBirth, "yyyy-MM-dd"),
            currentStatus: { active: Boolean(r.currentStatus?.active) },
          };
        }),
      };
    },
    staleTime: CLIENT_SEARCH_STALE_TIME,
    enabled: searchTerm.length > 0,
  });
}
