import { css } from "@emotion/core";
import type { HelpTextProps } from "@react-types/shared/src/inputs.d";
import type { ReactNode } from "react";
import type { FieldErrorProps, LabelProps } from "react-aria-components";
import { FieldError, Text } from "react-aria-components";

import { ncTheme } from "../../nc-theme";
import { NcFormattedMessage } from "../nc-formatted-message";
import { NcLabel } from "../nc-label";
import { NcVisuallyHidden } from "../nc-visually-hidden";

type FieldVariants = "horizontal" | "vertical";

const styles = {
  base: css`
    gap: ${ncTheme.spacing(2)};
    line-height: 1.25;
    justify-items: start;
  `,
  variant: {
    vertical: css`
      display: grid;
    `,
    horizontal: css`
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: baseline;
      column-gap: ${ncTheme.spacing(3)};
    `,
  },
  wrap: css`
    display: grid;
    gap: ${ncTheme.spacing(1)};
    align-items: start;
    width: 100%;
  `,
  message: css`
    font-size: ${ncTheme.fontSizes[1]};
    line-height: 1.25;
  `,
  error: css`
    color: ${ncTheme.colors.dangerActive};
  `,
  multi: css`
    ul {
      margin-block: 0;
    }
  `,
};

export const errorMessageStyles = [styles.error, styles.message];

export interface FieldLabelProps {
  label: string;
  labelNode?: ReactNode;
}
export interface FieldProps extends HelpTextProps, FieldLabelProps {
  name: string;
  variant?: FieldVariants;
}

type NcFieldProps = {
  label?: ReactNode | string;
  description?: ReactNode | string;
  children: ReactNode | ReactNode[];
  variant?: FieldVariants;
  isRequired?: boolean;
};

const FieldDescription = ({ children }: { children: ReactNode }) => {
  return children ? (
    <Text data-nc="NcField.Description" slot="description" css={styles.message}>
      {children}
    </Text>
  ) : (
    <></>
  );
};

const FieldErrorMessage = ({ ...props }: FieldErrorProps) => (
  <FieldError data-nc="NcField.ErrorMessage" {...props}>
    {({ validationErrors }) => {
      const firstError = validationErrors[0];
      if (!firstError) {
        return <></>;
      }
      return validationErrors.length === 1 ? (
        <NcFormattedMessage variant="danger" css={[styles.message]}>
          {firstError}
        </NcFormattedMessage>
      ) : (
        <NcFormattedMessage variant="danger" css={[styles.message, styles.multi]}>
          <ul>
            {validationErrors.map((error, index) => (
              <>
                <li key={index}>{error}</li>
                <li key={`a${index}`}>{error}</li>
              </>
            ))}
          </ul>
        </NcFormattedMessage>
      );
    }}
  </FieldError>
);

interface NcFieldLabelProps extends LabelProps {
  isRequired?: boolean;
}

export const NcFieldLabel = ({ children, isRequired, ...props }: NcFieldLabelProps) => {
  return (
    <NcLabel data-nc="NcField.Label" css={ncFieldLabelStyles.base} {...props}>
      {children}
      {isRequired && <RequiredAsterisk />}
    </NcLabel>
  );
};

const ncFieldLabelStyles = {
  base: css`
    gap: ${ncTheme.spacing(0.5)};
  `,
  requiredAsterisk: css`
    color: ${ncTheme.colors.dangerActive};
  `,
};

const RequiredAsterisk = () => {
  return (
    <span css={ncFieldLabelStyles.requiredAsterisk}>
      <span aria-hidden="true">*</span>
      <NcVisuallyHidden>(required)</NcVisuallyHidden>
    </span>
  );
};

export const NcField = ({
  label,
  description,
  children,
  variant = "vertical",
  isRequired,
}: NcFieldProps) => {
  return (
    <div data-nc="NcField" css={[styles.base, styles.variant[variant]]}>
      {label && <NcFieldLabel isRequired={isRequired}>{label}</NcFieldLabel>}
      <div css={styles.wrap}>
        {children}
        {description && <FieldDescription>{description}</FieldDescription>}
        <FieldErrorMessage />
      </div>
    </div>
  );
};
NcField.Description = FieldDescription;
