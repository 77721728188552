import { css } from "@emotion/core";
import type { ReactNode } from "react";

import { ncTheme } from "../nc-theme";

export const tagListStyles = {
  list: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${ncTheme.spacing(2)};
  `,
  tag: {
    base: css`
      color: ${ncTheme.colors.light};
      display: inline-flex;
      align-items: center;
      border-radius: ${ncTheme.borderRadius.rounded};
      vertical-align: baseline;
      font-weight: ${ncTheme.fontWeight.bold};
      line-height: 1.1; // allow for icon height
    `,
    content: css`
      display: inline-flex;
      gap: ${ncTheme.spacing(1.5)};
      padding-inline: ${ncTheme.spacing(3)};
      padding-block: ${ncTheme.spacing(2)};
    `,
    variant: {
      default: css`
        background-color: ${ncTheme.colors.main};
      `,
      neutral: css`
        background-color: ${ncTheme.colors.uiDark};
      `,
      success: css`
        background-color: ${ncTheme.colors.success};
      `,
      danger: css`
        background-color: ${ncTheme.colors.danger};
      `,
    },
  },
  error: css`
    color: ${ncTheme.colors.dangerActive};
  `,
};

type NcTagListProps = {
  children: ReactNode;
};

const NcTagList = ({ children, ...props }: NcTagListProps) => {
  return (
    <ul data-nc="NcTagList" css={tagListStyles.list} {...props}>
      {children}
    </ul>
  );
};

const Item = ({ children, ...props }: NcTagListProps) => {
  return (
    <li data-nc="NcTagList.Item" {...props}>
      {children}
    </li>
  );
};

type TagProps = {
  variant?: keyof typeof tagListStyles.tag.variant;
  children: ReactNode;
};

const Tag = ({ children, variant = "default", ...props }: TagProps) => {
  return (
    <span
      data-nc="NcTagList.Tag"
      css={css([tagListStyles.tag.base, tagListStyles.tag.variant[variant]])}
      {...props}
    >
      <span css={tagListStyles.tag.content}>{children}</span>
    </span>
  );
};

NcTagList.Item = Item;
NcTagList.Tag = Tag;

export { NcTagList };
