import styled from "../../theme";
import Box from "../primitives/box";

export const Table = styled(Box)`
  border-collapse: collapse;
`.withComponent("table");

Table.displayName = "Table";

Table.defaultProps = {
  width: "100%",
};

export default Table;
