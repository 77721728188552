import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { DateTime, Interval } from "luxon";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { IAccount, IOrganisation } from "~/shared/types";

import { useMeQuery } from "./use-me-query";

export interface IAccountContext {
  account: IAccount;
  canSchedule: boolean;
  organisation: IOrganisation;
  setOrganisation: Dispatch<SetStateAction<IOrganisation | undefined>>;
  isBillingEnabled: boolean;
  isUpgradable: boolean;
  isExpired: boolean;
  shouldChangePassword: boolean;
  clearShouldChangePassword: () => void;
  invalidate: () => Promise<void>;
  privilegeEnabled: (privilege: string) => boolean;
}

export const AccountContext = createContext<IAccountContext>({
  account: {} as IAccount,
  canSchedule: true,
  organisation: {} as IOrganisation,
  setOrganisation: () => {},
  isBillingEnabled: false,
  isUpgradable: true,
  isExpired: false,
  shouldChangePassword: false,
  clearShouldChangePassword: () => {},
  invalidate: () => Promise.resolve(),
  privilegeEnabled: _ => false,
});
export const useAccount = () => useContext(AccountContext);
export const useReadOnly = () => useContext(AccountContext)?.organisation?.readOnlyMode ?? true;
export const useIsNZ = () => useContext(AccountContext)?.organisation?.countryCode === "NZ";

export const AccountState = ({ children }: { children: ReactNode }) => {
  const [organisation, setOrganisation] = useState<IOrganisation>();

  const { data } = useMeQuery();
  const queryClient = useQueryClient();

  const account = data?.user;

  // FIXME: there is no need to store the Organisation in a state variable or to use a useEffect
  //  to extract it from the data.  React Query handles the cache for us, so using state is just
  //  double caching it.
  //  To remove we just need to replace the usage of setOrganisation outside of this component.
  useEffect(() => {
    if (data) {
      setOrganisation(data.organisation);
    }
  }, [data]);

  const [shouldChangePassword, setShouldChangePassword] = useState(false);

  const isBillingEnabled = determineBillingEnabledState(organisation);
  const isUpgradable = determineUpgradableState(organisation);
  const isExpired = determineExpireState(organisation);

  const canSchedule = !!account && account.securityRoles?.some(s => s.key === "WORKER");

  const privilegeEnabled = (privilege: string) => {
    const found = account?.privileges?.find(p => p.key === privilege);
    return Boolean(found);
  };

  useEffect(() => {
    if (account) {
      Sentry.configureScope(scope => {
        scope.setUser({
          id: String(account.id),
          username: account.username,
          name: account.firstName + " " + account.lastName,
          email: account.email,
          privileges: account.privileges,
          roles: account.securityRoles,
          teams: account.teams,
        });
        scope.setExtra("organisation", {
          id: organisation?.id,
          name: organisation?.tradingName || organisation?.legalName,
        });
      });
    }
  }, [account, organisation]);

  return (
    <AccountContext.Provider
      value={{
        account: account as IAccount,
        canSchedule,
        organisation: organisation as IOrganisation,
        setOrganisation,
        isBillingEnabled,
        isUpgradable,
        isExpired,
        shouldChangePassword,
        clearShouldChangePassword: () => setShouldChangePassword(false),
        invalidate: () => queryClient.invalidateQueries({ queryKey: useMeQuery.getKey() }),
        privilegeEnabled,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

function determineBillingEnabledState(organisation?: IOrganisation) {
  // Billing is enabled if the organisation has a billing link id, has no
  // trial end date, and is not manual billing.
  let isBillingEnabled = false;
  if (organisation) {
    isBillingEnabled =
      !!organisation &&
      !!organisation.billingLinkId &&
      !organisation.trialEnd &&
      !organisation.manualBilling;
  }

  return isBillingEnabled;
}

function determineUpgradableState(organisation?: IOrganisation) {
  // An organisation is upgradable if the organisation is not set to
  // manual billing and has a trial end date
  let isUpgradable = true;
  if (organisation) {
    isUpgradable = organisation.manualBilling ? false : !!organisation.trialEnd;
  }

  return isUpgradable;
}

function determineExpireState(organisation?: IOrganisation) {
  // An organisation is expired if the trial end date is in the past
  let isExpired = false;
  if (organisation) {
    const now = DateTime.local();
    const onTrial = Interval.fromDateTimes(now, new Date(organisation.trialEnd));
    isExpired = organisation.trialEnd ? !onTrial.isValid : false;
  }

  return isExpired;
}

export function canDeleteClientRecords(account: IAccount) {
  return account.privileges?.some(p => p.key === "PRIV_CAN_DELETE_CLIENT_RECORDS");
}
