import styled, { theme } from "../../theme";
import { Box, BoxProps } from "../primitives";

export const ErrorLoadingText = ({
  text,
  ...otherProps
}: BoxProps & {
  text?: string;
}) => {
  return (
    <Container role="presentation" style={{ fontSize: "1rem", padding: 10 }} {...otherProps}>
      {text}
    </Container>
  );
};

const Container = styled(Box)`
  color: ${theme.colors.danger.darkest};
  background-color: ${theme.colors.danger.light};
  border: solid 1px ${theme.colors.danger.mediumLight};
  border-radius: 3px;
  align-self: flex-start;
  flex-grow: 1;
`;
