import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconChevronDown = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={3.5}
      d="m20 9-7.072 6.657c-.486.457-1.37.457-1.856 0L4 9"
    />
  </NcIcon>
);
export { NcIconChevronDown };
