import { useI18n } from "~/hooks/use-i18n";

import { Flex, Text } from "../../shared/components/primitives";
import { SubscriptionTick } from "../../shared/icons";
import styled, { theme } from "../../shared/theme";

const Step = styled(Flex)`
  position: relative;
  font-weight: 600;
`;

Step.defaultProps = {
  flex: 1,
  flexDirection: "column",
  flexWrap: "nowrap",
  alignItems: "center",
  my: 3,
};

const StepLine = styled(Flex)<{ active: boolean }>`
  border-bottom: solid 4px ${theme.colors.neutral.medium};
  position: relative;
  &:after {
    z-index: 1;
    position: absolute;
    content: "";
    transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
    height: 4px;
    width: ${props => (props.active ? "100%" : 0)};
    background-color: ${theme.colors.primary.dark};
  }
`;

StepLine.defaultProps = {
  alignSelf: "flex-start",
  flex: 1,
  mt: 6,
};

interface ISubscriptionStepsProps {
  step: number;
}

const SubscriptionSteps = ({ step }: ISubscriptionStepsProps) => {
  const { t } = useI18n("admin-org");
  const packageComplete = step >= 2;
  const billingComplete = step >= 3;
  const purchaseComplete = step >= 4;
  const paymentConfirmed = step >= 4;
  return (
    <Flex my="4" justifyContent="space-between">
      <Step>
        <SubscriptionTick active={packageComplete} />
        <Text mt="4" textAlign="center">
          {t("admin-org:subscriptions.upgrade.select_plan")}
        </Text>
      </Step>
      <StepLine active={packageComplete} />
      <Step>
        <SubscriptionTick active={billingComplete} />
        <Text mt="4" textAlign="center">
          {t("admin-org:subscriptions.upgrade.billing_details")}
        </Text>
      </Step>
      <StepLine active={billingComplete} />
      <Step>
        <SubscriptionTick active={purchaseComplete} />
        <Text mt="4" textAlign="center">
          {t("admin-org:subscriptions.upgrade.purchase_subscription")}
        </Text>
      </Step>
      <StepLine active={purchaseComplete} />
      <Step>
        <SubscriptionTick active={paymentConfirmed} />
        <Text mt="4" textAlign="center">
          {t("admin-org:subscriptions.upgrade.upgrade_confirmed")}
        </Text>
      </Step>
    </Flex>
  );
};

export default SubscriptionSteps;
