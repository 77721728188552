import { DateTime, Interval } from "luxon";

import { IResourceAdjustmentPeriod } from "../../shared/types";
import { IResource } from "./resources-context";

const { fromDateTimes, merge } = Interval;
const { fromMillis } = DateTime;

export const getWorkingTime = (
  resource: IResource,
  date: DateTime,
  adjustmentPeriods: IResourceAdjustmentPeriod[]
): { workingTime?: Interval; period?: IResourceAdjustmentPeriod } => {
  const { available, unavailable } = getAdjustmentsByType(adjustmentPeriods);

  date = date.startOf("day");
  const todayInterval = fromDateTimes(date, date.endOf("day"));

  const leave = unavailable.find(({ interval }) => interval.overlaps(todayInterval));

  if (leave) {
    return { workingTime: undefined, period: leave };
  }

  const specialHours = (() => {
    const availableBlock = available
      .filter(({ interval }) => interval.overlaps(todayInterval))
      .sort((a, b) => b.id - a.id)[0];
    if (availableBlock) {
      const from = availableBlock.interval.start.set({
        year: date.year,
        month: date.month,
        day: date.day,
      });
      const to = availableBlock.interval.end.set({
        year: date.year,
        month: date.month,
        day: date.day,
      });
      availableBlock.interval = Interval.fromDateTimes(from, to);
      if (availableBlock.interval.isValid) {
        return { workingTime: availableBlock.interval, period: availableBlock };
      }
    }
  })();
  if (specialHours) {
    const { workingTime, period } = specialHours;
    return { workingTime, period };
  }

  return { workingTime: getWorkingHoursForDay(resource, date)[0], period: undefined };
};

export const getWorkingHoursForDay = ({ type, workingHours }: IResource, day: DateTime) => {
  const startOfDay = day.startOf("day");
  const endOfDay = day.endOf("day");
  switch (type) {
    case "space":
      return [Interval.fromDateTimes(startOfDay, endOfDay)];
    case "worker":
      return merge(
        workingHours
          .filter(wh => wh.weekday === day.weekday && wh.working)
          .map(wh =>
            fromDateTimes(
              startOfDay.set({ minute: wh.startTime }),
              startOfDay.set({ minute: wh.endTime })
            )
          )
      );
    default:
      return [];
  }
};

export const periodsByResource = (adjustmentPeriods: IResourceAdjustmentPeriod[]) =>
  adjustmentPeriods.reduce<{
    periodsById: {
      [key: string]: IResourceAdjustmentPeriod;
    };
    workers: { [resource: string]: IResourceAdjustmentPeriod[] };
    spaces: { [resource: string]: IResourceAdjustmentPeriod[] };
  }>(
    (res, period) => {
      const resourceType = period.worker ? "worker" : period.space ? "space" : "other";
      const periodsById = { ...res.periodsById, [period.id]: period };
      const partialResult = { ...res, periodsById };
      switch (resourceType) {
        case "worker": {
          const { workers } = res;
          const id = period.worker!.id;
          workers[id] = [...(workers[id] ? workers[id] : []), period];
          return { ...partialResult, workers };
        }
        case "space": {
          const { spaces } = res;
          const id = period.space!.id;
          spaces[id] = [...(spaces[id] ? spaces[id] : []), period];
          return { ...partialResult, spaces };
        }
        default:
          return partialResult;
      }
    },
    { periodsById: {}, workers: {}, spaces: {} }
  );

export const getAdjustmentsByType = (adjustmentPeriods?: IResourceAdjustmentPeriod[]) =>
  (adjustmentPeriods || []).reduce<{
    available: Array<IResourceAdjustmentPeriod & { interval: Interval }>;
    unavailable: Array<IResourceAdjustmentPeriod & { interval: Interval }>;
    blocked: Array<IResourceAdjustmentPeriod & { interval: Interval }>;
  }>(
    (res, period) => {
      const { type, from, to } = period;
      switch (type) {
        case "ALLOCATED": {
          return {
            ...res,
            available: [...res.available, { ...period, interval: intervalFromMillis(from, to) }],
          };
        }
        case "DEALLOCATED": {
          return {
            ...res,
            unavailable: [
              ...res.unavailable,
              { ...period, interval: intervalFromMillisAllDay(from, to) },
            ],
          };
        }
        case "BLOCKED":
          return {
            ...res,
            blocked: [...res.blocked, { ...period, interval: intervalFromMillis(from, to) }],
          };
        default:
          return res;
      }
    },
    { blocked: [], available: [], unavailable: [] }
  );

const intervalFromMillis = (from: number, to: number) =>
  fromDateTimes(fromMillis(from), fromMillis(to));

const intervalFromMillisAllDay = (from: number, to: number) =>
  fromDateTimes(fromMillis(from).startOf("day"), fromMillis(to).endOf("day"));
