import { css } from "@emotion/core";
import type { ReactNode } from "react";
import {
  GridList as ReactAriaGridList,
  GridListItem as ReactAriaGridListItem,
} from "react-aria-components";
import type {
  GridListItemProps as ReactAriaGridListItemProps,
  GridListProps as ReactAriaGridListProps,
  Selection,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";
import { NcIconTick } from "./nc-icons";
import { listBoxStyles } from "./nc-list-box";

const styles = {
  list: css`
    outline: none;
    &[data-orientation="horizontal"] {
      display: flex;
      flex-wrap: wrap;
    }

    &[data-empty="true"] {
      font-style: italic;
      padding: ${ncTheme.spacing(3)};
    }
  `,
  item: css`
    &[data-href] {
      cursor: pointer;
    }

    &:not(:last-of-type) {
      border-bottom: ${ncTheme.border()};
    }
  `,
  selection: css`
    display: none;
    border: ${ncTheme.border(ncTheme.colors.ui)};
    border-radius: ${ncTheme.borderRadius.small};
    height: 1.2em;
    transition: border ${ncTheme.transitionSpeed.medium};
    [data-selection-mode] & {
      display: initial;
    }
  `,
  selectionIcon: css`
    visibility: hidden;
    [aria-selected="true"] & {
      visibility: visible;
    }
    color: currentColor;
  `,
};

export type NcGridListSelection = Selection;
export interface NcGridListProps<T extends object> extends ReactAriaGridListProps<T> {}

const NcGridList = function <T extends object>({ ...props }: NcGridListProps<T>) {
  return <ReactAriaGridList data-nc="NcGridList" css={styles.list} {...props} />;
};

export type GridListItem = {
  id: number | string;
} & Record<string, string | number>;

export interface GridListItemProps extends ReactAriaGridListItemProps<GridListItem> {
  children: ReactNode;
  variant?: keyof typeof listBoxStyles.item.variant;
}

const GridListItem = ({
  children,
  variant = "default",
  textValue,
  ...props
}: GridListItemProps) => {
  const childTextValue = typeof children === "string" ? children : undefined;
  return (
    <ReactAriaGridListItem
      data-nc="NcGridList.Item"
      css={[listBoxStyles.item.base, listBoxStyles.item.variant[variant], styles.item]}
      textValue={textValue || childTextValue}
      {...props}
    >
      <div css={styles.selection}>
        <NcIconTick css={styles.selectionIcon} />
      </div>
      <>{children}</>
    </ReactAriaGridListItem>
  );
};

NcGridList.Item = GridListItem;

export { NcGridList };
