import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { customFetcher } from "~/graphql-hooks/custom-fetcher";
import * as Types from "~/graphql-hooks/types";

export type OrganisationWithSubscriptionQueryVariables = Types.Exact<{
  id: Types.Scalars["Long"]["input"];
}>;

export type OrganisationWithSubscriptionQuery = { __typename?: "Query" } & {
  organisationWithSubscription: { __typename?: "OrganisationWithSubscriptionPayload" } & {
    organisation: { __typename?: "Organisation" } & {
      featurePackage: { __typename?: "FeaturePackage" } & Pick<Types.FeaturePackage, "id" | "name">;
    };
    addons: Array<
      { __typename?: "FeatureAccessAddon" } & Pick<
        Types.FeatureAccessAddon,
        | "id"
        | "name"
        | "description"
        | "permissionName"
        | "enabled"
        | "addOn"
        | "unitPrice"
        | "totalPrice"
        | "billableUsers"
        | "chargeType"
        | "chargePeriod"
      >
    >;
    subscription: { __typename?: "SubscriptionInfo" } & Pick<
      Types.SubscriptionInfo,
      | "id"
      | "planPerUnitCosts"
      | "planName"
      | "planId"
      | "currencyCode"
      | "monthlyCost"
      | "totalBillableUsers"
      | "chargeModel"
    >;
  };
};

export type SubscriptionQueryVariables = Types.Exact<{
  estimateInput: Types.EstimateInput;
}>;

export type SubscriptionQuery = { __typename?: "Query" } & {
  packageChangeEstimate?: Types.Maybe<
    { __typename?: "SubscriptionInfo" } & Pick<
      Types.SubscriptionInfo,
      | "id"
      | "planPerUnitCosts"
      | "planName"
      | "planId"
      | "currencyCode"
      | "monthlyCost"
      | "totalBillableUsers"
      | "chargeModel"
    > & {
        addOns: Array<
          { __typename?: "SubscriptionAddOn" } & Pick<
            Types.SubscriptionAddOn,
            "id" | "name" | "description" | "price" | "chargeType"
          >
        >;
        coupons: Array<
          { __typename?: "SubscriptionCoupon" } & Pick<
            Types.SubscriptionCoupon,
            "id" | "name" | "discount" | "discountType" | "durationType"
          >
        >;
      }
  >;
};

export type PackagesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type PackagesQuery = { __typename?: "Query" } & {
  featurePackages: Array<
    { __typename?: "FeaturePackage" } & Pick<
      Types.FeaturePackage,
      "id" | "name" | "description" | "price" | "status"
    > & {
        packageFeatures: Array<
          { __typename?: "Feature" } & Pick<
            Types.Feature,
            "id" | "name" | "description" | "permissionName" | "enabled" | "countryCode"
          >
        >;
      }
  >;
};

export type BillingDetailsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BillingDetailsQuery = { __typename?: "Query" } & {
  billingDetails: { __typename?: "BillingDetailsDto" } & Pick<
    Types.BillingDetailsDto,
    | "firstName"
    | "lastName"
    | "addressLine1"
    | "addressLine2"
    | "addressLine3"
    | "city"
    | "state"
    | "postalCode"
    | "countryCode"
  >;
};

export type UpdateBillingDetailsMutationVariables = Types.Exact<{
  input: Types.BillingDetailsInput;
}>;

export type UpdateBillingDetailsMutation = { __typename?: "Mutation" } & {
  updateBillingDetails?: Types.Maybe<
    { __typename?: "BillingDetailsDto" } & Pick<
      Types.BillingDetailsDto,
      | "firstName"
      | "lastName"
      | "addressLine1"
      | "addressLine2"
      | "addressLine3"
      | "city"
      | "state"
      | "postalCode"
      | "countryCode"
    >
  >;
};

export type InvoicesQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.InvoiceQueryParams>;
}>;

export type InvoicesQuery = { __typename?: "Query" } & {
  invoices: { __typename?: "InvoiceQueryPayload" } & Pick<
    Types.InvoiceQueryPayload,
    "pageOffset"
  > & {
      results: Array<
        { __typename?: "InvoiceInfo" } & Pick<
          Types.InvoiceInfo,
          "id" | "status" | "amountDue" | "amountPaid" | "dueDate"
        >
      >;
    };
};

export type UpdateOrganisationPackageMutationVariables = Types.Exact<{
  id: Types.Scalars["Long"]["input"];
}>;

export type UpdateOrganisationPackageMutation = { __typename?: "Mutation" } & {
  updateOrganisationPackage?: Types.Maybe<
    { __typename?: "FeaturePackage" } & Pick<Types.FeaturePackage, "id">
  >;
};

export type UpdateOrganisationAddonFeaturesMutationVariables = Types.Exact<{
  features:
    | Array<Types.InputMaybe<Types.Scalars["Long"]["input"]>>
    | Types.InputMaybe<Types.Scalars["Long"]["input"]>;
}>;

export type UpdateOrganisationAddonFeaturesMutation = { __typename?: "Mutation" } & {
  updateOrganisationAddonFeatures?: Types.Maybe<
    { __typename?: "FeatureQueryPayload" } & {
      features: Array<{ __typename?: "Feature" } & Pick<Types.Feature, "id">>;
    }
  >;
};

export type PackageChangeEstimateQueryVariables = Types.Exact<{
  estimateInput: Types.EstimateInput;
}>;

export type PackageChangeEstimateQuery = { __typename?: "Query" } & {
  packageChangeEstimate?: Types.Maybe<
    { __typename?: "SubscriptionInfo" } & Pick<
      Types.SubscriptionInfo,
      | "id"
      | "planPerUnitCosts"
      | "planName"
      | "planId"
      | "currencyCode"
      | "monthlyCost"
      | "totalBillableUsers"
      | "chargeModel"
    > & {
        addOns: Array<
          { __typename?: "SubscriptionAddOn" } & Pick<
            Types.SubscriptionAddOn,
            "id" | "name" | "description" | "price" | "chargeType"
          >
        >;
        coupons: Array<
          { __typename?: "SubscriptionCoupon" } & Pick<
            Types.SubscriptionCoupon,
            "id" | "name" | "discount" | "discountType" | "durationType"
          >
        >;
      }
  >;
};

export type AddonsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AddonsQuery = { __typename?: "Query" } & {
  addonFeatures: { __typename?: "FeatureAddonQueryPayload" } & {
    features: Array<
      { __typename?: "FeatureAccessAddon" } & Pick<
        Types.FeatureAccessAddon,
        | "id"
        | "name"
        | "description"
        | "permissionName"
        | "enabled"
        | "addOn"
        | "unitPrice"
        | "totalPrice"
        | "billableUsers"
        | "chargeType"
        | "chargePeriod"
      >
    >;
  };
};

export type UserChangeEstimateQueryVariables = Types.Exact<{
  userCountUpdate: Types.Scalars["Int"]["input"];
}>;

export type UserChangeEstimateQuery = { __typename?: "Query" } & {
  userChangeEstimate?: Types.Maybe<
    { __typename?: "SubscriptionPayload" } & {
      addons: Array<
        { __typename?: "FeatureAccessAddon" } & Pick<
          Types.FeatureAccessAddon,
          | "id"
          | "name"
          | "description"
          | "permissionName"
          | "enabled"
          | "countryCode"
          | "orgLevelFeature"
          | "unitPrice"
          | "totalPrice"
          | "addOn"
          | "billableUsers"
          | "chargeType"
          | "chargePeriod"
        >
      >;
      subscription: { __typename?: "SubscriptionInfo" } & Pick<
        Types.SubscriptionInfo,
        | "currencyCode"
        | "planName"
        | "planId"
        | "monthlyCost"
        | "totalBillableUsers"
        | "totalUsers"
        | "planPerUnitCosts"
        | "chargeModel"
      > & {
          addOns: Array<
            { __typename?: "SubscriptionAddOn" } & Pick<
              Types.SubscriptionAddOn,
              "id" | "name" | "description" | "chargeType" | "price" | "totalPrice"
            >
          >;
          coupons: Array<
            { __typename?: "SubscriptionCoupon" } & Pick<
              Types.SubscriptionCoupon,
              "id" | "name" | "discountType" | "durationType" | "discount"
            >
          >;
        };
    }
  >;
};

export type UpgradeAccountMutationVariables = Types.Exact<{
  input: Types.UpgradeDetailsInput;
}>;

export type UpgradeAccountMutation = { __typename?: "Mutation" } & {
  upgradeAccount?: Types.Maybe<
    { __typename?: "UpgradePayload" } & Pick<Types.UpgradePayload, "firstName">
  >;
};

export const OrganisationWithSubscriptionDocument = `
    query organisationWithSubscription($id: Long!) {
  organisationWithSubscription(id: $id) {
    organisation {
      featurePackage {
        id
        name
      }
    }
    addons {
      id
      name
      description
      permissionName
      enabled
      addOn
      unitPrice
      totalPrice
      billableUsers
      chargeType
      chargePeriod
    }
    subscription {
      id
      planPerUnitCosts
      planName
      planId
      currencyCode
      monthlyCost
      totalBillableUsers
      chargeModel
    }
  }
}
    `;

export const useOrganisationWithSubscriptionQuery = <
  TData = OrganisationWithSubscriptionQuery,
  TError = unknown,
>(
  variables: OrganisationWithSubscriptionQueryVariables,
  options?: Omit<UseQueryOptions<OrganisationWithSubscriptionQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<OrganisationWithSubscriptionQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<OrganisationWithSubscriptionQuery, TError, TData>({
    queryKey: ["organisationWithSubscription", variables],
    queryFn: customFetcher<
      OrganisationWithSubscriptionQuery,
      OrganisationWithSubscriptionQueryVariables
    >(OrganisationWithSubscriptionDocument, variables),
    ...options,
  });
};

useOrganisationWithSubscriptionQuery.getKey = (
  variables: OrganisationWithSubscriptionQueryVariables
) => ["organisationWithSubscription", variables];

export const SubscriptionDocument = `
    query subscription($estimateInput: EstimateInput!) {
  packageChangeEstimate(estimateInput: $estimateInput) {
    id
    planPerUnitCosts
    planName
    planId
    currencyCode
    monthlyCost
    totalBillableUsers
    chargeModel
    addOns {
      id
      name
      description
      price
      chargeType
    }
    coupons {
      id
      name
      discount
      discountType
      durationType
    }
  }
}
    `;

export const useSubscriptionQuery = <TData = SubscriptionQuery, TError = unknown>(
  variables: SubscriptionQueryVariables,
  options?: Omit<UseQueryOptions<SubscriptionQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<SubscriptionQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<SubscriptionQuery, TError, TData>({
    queryKey: ["subscription", variables],
    queryFn: customFetcher<SubscriptionQuery, SubscriptionQueryVariables>(
      SubscriptionDocument,
      variables
    ),
    ...options,
  });
};

useSubscriptionQuery.getKey = (variables: SubscriptionQueryVariables) => [
  "subscription",
  variables,
];

export const PackagesDocument = `
    query packages {
  featurePackages {
    id
    name
    description
    price
    status
    packageFeatures {
      id
      name
      description
      permissionName
      enabled
      countryCode
    }
  }
}
    `;

export const usePackagesQuery = <TData = PackagesQuery, TError = unknown>(
  variables?: PackagesQueryVariables,
  options?: Omit<UseQueryOptions<PackagesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<PackagesQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<PackagesQuery, TError, TData>({
    queryKey: variables === undefined ? ["packages"] : ["packages", variables],
    queryFn: customFetcher<PackagesQuery, PackagesQueryVariables>(PackagesDocument, variables),
    ...options,
  });
};

usePackagesQuery.getKey = (variables?: PackagesQueryVariables) =>
  variables === undefined ? ["packages"] : ["packages", variables];

export const BillingDetailsDocument = `
    query billingDetails {
  billingDetails {
    firstName
    lastName
    addressLine1
    addressLine2
    addressLine3
    city
    state
    postalCode
    countryCode
  }
}
    `;

export const useBillingDetailsQuery = <TData = BillingDetailsQuery, TError = unknown>(
  variables?: BillingDetailsQueryVariables,
  options?: Omit<UseQueryOptions<BillingDetailsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<BillingDetailsQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<BillingDetailsQuery, TError, TData>({
    queryKey: variables === undefined ? ["billingDetails"] : ["billingDetails", variables],
    queryFn: customFetcher<BillingDetailsQuery, BillingDetailsQueryVariables>(
      BillingDetailsDocument,
      variables
    ),
    ...options,
  });
};

useBillingDetailsQuery.getKey = (variables?: BillingDetailsQueryVariables) =>
  variables === undefined ? ["billingDetails"] : ["billingDetails", variables];

export const UpdateBillingDetailsDocument = `
    mutation updateBillingDetails($input: BillingDetailsInput!) {
  updateBillingDetails(input: $input) {
    firstName
    lastName
    addressLine1
    addressLine2
    addressLine3
    city
    state
    postalCode
    countryCode
  }
}
    `;

export const useUpdateBillingDetailsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateBillingDetailsMutation,
    TError,
    UpdateBillingDetailsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateBillingDetailsMutation,
    TError,
    UpdateBillingDetailsMutationVariables,
    TContext
  >({
    mutationKey: ["updateBillingDetails"],
    mutationFn: (variables?: UpdateBillingDetailsMutationVariables) =>
      customFetcher<UpdateBillingDetailsMutation, UpdateBillingDetailsMutationVariables>(
        UpdateBillingDetailsDocument,
        variables
      )(),
    ...options,
  });
};

export const InvoicesDocument = `
    query invoices($input: InvoiceQueryParams) {
  invoices(input: $input) {
    pageOffset
    results {
      id
      status
      amountDue
      amountPaid
      dueDate
    }
  }
}
    `;

export const useInvoicesQuery = <TData = InvoicesQuery, TError = unknown>(
  variables?: InvoicesQueryVariables,
  options?: Omit<UseQueryOptions<InvoicesQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<InvoicesQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<InvoicesQuery, TError, TData>({
    queryKey: variables === undefined ? ["invoices"] : ["invoices", variables],
    queryFn: customFetcher<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, variables),
    ...options,
  });
};

useInvoicesQuery.getKey = (variables?: InvoicesQueryVariables) =>
  variables === undefined ? ["invoices"] : ["invoices", variables];

export const UpdateOrganisationPackageDocument = `
    mutation updateOrganisationPackage($id: Long!) {
  updateOrganisationPackage(id: $id) {
    id
  }
}
    `;

export const useUpdateOrganisationPackageMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateOrganisationPackageMutation,
    TError,
    UpdateOrganisationPackageMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateOrganisationPackageMutation,
    TError,
    UpdateOrganisationPackageMutationVariables,
    TContext
  >({
    mutationKey: ["updateOrganisationPackage"],
    mutationFn: (variables?: UpdateOrganisationPackageMutationVariables) =>
      customFetcher<UpdateOrganisationPackageMutation, UpdateOrganisationPackageMutationVariables>(
        UpdateOrganisationPackageDocument,
        variables
      )(),
    ...options,
  });
};

export const UpdateOrganisationAddonFeaturesDocument = `
    mutation updateOrganisationAddonFeatures($features: [Long]!) {
  updateOrganisationAddonFeatures(features: $features) {
    features {
      id
    }
  }
}
    `;

export const useUpdateOrganisationAddonFeaturesMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateOrganisationAddonFeaturesMutation,
    TError,
    UpdateOrganisationAddonFeaturesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateOrganisationAddonFeaturesMutation,
    TError,
    UpdateOrganisationAddonFeaturesMutationVariables,
    TContext
  >({
    mutationKey: ["updateOrganisationAddonFeatures"],
    mutationFn: (variables?: UpdateOrganisationAddonFeaturesMutationVariables) =>
      customFetcher<
        UpdateOrganisationAddonFeaturesMutation,
        UpdateOrganisationAddonFeaturesMutationVariables
      >(UpdateOrganisationAddonFeaturesDocument, variables)(),
    ...options,
  });
};

export const PackageChangeEstimateDocument = `
    query packageChangeEstimate($estimateInput: EstimateInput!) {
  packageChangeEstimate(estimateInput: $estimateInput) {
    id
    planPerUnitCosts
    planName
    planId
    currencyCode
    monthlyCost
    totalBillableUsers
    chargeModel
    addOns {
      id
      name
      description
      price
      chargeType
    }
    coupons {
      id
      name
      discount
      discountType
      durationType
    }
  }
}
    `;

export const usePackageChangeEstimateQuery = <TData = PackageChangeEstimateQuery, TError = unknown>(
  variables: PackageChangeEstimateQueryVariables,
  options?: Omit<UseQueryOptions<PackageChangeEstimateQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<PackageChangeEstimateQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<PackageChangeEstimateQuery, TError, TData>({
    queryKey: ["packageChangeEstimate", variables],
    queryFn: customFetcher<PackageChangeEstimateQuery, PackageChangeEstimateQueryVariables>(
      PackageChangeEstimateDocument,
      variables
    ),
    ...options,
  });
};

usePackageChangeEstimateQuery.getKey = (variables: PackageChangeEstimateQueryVariables) => [
  "packageChangeEstimate",
  variables,
];

export const AddonsDocument = `
    query addons {
  addonFeatures {
    features {
      id
      name
      description
      permissionName
      enabled
      addOn
      unitPrice
      totalPrice
      billableUsers
      chargeType
      chargePeriod
    }
  }
}
    `;

export const useAddonsQuery = <TData = AddonsQuery, TError = unknown>(
  variables?: AddonsQueryVariables,
  options?: Omit<UseQueryOptions<AddonsQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<AddonsQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<AddonsQuery, TError, TData>({
    queryKey: variables === undefined ? ["addons"] : ["addons", variables],
    queryFn: customFetcher<AddonsQuery, AddonsQueryVariables>(AddonsDocument, variables),
    ...options,
  });
};

useAddonsQuery.getKey = (variables?: AddonsQueryVariables) =>
  variables === undefined ? ["addons"] : ["addons", variables];

export const UserChangeEstimateDocument = `
    query userChangeEstimate($userCountUpdate: Int!) {
  userChangeEstimate(userCountUpdate: $userCountUpdate) {
    addons {
      id
      name
      description
      permissionName
      enabled
      countryCode
      orgLevelFeature
      unitPrice
      totalPrice
      addOn
      billableUsers
      chargeType
      chargePeriod
    }
    subscription {
      currencyCode
      planName
      planId
      monthlyCost
      totalBillableUsers
      totalUsers
      planPerUnitCosts
      chargeModel
      addOns {
        id
        name
        description
        chargeType
        price
        totalPrice
      }
      coupons {
        id
        name
        discountType
        durationType
        discount
      }
    }
  }
}
    `;

export const useUserChangeEstimateQuery = <TData = UserChangeEstimateQuery, TError = unknown>(
  variables: UserChangeEstimateQueryVariables,
  options?: Omit<UseQueryOptions<UserChangeEstimateQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<UserChangeEstimateQuery, TError, TData>["queryKey"];
  }
) => {
  return useQuery<UserChangeEstimateQuery, TError, TData>({
    queryKey: ["userChangeEstimate", variables],
    queryFn: customFetcher<UserChangeEstimateQuery, UserChangeEstimateQueryVariables>(
      UserChangeEstimateDocument,
      variables
    ),
    ...options,
  });
};

useUserChangeEstimateQuery.getKey = (variables: UserChangeEstimateQueryVariables) => [
  "userChangeEstimate",
  variables,
];

export const UpgradeAccountDocument = `
    mutation upgradeAccount($input: UpgradeDetailsInput!) {
  upgradeAccount(input: $input) {
    firstName
  }
}
    `;

export const useUpgradeAccountMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpgradeAccountMutation,
    TError,
    UpgradeAccountMutationVariables,
    TContext
  >
) => {
  return useMutation<UpgradeAccountMutation, TError, UpgradeAccountMutationVariables, TContext>({
    mutationKey: ["upgradeAccount"],
    mutationFn: (variables?: UpgradeAccountMutationVariables) =>
      customFetcher<UpgradeAccountMutation, UpgradeAccountMutationVariables>(
        UpgradeAccountDocument,
        variables
      )(),
    ...options,
  });
};
