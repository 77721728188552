import { css } from "@emotion/core";
import { ComponentProps } from "react";
import { NavLink } from "react-router-dom";

import { theme } from "../../theme";

const navLinkCss = {
  self: css`
    box-sizing: border-box;
    text-decoration: none;
    align-items: center;
    display: flex;
    white-space: nowrap;
    font-weight: 600;
    padding: 0.75rem 2rem 0.75rem 1.5rem;
    margin-bottom: 0.75rem;
    border-radius: 4px;
    transition: all 150ms ease;
    outline-color: ${theme.colors.info.mediumLight};
    &.active {
      font-weight: 700;
      position: relative;

      &::before {
        background-color: ${theme.colors.info.mediumLight};
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 5px;
      }
    }
    &:focus:not(.active):not(:active),
    &:hover:not(.active):not(:active) {
      cursor: pointer;
    }
  `,

  default: {
    self: css`
      color: ${theme.colors.neutral.lightest};
      &:hover,
      &:focus,
      &.active {
        background-color: ${theme.colors.info.medium};
      }
    `,
  },

  light: {
    self: css`
      color: ${theme.colors.info.medium};
      &:hover,
      &:focus {
        background-color: ${theme.colors.info.lightest};
      }

      &.active {
        color: ${theme.colors.neutral.lightest};
        background-color: ${theme.colors.info.mediumLight};

        &:before {
          display: none;
        }
      }
    `,
  },
};

export const SubLink = ({
  children,
  variant = "default",
  ...props
}: ComponentProps<typeof NavLink> & { variant?: "default" | "light" }) => {
  return (
    <NavLink {...props} css={[navLinkCss.self, navLinkCss[variant].self]}>
      {children}
    </NavLink>
  );
};
