import { NavLink } from "react-router-dom";

import styled, { theme } from "../../theme";

const StyledMenuLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  overflow: hidden;
  outline-color: ${theme.colors.primary.light};
  color: ${theme.colors.neutral.dark};
  transition: all 150ms ease;
  gap: 0.75rem;

  &.active {
    svg {
      fill: ${theme.colors.neutral.lightest};
    }
    position: relative;
    background-color: ${theme.colors.info.medium};
    color: ${theme.colors.neutral.lightest};
    font-weight: bold;
    &::before {
      background-color: ${theme.colors.info.mediumLight};
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      content: "";
      display: block;
      position: absolute;
      width: 0.25rem;
      top: 1px;
      bottom: 1px;
      left: 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: ${theme.colors.info.dark};
    color: ${theme.colors.neutral.lightest};
    z-index: 1;
  }
  svg {
    fill: ${theme.colors.neutral.darkest};
  }
  &:hover svg,
  &:focus svg,
  &:active svg {
    fill: ${theme.colors.neutral.lightest};
  }
`;

export const MenuLink = StyledMenuLink.withComponent(NavLink);
export const MenuLinkAnchor = StyledMenuLink.withComponent("a");
