import { ComponentProps, useEffect } from "react";

import styled from "../../theme";
import { Flex } from "../primitives";

const vhFn = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

const InnerShell = styled(Flex)`
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);

  @media print {
    height: inherit;
  }
`;

export const Shell = (props: ComponentProps<typeof InnerShell>) => {
  useEffect(() => {
    vhFn();
    window.addEventListener("resize", vhFn);
    return () => window.removeEventListener("resize", vhFn);
  }, []);
  return <InnerShell {...props} />;
};

Shell.displayName = "Shell";

Shell.defaultProps = {
  flexDirection: "column",
};

export default Shell;
