import { DateTime, Duration, Interval } from "luxon";

export interface ISelection {
  workerId?: number;
  spaceId?: number;
}

export interface IColumnisedTimeslots {
  [day: string]: {
    [resourceType: string]: { [id: string]: Interval[] };
  };
}

export interface ITimeslot {
  from: number;
  workerIds?: number[];
  spaceIds?: number[];
}

export interface ITimeslotsResponse {
  timeslots: ITimeslot[];
}

const accumulateIntervals = (
  interval: Interval,
  intervals: { [resourceId: string]: Interval[] },
  resourceIds?: number[],
  selectedResource?: number
) => {
  (resourceIds || [])
    .filter(rId => !selectedResource || selectedResource === rId)
    .forEach(rId => (intervals[rId] = Interval.merge([...(intervals[rId] || []), interval])));
  return intervals;
};

const INTERVAL_GAP = 5;

/**
 * This function is meant to be used inside the timeslots custom hook.
 * It's exported only for unit test purposes.
 */
export const filterBySelection = (
  selection: ISelection,
  data?: ITimeslotsResponse
): IColumnisedTimeslots => {
  const res: IColumnisedTimeslots = {};

  if (data) {
    data.timeslots
      // Step #1 - Filter timeslots if there is resource selection
      .filter(ts => {
        if (selection.workerId && ts.workerIds) {
          if (!ts.workerIds.includes(selection.workerId)) {
            return false;
          }
        }
        if (selection.spaceId && ts.spaceIds) {
          if (!ts.spaceIds.includes(selection.spaceId)) {
            return false;
          }
        }
        return true;
      })
      // Step #2 - Merge consecutive timeslots
      .forEach(ts => {
        const dateTime = DateTime.fromMillis(ts.from);
        const dayKey = dateTime.toFormat("yyyyLLdd");
        // Add 5 minutes to merge intervals
        // Timeslots come in 5 minutes intervals
        // Free timeslot1 at 10.00 needs to be grouped with free timeslot2 at 10.05
        // Make 5 minutes intervals of timeslots 1 and 2 are grouped together
        // [10.00 - 10.05] merged with [10.05 - 10.10] returns [10.00 - 10.10]
        const interval = Interval.after(dateTime, Duration.fromObject({ minutes: INTERVAL_GAP }));
        res[dayKey] = res[dayKey] || { workers: {}, spaces: {} };
        res[dayKey].workers = accumulateIntervals(
          interval,
          res[dayKey].workers,
          ts.workerIds,
          selection.workerId
        );
        res[dayKey].spaces = accumulateIntervals(
          interval,
          res[dayKey].spaces,
          ts.spaceIds,
          selection.spaceId
        );
      });
  }

  // Step #3 - Clean merged timeslots
  // Remove last 5 minutes of each merged interval
  // Timeslot1 10:00 and Timeslot2 10.05 belong to the same interval group
  // At this point, interval was saved as [10.00 - 10.10] and has to be mapped to [10.00 - 10.05]
  // Exceeding 5 minutes weren't correct
  Object.keys(res).forEach(key => {
    const workers: { [key: string]: Interval[] } = {};
    Object.keys(res[key].workers).forEach(wid => {
      workers[wid] = res[key].workers[wid].map(i =>
        Interval.fromDateTimes(i.start, i.end.minus({ minutes: INTERVAL_GAP }))
      );
    });
    const spaces: { [key: string]: Interval[] } = {};
    Object.keys(res[key].spaces).forEach(sid => {
      spaces[sid] = res[key].spaces[sid].map(i =>
        Interval.fromDateTimes(i.start, i.end.minus({ minutes: INTERVAL_GAP }))
      );
    });
    res[key] = {
      workers,
      spaces,
    };
  });
  return res;
};

/**
 * This function is meant to be used inside the timeslots custom hook.
 * It's exported only for unit test purposes.
 */
export const mergeColumnisedTimeslots = (res: IColumnisedTimeslots) =>
  Object.keys(res).reduce<{
    [day: string]: { intervals: Interval[]; workerIds: number[]; spaceIds: number[] };
  }>((d, dayKey) => {
    const workerIds = Object.keys(res[dayKey].workers).map(wId => Number(wId));
    const spaceIds = Object.keys(res[dayKey].spaces).map(sId => Number(sId));
    const workerIntervals = workerIds
      .map(wid => res[dayKey].workers[wid])
      .reduce<Interval[]>((group, intervals) => [...group, ...intervals], [])
      .map(wInterval => wInterval.union(Interval.after(wInterval.end, { minutes: INTERVAL_GAP })));
    const spacesIntervals = spaceIds
      .map(sid => res[dayKey].spaces[sid])
      .reduce<Interval[]>((group, intervals) => [...group, ...intervals], [])
      .map(sInterval => sInterval.union(Interval.after(sInterval.end, { minutes: INTERVAL_GAP })));
    const mergedIntervals = Interval.merge([...workerIntervals, ...spacesIntervals]).map(i =>
      Interval.fromDateTimes(i.start, i.end.minus({ minutes: INTERVAL_GAP }))
    );
    return {
      ...d,
      [dayKey]: {
        intervals: mergedIntervals,
        workerIds,
        spaceIds,
      },
    };
  }, {});
