import { createContext, ReactNode, useContext, useMemo } from "react";

import { FeaturePermission, type AvailableFeature, type Feature } from "~/graphql-hooks/types";

import { useFeaturesQuery } from "./api";

export interface IFeaturesContext {
  availableFeatures: Pick<AvailableFeature, "permissionName">[];
  editableFeatures: Pick<Feature, "permissionName" | "enabled" | "id" | "name" | "description">[];
  featuresLoaded: boolean;
  featureEnabled: (key: FeaturePermission) => boolean;
  refetchFeatures: () => void;
}

const FeatureContext = createContext<IFeaturesContext>({
  availableFeatures: [],
  editableFeatures: [],
  featuresLoaded: true,
  featureEnabled: () => false,
  refetchFeatures: () => null,
});

export const useFeatures = () => useContext(FeatureContext);

export const useIsTelehealthEnabled = () => {
  const { featureEnabled } = useFeatures();
  return featureEnabled(FeaturePermission.Telehealth);
};

export const FeatureState = ({ children }: { children: ReactNode }) => {
  const { data, refetch: refetchFeatures } = useFeaturesQuery();

  const availableFeatures = useMemo(() => data?.availableFeatures?.features ?? [], [data]);
  const editableFeatures = useMemo(() => data?.editableFeatures?.features ?? [], [data]);

  const value = useMemo(
    () => ({
      availableFeatures,
      editableFeatures,
      featuresLoaded: !!data,
      featureEnabled: (key: FeaturePermission) =>
        !!availableFeatures.find(f => f.permissionName === key),
      refetchFeatures,
    }),
    [availableFeatures, data, refetchFeatures]
  );

  return <FeatureContext.Provider value={value}>{children}</FeatureContext.Provider>;
};
