import css from "@emotion/css";
import { useState } from "react";

import { useI18n } from "~/hooks/use-i18n";
import { Button } from "~/shared/components/forms";
import { Box, Flex, PageTitle, Text } from "~/shared/components/primitives";
import { TickCircleOutline } from "~/shared/icons";
import { theme } from "~/shared/theme";

import SignupWorkflow from "./signup-workflow";

const styles = {
  header: css`
    background-color: ${theme.colors.primary.dark};
    background-image: url("/svg/signup-header-background.svg");
    text-align: center;
    padding-bottom: ${theme.space[6]};
    padding-top: ${theme.space[6]};
  `,
};

const InAppSignup = ({ onComplete }: { onComplete: () => void }) => {
  const { t } = useI18n("account");
  const [emailAccount, setEmailAccount] = useState<string>();

  return (
    <>
      <header css={styles.header}>
        <Box maxWidth="35rem" mx="auto" px="5" color="white">
          <PageTitle color="white" align="center">
            {t("account:signup.signup_title")}
          </PageTitle>
          <Text as="h2" fontSize="5" textAlign="center" mb="4">
            {t("account:signup.signup_trial")}
          </Text>
          <Text
            color="white"
            mb="6"
            as="p"
            fontSize="4"
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: t("account:signup.signup_description"),
            }}
          />
        </Box>
      </header>

      {emailAccount ? (
        <Box as="section" py="6">
          <Box maxWidth="40rem" mx="auto" px="5">
            <Flex alignItems="center" justifyContent="center" mb="4">
              <Box mr="3">
                <TickCircleOutline />
              </Box>
              <Text as="h2" fontSize="5">
                {t("account:signup.signup_success_title")}
              </Text>
            </Flex>
            <Text textAlign="center" fontSize="3">
              {t("account:signup.signup_success_message", { email: emailAccount })}
            </Text>
            <Flex mt="5">
              <Button variant="primary" onClick={onComplete} mx="auto">
                {t("account:signup.continue")}
              </Button>
            </Flex>
          </Box>
        </Box>
      ) : (
        <SignupWorkflow maxTries={3} onCompleted={({ email }) => setEmailAccount(email)} />
      )}
    </>
  );
};

export default InAppSignup;
