import { css } from "@emotion/core";
import type { NumberFieldProps as ReactAriaNumberFieldProps } from "react-aria-components";
import { Group, NumberField as ReactAriaNumberField } from "react-aria-components";
import { useFormContext } from "react-hook-form";

import { ncTheme } from "../../nc-theme";
import { useI18n } from "../../use-i18n";
import { NcButton } from "../nc-button";
import type { FieldProps } from "./nc-field";
import { NcField } from "./nc-field";
import type { PresetInputWidths } from "./nc-input";
import { NcInput } from "./nc-input";
import { useValidation } from "./use-validation";

const FORMAT_PRESETS: Record<string, Intl.NumberFormatOptions> = {
  default: {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  currency: {
    style: "currency",
    currency: "NZD",
    currencyDisplay: "narrowSymbol",
  },
  fixed2DP: {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  upTo2DP: {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  },
  percentage: {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  },
};

interface FieldNumberProps
  extends FieldProps,
    PresetInputWidths,
    Omit<ReactAriaNumberFieldProps, "name" | "value" | "validate" | "onBlur" | "autoFocus"> {
  showSteps?: boolean;
  formatType?: keyof typeof FORMAT_PRESETS;
}

const styles = {
  showSteps: css`
    display: inline-flex;
    width: fit-content;

    &[data-focus-within] {
      ${ncTheme.utilities.outlineStyles};
      outline: solid;
      ${ncTheme.utilities.outlineStyles}
      border-radius: ${ncTheme.borderRadius.medium};
      button {
        border-color: ${ncTheme.colors.active};
      }
    }

    input {
      appearance: textfield;
      border-radius: 0;

      &:focus-visible {
        outline: none;
      }

      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    button {
      font-weight: ${ncTheme.fontWeight.bold};
      font-size: ${ncTheme.fontSizes[5]};
      padding: 0 ${ncTheme.spacing(1.5)};
      border-color: ${ncTheme.colors.ui};
      width: 2.25rem;

      &:nth-of-type(1) {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:nth-of-type(2) {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  `,
};

const getFormatOptions = (formatType: keyof typeof FORMAT_PRESETS) => {
  return FORMAT_PRESETS[formatType] || FORMAT_PRESETS.default;
};

export const NcFieldNumber = ({
  label,
  labelNode,
  name,
  description,
  showSteps,
  isWheelDisabled = true,
  formatOptions,
  formatType = "default",
  inputWidth = "small",
  variant,
  ...props
}: FieldNumberProps) => {
  const { t } = useI18n();
  const {
    register,
    formState: { defaultValues },
    setValue,
  } = useFormContext();

  const { validationHandler } = useValidation({
    label: label,
    rules: {
      required: props?.isRequired ? {} : undefined,
    },
  });

  const field = register(name);

  const handleChange = (value: number) => {
    setValue(name, value, {
      shouldDirty: true,
      shouldValidate: true,
    });
    props?.onChange?.(value);
  };

  return (
    <ReactAriaNumberField
      data-nc="NcFieldNumber"
      isWheelDisabled={isWheelDisabled}
      formatOptions={{
        ...getFormatOptions(formatType),
        ...formatOptions, // supplied formatOptions will override the defaults from type
      }}
      {...props}
      {...field}
      validate={validationHandler}
      onChange={handleChange}
      defaultValue={defaultValues?.[name]}
    >
      <NcField
        {...{ description, label: labelNode || label, variant, isRequired: props.isRequired }}
      >
        <Group css={[...(showSteps ? [styles.showSteps] : [])]}>
          {showSteps && <NcButton slot="decrement">{t("-")}</NcButton>}
          <NcInput inputWidth={inputWidth} />
          {showSteps && <NcButton slot="increment">{t("+")}</NcButton>}
        </Group>
      </NcField>
    </ReactAriaNumberField>
  );
};
