import { css, Global, keyframes } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";
import type { ModalOverlayProps as ReactAriaModalOverlayProps } from "react-aria-components";
import { Heading, Dialog as ReactAriaDialog, Modal as ReactAriaModal } from "react-aria-components";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcButton } from "./nc-button";
import { NcFormattedMessage } from "./nc-formatted-message";
import { headingStyles } from "./nc-heading";
import { NcIconCross } from "./nc-icons";
import { NcLoadingIndicator } from "./nc-loading-indicator";

const animations = {
  modalSlideUp: keyframes`
        from {
            transform: translateY(3rem);
        }

        to {
            transform: translateX(0);
        }
    `,

  modalFade: keyframes`
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    `,
};

const styles = {
  global: css`
    .react-aria-ModalOverlay {
      position: fixed;
      inset: 0;
      z-index: 2;
      display: grid;
      place-items: center;
      background-color: ${ncTheme.colors.dark}80;
      &[data-entering] {
        animation: ${animations.modalFade} ${ncTheme.transitionSpeed.fast};
      }

      &[data-exiting] {
        animation: ${animations.modalFade} ${ncTheme.transitionSpeed.fast} reverse ease-in;
      }
    }
  `,
  modal: css`
    position: relative;
    outline: none;
    width: 100%;
    max-width: 40rem;
    max-height: calc(100vh - ${ncTheme.spacing(8)}); /* older browser fallback when no dvh unit */
    max-height: calc(100dvh - ${ncTheme.spacing(8)});
    margin: ${ncTheme.spacing(4)};
    border-radius: ${ncTheme.borderRadius.medium};
    border: ${ncTheme.border(ncTheme.colors.ui)};
    background-color: ${ncTheme.colors.light};
    color: ${ncTheme.colors.dark};
    box-shadow: ${ncTheme.shadows.xlarge};

    ${ncTheme.mediaQueries.width.small} {
      margin-inline: auto;
    }

    @media (prefers-reduced-motion: no-preference) {
      &[data-entering] {
        animation:
          ${animations.modalSlideUp} ${ncTheme.transitionSpeed.fast},
          ${animations.modalFade} ${ncTheme.transitionSpeed.fast};
      }

      &[data-exiting] {
        animation:
          ${animations.modalSlideUp} ${ncTheme.transitionSpeed.fast} reverse ease-in,
          ${animations.modalFade} ${ncTheme.transitionSpeed.fast} reverse ease-in;
      }
    }

    @media (prefers-reduced-motion: reduce) {
      &[data-entering] {
        animation: ${animations.modalFade} ${ncTheme.transitionSpeed.fast};
      }

      &[data-exiting] {
        animation: ${animations.modalFade} ${ncTheme.transitionSpeed.fast} reverse ease-in;
      }
    }

    section {
      outline: none;
    }
  `,
  dialog: css`
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - ${ncTheme.spacing(8)}); /* older browser fallback when no dvh unit */
    max-height: calc(100dvh - ${ncTheme.spacing(8)});
  `,
  close: css`
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    z-index: 1;
    padding: ${ncTheme.spacing(2)};
  `,
  header: css`
    padding: ${ncTheme.spacing(4)};
    border-bottom: ${ncTheme.border(ncTheme.colors.ui)};
  `,
  heading: [headingStyles.common, headingStyles.level[3]],
  body: css`
    padding: ${ncTheme.spacing(4)};
    overflow-y: auto;
    min-height: 0;
  `,
  loading: css`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${ncTheme.colors.brand};
    min-height: 20rem;
  `,
  footer: css`
    display: flex;
    gap: ${ncTheme.spacing(2)};
    justify-content: end;
    align-items: center;
    padding: ${ncTheme.spacing(4)};
    border-top: ${ncTheme.border(ncTheme.colors.ui)};
  `,
};

interface NcModalProps extends ReactAriaModalOverlayProps {
  children: ReactNode;
}

const NcModal = ({ children, isDismissable = true, onOpenChange, ...props }: NcModalProps) => {
  const { t } = useI18n();
  return (
    <>
      <Global styles={styles.global} />
      <ReactAriaModal
        data-nc="NcModal"
        css={styles.modal}
        isDismissable={isDismissable}
        onOpenChange={onOpenChange}
        {...props}
      >
        <ReactAriaDialog css={styles.dialog}>
          <NcButton
            variant="icon"
            onPress={() => onOpenChange?.(false)}
            aria-label={t("close")}
            css={styles.close}
          >
            <NcIconCross />
          </NcButton>
          {children}
        </ReactAriaDialog>
      </ReactAriaModal>
    </>
  );
};

const NcModalHeader = ({ ...props }) => (
  <div data-nc="NcModal.Header" css={styles.header} {...props} />
);

const ModalHeading = ({ children, ...props }: { children: ReactNode }) => (
  <Heading slot="title" css={styles.heading} {...props}>
    {children}
  </Heading>
);

interface NcModalBodyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  error?: boolean | string;
  empty?: boolean | string;
  isLoading?: boolean;
}

type NcModalBodyState = "LOADING" | "LOADED" | "EMPTY" | "ERROR";

function getPanelState({
  isLoading,
  empty,
  error,
}: Omit<NcModalBodyProps, "children">): NcModalBodyState {
  if (isLoading) return "LOADING";
  if (empty) return "EMPTY";
  if (error) return "ERROR";
  return "LOADED";
}

const NcModalBody = ({ children, isLoading, empty, error, ...props }: NcModalBodyProps) => {
  const state = getPanelState({ isLoading, empty, error });
  if (state === "LOADED") {
    return (
      <div data-nc="NcModal.Body" css={styles.body} {...props}>
        {children}
      </div>
    );
  }
  return (
    <div data-nc="NcModal.Body" css={styles.body}>
      {state === "LOADING" && (
        <div css={styles.loading}>
          <NcLoadingIndicator />
        </div>
      )}
      {state === "ERROR" && <NcPanelBodyError error={error} />}
      {state === "EMPTY" && <NcPanelBodyEmpty empty={empty} />}
    </div>
  );
};

const NcPanelBodyError = ({ error }: { error: NcModalBodyProps["error"] }) => {
  const { t } = useI18n();
  const errorText = typeof error === "string" ? error : t("error_default");
  return <NcFormattedMessage variant="danger">{errorText}</NcFormattedMessage>;
};

const NcPanelBodyEmpty = ({ empty }: { empty: NcModalBodyProps["empty"] }) => {
  const { t } = useI18n();
  const emptyText = typeof empty === "string" ? empty : t("empty_default");
  return <NcFormattedMessage variant="secondary">{emptyText}</NcFormattedMessage>;
};

const NcModalFooter = ({ ...props }) => (
  <div data-nc="NcModal.Footer" css={styles.footer} {...props} />
);

NcModal.Header = NcModalHeader;
NcModal.Body = NcModalBody;
NcModal.Footer = NcModalFooter;
NcModal.Heading = ModalHeading;

export { NcModal };
