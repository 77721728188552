import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";

import { useI18n } from "~/hooks/use-i18n";

import { Button } from "../../shared/components/forms";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../shared/components/modal";
import { DateInput, Feedback, Form, FormRow, Input, Label, TextArea } from "../../shared/ui";
import { Reminder, ReminderEdit, ReminderEditSchema } from "./types";

const RemindersEditModal = ({
  reminder,
  handleExit,
  onSaveReminder,
}: {
  reminder: Reminder;
  handleExit: () => void;
  onSaveReminder: (reminder: Reminder) => void;
}) => {
  const { t } = useI18n("org");

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ReminderEdit>({
    resolver: zodResolver(ReminderEditSchema),
    defaultValues: {
      title: reminder.title,
      description: reminder?.description ?? "",
      dueDate: new Date(reminder.dueDate),
    },
  });

  const onSubmit = handleSubmit(data => {
    onSaveReminder({
      sid: reminder.sid,
      id: reminder.id,
      title: data.title,
      description: data?.description,
      dueDate: data.dueDate.toISOString(),
      patientId: reminder.patientId,
      teamId: reminder.teamId,
      type: "REMINDER",
    });
  });

  return (
    <Modal handleExit={handleExit}>
      <ModalHeader>
        {t("org:dashboard.reminders.edit.heading")}: {reminder.patient?.firstName}{" "}
        {reminder.patient?.lastName}
      </ModalHeader>
      <ModalBody className="min-w-[30rem]">
        <Form id="reminderEditForm" onSubmit={onSubmit}>
          <FormRow>
            <Label htmlFor="title">{t("org:dashboard.reminders.edit.fields.title.label")}</Label>
            <Input {...register("title")} id="title" />
            {errors.title?.message && <Feedback>{t(errors.title.message)}</Feedback>}
          </FormRow>
          <FormRow>
            <Label htmlFor="description">
              {t("org:dashboard.reminders.edit.fields.description")}
            </Label>
            <TextArea {...register("description")} id="description" />
          </FormRow>
          <FormRow>
            <Label htmlFor="dueDate">
              {t("org:dashboard.reminders.edit.fields.due_date.label")}
            </Label>
            <Controller
              control={control}
              name="dueDate"
              render={({ field }) => (
                <DateInput
                  id="dueDate"
                  showTime={false}
                  value={field.value}
                  onDateSelect={date => field.onChange(date)}
                />
              )}
            />
            {errors.dueDate?.message && <Feedback>{t(errors.dueDate.message)}</Feedback>}
          </FormRow>
        </Form>
      </ModalBody>
      <ModalFooter className="flex justify-end gap-2">
        <Button type="button" onClick={handleExit}>
          {t("org:dashboard.reminders.edit.cancel")}
        </Button>
        <Button type="submit" variant="primary" form="reminderEditForm">
          {t("org:dashboard.reminders.edit.save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RemindersEditModal;
