import styled, { theme } from "../../theme";
import { Box } from "../primitives";

export const OverlayMessageBoxContent = styled(Box)``;

OverlayMessageBoxContent.defaultProps = {
  py: 4,
  px: 5,
  bg: theme.colors.neutral.lightest,
  flex: "1 1 auto",
};

export default OverlayMessageBoxContent;
