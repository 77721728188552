import ColorObj from "color";
import { ComponentProps } from "react";

import styled, { theme } from "../../theme";
import Box from "../primitives/box";

interface IColorProps extends Omit<ComponentProps<typeof Box>, "ref"> {
  size?: number | string;
  circleColor?: string;
}

export const Color = styled(Box)<IColorProps>`
  ${props => (props.size ? `height: ${props.size}rem; width: ${props.size}rem;` : "")}
  -webkit-touch-callout: none;
  -webkit-font-smoothing: subpixel-antialiased;
  border-radius: 50%;
  appearance: none;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  transition: all 0.1s ease-in-out;
  color: ${theme.colors.neutral.lightest};
  background-color: ${props => props.circleColor};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus,
  &:active {
    outline-color: ${props =>
      ColorObj(props.circleColor || "")
        .value(90)
        .desaturate(0.5)
        .value(90)
        .hex()};
    background-color: ${props => props.circleColor};
  }
  &:focus {
    z-index: 1;
  }
  &:disabled {
    background-color: ${props =>
      ColorObj(props.circleColor || "")
        .value(98)
        .desaturate(0.85)
        .value(98)
        .hex()};
    outline-color: ${theme.colors.info.mediumLight};
    cursor: not-allowed;
  }
`.withComponent("button");

Color.displayName = "Color";

Color.defaultProps = {
  circleColor: theme.colors.neutral.lightest,
  size: "1",
  type: "button",
};

export default Color;
