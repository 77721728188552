import { css } from "@emotion/core";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import type { LinkProps as ReactAriaLinkProps } from "react-aria-components";
import { Link as ReactAriaLink } from "react-aria-components";

import { ncTheme } from "../nc-theme";
import { buttonStyles } from "./nc-button";

const disabledStyles = css`
  &[aria-disabled="true"],
  &[data-disabled="true"],
  &:disabled {
    &,
    &:hover,
    &:focus,
    &:active {
      color: ${ncTheme.colors.disabled};
      cursor: not-allowed;
      text-decoration: none;
    }
  }
`;
const styles = {
  base: css`
    outline-style: none;
    &[data-focus-visible] {
      ${ncTheme.utilities.outlineStyles}
    }
  `,
  variant: {
    default: css`
      ${buttonStyles.link};
      font-weight: ${ncTheme.fontWeight.standard};

      ${disabledStyles};
    `,
    icon: css`
      ${buttonStyles.link};
      ${buttonStyles.variant.icon};
      font-weight: ${ncTheme.fontWeight.standard};

      ${disabledStyles};
    `,
    buttonSuccess: css`
      ${buttonStyles.base};
      ${buttonStyles.variant.primary}
      &:hover,
      &:focus,
      &[data-hovered="true"],
      &[data-focused="true"] {
        color: ${ncTheme.colors.light};
      }
    `,
    button: css`
      ${buttonStyles.base};
      ${buttonStyles.variant.default}
    `,
    buttonDanger: css`
      ${buttonStyles.base};
      ${buttonStyles.variant.danger}
    `,
    buttonIcon: css`
      ${buttonStyles.base};
      ${buttonStyles.variant.icon}
    `,
    buttonIconDanger: css`
      ${buttonStyles.base};
      ${buttonStyles.variant.iconDanger}
    `,
  },
};

export interface CommonLinkProps {
  variant?: keyof typeof styles.variant;
  isDisabled?: boolean;
  children: ReactNode;
}

export interface NcLinkProps extends Omit<ReactAriaLinkProps, "children">, CommonLinkProps {}

export const NcLink = forwardRef<HTMLAnchorElement, NcLinkProps>(
  ({ variant = "default", isDisabled, children, ...props }, ref) => {
    return (
      <ReactAriaLink
        data-nc="NcLink"
        ref={ref}
        css={[styles.base, styles.variant[variant]]}
        isDisabled={isDisabled}
        {...props}
      >
        {children}
      </ReactAriaLink>
    );
  }
);
