import { NcButton, NcModal } from "@noted/noted-components";
import { useEffect, useState } from "react";

import { useAuth } from "~/auth-provider";
import { useI18n } from "~/hooks/use-i18n";

export const AuthTimeoutModal = ({ handleExit }: { handleExit: () => void }) => {
  const { t } = useI18n();
  const { hasPassedExpiryThreshold, getToken, logout } = useAuth();
  const [hasExpired, setHasExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleContinue = () => {
    setIsLoading(true);
    getToken({
      cacheMode: "off",
    }).finally(() => {
      handleExit();
      setIsLoading(false);
    });
  };

  const handleLogout = () => {
    setIsLoading(true);
    logout().catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const expiryCheckInterval = setInterval(() => {
      const getExpiry = async () => {
        const expired = await hasPassedExpiryThreshold(0);
        if (expired !== hasExpired) {
          setHasExpired(expired);
        }
      };
      getExpiry().catch(console.error);
    }, 1000);

    const automaticLogoutTimeout = hasExpired ? setTimeout(() => handleLogout(), 30000) : null;

    return () => {
      clearInterval(expiryCheckInterval);
      if (automaticLogoutTimeout) {
        clearTimeout(automaticLogoutTimeout);
      }
    };
  }, [hasPassedExpiryThreshold]);

  return (
    <NcModal onOpenChange={handleExit} isOpen={true} className="max-w-[30rem]">
      {hasExpired ? (
        <>
          <NcModal.Header className="text-center">
            <NcModal.Heading>{t("timeoutWarning.expired.heading")}</NcModal.Heading>
          </NcModal.Header>
          <NcModal.Body className="grid gap-4 p-5 text-center">
            <IconExpired />
            <p className="text-xl">{t("timeoutWarning.expired.state")}</p>
          </NcModal.Body>
          <NcModal.Footer className="justify-center">
            <NcButton variant="primary" onPress={() => logout()}>
              {t("timeoutWarning.expired.action.continue")}
            </NcButton>
          </NcModal.Footer>
        </>
      ) : (
        <>
          <NcModal.Header className="text-center">
            <NcModal.Heading>{t("timeoutWarning.expiring.heading")}</NcModal.Heading>
          </NcModal.Header>
          <NcModal.Body className="grid gap-2 p-5 text-center">
            <IconExpiring />
            <p className="text-xl">{t("timeoutWarning.expiring.state")}</p>
            <p>{t("timeoutWarning.expiring.action.description")}</p>
          </NcModal.Body>
          <NcModal.Footer className="justify-center">
            <NcButton className="w-40" onPress={handleLogout} isDisabled={isLoading}>
              {t("timeoutWarning.expiring.action.end")}
            </NcButton>
            <NcButton
              className="w-40"
              variant="primary"
              onPress={handleContinue}
              isDisabled={isLoading}
            >
              {t("timeoutWarning.expiring.action.continue")}
            </NcButton>
          </NcModal.Footer>
        </>
      )}
    </NcModal>
  );
};

const IconExpiring = () => (
  <svg width="74" height="98" viewBox="0 0 74 98" className="mx-auto my-2 block">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2C0 0.89543 0.895431 0 2 0H72C73.1046 0 74 0.895431 74 2V5C74 6.10457 73.1046 7 72 7H70.9473C70.1822 25.0816 61.1694 40.2842 48.7508 46.2707C48.2758 46.4997 47.7958 46.7152 47.3112 46.9168C46.8778 47.0971 46.4407 47.2663 46 47.4243V50.5757C46.4407 50.7337 46.8778 50.9029 47.3112 51.0832C47.7958 51.2848 48.2758 51.5003 48.7508 51.7293C61.1694 57.7158 70.1822 72.9184 70.9473 91H72C73.1046 91 74 91.8954 74 93V96C74 97.1046 73.1046 98 72 98H2C0.89543 98 0 97.1046 0 96V93C0 91.8954 0.895431 91 2 91H3.05275C3.85332 72.0791 13.685 56.3107 27 50.9559V47.0441C13.685 41.6893 3.85332 25.9209 3.05275 7H2C0.89543 7 0 6.10457 0 5V2ZM65.9432 91H8.05691C8.85298 73.5879 17.9005 60.0046 28.8656 55.5948C30.7593 54.8332 32 52.997 32 50.9559V47.0441C32 45.003 30.7593 43.1668 28.8656 42.4052C17.9005 37.9954 8.85298 24.4121 8.05691 7H65.9432C65.1277 24.8648 55.6392 38.6587 44.3133 42.7174C42.3259 43.4295 41 45.3132 41 47.4243V50.5757C41 52.6868 42.3259 54.5705 44.3133 55.2826C55.6392 59.3413 65.1277 73.1352 65.9432 91Z"
      fill="#4E5864"
    />
    <path
      d="M22.6669 32C27.0092 36.7214 32.0686 39 37 39C41.9314 39 46.9908 36.7214 51.3331 32H22.6669Z"
      fill="#FFB366"
    />
    <circle cx="37" cy="44" r="2" fill="#FFB366" />
    <circle cx="37" cy="51" r="2" fill="#FFB366" />
    <circle cx="37" cy="58" r="2" fill="#FFB366" />
    <path
      d="M55.7281 83C54.4841 78.0514 52.4154 73.8032 49.9171 70.5334C45.7989 65.1434 41.1055 63 37 63C32.8945 63 28.2011 65.1434 24.0829 70.5334C21.5846 73.8032 19.5159 78.0514 18.2719 83H55.7281Z"
      fill="#FFB366"
    />
  </svg>
);

const IconExpired = () => (
  <svg width="90" height="98" viewBox="0 0 90 98" className="mx-auto block">
    <path
      d="M10 0C8.89543 0 8 0.89543 8 2V5C8 6.10457 8.89543 7 10 7H11.0528C11.8533 25.9209 21.685 41.6893 35 47.0441V50.9559C21.685 56.3107 11.8533 72.0791 11.0528 91H10C8.89543 91 8 91.8954 8 93V96C8 97.1046 8.89543 98 10 98H63.1029C60.3487 96.2608 58.0601 93.851 56.4666 91H16.0569C16.853 73.5879 25.9005 60.0046 36.8656 55.5948C38.7593 54.8332 40 52.997 40 50.9559V47.0441C40 45.003 38.7593 43.1668 36.8656 42.4052C25.9005 37.9954 16.853 24.4121 16.0569 7H73.9432C73.1277 24.8648 63.6392 38.6587 52.3133 42.7174C50.3259 43.4295 49 45.3132 49 47.4243V50.5757C49 52.6868 50.3259 54.5705 52.3133 55.2826C56.7678 56.8789 60.9381 59.9812 64.4083 64.2447C65.9455 63.4331 67.6042 62.8209 69.35 62.4425C65.8462 57.7376 61.5532 54.0443 56.7508 51.7293C56.2758 51.5003 55.7958 51.2848 55.3112 51.0832C54.8778 50.9029 54.4407 50.7337 54 50.5757V47.4243C54.4407 47.2663 54.8778 47.0971 55.3112 46.9168C55.7958 46.7152 56.2758 46.4997 56.7508 46.2707C69.1694 40.2842 78.1822 25.0816 78.9473 7H80C81.1046 7 82 6.10457 82 5V2C82 0.895431 81.1046 0 80 0H10Z"
      fill="#4E5864"
    />
    <path
      d="M57.6308 70.1652C53.5824 65.0456 49.0107 63 45 63C40.8945 63 36.2011 65.1434 32.0829 70.5334C29.5846 73.8032 27.5159 78.0514 26.2719 83H54.0568C54.0192 82.5049 54 82.0047 54 81.5C54 77.2726 55.3452 73.3595 57.6308 70.1652Z"
      fill="#FFB366"
    />
    <circle cx="73.5" cy="81.5" r="16.5" fill="#FFB366" />
    <path
      d="M73.65 88.15C72.693 88.15 72 88.843 72 89.8C72 90.757 72.693 91.45 73.65 91.45C74.6235 91.45 75.3 90.7735 75.3 89.8C75.3 88.8265 74.6235 88.15 73.65 88.15Z"
      fill="white"
    />
    <path d="M72.4455 84.85H74.8545L75.3 70H72L72.2228 77.425L72.4455 84.85Z" fill="white" />
  </svg>
);
