import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconCalendar = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#nc-icon-calendar_svg__a)">
      <rect width={20} height={18} x={2} y={4} stroke="currentColor" strokeWidth={2.5} rx={2} />
      <path
        fill="currentColor"
        d="M8.25 2a1.25 1.25 0 1 0-2.5 0zm-2.5 5v1.25h2.5V7zm0-5v5h2.5V2zM18.25 2a1.25 1.25 0 1 0-2.5 0zm-2.5 5v1.25h2.5V7zm0-5v5h2.5V2z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M22 11H2"
      />
      <path fill="currentColor" d="M7.2 16.2V14H5v2.2zM13.2 16.2V14H11v2.2zM19.2 16.2V14H17v2.2z" />
    </g>
    <defs>
      <clipPath id="nc-icon-calendar_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </NcIcon>
);
export { NcIconCalendar };
