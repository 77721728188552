import { NcButton, NcIconEnvelope, NcIconPlus, NcLink } from "@noted/noted-components";
import { useState } from "react";

import InviteClient from "~/dashboard/clients/invite-client";
import { useI18n } from "~/hooks/use-i18n";

import { useAccount } from "../../account/account.context";

const ClientActions = () => {
  const { t } = useI18n("org");
  const { account, organisation } = useAccount();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const canCreateClients = account.privileges?.find(
    privilege => privilege.key === "PRIV_CAN_CREATE_CLIENT"
  );

  return canCreateClients ? (
    <div
      data-testid="client-actions"
      className="flex items-center justify-center gap-2 border-t border-ui max-sm:mt-3 max-sm:flex-wrap max-sm:pt-3 sm:ml-3 sm:flex-col sm:border-l sm:border-t-0 sm:pl-4 xl:flex-row"
    >
      <NcLink
        variant="button"
        isDisabled={organisation.readOnlyMode}
        href="/n/clients/add"
        className="w-40"
      >
        <NcIconPlus />
        {t("org:dashboard.clients.add")}
      </NcLink>
      <NcButton onPress={() => setIsInviteModalOpen(true)} className="w-40">
        <NcIconEnvelope />
        {t("org:dashboard.clients.invite.title")}
      </NcButton>
      <InviteClient
        isInviteModalOpen={isInviteModalOpen}
        setIsInviteModalOpen={setIsInviteModalOpen}
      />
    </div>
  ) : (
    <></>
  );
};

export default ClientActions;
