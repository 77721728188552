import { ComponentProps } from "react";

import { LeftArrow } from "../../icons";
import { Flex, Text } from "../primitives";
import { Link } from "./link";

interface IBackToParent {
  to: string;
  text: string;
}

export const BackToParent = ({
  to,
  text,
  ...flexProps
}: IBackToParent & ComponentProps<typeof Flex>) => {
  return (
    <Flex alignSelf="self-start" {...flexProps}>
      <Link to={to}>
        <Flex alignItems="center">
          <LeftArrow />
          <Text ml="3" my="1" py="1">
            {text}
          </Text>
        </Flex>
      </Link>
    </Flex>
  );
};
