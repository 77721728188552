import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";
import { useMemo, useState } from "react";
import { useId } from "react-aria";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcButton } from "./nc-button";

const styles = {
  body: css`
    flex-wrap: wrap;
  `,
  toggle: css`
    order: 10;
    text-decoration: underline;
    font-size: 0.85em; // relative unit to respond to context
    font-weight: ${ncTheme.fontWeight.standard};
  `,
};

interface NcReadMoreProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode | string;
  summary?: ReactNode | string;
  summaryLength?: number;
  moreLabel?: string | ReactNode;
  lessLabel?: string | ReactNode;
}

export const NcReadMore = ({
  children,
  summary,
  summaryLength = 200,
  moreLabel,
  lessLabel,
  ...props
}: NcReadMoreProps) => {
  const { t } = useI18n();
  const sectionId = useId();
  const [isExpanded, setIsExpanded] = useState(false);
  const useAutoSummary = useMemo(() => typeof children === "string", [children]);
  const longEnough = String(children).length > summaryLength;

  if (useAutoSummary && !longEnough) {
    // no toggle if not exceeding summary length
    return (
      <div data-nc="NcReadMore" {...props}>
        {children}
      </div>
    );
  }
  const displaySummary = useAutoSummary ? `${String(children).slice(0, summaryLength)}…` : summary;
  return (
    <div data-nc="NcReadMore" id={sectionId} css={styles.body} aria-live="polite" {...props}>
      {isExpanded ? children : displaySummary}
      &nbsp;
      <NcButton
        variant="link"
        css={styles.toggle}
        onPress={() => setIsExpanded(!isExpanded)}
        aria-controls={sectionId}
        aria-expanded={isExpanded}
      >
        {isExpanded ? lessLabel || t("read less") : moreLabel || t("read more")}
      </NcButton>
    </div>
  );
};
