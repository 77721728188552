import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconExclamationTriangle = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.353 2.579c1.114-2.105 4.18-2.105 5.294 0l8.013 15.138C23.692 19.668 22.25 22 20.013 22H3.987c-2.238 0-3.68-2.332-2.647-4.283zM14 17a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-.5-10a1.5 1.5 0 0 0-3 0v4a1.5 1.5 0 0 0 3 0z"
      clipRule="evenodd"
    />
  </NcIcon>
);
export { NcIconExclamationTriangle };
