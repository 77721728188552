import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconEllipsis = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <circle cx={4} cy={12} r={3} fill="currentColor" />
    <circle cx={12} cy={12} r={3} fill="currentColor" />
    <circle cx={20} cy={12} r={3} fill="currentColor" />
  </NcIcon>
);
export { NcIconEllipsis };
