import { css } from "@emotion/core";
import { useEffect, useRef } from "react";

import { useI18n } from "~/hooks/use-i18n";

import { theme } from "../../theme";
import { Label } from "../../ui";
import { Button } from "../forms";
import { ActionsModal, ActionsModalProps } from "./actions-modal";

interface IConfirmActionModalProps extends Omit<ActionsModalProps, "children"> {
  title: string;
  message: string;
  onContinue: () => void;
  continueLabel: string;
  cancelLabel?: string;
  variant?: "primary" | "danger";
}

export function ConfirmActionModal({
  title,
  message,
  inProgress,
  onCancel,
  onContinue,
  continueLabel,
  cancelLabel,
  variant = "primary",
}: IConfirmActionModalProps) {
  const { t } = useI18n();
  const continueRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const continueButton = continueRef?.current;
    if (continueButton) {
      continueButton?.focus();
    }
  }, []);

  return (
    <ActionsModal onCancel={onCancel} inProgress={inProgress}>
      <div css={style}>
        <Label>{title}</Label>
        <div className="message">{message}</div>
        <div className="actions">
          <Button data-testid="confirm-cancel" onClick={onCancel}>
            {cancelLabel || t("cancel")}
          </Button>
          <Button
            data-testid="confirm-continue"
            ref={continueRef}
            onClick={onContinue}
            variant={variant}
          >
            {continueLabel}
          </Button>
        </div>
      </div>
    </ActionsModal>
  );
}

const style = css`
  .actions {
    align-items: baseline;
    display: flex;
    justify-content: flex-end;
    margin: ${theme.space[4]} 0 0;

    button + button {
      margin-left: ${theme.space[1]};
    }
  }

  .message {
    color: ${theme.colors.neutral.darkest};
    margin: ${theme.space[4]} 0 0 0;
    padding: ${theme.space[2]} 0;
    max-width: 25rem;
  }
`;
