import { captureMessage } from "@sentry/react";
import { DefaultError, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactNode } from "react";

import { clearToken } from "~/global-token";

declare module "@tanstack/react-query" {
  interface Register {
    defaultError: {
      status: number;
      code?: string;
      message?: string;
    };
  }
}

const NO_RETRY_RESPONSE_CODES = [401, 403, 409, 429, 451, 503];

function retry(maxTries = 0) {
  return (count: number, err: DefaultError) => {
    if (NO_RETRY_RESPONSE_CODES.includes(err.status)) {
      return false;
    }

    return count < Math.max(maxTries, 0);
  };
}

function isNotAuthenticatedError(err: DefaultError) {
  return err.status === 401;
}

function isHTTPError(err: DefaultError) {
  return err.status;
}

function sentryCaptureErrorState(error: DefaultError, query?: unknown) {
  if (!isHTTPError(error)) {
    // intercept error and report to sentry
    captureMessage(error.message || (error as { error?: string })?.error || "", {
      extra: {
        query,
        error,
      },
    });
  }
  return false; // false = return error as state
}

function handleNotAuthenticatedError(err: DefaultError) {
  if (isNotAuthenticatedError(err)) {
    clearToken();

    const path = location.pathname;
    location.href = path.startsWith("/login") ? path : `/login?redirectTo=${path}`;
  }
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => {
      handleNotAuthenticatedError(error);
    },
  }),
  defaultOptions: {
    queries: {
      retry: retry(3),
      refetchOnWindowFocus: false,
      throwOnError: (error, query) => sentryCaptureErrorState(error, query),
    },
    mutations: {
      retry: retry(),
      onError: handleNotAuthenticatedError,
      throwOnError: error => sentryCaptureErrorState(error),
    },
  },
});

export const NetworkLayer = ({ children }: { children: ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools position="bottom" />
    </QueryClientProvider>
  );
};
