import { css } from "@emotion/core";
import type { ReactNode } from "react";

import { ncTheme } from "../nc-theme";
import { NcIconChevronRight } from "./nc-icons";

const styles = {
  details: css`
    margin-left: ${ncTheme.spacing(5.75)};
  `,
  summary: css`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: ${ncTheme.spacing(1.5)};
    border-radius: ${ncTheme.borderRadius.rounded};
    margin-left: -${ncTheme.spacing(5.75)};

    &::marker,
    &::-webkit-details-marker {
      display: none;
    }

    &:focus-visible {
      ${ncTheme.utilities.outlineStyles}
    }

    details[open] > & {
      margin-bottom: ${ncTheme.spacing(2.5)};
    }
  `,
  summaryIcon: css`
    font-size: 0.85em;
    transition: transform ${ncTheme.transitionSpeed.fast};

    details[open] > summary > & {
      transform: rotate(90deg);
    }
  `,
};

interface NcCollapseProps extends React.DetailsHTMLAttributes<HTMLDetailsElement> {
  children: ReactNode;
}

export const NcCollapse = ({ children, ...props }: NcCollapseProps) => {
  return (
    <details data-nc="NcCollapse" css={styles.details} {...props}>
      {children}
    </details>
  );
};

const NcCollapseSummary = ({ children, ...props }: { children: ReactNode }) => {
  return (
    <summary data-nc="NcCollapse.Summary" css={styles.summary} {...props}>
      <NcIconChevronRight css={styles.summaryIcon} />
      {children}
    </summary>
  );
};

NcCollapse.Summary = NcCollapseSummary;
