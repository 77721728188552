import {
  alignContent,
  AlignContentProps,
  alignItems,
  AlignItemsProps,
  alignSelf,
  AlignSelfProps,
  background,
  BackgroundColorProps,
  BackgroundProps,
  borders,
  BordersProps,
  bottom,
  BottomProps,
  color,
  compose,
  display,
  DisplayProps,
  flex,
  flexBasis,
  FlexBasisProps,
  flexbox,
  FlexboxProps,
  flexDirection,
  FlexDirectionProps,
  FlexGrowProps,
  FlexProps,
  FlexShrinkProps,
  flexWrap,
  FlexWrapProps,
  fontFamily,
  FontFamilyProps,
  fontSize,
  FontSizeProps,
  fontStyle,
  FontStyleProps,
  fontWeight,
  FontWeightProps,
  gridArea,
  GridAreaProps,
  gridAutoColumns,
  GridAutoColumnsProps,
  gridAutoFlow,
  GridAutoFlowProps,
  gridAutoRows,
  GridAutoRowsProps,
  gridColumn,
  gridColumnGap,
  GridColumnGapProps,
  GridColumnProps,
  gridGap,
  GridGapProps,
  gridRow,
  gridRowGap,
  GridRowGapProps,
  GridRowProps,
  gridTemplateAreas,
  GridTemplateAreasProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  gridTemplateRows,
  GridTemplateRowsProps,
  height,
  HeightProps,
  justifyContent,
  JustifyContentProps,
  justifySelf,
  JustifySelfProps,
  left,
  LeftProps,
  letterSpacing,
  LetterSpacingProps,
  lineHeight,
  LineHeightProps,
  maxHeight,
  MaxHeightProps,
  maxWidth,
  MaxWidthProps,
  minHeight,
  MinHeightProps,
  minWidth,
  MinWidthProps,
  order,
  OrderProps,
  overflow,
  OverflowProps,
  position,
  PositionProps,
  right,
  RightProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  top,
  TopProps,
  verticalAlign,
  VerticalAlignProps,
  width,
  WidthProps,
  zIndex,
  ZIndexProps,
} from "styled-system";

import { styled } from "../../theme";

type StyleBoxProps = AlignContentProps &
  AlignItemsProps &
  AlignSelfProps &
  BackgroundColorProps &
  BackgroundProps &
  BordersProps &
  BottomProps &
  DisplayProps &
  FlexBasisProps &
  FlexboxProps &
  FlexDirectionProps &
  FlexGrowProps &
  FlexProps &
  FlexShrinkProps &
  FlexWrapProps &
  FontFamilyProps &
  FontSizeProps &
  FontStyleProps &
  FontWeightProps &
  GridAreaProps &
  GridAutoColumnsProps &
  GridAutoFlowProps &
  GridAutoRowsProps &
  GridColumnGapProps &
  GridColumnProps &
  GridGapProps &
  GridRowGapProps &
  GridRowProps &
  GridTemplateAreasProps &
  GridTemplateColumnsProps &
  GridTemplateRowsProps &
  HeightProps &
  JustifyContentProps &
  JustifySelfProps &
  LeftProps &
  LetterSpacingProps &
  LineHeightProps &
  MaxHeightProps &
  MaxWidthProps &
  MinHeightProps &
  MinWidthProps &
  OrderProps &
  OverflowProps &
  PositionProps &
  RightProps &
  SpaceProps &
  TextAlignProps &
  TopProps &
  WidthProps &
  VerticalAlignProps &
  ZIndexProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: any;
    variant?: string;
  };

export type BoxProps = StyleBoxProps;

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  position: relative;
  ${compose(
    alignContent,
    alignItems,
    alignSelf,
    background,
    borders,
    bottom,
    color,
    display,
    flex,
    flexBasis,
    flexbox,
    flexDirection,
    flexWrap,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    gridArea,
    gridAutoColumns,
    gridAutoFlow,
    gridAutoRows,
    gridColumn,
    gridColumnGap,
    gridGap,
    gridRow,
    gridRowGap,
    gridTemplateAreas,
    gridTemplateColumns,
    gridTemplateRows,
    height,
    justifyContent,
    justifySelf,
    left,
    letterSpacing,
    lineHeight,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    order,
    overflow,
    position,
    right,
    space,
    textAlign,
    top,
    verticalAlign,
    width,
    zIndex
  )};
`;

Box.displayName = "Box";

export default Box;
