import {
  createContext,
  ReactNode,
  Reducer,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";

import { InterruptCreateOrEditActionModal } from "../interrupt-create-or-edit-action-modal";

/*
    What this context does:
    - Provides a way to determine the current "tab" and the "view" (component) within that tab.
    - Provides "back" functionality that can change both views and tabs. 
    - Providers "reset" functionality that cancels a "create" or "update" action and resets the
      view to the "default" view of the current tab.
    
    When a change of view is needed, consumers should pass the required view to "setView()",
    and listen for state change of "tab" and "view" (both may change).

    To cancel a create or update action call "reset()".
    To go "back" to the previous view, call "back()".

    What this context DOES NOT DO:
    - Handles state cleanup of view components, forms, and other contexts.
    - Warns you that you are about to override a previous create or update action with a new one.
      You can use "isDoingAction" to see whether there is already an action in progress.
      Do this before calling "setView()".
*/

type Tab = "CREATE" | "LIST" | "SEARCH";

// Every possible view of every tab.
export type View =
  | "CREATE"
  | "APPOINTMENT_LIST"
  | "UPCOMING_APPOINTMENT_SEARCH_BY_CLIENT"
  | "APPOINTMENT_CREATE_TIMESLOT_SEARCH"
  | "APPOINTMENT_RESCHEDULE_TIMESLOT_SEARCH"
  | "APPOINTMENT_TIMESLOT_SELECT"
  | "CREATE_APPOINTMENT_FORM"
  | "CREATE_APPOINTMENT_FORM_FROM_CALENDAR"
  | "RESCHEDULE_APPOINTMENT_FORM"
  | "APPOINTMENT_DETAILS"
  | "BLOCKED_TIME_CREATE"
  | "BLOCKED_TIME_EDIT";

// Default views
const createTabDefaultView: View = "CREATE";
const listTabDefaultView: View = "APPOINTMENT_LIST";
const searchTabDefaultView: View = "UPCOMING_APPOINTMENT_SEARCH_BY_CLIENT";

const getDefaultItemForTab = (tab: Tab) => {
  return {
    tab,
    view:
      tab === "CREATE"
        ? createTabDefaultView
        : tab === "LIST"
        ? listTabDefaultView
        : searchTabDefaultView,
    isDefaultView: true,
  };
};

const getCurrentTabViewOrDefault = (state: ITabView[]): ITabView => {
  if (!state.length) {
    // When state is empty, we are always on a default view
    // TODO: do we always want to start on "CREATE"?
    return getDefaultItemForTab("CREATE");
  }
  return state[state.length - 1];
};

interface IInterruptModalState {
  isModalVisible: boolean;
  onModalConfirm: () => void;
}

const initialModalState: IInterruptModalState = {
  isModalVisible: false,
  onModalConfirm: () => undefined,
};

interface IAgendaTabViewContext {
  tab: Tab;
  setTab: (tab: Tab, callback?: () => void, showWarningIfActionInProgress?: boolean) => void;
  view: View;
  setView: (view: View, callback?: () => void, showWarningIfActionInProgress?: boolean) => void;
  back: (callback?: () => void) => void;
  reset: (callback?: () => void) => void;
  showBackButton: boolean;
  showResetButton: boolean;
  isDoingAction: boolean;
}

export const AgendaTabViewContext = createContext<IAgendaTabViewContext>(
  undefined as unknown as IAgendaTabViewContext
);
export const useAgendaTabView = () => useContext(AgendaTabViewContext);

export const AgendaTabViewProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(tabViewReducer, []);
  const [interruptModal, setInterruptModal] = useState<IInterruptModalState>(initialModalState);

  const setTab = useCallback(
    (tab: Tab, callback?: () => void, showWarningIfActionInProgress?: boolean) => {
      const current = getCurrentTabViewOrDefault(state);
      const f = () => {
        if (callback) {
          callback();
        }
        dispatch({
          type: "SET_TAB",
          payload: tab,
        });
      };

      if (showWarningIfActionInProgress && current.isActionView) {
        // We want to show confirmation modal
        setInterruptModal({
          isModalVisible: true,
          onModalConfirm: f,
        });
      } else {
        f();
      }
    },
    [state]
  );

  const setView = useCallback(
    (view: View, callback?: () => void, showWarningIfActionInProgress?: boolean) => {
      const current = getCurrentTabViewOrDefault(state);
      const f = () => {
        if (callback) {
          callback();
        }
        dispatch({
          type: "SET_VIEW",
          payload: view,
        });
      };

      if (showWarningIfActionInProgress && current.isActionView) {
        // We want to show confirmation modal
        setInterruptModal({
          isModalVisible: true,
          onModalConfirm: f,
        });
      } else {
        f();
      }
    },
    [state]
  );

  const back = useCallback((callback?: () => void) => {
    if (callback) {
      callback();
    }
    dispatch({
      type: "BACK",
    });
  }, []);

  const reset = useCallback((callback?: () => void) => {
    if (callback) {
      callback();
    }
    dispatch({
      type: "RESET",
    });
  }, []);

  const currentItem = useMemo(() => getCurrentTabViewOrDefault(state), [state]);

  const value = useMemo(
    () => ({
      tab: currentItem.tab,
      setTab,
      view: currentItem.view,
      setView,
      back,
      reset,
      showBackButton: !!currentItem.showBackButton,
      showResetButton: !currentItem.isDefaultView,
      isDoingAction: !!currentItem.isActionView,
    }),
    [
      back,
      currentItem.isActionView,
      currentItem.isDefaultView,
      currentItem.showBackButton,
      currentItem.tab,
      currentItem.view,
      reset,
      setTab,
      setView,
    ]
  );

  return (
    <AgendaTabViewContext.Provider value={value}>
      {children}
      {interruptModal.isModalVisible && (
        <InterruptCreateOrEditActionModal
          handleExit={() => setInterruptModal(initialModalState)}
          onConfirm={() => {
            interruptModal.onModalConfirm();
            setInterruptModal(initialModalState);
          }}
        />
      )}
    </AgendaTabViewContext.Provider>
  );
};

// ------ Reducer below here ------

interface ITabView {
  tab: Tab;
  view: View;
  isDefaultView?: boolean; // determines a "home" view
  isActionView?: boolean; // is the view involved in stateful creating or updating
  showBackButton?: boolean;
}

interface ISetTabAction {
  type: "SET_TAB";
  payload: Tab;
}
interface ISetViewAction {
  type: "SET_VIEW";
  payload: View;
}
interface IBackAction {
  type: "BACK";
}
interface IResetAction {
  type: "RESET";
}
type TabViewActions = ISetTabAction | ISetViewAction | IBackAction | IResetAction;

const tabViewReducer: Reducer<ITabView[], TabViewActions> = (state, action) => {
  switch (action.type) {
    // Sets the tab and resets the view to default
    case "SET_TAB": {
      return [getDefaultItemForTab(action.payload)];
    }
    case "SET_VIEW": {
      switch (action.payload) {
        case "CREATE": {
          return [
            {
              tab: "CREATE",
              view: action.payload,
              isDefaultView: true,
            },
          ];
        }
        case "APPOINTMENT_LIST": {
          return [
            {
              tab: "LIST",
              view: action.payload,
              isDefaultView: true,
            },
          ];
        }
        case "UPCOMING_APPOINTMENT_SEARCH_BY_CLIENT": {
          return [
            {
              tab: "SEARCH",
              view: action.payload,
              isDefaultView: true,
            },
          ];
        }
        case "APPOINTMENT_CREATE_TIMESLOT_SEARCH":
        case "BLOCKED_TIME_CREATE": {
          return [
            ...state,
            {
              tab: "CREATE",
              view: action.payload,
              isActionView: true,
            },
          ];
        }
        case "APPOINTMENT_TIMESLOT_SELECT": {
          const currentTabViewItem = getCurrentTabViewOrDefault(state);
          return [
            ...state,
            {
              tab: currentTabViewItem.tab,
              view: action.payload,
              isActionView: true,
              showBackButton: true,
            },
          ];
        }
        case "CREATE_APPOINTMENT_FORM": {
          return [
            ...state,
            {
              tab: "CREATE",
              view: action.payload,
              isActionView: true,
              showBackButton: true,
            },
          ];
        }
        case "CREATE_APPOINTMENT_FORM_FROM_CALENDAR": {
          return [
            ...state,
            {
              tab: "CREATE",
              view: action.payload,
              isActionView: true,
            },
          ];
        }
        case "RESCHEDULE_APPOINTMENT_FORM": {
          const currentTabViewItem = getCurrentTabViewOrDefault(state);
          const tab = currentTabViewItem.tab === "CREATE" ? "LIST" : currentTabViewItem.tab;
          return [
            ...state,
            {
              tab,
              view: action.payload,
              isActionView: true,
              showBackButton: true,
            },
          ];
        }
        case "APPOINTMENT_DETAILS": {
          const currentTabViewItem = getCurrentTabViewOrDefault(state);
          const tab = currentTabViewItem.tab === "CREATE" ? "LIST" : currentTabViewItem.tab;
          return [
            // No need to spread state as we can't go "back", we can only "reset"
            {
              tab,
              view: action.payload,
            },
          ];
        }
        case "APPOINTMENT_RESCHEDULE_TIMESLOT_SEARCH": {
          const currentTabViewItem = getCurrentTabViewOrDefault(state);
          const tab = currentTabViewItem.tab === "CREATE" ? "LIST" : currentTabViewItem.tab;
          return [
            ...state,
            {
              tab,
              view: action.payload,
              isActionView: true,
              showBackButton: true,
            },
          ];
        }
        case "BLOCKED_TIME_EDIT": {
          const tab = "LIST";
          return [{ tab, view: action.payload, isActionView: true }];
        }
        default: {
          return state;
        }
      }
    }
    case "BACK": {
      return state.slice(0, -1);
    }
    case "RESET": {
      const currentTabViewItem = getCurrentTabViewOrDefault(state);
      return [getDefaultItemForTab(currentTabViewItem.tab)];
    }
    default: {
      return state;
    }
  }
};
