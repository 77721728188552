import css from "@emotion/css";
import { useQuery } from "@tanstack/react-query";

import { restFetcher } from "~/graphql-hooks/custom-fetcher";
import { useI18n } from "~/hooks/use-i18n";
import AlertMessageBox from "~/shared/components/message-box/alert-message-box";
import { theme } from "~/shared/theme";

import { LinkAsButton } from "../shared/components/buttons";
import { PagePublic, Shell } from "../shared/components/layout";
import {
  OverlayMessageBox,
  OverlayMessageBoxContent,
  OverlayMessageBoxHighlight,
} from "../shared/components/overlay-message-box";
import { PageTitle } from "../shared/components/primitives";
import { LogoIcon } from "../shared/icons";

const style = {
  container: css`
    display: grid;
    gap: ${theme.space[3]};
    margin-bottom: ${theme.space[3]};
  `,

  description: css`
    line-height: ${theme.lineHeight};
  `,
};

const ApiOnlyUser = () => {
  const { t } = useI18n("account");

  const { isLoading, isError } = useQuery({
    queryKey: ["api-user-health"],
    queryFn: () => restFetcher("/v1/api-user/health"),
  });

  return (
    <Shell>
      <PagePublic>
        <OverlayMessageBox>
          <OverlayMessageBoxHighlight>
            <LogoIcon />
          </OverlayMessageBoxHighlight>

          <OverlayMessageBoxContent data-testid="api-only-landing">
            <div css={style.container}>
              <PageTitle>{t("account:api_only_user.title")}</PageTitle>
              <p css={style.description}>{t("account:api_only_user.description")}</p>

              {!isError && !isLoading && (
                <AlertMessageBox>{t("account:api_only_user.ok")}</AlertMessageBox>
              )}
              {isError && (
                <AlertMessageBox variant="warning">
                  {t("account:api_only_user.error")}
                </AlertMessageBox>
              )}
            </div>

            <LinkAsButton to="/logout">{t("account:api_only_user.logout")}</LinkAsButton>
          </OverlayMessageBoxContent>
        </OverlayMessageBox>
      </PagePublic>
    </Shell>
  );
};

export default ApiOnlyUser;
