import { css } from "@emotion/core";
import { Popover } from "@headlessui/react";
import { config as notedConfig } from "@noted/configuration";
import { useContext } from "react";

import { useFeatures } from "~/account/features.context";
import { useAuth } from "~/auth-provider";
import { FeaturePermission } from "~/graphql-hooks/types";
import { useI18n } from "~/hooks/use-i18n";
import { ToggleButtonIcon } from "~/shared/components/choice/dropdown-search";
import { MenuLink, MenuLinkAnchor } from "~/shared/components/forms/menu-link";

import { AccountContext } from "../account/account.context";
import { Header } from "../shared/components/layout";
import styled, { BREAKPOINT_LARGE_QUERY, theme } from "../shared/theme";
import { NavigationContext } from "./navigation.context";

const wrapperStyles = css`
  display: flex;
  flex-grow: 1;
  padding: ${theme.space[4]};
  padding-left: ${theme.space[5]};
  align-items: center;
  gap: ${theme.space[3]};
`;

const desktopVisibleStyles = css`
  display: none;
  gap: ${theme.space[3]};
  @media ${BREAKPOINT_LARGE_QUERY} {
    display: flex;
    align-items: center;
  }
`;

const headerStyles = css`
  position: relative;

  .skip-links {
    background: ${theme.colors.neutral.lightest};
    display: flex;
    height: 100%;
    position: absolute;
    width: 0;
    align-items: center;
    overflow: hidden;

    &:focus-within {
      width: 50%;
      padding-left: ${theme.space[2]};
    }

    a {
      padding: ${theme.space[2]};
    }
  }

  @media print {
    display: none;
  }
`;

const userMenuStyles = css`
  .button {
    display: flex;
    align-items: center;
    gap: ${theme.space[3]};
    padding: 1rem 1.25rem;
    font-size: ${theme.fontSizes[2]};
    color: ${theme.colors.neutral.dark};
    transition:
      background-color 150ms,
      color 150ms;
    cursor: pointer;
    outline-color: ${theme.colors.info.mediumLight};
    outline-width: 0.15rem;
    outline-offset: 0.25rem;

    &:hover,
    &:focus,
    &[data-headlessui-state="open"] {
      background: ${theme.colors.neutral.light};
      color: ${theme.colors.primary.dark};
    }

    svg:last-of-type {
      position: relative;
      top: 0.1rem;
      margin-left: -${theme.space[2]};
    }
  }

  .menu {
    position: absolute;
    right: 0;
    background: ${theme.colors.neutral.lightest};
    border: 0.1rem solid ${theme.colors.neutral.medium};
    box-shadow: ${theme.shadows.popOver};
    border-radius: ${theme.borderRadius.small};
    margin: 0 ${theme.space[3]};
    padding: ${theme.space[1]} 0;
    max-width: unset !important;
    overflow: hidden;
    width: 13.375rem;
    z-index: 1;
    top: calc(100% + ${theme.space[1]});
  }

  .item {
    padding: ${theme.space[2]} ${theme.space[4]};
  }
`;

const AppHeader = () => {
  const { t } = useI18n();
  const { organisation } = useContext(AccountContext);

  return (
    <Header css={headerStyles}>
      <div className="skip-links">
        <a href="#main">{t("skip_to_main")}</a>
      </div>
      <div css={wrapperStyles}>
        <div css={desktopVisibleStyles}>
          <Logo />
          <div
            css={css`
              font-size: ${theme.fontSizes[3]};
            `}
          >
            {organisation.tradingName || organisation.legalName}
          </div>
        </div>
        <NavButton />
        <div
          css={css`
            margin: auto;
          `}
        />
      </div>
      <UserMenu />
    </Header>
  );
};

export default AppHeader;

const UserMenu = () => {
  const { t } = useI18n();
  const { account } = useContext(AccountContext);
  const { session } = useAuth();
  const { featureEnabled } = useFeatures();
  return (
    <Popover css={userMenuStyles}>
      <Popover.Button aria-haspopup className="button">
        <UserIcon />
        {account.firstName} {account.lastName}
        <ToggleButtonIcon />
      </Popover.Button>
      <Popover.Panel className="menu">
        <ul role="menu" aria-label={t("user_menu.label")}>
          <li role="menuitem">
            {featureEnabled(FeaturePermission.NotedIdAuthentication) ? (
              <Popover.Button as={MenuLink} className="item" to={"user-settings"}>
                {t("user_menu.settings")}
              </Popover.Button>
            ) : (
              <Popover.Button as={MenuLink} className="item" to={"/n/user/profile"}>
                {t("user_menu.settings")}
              </Popover.Button>
            )}
          </li>
          {session && (
            <li role="menuitem">
              <MenuLinkAnchor className="item" href={`${notedConfig.landingUrl}/users`}>
                {t("user_menu.switch_user")}
              </MenuLinkAnchor>
            </li>
          )}
        </ul>
      </Popover.Panel>
    </Popover>
  );
};

const UserIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 0C6.27223 0 0 6.27223 0 14C0 17.4378 1.24487 20.5787 3.28454 23.0109C3.56224 23.3174 3.83037 23.6238 4.1368 23.9207C6.66484 26.4487 10.1601 28 14 28C17.8399 28 21.3351 26.4487 23.8632 23.9207C24.1696 23.6142 24.4377 23.3078 24.7154 23.0109C26.7551 20.5787 28 17.4378 28 14C28.0096 6.27223 21.7373 0 14 0ZM14 7.61286C17.0164 7.61286 19.4774 10.0739 19.4774 13.0903C19.4774 16.1067 17.0164 18.5677 14 18.5677C10.9836 18.5677 8.52257 16.1067 8.52257 13.0903C8.52257 10.0739 10.9932 7.61286 14 7.61286ZM14 26.7839C10.4665 26.7839 7.2394 25.3187 4.9316 23.0109C6.51163 21.4022 8.70451 20.3967 11.1464 20.3967H16.8728C19.3051 20.3967 21.4979 21.4022 23.0875 23.0109C20.7606 25.3283 17.5335 26.7839 14 26.7839Z"
        fill="#27323F"
      />
    </svg>
  );
};

const Logo = () => {
  return (
    <svg
      width="119"
      height="24"
      viewBox="0 0 119 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.001 2.68143V0.548782H114.197V0.263428H116.131V0.548782H115.324V2.68143H115.001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.623 2.68143V0.263428H117.109L117.688 1.97555C117.74 2.13475 117.78 2.25377 117.803 2.33337C117.831 2.24551 117.874 2.11598 117.933 1.94589L118.517 0.263428H118.953V2.68143H118.641V0.657291L117.932 2.68143H117.64L116.934 0.622748V2.68143H116.623Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.7822 3.82513H101.649C106.363 3.82513 109.651 7.17405 109.651 11.9693C109.651 16.782 106.363 20.1425 101.649 20.1425H97.7822V3.82513ZM102.147 0H95.1718C94.4148 0 93.8015 0.643563 93.8015 1.43481V22.5648C93.8015 23.3561 94.4148 24 95.1718 24H102.147C108.621 24 113.888 18.6055 113.888 11.9693C113.888 5.37022 108.621 0 102.147 0V0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.6081 20.2311H80.5144C79.7358 20.2311 79.1013 20.8624 79.1013 21.6396V22.5757C79.1013 23.3606 79.742 24 80.5311 24H87.6081C88.3948 24 89.0363 23.3606 89.0363 22.5757V21.6555C89.0363 20.8705 88.3948 20.2311 87.6081 20.2311"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.6081 0H80.5311C79.742 0 79.1013 0.639345 79.1013 1.42587V2.36577C79.1013 3.13916 79.7358 3.77078 80.5144 3.77078H87.6081C88.3948 3.77078 89.0363 3.13105 89.0363 2.34452V1.42587C89.0363 0.639345 88.3948 0 87.6081 0"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.2802 12.4627V11.5425C85.2802 11.3563 85.2476 11.1755 85.1729 11.0009C84.9525 10.4596 84.4373 10.1127 83.8516 10.1127H77.6376V3.81702C77.6376 3.04362 76.9802 2.39346 76.2098 2.39346H74.9981C74.2211 2.39346 73.5688 3.04362 73.5688 3.81702V10.1127H67.3513C66.5599 10.1127 65.9185 10.7521 65.9185 11.5371V12.4576C65.9185 12.6477 65.9572 12.8324 66.025 12.9958C66.2465 13.5362 66.7648 13.887 67.3513 13.887H73.5688V20.1823C73.5688 20.953 74.2211 21.607 74.9981 21.607H76.2098C76.9802 21.607 77.6376 20.953 77.6376 20.1823V13.887H83.8516C84.6418 13.887 85.2802 13.2469 85.2802 12.4627"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.1835 2.32964V1.41644C68.1835 0.635286 67.5566 0 66.7862 0H50.6408C49.8715 0 49.2432 0.635286 49.2432 1.41644V2.32964C49.2432 3.11079 49.8715 3.74608 50.6408 3.74608H56.7208V22.5851C56.7208 23.3486 57.3643 24 58.1197 24H59.3047C60.0634 24 60.7039 23.3486 60.7039 22.5851V3.74608H66.7862C67.5566 3.74608 68.1835 3.11079 68.1835 2.32964"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0329 20.2077C31.5169 20.2077 27.8473 16.54 27.8473 12.029C27.8473 7.48653 31.5169 3.78776 36.0329 3.78776C40.5632 3.78776 44.2499 7.48653 44.2499 12.029C44.2499 16.54 40.5632 20.2077 36.0329 20.2077M36.0329 0C29.4257 0 24.0552 5.39745 24.0552 12.029C24.0552 18.7419 29.3143 24 36.0329 24C42.654 24 48.0405 18.6291 48.0405 12.029C48.0405 5.39745 42.654 0 36.0329 0"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9561 0H16.865C16.0934 0 15.4662 0.623501 15.4662 1.39016V16.0458L2.90716 0.487647C2.69039 0.21941 2.36295 0 2.01732 0H1.39882C0.627592 0 0 0.671118 0 1.42233V22.611C0 23.3656 0.638583 24 1.39882 24H2.48536C3.25696 24 3.88418 23.3765 3.88418 22.611V7.65923L16.2962 23.4038C16.6752 23.7743 17.2337 24 17.8121 24C18.5826 24 19.3534 23.3289 19.3534 22.5826V1.39016C19.3534 0.63631 18.7118 0 17.9561 0Z"
        fill="currentColor"
      />
    </svg>
  );
};

const NavButton = () => {
  const { t } = useI18n();
  const { open, setOpen } = useContext(NavigationContext);

  const styles = css`
    @media ${BREAKPOINT_LARGE_QUERY} {
      display: none;
    }
  `;

  return (
    <Burger
      css={styles}
      onClick={() => setOpen(!open)}
      aria-haspopup
      aria-controls="main-nav"
      aria-expanded={open}
      aria-label={open ? t("close_menu") : t("open_menu")}
    >
      <Top open={open} />
      <Middle open={open} />
      <Bottom open={open} />
    </Burger>
  );
};

const Burger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 6px;
  appearance: none;
  border: none;
  outline-color: ${theme.colors.info.mediumLight};
  overflow: hidden;
`;

interface IBurgerProps {
  open: boolean;
}

const Top = styled.div<IBurgerProps>`
  height: 2px;
  width: 20px;
  transition: all 0.2s ease;
  transform: ${props => (props.open ? "translateX(4px) rotate(45deg)" : "none")};
  transform-origin: top left;
  margin-bottom: 5px;
  background-color: ${theme.colors.neutral.dark};
`;

const Middle = styled.div<IBurgerProps>`
  height: 2px;
  width: 20px;
  transition: all 0.2s ease;
  opacity: ${props => (props.open ? 0 : 1)};
  background-color: ${theme.colors.neutral.dark};
  transform: ${props => (props.open ? "translateX(-16px)" : "none")};
`;

const Bottom = styled.div<IBurgerProps>`
  height: 2px;
  width: 20px;
  transition: all 0.2s ease;
  transform: ${props => (props.open ? "translateX(2px) rotate(-45deg)" : "none")};
  transform-origin: top left;
  margin-top: 5px;
  background-color: ${theme.colors.neutral.dark};
`;
