import styled, { theme } from "../../theme";
import Flex from "../primitives/flex";

export const PageContent = styled(Flex)`
  background-color: ${theme.colors.neutral.lightest};
`;

PageContent.displayName = "PageContent";

PageContent.defaultProps = {
  py: 5,
  mx: [5, 6],
};

export default PageContent;
