import { KeyboardEventHandler, MouseEventHandler, ReactNode, useEffect } from "react";

interface SearchPopoverLayoutProps {
  popoverOpen: boolean;
  closeAction: () => void;
  children: {
    search: ReactNode;
    controls?: ReactNode;
    results: ReactNode;
  };
}

const SearchPopoverLayout = ({ closeAction, popoverOpen, children }: SearchPopoverLayoutProps) => {
  const { controls, search, results } = children;

  const handleEsc = ((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeAction();
    }
  }) as unknown as KeyboardEventHandler<HTMLDivElement>;

  const handleClick = ((event: MouseEvent) => {
    // prevent clicks inside from bubbling up to body
    event.stopPropagation();
  }) as unknown as MouseEventHandler<HTMLDivElement>;

  useEffect(() => {
    const hasParent = window.parent;
    if (popoverOpen) {
      if (hasParent) {
        window.parent.document.body.addEventListener("click", closeAction);
      }
      document.body.addEventListener("click", closeAction);
      return;
    }
    if (hasParent) {
      document.body.removeEventListener("click", closeAction);
    }
    document.body.removeEventListener("click", closeAction);
  }, [popoverOpen]);

  return (
    <div
      className="relative flex flex-grow flex-col 2xl:grid 2xl:grid-cols-[1fr,auto] 2xl:items-center 2xl:gap-3"
      tabIndex={0}
      onKeyDown={handleEsc}
      onClick={handleClick}
    >
      <div className="relative">
        {search}
        {popoverOpen && (
          <div className="round-br-sm absolute left-0 right-0 top-9 z-[1] flex justify-center overflow-hidden rounded-bl-sm border border-ui bg-light shadow">
            {results}
          </div>
        )}
      </div>
      {controls && controls}
    </div>
  );
};

export default SearchPopoverLayout;
