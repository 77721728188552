export const config = {
  username: {
    pattern: /^[a-zA-Z0-9_-āēīōūĀĒĪŌŪ]*$/,
    minLength: 3,
    maxLength: 90,
  },
  password: {
    minLength: 15,
    maxLength: 255,
  },
  email: {
    pattern:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
};
