import { ComponentProps } from "react";

import { Text } from "../components/primitives";
import { theme } from "../theme";

const Feedback = ({
  variant = "error",
  ...props
}: ComponentProps<typeof Text> & {
  variant?: "error" | "info" | "neutral";
}) => {
  let color = "currentColor";

  switch (variant) {
    case "neutral": {
      color = theme.colors.neutral.dark;
      break;
    }
    case "info": {
      color = theme.colors.info.dark;
      break;
    }
    case "error":
    default: {
      color = theme.colors.danger.dark;
      break;
    }
  }

  return <Text fontSize="1" color={color} {...props} />;
};

export default Feedback;
