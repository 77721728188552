import { createContext, memo, ReactNode, useContext, useMemo } from "react";

import { type Location, type Service, type User, type WorkingHours } from "~/graphql-hooks/types";

import { useGetResourcesQuery } from "../api";

export interface IResource {
  id: number;
  name: string;
  workingHours: WorkingHours[];
  type: "worker" | "space";
  services: Service[];
}

export interface IResourcesContext {
  isLoading: boolean;
  isError: boolean;
  loadedOnce: boolean;
  workers: IResource[];
  spaces: IResource[];
}

const workerToResource = (w: User): IResource => {
  return {
    id: w.id,
    name: w.name ?? "",
    workingHours: (w.workingHours ?? []) as WorkingHours[],
    type: "worker",
    services: (w.services ?? []) as Service[],
  };
};

const spaceToResource = (s: Location): IResource => {
  return {
    id: s.id,
    name: s.name ?? "",
    workingHours: [],
    type: "space",
    services: (s.services ?? []) as Service[],
  };
};

const ResourcesContext = createContext<IResourcesContext>({
  isLoading: false,
  isError: false,
  loadedOnce: true,
  workers: [],
  spaces: [],
});
export const useResources = () => useContext(ResourcesContext);

export const ResourcesState = memo(({ children }: { children: ReactNode }) => {
  const { data, isError, isLoading } = useGetResourcesQuery();
  const value = useMemo(
    () => ({
      isLoading,
      isError,
      loadedOnce: !!data,
      workers: data ? (data?.workers?.results ?? []).map(workerToResource) : [],
      spaces: data ? (data?.spaces ?? []).map(spaceToResource) : [],
    }),
    [isLoading, isError, data]
  );
  return <ResourcesContext.Provider value={value}>{children}</ResourcesContext.Provider>;
});
ResourcesState.displayName = "ResourcesState";
