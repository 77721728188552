import { QueryClient, useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { useFeatures } from "~/account/features.context";
import { restFetcher, restPutter } from "~/graphql-hooks/custom-fetcher";
import { FeaturePermission } from "~/graphql-hooks/types";
import { IAccount, IOrganisation } from "~/shared/types";

import { assignSettings } from "./assign-settings-json";

type AccountSettingsJson = IAccount["settingsJson"];

interface IPriorMapAccount extends Omit<IAccount, "settingsJson"> {
  settingsJson?: string | null;
  clinicId: number;
}

const parseSettings = <T,>(value?: string | null): T => {
  if (typeof value !== "string") {
    return value as T;
  }
  try {
    return JSON.parse(value) as T;
  } catch {
    return undefined as T;
  }
};

export const useMeQuery = () => {
  const [userQuery, orgQuery] = useQueries({
    queries: [
      {
        queryKey: ["me", "user"],
        queryFn: () => restFetcher<IPriorMapAccount>("/v1/users/me"),
        refetchOnWindowFocus: false,
        select: account => {
          const { settingsJson: stringValue, ...rest } = account as IPriorMapAccount;
          return {
            ...rest,
            settingsJson: parseSettings<AccountSettingsJson>(stringValue),
          };
        },
      },
      {
        queryKey: ["me", "organisation"],
        queryFn: () => restFetcher<IOrganisation>("/v1/organisations/me"),
        refetchOnWindowFocus: false,
      },
    ],
  });

  const { data: userMeData, isError: isUserError, isLoading: userLoading } = userQuery;
  const { data: orgMeData, isError: isOrgError, isLoading: orgLoading } = orgQuery;

  const isError = useMemo(() => isUserError || isOrgError, [isUserError, isOrgError]);
  const isLoading = useMemo(() => orgLoading && userLoading, [orgLoading, userLoading]);
  const data = useMemo(
    () => (orgMeData && userMeData && { organisation: orgMeData, user: userMeData }) || undefined,
    [orgMeData, userMeData]
  );

  return useMemo(() => ({ data, isError, isLoading }), [data, isError, isLoading]);
};
useMeQuery.getKey = () => ["me"];

const setPartialSettingsCache = (
  queryClient: QueryClient,
  partialSettings?: AccountSettingsJson | null
) =>
  queryClient.setQueryData<IPriorMapAccount | undefined>(["me", "user"], cachedAccount => {
    const { settingsJson: current, ...rest } = (cachedAccount ?? {}) as IPriorMapAccount;
    const old = parseSettings(current) as AccountSettingsJson;
    const newSettings = assignSettings({ old, new: partialSettings });
    return { ...rest, settingsJson: JSON.stringify(newSettings) };
  });

export const useUserSettingsSetter = () => {
  const queryClient = useQueryClient();
  const { data } = useMeQuery();
  const { featureEnabled } = useFeatures();

  const notedIdEnabled = featureEnabled(FeaturePermission.NotedIdAuthentication);

  return useMutation({
    mutationFn: (settings: AccountSettingsJson) => {
      const id = data?.user?.id;

      if (notedIdEnabled) {
        const mergedSettings = assignSettings({
          old: data?.user.settingsJson ?? {},
          new: settings,
        });

        return restPutter(`/v2/users/${id}`, {
          id,
          organisationId: data?.user?.clinicId,
          title: data?.user?.title,
          firstName: data?.user?.firstName,
          lastName: data?.user?.lastName,
          email: data?.user?.email,
          phone: data?.user?.phone,
          registrationNumber: data?.user?.registrationNumber,
          shortCode: data?.user?.shortCode,
          isSuspended: data?.user?.suspended,
          securityRoles: data?.user?.securityRoles.map(({ key }) => ({ key })),
          privileges: data?.user?.privileges?.map(({ key }) => ({ key })),
          settings: mergedSettings,
        });
      }

      const mergedSettings = setPartialSettingsCache(queryClient, settings)?.settingsJson;
      return restPutter(`/v1/users/${id}`, {
        id,
        clinicId: data?.user.clinicId,
        username: data?.user?.username,
        firstName: data?.user?.firstName,
        lastName: data?.user?.lastName,
        email: data?.user?.email,
        settingsJson: mergedSettings,
      });
    },
    onMutate: accountSettings => {
      const me = queryClient.getQueryData<IPriorMapAccount>(["me", "user"]);
      if (me) {
        queryClient.setQueryData<IPriorMapAccount>(["me", "user"], {
          ...me,
          settingsJson: JSON.stringify(accountSettings),
        });
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["me", "user"] }),
  });
};
