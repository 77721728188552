import { createContext, ReactNode, RefObject, useContext, useMemo, useRef, useState } from "react";
import { useScroll } from "react-use-gesture";

import { Box } from "../../shared/components/primitives";
import styled from "../../shared/theme";

type CalendarType = {
  fixedHeader: ReactNode;
  fixedColumn: ReactNode;
  content: ReactNode;
  contentRef?: RefObject<HTMLDivElement>;
  children?: ReactNode;
};

const useScrollSync = (
  xAxisRef: RefObject<HTMLDivElement>,
  yAxisRef: RefObject<HTMLDivElement>
) => {
  const [scrolling, setScrolling] = useState(false);
  const bind = useScroll(({ first, last, xy: [left, top] }) => {
    if (xAxisRef.current && yAxisRef.current) {
      xAxisRef.current.scrollLeft = left;
      yAxisRef.current.scrollTop = top;
    }
    if (first) {
      setScrolling(true);
    }
    if (last) {
      setScrolling(false);
    }
  });
  return useMemo(() => ({ bind, scrolling }), [bind, scrolling]);
};

const CalendarContext = createContext({ scrolling: false });
export const useCalendar = () => useContext(CalendarContext);

export const Calendar = ({
  fixedHeader,
  fixedColumn,
  content,
  contentRef,
  children,
}: CalendarType) => {
  const yAxisRef = useRef<HTMLDivElement>(null);
  const xAxisRef = useRef<HTMLDivElement>(null);

  const { bind, scrolling } = useScrollSync(xAxisRef, yAxisRef);
  const value = useMemo(() => ({ scrolling }), [scrolling]);
  return (
    <CalendarContext.Provider value={value}>
      <Box
        width="100%"
        height="100%"
        margin="auto"
        maxWidth="100%"
        maxHeight="100%"
        display="grid"
        gridTemplateColumns="auto 1fr"
        gridTemplateRows="auto 1fr"
        overflow="hidden"
      >
        <TopLeftCell data-testid="white-box" />
        <FixedHeader ref={xAxisRef} paddingRight="1.11rem">
          {fixedHeader}
        </FixedHeader>
        <FixedColumn paddingBottom="1.11rem" ref={yAxisRef}>
          {fixedColumn}
        </FixedColumn>
        <Box overflow="scroll" ref={contentRef} {...bind()}>
          {content}
        </Box>
      </Box>
      {children}
    </CalendarContext.Provider>
  );
};

const TopLeftCell = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.colors.neutral.medium}`,
  borderBottom: `1px solid ${theme.colors.neutral.medium}`,
}));

const FixedHeader = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.neutral.medium}`,
  overflow: "hidden",
}));

const FixedColumn = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.colors.neutral.medium}`,
  overflow: "hidden",
}));
