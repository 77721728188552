import { useMemo, useState } from "react";
import { useDebounce } from "use-debounce";

import { config } from "../../administration/users/config";

export const usePasswordState = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [debouncedPassword] = useDebounce(password, 250);
  const [debouncedConfirmPassword] = useDebounce(confirmPassword, 250);

  const tooShort = debouncedPassword && debouncedPassword.length < config.password.minLength;
  const tooLong = debouncedPassword && debouncedPassword.length > config.password.maxLength;
  const mismatch = debouncedConfirmPassword && debouncedPassword !== debouncedConfirmPassword;

  const errors = useMemo(
    () =>
      tooLong || tooShort || mismatch
        ? {
            ...(tooLong ? { tooLong } : {}),
            ...(mismatch ? { mismatch } : {}),
            ...(tooShort ? { tooShort } : {}),
          }
        : undefined,
    [mismatch, tooLong, tooShort]
  );

  return useMemo(
    () => ({
      password,
      setPassword,
      confirmPassword,
      setConfirmPassword,
      errors,
    }),
    [confirmPassword, errors, password]
  );
};

export default usePasswordState;
