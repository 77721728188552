import { DateTime, Interval } from "luxon";
import { ComponentProps, forwardRef, KeyboardEvent } from "react";

import { MinutesInterval } from "../../functions/date-helper";
import { theme } from "../../theme";
import { default as Dropdown, eventValue } from "../../ui/select";
import { Box, Flex, Grid } from "../primitives";

interface ITimePickerProps extends Omit<ComponentProps<typeof Flex>, "onChange"> {
  onChange: (day: DateTime) => void;
  selectedDay: DateTime;
  interval: MinutesInterval;
  allowedIntervals?: Interval[];
  disabled?: boolean;
  id?: string;
  onConfirm?: () => void;
}

const validHour = (date: DateTime, allowedIntervals?: Interval[]) => {
  if (!allowedIntervals) {
    return true;
  } else {
    const from = date.startOf("hour");
    const to = from.endOf("hour");
    const intervalToCheck = Interval.fromDateTimes(from, to);
    return allowedIntervals.some(i => !!i.intersection(intervalToCheck));
  }
};
const validMinute = (date: DateTime, allowedIntervals?: Interval[]) => {
  if (!allowedIntervals) {
    return true;
  } else {
    const from = date.startOf("minute");
    const to = from.endOf("minute");
    const intervalToCheck = Interval.fromDateTimes(from, to);
    return allowedIntervals.some(i => !!i.intersection(intervalToCheck));
  }
};

export const TimePicker = forwardRef<HTMLDivElement, ITimePickerProps>((componentProps, ref) => {
  const {
    selectedDay,
    onChange,
    interval,
    allowedIntervals,
    disabled,
    id,
    onConfirm,
    ...flexProps
  } = componentProps;

  const hourColor = !validHour(selectedDay, allowedIntervals)
    ? theme.colors.neutral.mediumDark
    : undefined;
  const minuteColor = !validMinute(selectedDay, allowedIntervals)
    ? theme.colors.neutral.mediumDark
    : undefined;

  const onTimeSubmit = (e: KeyboardEvent) => {
    if (onConfirm && e.key === "Enter") {
      e.stopPropagation();
      e.preventDefault();
      onConfirm();
    }
  };

  return (
    <Flex alignItems="center" mr="2" mb="4" {...flexProps} ref={ref}>
      <Grid gridGap="1" gridTemplateColumns="repeat(2, 1fr)">
        <Flex alignItems="flex-end">
          <Dropdown
            value={padTime(selectedDay.hour)}
            onChange={e => onChange(selectedDay.set({ hour: Number(eventValue(e)) }))}
            style={{ color: hourColor }}
            mb="0"
            disabled={disabled}
            id={id}
            onKeyDown={onTimeSubmit}
          >
            {[...Array(24)]
              .map((_, i) => padTime(i))
              .map(h => (
                <option key={h} value={h}>
                  {h}
                </option>
              ))}
          </Dropdown>
        </Flex>
        <Box>
          <Dropdown
            value={padTime(selectedDay.minute)}
            onChange={e => onChange(selectedDay.set({ minute: Number(eventValue(e)) }))}
            style={{ color: minuteColor }}
            mb="0"
            disabled={disabled}
            onKeyDown={onTimeSubmit}
          >
            {[...Array(60 / interval)]
              .map((_, i) => padTime(i * interval))
              .map(m => (
                <option key={m} value={m}>
                  {m}
                </option>
              ))}
          </Dropdown>
        </Box>
      </Grid>
    </Flex>
  );
});
TimePicker.displayName = "TimePicker";

const padTime = (t: number) => (t < 10 ? `0${t}` : String(t));
