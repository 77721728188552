import { keyframes } from "@emotion/core";

import styled from "../../theme";
import { Box } from "../primitives";

const animation = ({ direction }: { direction?: "left" | "right" }) => {
  if (!direction) {
    return undefined;
  }
  if (direction === "right") {
    const keyframe = keyframes`
      0% {
        opacity: 0;
        transform: translateX(30px);
      }
      80% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
        transform: translateX(0px);
      }
    `;
    return `${keyframe} 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`;
  } else {
    const keyframe = keyframes`
      0% {
        opacity: 0;
        transform: translateX(-30px);
      }
      80% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
        transform: translateX(0px);
      }
    `;
    return `${keyframe} 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`;
  }
};

export const TransitionBox = styled(Box)<{ direction?: "left" | "right" }>(({ direction }) => ({
  animation: animation({ direction }),
}));
