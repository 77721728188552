import { NcFieldDate, NcForm } from "@noted/noted-components";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { restPutter } from "~/graphql-hooks/custom-fetcher";
import { useI18n } from "~/hooks/use-i18n";
import { PrimhdCodeQueryResponse } from "~/primhdv2/api";
import RecordDetailsPanel from "~/primhdv2/records/record-forms/record-details-panel";
import { useToast } from "~/shared/components/alerts/alerts";

import PrimhdCodeComboBox from "../../primhd-code-combo-box";
import { PrimhdActivityRecord } from "../../types";

type UpdateApi = Pick<
  PrimhdActivityRecord,
  "id" | "referralId" | "activityType" | "activitySetting" | "startDate" | "endDate"
>;

type ActivityFormFields = {
  startDate: Date;
  endDate: Date;
  activityId: string;
  activityType: string;
  activitySetting: string;
};

interface ActivityFormProps {
  record: PrimhdActivityRecord;
  formId: string;
  codes: PrimhdCodeQueryResponse;
  onRecordUpdate: () => void;
}

export const ActivityForm = ({ formId, record, codes, onRecordUpdate }: ActivityFormProps) => {
  const { t } = useI18n("primhd");
  const { enqueueError } = useToast();

  const { mutate: updateRecord } = useMutation({
    mutationFn: (body: UpdateApi) => restPutter(`/v1/primhd/records/activity/${record.id}`, body),
    onSuccess() {
      onRecordUpdate();
    },
    onError(error) {
      const errorMessage =
        error.status === 400 && error.message
          ? error.message
          : t("primhd:records.edit.update_error");
      enqueueError(errorMessage);
    },
  });

  const form = useForm<ActivityFormFields>({
    defaultValues: {
      activityType: record.activityType,
      activitySetting: record.activitySetting,
      startDate: new Date(record.startDate),
      endDate: new Date(record.endDate),
    },
  });

  const onSubmit = form.handleSubmit(data => {
    if (!data) return;

    const { startDate, endDate, activityType, activitySetting } = data;

    updateRecord({
      id: record.id,
      referralId: record.referralId,
      activityType: activityType,
      activitySetting: activitySetting,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
  });

  return (
    <RecordDetailsPanel>
      <NcForm form={form} onSubmit={onSubmit} id={formId}>
        <NcForm.Row>
          <PrimhdCodeComboBox
            inputWidth="full"
            className="flex-grow"
            label={t("primhd:records.edit.activity_record.type_label")}
            name="activityType"
            codes={codes["ACTIVITY_TYPE"]}
            isRequired
          />
          <PrimhdCodeComboBox
            inputWidth="full"
            className="flex-grow"
            label={t("primhd:records.edit.activity_record.setting_label")}
            name="activitySetting"
            codes={codes["ACTIVITY_SETTING"]}
            isRequired
          />
        </NcForm.Row>
        <NcForm.Row>
          <NcFieldDate
            inputWidth="full"
            className="flex-grow"
            label={t("primhd:records.edit.activity_record.start_date_label")}
            name="startDate"
            picker
            includeTime
            isRequired
          />
          <NcFieldDate
            inputWidth="full"
            className="flex-grow"
            label={t("primhd:records.edit.activity_record.end_date_label")}
            name="endDate"
            picker
            includeTime
            isRequired
          />
        </NcForm.Row>
      </NcForm>
    </RecordDetailsPanel>
  );
};
