import { css } from "@emotion/core";
import { forwardRef } from "react";
import {
  Group as ReactAriaGroup,
  type GroupProps as ReactAriaGroupProps,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";

const styles = {
  outer: css`
    display: grid;
    gap: ${ncTheme.spacing(4)};
  `,
};

export interface NcGroupProps extends ReactAriaGroupProps {
  "aria-label"?: string;
  "aria-labelledby"?: string;
}

const NcGroup = forwardRef<HTMLDivElement, NcGroupProps>(({ children, ...props }, ref) => (
  <ReactAriaGroup data-nc="NcGroup" ref={ref} css={styles.outer} {...props}>
    {children}
  </ReactAriaGroup>
));

export { NcGroup };
