import { css } from "@emotion/core";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import type { CheckboxGroupProps as ReactAriaCheckboxGroupProps } from "react-aria-components";
import { CheckboxGroup as ReactAriaCheckboxGroup } from "react-aria-components";
import { useFormContext } from "react-hook-form";

import { ncTheme } from "../../nc-theme";
import { checkboxStyles, NcCheckbox } from "./nc-checkbox";
import type { FieldLabelProps, FieldProps } from "./nc-field";
import { NcField, NcFieldLabel } from "./nc-field";
import { useValidation } from "./use-validation";

interface FieldCheckboxProps
  extends FieldProps,
    Omit<ReactAriaCheckboxGroupProps, "name" | "validate" | "onBlur" | "onChange"> {
  onChange?: (value: unknown) => void;
  options: {
    label: string;
    value: string;
  }[];
}

interface NcCheckboxGroupProps extends ReactAriaCheckboxGroupProps, FieldLabelProps {
  children: ReactNode;
}

export const checkboxFieldStyles = {
  ...checkboxStyles,
  group: css`
    display: grid;
    gap: ${ncTheme.spacing(3)};
  `,
};

const CheckboxGroup = forwardRef<HTMLDivElement, NcCheckboxGroupProps>(
  ({ label, labelNode, children, ...props }, ref) => {
    return (
      <ReactAriaCheckboxGroup
        data-nc="NcCheckboxGroup"
        ref={ref}
        css={checkboxFieldStyles.group}
        {...props}
      >
        <NcFieldLabel isRequired={props.isRequired}>{labelNode || label}</NcFieldLabel>
        {children}
      </ReactAriaCheckboxGroup>
    );
  }
);

export const NcFieldCheckbox = ({ name, description, options, ...props }: FieldCheckboxProps) => {
  const { register, setValue, formState } = useFormContext();

  const field = register(name);

  const { defaultValues } = formState;

  const { validationHandler } = useValidation({
    label: props.label,
    rules: {
      required: props?.isRequired ? {} : undefined,
    },
  });

  const handleChange = (value: unknown) => {
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    props.onChange?.(value);
  };

  return (
    <CheckboxGroup
      data-nc="NcFieldCheckbox"
      {...field}
      {...props}
      name={name}
      defaultValue={defaultValues?.[name] || []}
      validate={validationHandler}
      onChange={handleChange}
    >
      <NcField description={description}>
        {options.map((option, index) => (
          <NcCheckbox name={`${name}.${index}`} key={option.value} value={option.value}>
            {option.label}
          </NcCheckbox>
        ))}
      </NcField>
    </CheckboxGroup>
  );
};
