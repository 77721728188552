import { keyframes } from "@emotion/core";
import { SVGProps } from "react";

import { useI18n } from "~/hooks/use-i18n";

import styled, { theme } from "../../theme";
import { VisuallyHidden } from "../../ui";

const LoadingWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
`;

interface LoadingLogoProps extends SVGProps<SVGSVGElement> {
  center?: boolean;
}

export const LoadingLogo = ({ center, ...props }: LoadingLogoProps) => {
  const { t } = useI18n();

  const Wrap = center ? LoadingWrap : styled.div``;
  return (
    <Wrap className="loading-wrap" aria-live="polite">
      <VisuallyHidden>{t("loading")}</VisuallyHidden>
      <Logo
        className="loading-logo"
        viewBox="0 0 300 272"
        width="50"
        role="presentation"
        {...props}
      >
        <Corner d="M94.6077 0H15.7155C7.05649 0 0 7.12582 0 15.9001V26.4667C0 35.3282 7.12541 42.5456 15.9008 42.5456H94.6077C103.357 42.5456 110.491 35.3282 110.491 26.4667V16.0789C110.491 7.21741 103.357 0 94.6077 0Z" />
        <Corner d="M282.608 0H203.901C195.125 0 188 7.21741 188 16.0963V26.7066C188 35.4372 195.056 42.5674 203.716 42.5674H282.608C291.357 42.5674 298.491 35.3457 298.491 26.4667V16.0963C298.491 7.21741 291.357 0 282.608 0Z" />
        <Corner d="M94.6077 229H15.7155C7.05648 229 0 236.126 0 244.9V255.467C0 264.328 7.12541 271.546 15.9008 271.546H94.6077C103.357 271.546 110.491 264.328 110.491 255.467V245.079C110.491 236.217 103.357 229 94.6077 229Z" />
        <Corner d="M282.608 229H203.716C195.056 229 188 236.126 188 244.9V255.467C188 264.328 195.125 271.546 203.901 271.546H282.608C291.357 271.546 298.491 264.328 298.491 255.467V245.079C298.491 236.217 291.357 229 282.608 229Z" />
        <Cross d="M256.832 141.329V130.941C256.832 128.839 256.47 126.798 255.639 124.827C253.187 118.717 247.458 114.801 240.944 114.801H171.835V43.7305C171.835 34.9998 164.525 27.6603 155.956 27.6603H142.481C133.839 27.6603 126.584 34.9998 126.584 43.7305V114.801H57.4366C48.6354 114.801 41.5013 122.019 41.5013 130.88V141.272C41.5013 143.418 41.9321 145.502 42.686 147.347C45.1502 153.448 50.9143 157.408 57.4366 157.408H126.584V228.474C126.584 237.174 133.839 244.557 142.481 244.557H155.956C164.525 244.557 171.835 237.174 171.835 228.474V157.408H240.944C249.732 157.408 256.832 150.182 256.832 141.329Z" />
      </Logo>
    </Wrap>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const pulse = keyframes`
  0%, 100% {
    opacity: 1;
  }
  10%, 60% {
    opacity: 0;
  }
`;

const Corner = styled.path`
  animation: ${pulse} 2s infinite 0.5s cubic-bezier(0, 0, 0.2, 1);
`;

const rotate = keyframes`
  0% {
    transform: rotate(0turn);
  }
  50% {
    transform: rotate(0.25turn);
  }
  100% {
    transform: rotate(0.25turn);
  }
`;

const Cross = styled.path`
  animation: ${rotate} 2s infinite 0.5s;
  will-change: transform;
  transform-origin: center;
`;

const Logo = styled.svg`
  fill: ${theme.colors.primary.dark};
  opacity: 0;
  animation: ${fadeIn} 0.5s 0.25s forwards;
`;
