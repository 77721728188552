import { useMemo } from "react";

import { useAppointmentForm } from "./context/appointment-form-context";
import { useAppointmentTimeslotSearch } from "./context/appointment-timeslot-search-context";
import { useTimeslots } from "./context/timeslots-context";

export const useShowOverlay = () => {
  const timeslots = useTimeslots();
  const searchData = useAppointmentTimeslotSearch()?.searchData;

  return useMemo(
    () => !!(searchData || (timeslots && timeslots.intervalsByColumn?.loading)),
    [searchData, timeslots]
  );
};

export const useShowOverlayCursor = () => {
  const { workerId, spaceId, service, date } = useAppointmentForm();

  return useMemo(
    () =>
      service &&
      ((service.requiredSpaces && !spaceId) || (service.requiredWorkers && !workerId) || !date),
    [date, service, spaceId, workerId]
  );
};
