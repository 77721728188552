import { isEqual, uniqBy } from "lodash-es";
import { DateTime } from "luxon";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";

import { startOfWeekDays } from "../../../shared/functions/date-helper";
import { useCalendar } from "../../shared/calendar";
import { useWeekViewFlag, useWeekViewResource } from "../context/booking-view-context";
import { IResource } from "../resources-context";
import { useShowOverlay, useShowOverlayCursor } from "../use-show-overlay";
import { useBookingViewDate } from "./booking-view-context";
import { useBookingCalendarRef } from "./calendar-ref-context";
import { useDayViewSelectedResources } from "./resource-filter-context";

interface ITimeslotVisibility extends Pick<IResource, "id" | "type"> {
  startOfDay: number;
  observer: IntersectionObserverEntry;
}

const OverlayContext = createContext<{
  addHiddenTsBox: (state: ITimeslotVisibility) => void;
  removeHiddenTsBox: (state: ITimeslotVisibility) => void;
  tsVisibility?: TimeslotVisibility;
  clearArrows: () => void;
}>({
  addHiddenTsBox: () => undefined,
  removeHiddenTsBox: () => undefined,
  clearArrows: () => undefined,
});

type TimeslotVisibility = {
  [key: string]: IntersectionObserverEntry[];
};

const clearOrphanBoxes = (state: TimeslotVisibility) => {
  const prunedState: TimeslotVisibility = {};
  [...Object.keys(state)].forEach(k => {
    prunedState[k] = state[k].filter(a => a.target.isConnected);
  });
  return prunedState;
};

export const OverlayState = ({ children }: { children: ReactNode }) => {
  const showOverlay = useShowOverlay();
  const showCursor = useShowOverlayCursor();
  const [tsVisibility, setTsVisibility] = useState<TimeslotVisibility>();

  const addHiddenTsBox = useCallback(({ startOfDay, id, type, observer }: ITimeslotVisibility) => {
    if (observer) {
      const key = `${startOfDay}-${id}-${type}`;
      setTsVisibility((state = {}) => {
        const localState = clearOrphanBoxes(state);
        const stateResult = {
          ...localState,
          [key]: uniqBy([...(localState[key] ?? []), observer], o => o.target),
        };
        return isEqual(stateResult, state) ? state : stateResult;
      });
    }
  }, []);

  const removeHiddenTsBox = useCallback(
    ({ startOfDay, id, type, observer }: ITimeslotVisibility) => {
      const key = `${startOfDay}-${id}-${type}`;
      setTsVisibility((state = {}) => {
        const stateResult = clearOrphanBoxes(state);
        stateResult[key] = (stateResult[key] ?? []).filter(i => i.target !== observer.target);
        if (stateResult[key]?.length === 0) {
          delete stateResult[key];
        }
        return isEqual(stateResult, state)
          ? state
          : Object.keys(stateResult).length
          ? stateResult
          : undefined;
      });
    },
    []
  );

  const clearArrows = useCallback(() => setTsVisibility(undefined), []);

  useEffect(() => {
    if (!showOverlay || !showCursor) {
      setTsVisibility(undefined);
    }
  }, [showOverlay, showCursor]);

  const value = useMemo(
    () => ({ addHiddenTsBox, removeHiddenTsBox, tsVisibility, clearArrows }),
    [addHiddenTsBox, removeHiddenTsBox, tsVisibility, clearArrows]
  );

  return <OverlayContext.Provider value={value}>{children}</OverlayContext.Provider>;
};

export const useTimeslotsVisibility = () => {
  const { tsVisibility } = useContext(OverlayContext);

  const { weekView } = useWeekViewFlag();
  const weekResource = useWeekViewResource();
  const { viewDate } = useBookingViewDate();
  const dayResources = useDayViewSelectedResources();
  const { scrolling } = useCalendar();

  const setter = useCallback(() => {
    if (tsVisibility) {
      let keys: string[] = [];
      if (weekView) {
        if (weekResource) {
          keys = startOfWeekDays(viewDate).map(
            d => `${d.toMillis()}-${weekResource.id}-${weekResource.type}`
          );
        }
      } else {
        keys = dayResources.map(r => `${viewDate.toMillis()}-${r.id}-${r.type}`);
      }
      const result = keys.reduce<IntersectionObserverEntry[]>(
        (res, key) => [...res, ...(tsVisibility[key] ?? [])],
        []
      );
      return result;
    } else {
      return [];
    }
  }, [dayResources, tsVisibility, viewDate, weekResource, weekView]);

  const [state, setState] = useState<IntersectionObserverEntry[]>(() => setter());

  useEffect(() => {
    setState(setter());
  }, [setter]);

  useEffect(() => {
    if (!scrolling) {
      setState(setter());
    }
  }, [scrolling, setter]);

  return state;
};

export const useTimeslotRef = (valid: boolean, column?: { resource: IResource; day: DateTime }) => {
  const { calendarRef } = useBookingCalendarRef();
  const { addHiddenTsBox, removeHiddenTsBox } = useContext(OverlayContext);

  const [inViewRef, inView, observer] = useInView({
    root: calendarRef.current,
    delay: 250,
    skip: !valid,
  });

  useEffect(() => {
    if (valid && column && observer) {
      if (!inView) {
        addHiddenTsBox({
          observer,
          ...column.resource,
          startOfDay: column.day.startOf("day").toMillis(),
        });
      } else {
        removeHiddenTsBox({
          observer,
          ...column.resource,
          startOfDay: column.day.startOf("day").toMillis(),
        });
      }
    }
  }, [observer, inView, valid, column, addHiddenTsBox, removeHiddenTsBox]);

  return inViewRef;
};
