import { forwardRef, useMemo } from "react";
import { useObjectRef } from "react-aria";
import { type Key } from "react-aria-components";
import { useFormContext } from "react-hook-form";

import { NcSelect, type NcSelectItem, type NcSelectProps } from "./nc-select";
import { useValidation } from "./use-validation";

export type NcFieldSelectItem = NcSelectItem;

export interface NcFieldSelectProps extends Omit<NcSelectProps, "onSelectionChange"> {
  onSelectionChange?: (item: NcSelectItem | undefined) => void;
  storeItemAsValue?: boolean;
}

const findItemById = (items: NcFieldSelectItem[], id: Key) => items.find(item => item.id === id);

const findItemByValue = (items: NcFieldSelectItem[], property: Key, value: Key) =>
  items.find(item => item[property] === value);

const getSelectedItem = (items: NcFieldSelectItem[], property: Key, value: Key | { id: Key }) => {
  if (value && typeof value === "object") {
    return {
      defaultSelectedKey: value?.id,
    };
  }
  const found = findItemByValue(items, property, value);
  return found
    ? {
        defaultSelectedKey: found?.id,
      }
    : {};
};

export const NcFieldSelect = forwardRef(
  ({ label, items, name, storeItemAsValue, ...props }: NcFieldSelectProps, ref) => {
    const objRef = useObjectRef(ref);
    const itemsArray = useMemo(() => Array.from(items as unknown as NcFieldSelectItem[]), [items]);
    const {
      register,
      formState: { defaultValues },
      setValue,
    } = useFormContext();

    const { validationHandler } = useValidation({
      label: label,
      rules: {
        required: props?.isRequired ? {} : undefined,
      },
    });

    const field = register(name);

    const handleChange = (id: Key) => {
      const item = findItemById(itemsArray, id);
      setValue(name, storeItemAsValue ? item : id, {
        shouldValidate: true,
        shouldDirty: true,
      });
      props?.onSelectionChange?.(item);
    };

    return (
      <NcSelect
        data-nc="NcFieldSelect"
        label={label}
        items={itemsArray}
        {...{
          ...props,
          ref: objRef,
        }}
        {...getSelectedItem(itemsArray, "id", defaultValues?.[name])}
        validate={validationHandler}
        onSelectionChange={handleChange}
        {...field}
      />
    );
  }
);
