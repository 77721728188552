import styled, { theme } from "../../theme";
import { Flex } from "../primitives";

export const OverlayMessageBox = styled(Flex)`
  border-radius: 6px;
  box-shadow:
    0px 10px 20px ${theme.colors.neutral.dark}33,
    0px 4px 4px ${theme.colors.neutral.dark}52;
`;

OverlayMessageBox.defaultProps = {
  flexGrow: 0,
  flexShrink: 1,
  flexBasis: "35rem",
  m: 3,
  flexDirection: ["column", "row"],
  overflow: "hidden",
};

export default OverlayMessageBox;
