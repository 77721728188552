import { useI18n } from "~/hooks/use-i18n";

import Button from "../../shared/components/forms/button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../shared/components/modal";
import { Flex, Text } from "../../shared/components/primitives";

interface IInterruptCreateOrEditActionModalProps {
  onConfirm: () => void;
  handleExit: () => void;
}

export const InterruptCreateOrEditActionModal = ({
  onConfirm,
  handleExit,
}: IInterruptCreateOrEditActionModalProps) => {
  const { t } = useI18n("scheduling");

  return (
    <Modal handleExit={handleExit}>
      <ModalHeader>
        <Text p="0.25rem">{t("scheduling:agenda.interrupt_action_modal.heading")}</Text>
      </ModalHeader>
      <ModalBody>
        <Text>{t("scheduling:agenda.interrupt_action_modal.body")}</Text>
      </ModalBody>
      <ModalFooter>
        <Flex justifyContent="flex-end">
          <Button variant="default" onClick={handleExit}>
            {t("scheduling:agenda.interrupt_action_modal.cancel")}
          </Button>
          <Button ml="2" variant="primary" onClick={onConfirm}>
            {t("scheduling:agenda.interrupt_action_modal.submit")}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
