import { css } from "@emotion/core";
import type { InputHTMLAttributes, WheelEvent } from "react";
import { forwardRef } from "react";
import { Input as ReactAriaInput } from "react-aria-components";

import { ncTheme } from "../../nc-theme";
import { buttonStyles } from "../nc-button";

const disabledInputStyles = css`
  cursor: not-allowed;
  background-color: ${ncTheme.colors.disabledLight};
`;

export const inputStyles = css`
  font-size: ${ncTheme.fontSizes[2]};
  font-weight: ${ncTheme.fontWeight.standard};
  background-color: ${ncTheme.colors.light};
  border: ${ncTheme.border(ncTheme.colors.ui)};
  border-radius: ${ncTheme.borderRadius.medium};
  padding-top: ${ncTheme.spacing(2)};
  padding-right: ${ncTheme.spacing(3)};
  padding-bottom: ${ncTheme.spacing(2)};
  padding-left: ${ncTheme.spacing(3)};
  outline: none;
  max-width: 100%;

  &[data-invalid="true"] {
    border-color: ${ncTheme.colors.danger};
  }

  &:focus,
  &[data-focused="true"] {
    border-color: ${ncTheme.colors.active};
  }

  &[data-focused="true"][data-focus-visible="true"] {
    ${ncTheme.utilities.outlineStyles}
  }

  &::placeholder,
  [data-placeholder="true"] {
    color: ${ncTheme.colors.uiDark};
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: 1em;
    width: 1em;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDIwIDIwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMjMuMDUgMTIzLjA1OyINCgkgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8cGF0aCBkPSJNMTguNjc1MzU3MSAyLjA4MTUyNDg2bC0uNjQ4NzU5Ny0uNjc0NzY3OTVjLS4zNTc4Mzc3LS4zNzI2MDIyMS0uOTM5Mzg5Ni0uMzcyNjAyMjEtMS4yOTc5Mjg2IDBMOS45ODA3MTQyOSA4LjMzNTc4NDUzIDMuMjE1MzQ0MTYgMS4zMDEzNzU2OWMtLjM1ODQ4MDUyLS4zNzI1NDE0NC0uOTM5NzQwMjYtLjM3MjU0MTQ0LTEuMjk3OTI4NTggMGwtLjY0ODc1OTc0LjY3NDY0NjRjLS4zNTgxODgzLjM3MjcyMzc3LS4zNTgxODgzLjk3NjQ0NzUyIDAgMS4zNDkyMzIwNWw4LjA1NSA4LjM3NjM0ODA2Yy4zNTgxODgzMi4zNzI5MDYxLjkzODk4MDUyLjM3MjkwNjEgMS4yOTc1Nzc5MyAwbDguMDU0MTIzMzMtOC4yNzEwMjc2MWMuMzU5MDY1LS4zNzI1NDE0NC4zNTkwNjUtLjk3NjUwODI5IDAtMS4zNDkwNDk3M3oiPjwvcGF0aD48cGF0aCBkPSJNMTguNjc1MzU3MSAxNy45MTg0NzUxNGwtLjY0ODc1OTcuNjc0NzY3OTVjLS4zNTc4Mzc3LjM3MjYwMjIxLS45MzkzODk2LjM3MjYwMjIxLTEuMjk3OTI4NiAwbC02Ljc0Nzk1NDUxLTYuOTI5MDI3NjItNi43NjUzNzAxMyA3LjAzNDQwODg0Yy0uMzU4NDgwNTIuMzcyNTQxNDQtLjkzOTc0MDI2LjM3MjU0MTQ0LTEuMjk3OTI4NTggMGwtLjY0ODc1OTc0LS42NzQ2NDY0Yy0uMzU4MTg4My0uMzcyNzIzNzctLjM1ODE4ODMtLjk3NjQ0NzUyIDAtMS4zNDkyMzIwNWw4LjA1NS04LjM3NjM0ODA2Yy4zNTgxODgzMi0uMzcyOTA2MS45Mzg5ODA1Mi0uMzcyOTA2MSAxLjI5NzU3NzkzIDBsOC4wNTQxMjMzMyA4LjI3MTAyNzYxYy4zNTkwNjUuMzcyNTQxNDQuMzU5MDY1Ljk3NjUwODI5IDAgMS4zNDkwNDk3M3oiPjwvcGF0aD4NCjwvZz4NCjwvc3ZnPg0K);
    background-size: contain;
  }

  &:disabled,
  &[data-disabled="true"],
  &[aria-disabled="true"] {
    ${disabledInputStyles}
  }

  &[type="file"] {
    ::file-selector-button {
      ${buttonStyles.base}
      ${buttonStyles.variant.default}

      font-size: ${ncTheme.fontSizes[0]};
      padding: ${ncTheme.spacing(1)} ${ncTheme.spacing(1.5)};
      margin-right: ${ncTheme.spacing(3)};
    }
  }
`;

export const inputWidthStyles = {
  xsmall: css`
    width: 5.5rem;
  `,
  small: css`
    width: 10rem;
  `,
  medium: css`
    width: 15rem;
  `,
  large: css`
    width: 100%;
    max-width: 25rem;
  `,
  full: css`
    max-width: 100%;
    width: 100%;
  `,
};

export interface PresetInputWidths {
  inputWidth?: keyof typeof inputWidthStyles;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement>, PresetInputWidths {
  type?: "text" | "password" | "email" | "number" | "file" | "tel" | "url" | "search";
}

function stopNumberValueChangeOnMouseWheel(e: WheelEvent<HTMLInputElement>) {
  e.currentTarget.blur();
}

export const NcInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = "text",
      autoComplete = "off",
      autoCapitalize = "none",
      onWheel = type === "number" ? stopNumberValueChangeOnMouseWheel : undefined,
      inputWidth = "medium",
      ...props
    },
    ref
  ) => {
    return (
      <ReactAriaInput
        data-nc="NcInput"
        type={type}
        ref={ref}
        css={[inputStyles, inputWidthStyles[inputWidth]]}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        maxLength={props.maxLength || 255}
        onWheel={onWheel}
        {...props}
      />
    );
  }
);
