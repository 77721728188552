import { css } from "@emotion/core";
import { Children, useEffect, type HTMLAttributes, type ReactNode } from "react";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcFormattedMessage } from "./nc-formatted-message";
import { NcHeading, type NcHeadingProps } from "./nc-heading";
import { NcLoadingIndicator } from "./nc-loading-indicator";

const styles = {
  page: {
    base: css`
      position: relative;
      width: 100%;
      height: 100%;
      /* Height fix to stop sticky headers disappearing halfway through scroll */
      // height: fit-content;
      overflow: auto;
      display: flex;
      flex-direction: column;
      background-color: ${ncTheme.colors.light};
    `,
    loading: css`
      align-content: center;
      padding-block: ${ncTheme.spacing(8)};
    `,
  },
  wrapper: {
    base: css`
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-inline: ${ncTheme.spacing(8)};
      margin-inline: auto;
    `,
    variant: {
      default: css`
        max-width: ${ncTheme.containers.page};
      `,
      fullWidth: css``,
    },
  },
  header: {
    base: css`
      background-color: ${ncTheme.colors.light};
      padding-top: ${ncTheme.spacing(6)};
      padding-bottom: ${ncTheme.spacing(4)};
      border-bottom: solid 1px ${ncTheme.colors.ui};
    `,
    variant: {
      default: css``,
      sticky: css`
        position: sticky;
        top: 0;
        z-index: 1;
      `,
    },
  },
  body: {
    base: css`
      display: grid;
      align-content: start;
      gap: ${ncTheme.spacing(8)};
      padding-block: ${ncTheme.spacing(4)};
    `,
    variant: {
      default: css``,
      fullHeight: css`
        flex-grow: 1;
      `,
    },
  },
  footer: {
    base: css`
      padding-block: ${ncTheme.spacing(4)};
      background-color: ${ncTheme.colors.light};
      border-top: solid 1px ${ncTheme.colors.ui};
    `,
    variant: {
      default: css``,
      sticky: css`
        position: sticky;
        bottom: 0;
        z-index: 1;
      `,
    },
  },
};

type NcPageProps = {
  children: ReactNode;
  error?: boolean | string;
  empty?: boolean | string;
  isLoading?: boolean;
};

type NcPageState = "LOADING" | "LOADED" | "EMPTY" | "ERROR";

function getPageState({ isLoading, empty, error }: Omit<NcPageProps, "children">): NcPageState {
  if (isLoading) return "LOADING";
  if (empty) return "EMPTY";
  if (error) return "ERROR";
  return "LOADED";
}

const NcPageError = ({ error }: { error: NcPageProps["error"] }) => {
  const { t } = useI18n();
  const errorText = typeof error === "string" ? error : t("error_default");
  return (
    <NcFormattedMessage variant="danger" className="p-3">
      {errorText}
    </NcFormattedMessage>
  );
};

const NcPageEmpty = ({ empty }: { empty: NcPageProps["empty"] }) => {
  const { t } = useI18n();
  const emptyText = typeof empty === "string" ? empty : t("empty_default");
  return (
    <NcFormattedMessage variant="secondary" className="p-3">
      {emptyText}
    </NcFormattedMessage>
  );
};

export const NcPage = ({ children, empty, error, isLoading, ...props }: NcPageProps) => {
  const state = getPageState({ isLoading, empty, error });
  return (
    <div data-nc="NcPage" css={[styles.page.base, isLoading && styles.page.loading]} {...props}>
      {state === "LOADING" && <NcLoadingIndicator variant="logo" />}
      {state === "ERROR" && <NcPageError error={error} />}
      {state === "EMPTY" && <NcPageEmpty empty={empty} />}
      {state === "LOADED" && <>{children}</>}
    </div>
  );
};

interface NcPageWrapperProps extends HTMLAttributes<HTMLDivElement> {
  variant?: keyof typeof styles.wrapper.variant;
}

const NcPageWrapper = ({ variant = "default", ...props }: NcPageWrapperProps) => (
  <div
    data-nc="NcPage.Wrapper"
    css={[styles.wrapper.base, styles.wrapper.variant[variant]]}
    {...props}
  />
);

interface NcPageHeaderProps extends HTMLAttributes<HTMLDivElement> {
  variant?: keyof typeof styles.header.variant;
}

const NcPageHeader = ({ variant = "default", ...props }: NcPageHeaderProps) => (
  <div
    data-nc="NcPage.Header"
    css={[styles.header.base, styles.header.variant[variant]]}
    {...props}
  />
);

interface NcPageBodyProps extends HTMLAttributes<HTMLDivElement> {
  variant?: keyof typeof styles.body.variant;
}

const NcPageBody = ({ variant = "default", ...props }: NcPageBodyProps) => (
  <div data-nc="NcPage.Body" css={[styles.body.base, styles.body.variant[variant]]} {...props} />
);

interface NcPageFooterProps extends HTMLAttributes<HTMLDivElement> {
  variant?: keyof typeof styles.footer.variant;
}

const NcPageFooter = ({ variant = "default", ...props }: NcPageFooterProps) => (
  <div
    data-nc="NcPage.Footer"
    css={[styles.footer.base, styles.footer.variant[variant]]}
    {...props}
  />
);

const getComponentText = (children: ReactNode) =>
  Children.toArray(children)
    .filter(child => typeof child === "string")
    .join(" ") || "Noted";

interface NcPageHeadingProps extends Omit<NcHeadingProps, "level" | "styleAs"> {
  level?: NcHeadingProps["level"];
  styleAs?: NcHeadingProps["styleAs"];
}
const NcPageHeading = ({ children, level = 1, styleAs = 1, ...props }: NcPageHeadingProps) => {
  useEffect(() => {
    if (level === 1) {
      const documentTitle = getComponentText(children);
      document.title = documentTitle;
    }
  }, [children]);

  return (
    <NcHeading level={level} styleAs={styleAs} {...props}>
      {children}
    </NcHeading>
  );
};

NcPage.Wrapper = NcPageWrapper;
NcPage.Header = NcPageHeader;
NcPage.Heading = NcPageHeading;
NcPage.Body = NcPageBody;
NcPage.Footer = NcPageFooter;
