import { css, keyframes } from "@emotion/core";
import { ComponentProps, FC } from "react";
import { NavLink as Link } from "react-router-dom";

import { BREAKPOINT_LARGE_QUERY, ITheme, theme } from "../../theme";

const menuItemTextIn = keyframes`
  0% {
      opacity: 0;
      right: 0;
  }
  100% {
      opacity: 1;
  }
`;

const navLinkStyles = ({
  theme,
  desktopExpanded,
}: {
  theme: ITheme;
  desktopExpanded?: boolean;
}) => {
  const tooltipTitle = css`
    span {
      font: 0/0 a;
      color: transparent;
      text-shadow: none;
      background-color: transparent;
      border: 0;
    }
    &:hover,
    &:focus {
      span {
        right: -0.66rem;
        z-index: 1;
        padding: 0.5rem 1rem;
        white-space: nowrap;
        transform: translateX(100%);
        animation: ${menuItemTextIn} 250ms;
        font: inherit;
        color: inherit;
        border-radius: 3px;
        box-shadow:
          0 2px 5px rgba(39, 50, 63, 0.4),
          0 8px 12px rgba(39, 50, 63, 0.15);
        &,
        &::before {
          display: block;
          position: absolute;
          background-color: ${theme.colors.neutral.dark};
        }
        &::before {
          content: "";
          left: -0.4rem;
          top: 50%;
          width: 0.5rem;
          height: 0.75rem;
          clip-path: polygon(0 50%, 100% 100%, 100% 0);
          transform: translateY(-50%);
          z-index: 2;
        }
      }
    }
  `;

  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    padding: 1rem 3rem 1rem 1.5rem;
    min-height: 1.5rem;
    outline: none;
    color: ${theme.colors.neutral.lightest};
    transition: all 150ms ease;

    &:hover,
    &:focus {
      background-color: ${theme.colors.primary.mediumDark};
      z-index: 1;
    }

    &:focus-visible {
      text-decoration: underline;
      outline-offset: -0.15rem;
    }

    &.active {
      position: relative;

      &,
      &:hover,
      &:focus {
        background-color: ${theme.colors.info.dark};
        font-weight: bold;
      }

      &::before {
        background-color: ${theme.colors.info.mediumLight};
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        content: "";
        display: block;
        position: absolute;
        width: 0.25rem;
        top: 5px;
        bottom: 5px;
        left: 0;
      }
    }

    > * {
      flex-shrink: 0;
    }

    span {
      padding-left: ${theme.space[3]};
    }

    @media ${BREAKPOINT_LARGE_QUERY} {
      ${!desktopExpanded ? tooltipTitle : null};
    }
  `;
};

export interface NavLinkProps extends ComponentProps<typeof Link> {
  title: string;
  icon: FC;
  condition?: boolean;
  role?: string;
  desktopExpanded?: boolean;
}

export const NavLink = (props: NavLinkProps) => {
  const { title, icon: Icon, condition, desktopExpanded, ...linkProps } = props;
  const styles = navLinkStyles({ theme, desktopExpanded });

  if (condition === false) {
    return null;
  }
  return (
    <Link css={styles} {...linkProps}>
      <Icon
        css={css`
          max-width: 1.5rem;
          max-height: 1.5rem;
        `}
      />
      <span>{title}</span>
    </Link>
  );
};

interface NavLinkAnchorProps {
  title: string;
  icon: FC;
  condition?: boolean;
  desktopExpanded?: boolean;
  href: string;
  target?: string;
  role?: string;
  onClick?: () => void;
}

export const NavLinkAnchor = (props: NavLinkAnchorProps) => {
  const { icon: Icon, title, desktopExpanded, condition, ...anchorProps } = props;
  const styles = navLinkStyles({ theme, desktopExpanded });
  if (condition === false) {
    return null;
  }
  return (
    <a css={styles} {...anchorProps}>
      <Icon />
      <span>{title}</span>
    </a>
  );
};
