/*
 * Helpers to reduce overhead in making custom keyboard event handlers for enhancing a11y
 * */

import { KeyboardEvent } from "react";

type Keys =
  | "Enter"
  | "Escape"
  | " "
  | "ArrowDown"
  | "ArrowLeft"
  | "ArrowRight"
  | "ArrowUp"
  | "Delete"
  | "Backspace";

// wrapper function to run event handler only on specified key presses
export const getKeyboardHandler =
  (targetKeys: Keys[], action: (event: KeyboardEvent<HTMLElement>) => void) =>
  (event: KeyboardEvent<HTMLElement>) => {
    const code = event.key;
    if ((targetKeys as string[]).includes(code)) {
      action(event);
    }
  };
