export interface IColorTheme {
  darkest: string;
  dark: string;
  mediumDark: string;
  medium: string;
  mediumLight: string;
  light: string;
  lightest: string;
}
const primary: IColorTheme = {
  darkest: "#0f3641",
  dark: "#004F62", // brand
  mediumDark: "#407887", //brandActive
  medium: "#628693",
  mediumLight: "#89a3ad",
  light: "#afc1c8",
  lightest: "#D9E5E8",
};
const neutral: IColorTheme = {
  darkest: "#07080A",
  dark: "#181C21", // dark
  mediumDark: "#3A4550", // uiDark
  medium: "#AFB4BA", // ui
  mediumLight: "#C8CBD0",
  light: "#F0F1F3", // uiLight
  lightest: "#F9FAFB", // light
};
const info: IColorTheme = {
  darkest: "#0f1e32",
  dark: "#0e2642", // mainDark
  mediumDark: "#092f51",
  medium: "#003762", // main
  mediumLight: "#39547b", // active
  light: "#8794ae",
  lightest: "#D5DEE5", // focused
};
const warning: IColorTheme = {
  darkest: "#AC5500",
  dark: "#BB6711",
  mediumDark: "#E08122",
  medium: "#F29D49",
  mediumLight: "#FFB366",
  light: "#FFD1A2",
  lightest: "#FFEBD7",
};
const danger: IColorTheme = {
  darkest: "#5c1508",
  dark: "#791408",
  mediumDark: "#921A13", // danger
  medium: "#AA1F1D",
  mediumLight: "#C32528", // dangerActive
  light: "#DA7778",
  lightest: "#F5DBDB",
};
const highlight: IColorTheme = {
  darkest: "#5C4813",
  dark: "#8C6D1F",
  mediumDark: "#CAA53D",
  medium: "#F4CA64",
  mediumLight: "#FAE29F",
  light: "#FDF3D7",
  lightest: "#FFFCF4",
};
const success: IColorTheme = {
  darkest: "#003727",
  dark: "#004832",
  mediumDark: "#00583E",
  medium: "#006949", // success
  mediumLight: "#278065", // successActive
  light: "#69A794",
  lightest: "#D1E4DE", // highlighted
};

export interface IColors {
  primary: IColorTheme;
  neutral: IColorTheme;
  info: IColorTheme;
  warning: IColorTheme;
  danger: IColorTheme;
  highlight: IColorTheme;
  success: IColorTheme;
  disabled: string;
  disabledLight: string;
}

export const colors: IColors = {
  primary,
  neutral,
  info,
  warning,
  danger,
  highlight,
  success,
  disabled: "#606060",
  disabledLight: "#DDDDDD",
};
