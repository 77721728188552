import { clone, merge } from "lodash-es";

import { useMeQuery } from "./use-me-query";

export type UserSettings = NonNullable<
  ReturnType<typeof useMeQuery>["data"]
>["user"]["settingsJson"];
export type OrganisationSettings = NonNullable<
  ReturnType<typeof useMeQuery>["data"]
>["organisation"]["settingsJson"];

type Params =
  | { old: UserSettings; new: UserSettings }
  | { old: OrganisationSettings; new: OrganisationSettings };

export const assignSettings = (params: Params) => {
  // Replace undefined with nulls
  const mapped = mapUndefinedToNull(clone(params.new));
  const result = merge(params.old, mapped);
  // Clear null entries
  return deleteNull(result);
};

const mapUndefinedToNull = <T>(obj?: T) => {
  if (typeof obj !== "object") {
    return obj;
  }
  const mapper = obj as Record<string, unknown>;
  Object.keys(mapper).forEach(key => {
    const value = mapper[key];
    if (value === undefined) {
      mapper[key] = null;
    } else if (typeof value === "object") {
      return mapUndefinedToNull(value);
    }
  });
  return mapper;
};

const deleteNull = <T>(obj?: T) => {
  if (typeof obj !== "object") {
    return obj;
  }
  const mapper = obj as Record<string, unknown>;
  Object.keys(mapper).forEach(key => {
    const value = mapper[key];
    if (value === null) {
      delete mapper[key];
    } else if (typeof value === "object") {
      return deleteNull(value);
    }
  });
  return mapper;
};
