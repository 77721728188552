import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";

import { ncTheme } from "../nc-theme";

const styles = css`
  display: flex;
  gap: ${ncTheme.spacing(4)};
  align-items: center;
  flex-wrap: wrap;
`;

interface NcFlexLayoutProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

export const NcFlexLayout = ({ children, ...props }: NcFlexLayoutProps) => {
  return (
    <div data-nc="NcFlexLayout" {...props} css={styles}>
      {children}
    </div>
  );
};
