import { NcIconMagnify, NcInput } from "@noted/noted-components";
import { ChangeEvent, KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";

import { useI18n } from "~/hooks/use-i18n";

interface SearchBoxProps {
  id: string;
  labelKey?: string;
  defaultValue?: string;
  onChange: (term: string) => void;
  onKeyboardNavOut?: (event: KeyboardEvent) => void;
}

const SearchBox = ({ id, labelKey, defaultValue, onChange, onKeyboardNavOut }: SearchBoxProps) => {
  const { t } = useI18n("org");
  const [searchKey, setSearchKey] = useState(defaultValue || "");
  const [searchKeyDebounced] = useDebounce(searchKey, 600);
  const searchRef = useRef<HTMLInputElement>(null);

  const onKeyboardNav = (event: KeyboardEvent) => {
    if (event.key === "ArrowDown") {
      onKeyboardNavOut?.(event);
    }
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchKey(value);
  };

  const label = labelKey ? t(labelKey) : t("search");

  useEffect(() => {
    onChange(searchKeyDebounced);
  }, [searchKeyDebounced]);

  useEffect(() => {
    if (defaultValue === "" && searchRef?.current) {
      searchRef.current.value = "";
    }
  }, [defaultValue]);

  return (
    <div className="search-box">
      <div className="relative w-full">
        <label htmlFor={id} className="sr-only">
          {label}
        </label>
        <NcIconMagnify
          className="pointer-events-none absolute left-3 top-2.5 z-[2] text-disabled"
          opacity={0.5}
        />
        <NcInput
          inputWidth="full"
          id={id}
          ref={searchRef}
          className="z-0 pl-9"
          aria-label={label}
          placeholder={label}
          defaultValue={searchKey}
          onChange={onInputChange}
          onKeyUp={onKeyboardNav as unknown as KeyboardEventHandler<HTMLInputElement>}
        />
      </div>
    </div>
  );
};

export default SearchBox;
