import { css } from "@emotion/core";
import type { ValidationError } from "@react-types/shared";
import type { ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import type { ErrorOption, UseFormRegisterReturn } from "react-hook-form";
import { useFieldArray, useFormContext } from "react-hook-form";

import { ncTheme } from "../../nc-theme";
import { useI18n } from "../../use-i18n";
import { NcButton } from "../nc-button";
import { NcFormattedMessage } from "../nc-formatted-message";
import { NcIconCross, NcIconPlus } from "../nc-icons";
import { NcVisuallyHidden } from "../nc-visually-hidden";
import type { FieldsetProps } from "./nc-fieldset";
import { NcFieldset } from "./nc-fieldset";
import type { ValidationResult } from "./use-validation";
import { useValidation } from "./use-validation";

export interface NcFieldArrayInputProps extends UseFormRegisterReturn<string> {
  name: string;
  "aria-label": string;
}

interface FieldArrayProps extends Omit<FieldsetProps, "children"> {
  label: string;
  labelNode?: ReactNode;
  isRequired?: boolean;
  minLength?: number;
  maxLength?: number;
  children: (props: NcFieldArrayInputProps) => ReactNode;
}

export type ErrorList = Record<number | "root", { message?: string }>;

const styles = {
  outer: css`
    padding-bottom: ${ncTheme.spacing(2)};
  `,
  items: css`
    display: grid;
    gap: 0;
  `,
  item: css`
    align-items: center;
    display: grid;
    gap: ${ncTheme.spacing(2)};
    grid-template-columns: 1fr auto;
    padding: ${ncTheme.spacing(2)} 0;

    &:not(:last-of-type) {
      border-bottom: ${ncTheme.border(ncTheme.colors.uiLight)};
    }
  `,
  actions: css`
    display: flex;
    padding: 0 0 ${ncTheme.spacing(1)};
    font-size: ${ncTheme.fontSizes[1]};
  `,
};

const canAddAnother = (fields: { id: string }[] | undefined, values: unknown[] = []) => {
  if (fields?.length === 0) return true;
  const lastFieldIsEmpty =
    fields?.length && ([null, undefined, ""] as unknown[]).includes(values[fields?.length - 1]);
  return !lastFieldIsEmpty;
};

export const NcFieldArray = ({
  label,
  labelNode,
  name,
  description,
  isRequired,
  minLength,
  maxLength,
  children: renderItemInput,
  ...props
}: FieldArrayProps) => {
  const { t } = useI18n();
  const [isAdding, setIsAdding] = useState(false);
  const {
    register,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useFormContext();
  const { append, remove, fields } = useFieldArray({
    name,
  });
  const values = watch(name);

  const { validationHandler } = useValidation({
    label: label,
    rules: {
      required: isRequired ? {} : undefined,
      minLength: minLength
        ? {
            message: `{{label}} must have at least {{minLength}} items`,
            value: minLength,
          }
        : undefined,
      maxLength: maxLength
        ? {
            message: `{{label}} must have at most {{maxLength}} items`,
            value: maxLength,
          }
        : undefined,
    },
  });

  const focusOnLastItem = useCallback(
    (fields: Record<"id", string>[]) => {
      const lastItem = fields[fields.length - 1];
      if (lastItem) {
        const lastItemName = `${name}.${fields.length - 1}`;
        document.getElementsByName(lastItemName)?.[0]?.focus();
      }
    },
    [name]
  );

  const validate = useCallback(() => {
    const currentValue = getValues(name);
    const result = validationHandler(currentValue);
    if (([true, null, undefined] as ValidationResult[]).includes(result)) {
      clearErrors([name]);
      return;
    }
    // we set the error to the form to prevent the form from submitting
    setError(name, { message: result } as ErrorOption);
    focusOnLastItem(fields);
  }, [validationHandler]);

  const addItem = useCallback(() => {
    append(null);
    setIsAdding(true);
  }, [append]);

  const removeItem = (index: number) => {
    remove(index);
    validate();
  };

  useEffect(() => {
    if (!fields.length) {
      if (isRequired || (minLength && minLength > 0)) {
        // on load if at least one is required, add an initial field
        append(null);
        focusOnLastItem(fields);
      }
    }
  }, [addItem, fields.length, isRequired, minLength]);

  useEffect(() => {
    if (isAdding) {
      setIsAdding(false);
      focusOnLastItem(fields);
      validate();
    }
  }, [isAdding]);

  useEffect(() => {
    if (isSubmitting) {
      // validate the form when submitting
      validate();
    }
  }, [isSubmitting, values]);

  useEffect(() => {
    if (values === undefined && fields.length > 0) {
      // when the form is reset, remove all fields
      remove();
    }
  }, [fields, values]);

  const findIndex = (id: string) => fields.findIndex(field => field.id === id);

  const errorMessage = errors?.[name]?.message as ValidationError;

  return (
    <NcFieldset
      data-nc="NcFieldArray"
      name={name}
      {...props}
      {...{
        label,
        labelNode,
        description,
        errorMessage,
      }}
      css={styles.outer}
    >
      <ul css={styles.items} aria-label={label}>
        {fields.length < 1 && (
          <li css={styles.item}>
            <NcFormattedMessage className="nc-mt-1">{t("None")}</NcFormattedMessage>
          </li>
        )}
        {fields.map(item => {
          const index = findIndex(item.id);
          const itemName = `${name}.${index}`;
          const field = register(itemName, {
            validate: {
              isRequired: (value: string) => {
                if (isRequired && fields.length <= 1 && !value) {
                  return "This field is required";
                }
                return true;
              },
            },
          });
          return (
            <li key={item.id} css={styles.item} data-item-id={item.id} tabIndex={-1}>
              {renderItemInput({
                ...field,
                "aria-label": label,
              })}
              <NcButton variant="icon" onPress={() => removeItem(index)}>
                <NcVisuallyHidden>{t("Remove")}</NcVisuallyHidden>
                <NcIconCross />
              </NcButton>
            </li>
          );
        })}
      </ul>
      <div css={styles.actions}>
        <NcButton type="button" onPress={addItem} isDisabled={!canAddAnother(fields, values)}>
          <NcIconPlus />
          {t("Add")}
        </NcButton>
      </div>
    </NcFieldset>
  );
};
