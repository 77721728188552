import css from "@emotion/css";
import { NcFlexLayout, NcHeading } from "@noted/noted-components";
import {
  Children,
  ComponentProps,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  Ref,
  useEffect,
} from "react";

import { appInsideIframe } from "~/app-inside-iframe";

import config from "../../../routes/config";

const getComponentText = (children: ReactNode) =>
  Children.toArray(children)
    .filter(child => typeof child === "string")
    .join(" ") || "Noted";

interface PageTitleProps extends Omit<ComponentProps<typeof NcHeading>, "level"> {
  align?: "left" | "center";
  children: ReactNode;
}
export const PageTitle = forwardRef<HTMLDivElement, PageTitleProps>((props, ref) => {
  const { align = "left", children } = props;

  useEffect(() => {
    const documentTitle = getComponentText(children);
    document.title = documentTitle;
    if (appInsideIframe) {
      window.parent.postMessage({ documentTitle }, config.angularOrigin);
    }
  }, [children]);

  return (
    <NcFlexLayout
      css={
        align === "center"
          ? css`
              justify-content: center;
            `
          : css`
              justify-content: start;
            `
      }
    >
      <NcHeading level={1} {...props} ref={ref as Ref<HTMLAttributes<HTMLHeadingElement>>}>
        {children}
      </NcHeading>
    </NcFlexLayout>
  );
});

PageTitle.displayName = "PageTitle";

export default PageTitle;
