import { DateTime } from "luxon";
import { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from "react";

import { IResource } from "../../agenda/resources-context";

export type AdjustmentType = "ALLOCATED" | "DEALLOCATED";

interface IAdjustmentFormValues {
  id?: number;
  resource?: IResource;
  type?: AdjustmentType;
  from?: DateTime;
  to?: DateTime;
  errors: IFormErrors;
  notesRef: MutableRefObject<HTMLTextAreaElement | null>;
}

interface IFormErrors {
  availabilityType: boolean;
  resource: boolean;
  from: boolean;
  to: boolean;
  fromAfterTo: boolean;
  fromTimeAfterToTime: boolean;
}

const initialErrors: IFormErrors = {
  availabilityType: false,
  resource: false,
  from: false,
  to: false,
  fromAfterTo: false,
  fromTimeAfterToTime: false,
};

const validateForm = (state: IAdjustmentFormValues): IFormErrors => {
  return {
    availabilityType: !state.type,
    resource: !state.resource,
    from: !state.from,
    to: !state.to,
    fromAfterTo:
      state.from && state.to ? state.from.startOf("day") > state.to.startOf("day") : false,
    fromTimeAfterToTime:
      state.from && state.to
        ? state.from >
          state.to.set({ year: state.from.year, month: state.from.month, day: state.from.day })
        : false,
  };
};

export interface IAdjustmentFormData extends IAdjustmentFormValues {
  type?: AdjustmentType;
  setType: Dispatch<SetStateAction<AdjustmentType | undefined>>;
  setResource: Dispatch<SetStateAction<IResource | undefined>>;
  setFromDate: (date?: { year: number; month: number; day: number }) => void;
  setToDate: (date?: { year: number; month: number; day: number }) => void;
  setFromTime: (time: { hour: number; minute: number }) => void;
  setToTime: (time: { hour: number; minute: number }) => void;
  setErrors: (errors: IFormErrors) => void;
  validateForm: (state: IAdjustmentFormValues) => IFormErrors;
  setId: Dispatch<SetStateAction<number | undefined>>;
  clear: () => void;
}

export const useAdjustmentFormData = (): IAdjustmentFormData => {
  const [id, setId] = useState<number>();
  const [resource, setResource] = useState<IResource>();
  const [type, setType] = useState<AdjustmentType>();
  const [from, setFrom] = useState<DateTime>();
  const [to, setTo] = useState<DateTime>();
  const [errors, setErrors] = useState<IFormErrors>(initialErrors);

  const setFromDate = (date?: { year: number; month: number; day: number }) => {
    if (date) {
      const { year, month, day } = date;
      setFrom(f =>
        f ? f.set({ year, month, day }) : DateTime.fromObject({ year, month, day }).set({ hour: 8 })
      );
    } else {
      setFrom(undefined);
    }
  };

  const setToDate = (date?: { year: number; month: number; day: number }) => {
    if (date) {
      const { year, month, day } = date;
      setTo(t =>
        t
          ? t.set({ year, month, day })
          : DateTime.fromObject({ year, month, day }).set({ hour: 17 })
      );
    } else {
      setFrom(undefined);
    }
  };

  const setFromTime = (time: { hour: number; minute: number }) => {
    const { hour, minute } = time;
    setFrom(f => (f || DateTime.local()).set({ hour, minute }));
  };
  const setToTime = (time: { hour: number; minute: number }) => {
    const { hour, minute } = time;
    setTo(f => (f || DateTime.local()).set({ hour, minute }));
  };

  const notesRef = useRef<HTMLTextAreaElement>(null);
  const clear = () => {
    setId(undefined);
    setResource(undefined);
    setType(undefined);
    setFrom(undefined);
    setTo(undefined);
    setErrors(initialErrors);
    if (notesRef.current) {
      notesRef.current.value = "";
    }
  };

  return {
    resource,
    setResource,
    type,
    setType,
    from,
    to,
    setFromDate,
    setToDate,
    setFromTime,
    setToTime,
    notesRef,
    id,
    setId,
    clear,
    errors,
    setErrors,
    validateForm,
  };
};
