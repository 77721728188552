import * as Sentry from "@sentry/react";
import i18next from "i18next";
import Backend from "i18next-http-backend";
import { Settings } from "luxon";
import { initReactI18next } from "react-i18next";

Settings.defaultLocale = "en-NZ";
const i18nFileVersion = import.meta.env.VITE_COMMIT_SHA;

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en-NZ",
    fallbackLng: "en-NZ",
    supportedLngs: ["en-NZ", "mi"],
    load: "currentOnly",
    ns: ["common"],
    defaultNS: "common",
    saveMissing: true,
    missingKeyHandler: (languages, namespace, key, fallback) => {
      const extra = { languages, namespace, key, fallback };
      console.error(`Missing translation ${namespace}, "${key}"`);
      Sentry.captureMessage(
        `Missing translation for key "${key}" in "${namespace}". Falling back to value "${fallback}".`,
        { extra }
      );
    },
    interpolation: {
      // Escaping not needed in react
      escapeValue: false,
    },
    backend: {
      queryStringParams: {
        ...(i18nFileVersion ? { v: import.meta.env.VITE_COMMIT_SHA } : null),
      },
    },
  });

if (import.meta.hot) {
  // listen for i18n locale hot update events are reload
  import.meta.hot.on("locales-update", () => {
    i18next.reloadResources().then(() => {
      i18next.changeLanguage(i18next.language);
    });
  });
}
