import { css } from "@emotion/core";
import { ReactNode } from "react";

import { theme } from "../../theme";
import { LoadingLogo } from "../loading/loading-logo";
import { Modal } from "../modal";

export interface ActionsModalProps {
  inProgress: boolean;
  onCancel: () => void;
  children: ReactNode;
}

export function ActionsModal({ inProgress, onCancel, children }: ActionsModalProps) {
  return (
    <Modal css={style} handleExit={onCancel}>
      {inProgress && (
        <div className="loadingCover">
          <LoadingLogo />
        </div>
      )}
      {children}
    </Modal>
  );
}

const style = css`
  min-width: max-content;
  padding: ${theme.space[4]};
  top: 30vh;

  .loadingCover {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    &:before {
      background-color: ${theme.colors.neutral.lightest};
      content: "";
      display: block;
      height: 100%;
      opacity: 0.8;
      position: absolute;
      width: 100%;
    }
  }
`;
