import { config } from "@noted/configuration";
import * as Sentry from "@sentry/react";
import { useContext, useState } from "react";
import { useWizard } from "react-wizard-primitive";

import { useI18n } from "~/hooks/use-i18n";

import { AccountContext } from "../../account/account.context";
import Button from "../../shared/components/forms/button";
import { LoadingLogo } from "../../shared/components/loading/loading-logo";
import MessageBox from "../../shared/components/message-box/message-box";
import Box from "../../shared/components/primitives/box";
import Flex from "../../shared/components/primitives/flex";
import Text from "../../shared/components/primitives/text";
import { useScript } from "../../shared/hooks/use-script";
import { useUpgradeAccountMutation } from "./api";
import { BillingDetails } from "./billing";
import BillingDetailsForm from "./billing-details-form";
import PackageSelect from "./package-select";
import PaymentConfirmed from "./payment-confirmed";
import PaymentForm from "./payment-form";
import { PaymentInformationWithAddons } from "./payment-information-with-addons";
import SubscriptionSteps from "./subscription-steps";
import Unverified from "./unverified";
import { useAddons } from "./use-addons";

const Upgrade = () => {
  const { organisation } = useContext(AccountContext);
  const { t, locale } = useI18n("admin-org");
  const [stripeLoaded, stripeLoadError] = useScript("https://js.stripe.com/v3/");
  const [billingDetails, setBillingDetails] = useState({} as BillingDetails);
  const { getStep, activeStepIndex } = useWizard();

  const { mutateAsync: upgradeAccount } = useUpgradeAccountMutation();

  const [tempSelectedPackageId, setTempSelectedPackageId] = useState("");
  const [tempSelectedPackageName, setTempSelectedPackageName] = useState("");
  const [selectedPackageId, setSelectedPackageId] = useState("");
  const packageSelectStep = getStep();
  const packageConfirmStep = getStep();
  const billingDetailsStep = getStep();
  const paymentDetailsStep = getStep();
  const paymentConfirmedStep = getStep();
  const [somethingWentWrong, setSomethingWentWrong] = useState(false);
  const [pendingSubscription, setPendingSubscription] = useState(false);
  const { addons, loading: addonsLoading, error: addonsError, refetch, toggleAddon } = useAddons();

  if (!stripeLoaded || stripeLoadError) {
    return null;
  }

  const onPackageSelect = ({
    packageId,
    packageName,
  }: {
    packageId: string;
    packageName: string;
  }) => {
    setTempSelectedPackageId(packageId);
    setTempSelectedPackageName(packageName);
    packageSelectStep.nextStep();
  };

  const onPackageCancel = () => {
    packageConfirmStep.previousStep();
  };

  const onPackageConfirmation = () => {
    setSelectedPackageId(tempSelectedPackageId);
    packageConfirmStep.nextStep();
  };

  const onBillingDetailsSubmit = (submittedData: BillingDetails) => {
    setBillingDetails(submittedData);
    billingDetailsStep.nextStep();
  };

  const onPaymentFormSubmit = async (token: string) => {
    setPendingSubscription(true);

    try {
      await upgradeAccount({
        input: {
          ...billingDetails,
          cardToken: token,
          packageId: selectedPackageId,
          addOnIds: addons.filter(a => a.enabled).map(a => a.id),
        },
      });
      paymentDetailsStep.nextStep();
    } catch (e) {
      Sentry.captureMessage(String(e), { extra: { error: e } });
      setSomethingWentWrong(true);
    }
    setPendingSubscription(false);
  };

  return (
    <Flex flexDirection="column" data-testid="upgrade-page-content">
      <Box mb="3" maxWidth="60rem" data-testid="icon-list">
        <Text style={{ display: "block" }} as="small" mt="1" mb="3" fontSize="3" color="#777777">
          {t("admin-org:subscriptions.upgrade.modal_description")}
        </Text>
      </Box>
      <SubscriptionSteps step={activeStepIndex} />
      {organisation.emailVerified ? (
        <Box maxWidth="60rem">
          {packageSelectStep.isActive && (
            <PackageSelect
              selectedPackageId={tempSelectedPackageId}
              selectPackage={onPackageSelect}
            />
          )}
          {packageConfirmStep.isActive && (
            <>
              {tempSelectedPackageId && (
                <>
                  <PaymentInformationWithAddons
                    packageId={tempSelectedPackageId}
                    packageName={tempSelectedPackageName}
                    addons={addons}
                    addonsLoading={addonsLoading}
                    addonsError={Boolean(addonsError)}
                    toggleAddon={toggleAddon}
                    refetchAddons={refetch}
                  />
                  <Flex mt="4">
                    <Button
                      mr="3"
                      fontSize="3"
                      variant="primary"
                      onClick={onPackageConfirmation}
                      data-testid="confirm-package-select-button"
                    >
                      {t("confirm")}
                    </Button>
                    <Button onClick={onPackageCancel}>{t("cancel")}</Button>
                  </Flex>
                </>
              )}
            </>
          )}
          {billingDetailsStep.isActive && (
            <BillingDetailsForm
              isUpgrade={true}
              onSubmit={onBillingDetailsSubmit}
              details={{} as BillingDetails}
            />
          )}
          {paymentDetailsStep.isActive && (
            <>
              {somethingWentWrong ? (
                <Text
                  fontWeight={500}
                  fontSize="4"
                  mb="2"
                  dangerouslySetInnerHTML={{
                    __html: t("admin-org:subscriptions.upgrade.something_went_wrong", {
                      supportPageUrl: config.supportPageUrl,
                    }),
                  }}
                />
              ) : (
                <>
                  <Flex>
                    <Text fontSize="3" as="h3" mr="3" mb="3">
                      {t("admin-org:subscriptions.upgrade.payment_method")}
                    </Text>
                    {pendingSubscription && <LoadingLogo width="25" height="25" />}
                  </Flex>

                  <PaymentForm
                    isUpgrade={true}
                    onCreateToken={onPaymentFormSubmit}
                    locale={locale}
                  />
                </>
              )}
              <MessageBox mt="4" maxWidth="40rem">
                <Text fontWeight={500} fontSize="4" mt="0" mb="2" as={"h3"}>
                  {t("admin-org:subscriptions.upgrade.please_note")}
                </Text>
                <Text mb="2" fontSize="2">
                  {t("admin-org:subscriptions.upgrade.description_one")}
                </Text>
                <Text
                  fontSize="2"
                  dangerouslySetInnerHTML={{
                    __html: t("admin-org:subscriptions.upgrade.description_two", {
                      termsPageUrl: config.termsPageUrl,
                      supportPageUrl: config.supportPageUrl,
                    }),
                  }}
                />
              </MessageBox>
            </>
          )}
          {paymentConfirmedStep.isActive && <PaymentConfirmed />}
        </Box>
      ) : (
        <Unverified />
      )}
    </Flex>
  );
};

export default Upgrade;
