import { Interval } from "luxon";
import {
  createContext,
  Dispatch,
  ReactNode,
  Reducer,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";

import { type Location, type Service, type User } from "~/graphql-hooks/types";

interface IAppointmentTimeslotSearchContext {
  service?: Service;
  workerValue: string;
  spaceValue: string;
  durationValue: string;
  customDurationStart?: Date;
  customDurationEnd?: Date;
  setService: (service?: Service) => void;
  setWorkerValue: (workerValue: string) => void;
  setSpaceValue: (spaceValue: string) => void;
  setDurationValue: (durationValue: string) => void;
  setCustomDurationStart: (d?: Date) => void;
  setCustomDurationEnd: (d?: Date) => void;
  searchData?: ISearchData;
  setSearchData: Dispatch<SetStateAction<ISearchData | undefined>>;
  clear: () => void;
}

export interface ISearchData {
  service: Service;
  worker?: User;
  space?: Location;
  timeframe: Interval;
}

export const initialState = {
  service: undefined,
  workerValue: "",
  spaceValue: "",
  durationValue: "",
  customDurationStart: undefined,
  customDurationEnd: undefined,
  workerEnabled: false,
  spaceEnabled: false,
  workerNotRequired: false,
  spaceNotRequired: false,
};

export const AppointmentTimeslotSearchContext = createContext<IAppointmentTimeslotSearchContext>(
  {} as IAppointmentTimeslotSearchContext
);
export const useAppointmentTimeslotSearch = () => useContext(AppointmentTimeslotSearchContext);

export const AppointmentTimeslotSearchProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(timeslotSearchReducer, initialState);
  const [searchData, setSearchData] = useState<ISearchData>();

  const clear = useCallback(() => {
    dispatch({ type: "CLEAR" });
    setSearchData(undefined);
  }, []);

  const setService = useCallback((service?: Service) => {
    dispatch({ type: "SET_SERVICE", payload: service });
  }, []);

  const setWorkerValue = useCallback((workerValue: string) => {
    dispatch({
      type: "SET_WORKER",
      payload: workerValue,
    });
  }, []);

  const setSpaceValue = useCallback((spaceValue: string) => {
    dispatch({
      type: "SET_SPACE",
      payload: spaceValue,
    });
  }, []);

  const setDurationValue = useCallback((durationValue: string) => {
    dispatch({
      type: "SET_DURATION",
      payload: durationValue,
    });
  }, []);

  const setCustomDurationStart = useCallback((d?: Date) => {
    dispatch({ type: "SET_CUSTOM_DURATION_START", payload: d });
  }, []);

  const setCustomDurationEnd = useCallback((d?: Date) => {
    dispatch({ type: "SET_CUSTOM_DURATION_END", payload: d });
  }, []);

  const value = useMemo(
    () => ({
      service: state.service,
      workerValue: state.workerValue,
      spaceValue: state.spaceValue,
      durationValue: state.durationValue,
      customDurationStart: state.customDurationStart,
      customDurationEnd: state.customDurationEnd,
      setService,
      setWorkerValue,
      setSpaceValue,
      setDurationValue,
      setCustomDurationStart,
      setCustomDurationEnd,
      searchData,
      setSearchData,
      clear,
    }),
    [
      clear,
      searchData,
      setCustomDurationEnd,
      setCustomDurationStart,
      setDurationValue,
      setService,
      setSpaceValue,
      setWorkerValue,
      state.customDurationEnd,
      state.customDurationStart,
      state.durationValue,
      state.service,
      state.spaceValue,
      state.workerValue,
    ]
  );

  return (
    <AppointmentTimeslotSearchContext.Provider value={value}>
      {children}
    </AppointmentTimeslotSearchContext.Provider>
  );
};

// ------ Reducer below here ----------

interface IState {
  service?: Service;
  workerValue: string;
  spaceValue: string;
  durationValue: string;
  customDurationStart?: Date;
  customDurationEnd?: Date;
}

interface IClearAction {
  type: "CLEAR";
}

interface ISetServiceAction {
  type: "SET_SERVICE";
  payload?: Service;
}

interface ISetWorkerAction {
  type: "SET_WORKER";
  payload: string;
}

interface ISetSpaceAction {
  type: "SET_SPACE";
  payload: string;
}

interface ISetDurationAction {
  type: "SET_DURATION";
  payload: string;
}

interface ISetDurationStartAction {
  type: "SET_CUSTOM_DURATION_START";
  payload?: Date;
}

interface ISetDurationEndAction {
  type: "SET_CUSTOM_DURATION_END";
  payload?: Date;
}

type Actions =
  | IClearAction
  | ISetServiceAction
  | ISetWorkerAction
  | ISetSpaceAction
  | ISetDurationAction
  | ISetDurationStartAction
  | ISetDurationEndAction;

const timeslotSearchReducer: Reducer<IState, Actions> = (state, action) => {
  switch (action.type) {
    case "CLEAR": {
      return initialState;
    }
    case "SET_SERVICE": {
      return {
        ...state,
        service: action.payload,
        workerValue: "",
        spaceValue: "",
      };
    }
    case "SET_WORKER": {
      return { ...state, workerValue: action.payload };
    }
    case "SET_SPACE": {
      return { ...state, spaceValue: action.payload };
    }
    case "SET_DURATION": {
      return {
        ...state,
        durationValue: action.payload,
        customDurationStart: undefined,
        customDurationEnd: undefined,
      };
    }
    case "SET_CUSTOM_DURATION_START": {
      return { ...state, customDurationStart: action.payload };
    }
    case "SET_CUSTOM_DURATION_END": {
      return { ...state, customDurationEnd: action.payload };
    }
    default:
      return state;
  }
};
