import { css } from "@emotion/core";
import type { ReactNode } from "react";
import type { Placement } from "react-aria";
import type {
  MenuItemProps as ReactAriaMenuItemProps,
  MenuProps as ReactAriaMenuProps,
} from "react-aria-components";
import {
  Menu as ReactAriaMenu,
  MenuItem as ReactAriaMenuItem,
  Separator as ReactAriaMenuSeparator,
  MenuTrigger as ReactAriaMenuTrigger,
  Popover as ReactAriaPopover,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";
import { listBoxStyles } from "./nc-list-box";

const styles = {
  item: css`
    cursor: pointer;
  `,
  popover: css`
    background-color: ${ncTheme.colors.light};
    border: ${ncTheme.border()};
    border-radius: ${ncTheme.borderRadius.small};
    min-width: var(--trigger-width);
    box-shadow: ${ncTheme.shadows.large};
    overflow-y: auto;
    a[role="menuitem"],
    div[role="menuitem"] {
      border-bottom: none;
    }
  `,
  separator: css`
    height: 1px;
    background-color: ${ncTheme.colors.ui};
  `,
};

interface NcMenuProps<T extends object> extends ReactAriaMenuProps<T> {
  placement?: Placement;
}

const NcMenu = function <T extends object>({ placement, ...props }: NcMenuProps<T>) {
  return (
    <ReactAriaPopover data-nc="NcMenu" css={styles.popover} placement={placement}>
      <ReactAriaMenu css={listBoxStyles.list} {...props} />
    </ReactAriaPopover>
  );
};

export interface MenuItemProps extends ReactAriaMenuItemProps {
  children: ReactNode;
  variant?: keyof typeof listBoxStyles.item.variant;
}

const MenuItem = ({ children, variant = "default", ...props }: MenuItemProps) => (
  <ReactAriaMenuItem
    data-nc="NcMenu.Item"
    css={[styles.item, listBoxStyles.item.base, listBoxStyles.item.variant[variant]]}
    {...props}
  >
    {children}
  </ReactAriaMenuItem>
);

const Separator = ({ ...props }) => <ReactAriaMenuSeparator css={styles.separator} {...props} />;

NcMenu.Item = MenuItem;
NcMenu.Trigger = ReactAriaMenuTrigger;
NcMenu.Separator = Separator;

export { NcMenu };
