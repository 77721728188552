import { DateTime } from "luxon";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";

import { IResource } from "../resources-context";

interface IMenuContext {
  menu?: { resource: IResource; time: DateTime };
  setMenu: Dispatch<SetStateAction<{ resource: IResource; time: DateTime } | undefined>>;
}

export const MenuContext = createContext<IMenuContext>({ setMenu: () => {} });

export const useMenu = () => useContext<IMenuContext>(MenuContext);

export const MenuState = ({ children }: { children: ReactNode }) => {
  const [menu, setMenu] = useState<{ resource: IResource; time: DateTime }>();
  const value = useMemo(
    () => ({
      menu,
      setMenu,
    }),
    [menu]
  );
  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};
