import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconSortAscending = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M11.216 1.36a1.034 1.034 0 0 1 1.568 0l5.97 6.96c.567.663.092 1.68-.784 1.68H6.03c-.876 0-1.35-1.017-.783-1.68z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="m18 16-5.304 5.706a.97.97 0 0 1-1.392 0L6 16"
    />
  </NcIcon>
);
export { NcIconSortAscending };
