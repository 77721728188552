import { variant } from "styled-system";

import styled, { theme } from "../../theme";
import Flex from "../primitives/flex";

const bannerVariants = {
  variants: {
    default: {
      color: theme.colors.neutral.dark,
      backgroundColor: theme.colors.neutral.lightest,
    },
    warning: {
      color: theme.colors.warning.darkest,
      backgroundColor: theme.colors.warning.lightest,
    },
    danger: {
      color: theme.colors.danger.darkest,
      backgroundColor: theme.colors.danger.lightest,
    },
    info: {
      color: theme.colors.info.mediumDark,
      backgroundColor: theme.colors.info.lightest,
    },
    success: {
      color: theme.colors.success.darkest,
      backgroundColor: theme.colors.success.lightest,
    },
  },
};

export const Banner = styled(Flex)`
  ${variant(bannerVariants)};
`;

Banner.displayName = "Banner";

Banner.defaultProps = {
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  variant: "default",
  flexDirection: ["column", "row"],
  p: 3,
};
