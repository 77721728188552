import { ComponentProps, forwardRef, useEffect, useState } from "react";

import { ButtonGroup } from "../buttons/button-group";
import Button from "./button";

type RadioButtonKey = string;

interface IRadioButtonGroupProps {
  options: Array<{ key: RadioButtonKey; value: string }>;
  value?: RadioButtonKey;
  onChange?: (value: RadioButtonKey) => void;
  selectedVariant?: string;
  name: string;
  disabled?: boolean;
}

export const RadioButtonGroup = forwardRef<
  HTMLInputElement,
  IRadioButtonGroupProps & Omit<ComponentProps<typeof ButtonGroup>, "onChange">
>(({ options, value, onChange, name, disabled, ...flexProps }, ref) => {
  const [uncontrolledValue, setUncontrolledValue] = useState(value);
  const onClick = (key: RadioButtonKey) => (onChange && onChange(key)) || setUncontrolledValue(key);

  useEffect(() => setUncontrolledValue(value), [value]);

  return (
    <ButtonGroup width="100%" {...flexProps}>
      {options.map(option => (
        <li key={option.key} aria-current={uncontrolledValue === option.key}>
          <Button disabled={disabled} variant="default" onClick={() => onClick(option.key)}>
            {option.value}
          </Button>
        </li>
      ))}
      {ref && (
        <input value={uncontrolledValue} readOnly={true} name={name} hidden={true} ref={ref} />
      )}
    </ButtonGroup>
  );
});
RadioButtonGroup.displayName = "RadioButtonGroup";

export default RadioButtonGroup;
