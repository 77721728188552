import { postcodeValidator } from "postcode-validator";
import { useContext } from "react";
import { useForm } from "react-hook-form";

import { AccountContext } from "~/account/account.context";
import { useI18n, useI18nCountries } from "~/hooks/use-i18n";
import { Button } from "~/shared/components/forms";
import { Form, FormGroup, FormRow, Input2 as Input, Label } from "~/shared/ui";

import { BillingDetails } from "./billing";

interface IUpgradeForm {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postalCode: string;
  city: string;
  state: string;
  countryCode: string;
}

interface IBillingDetailsForm {
  onSubmit: (data: BillingDetails) => void;
  isUpgrade?: boolean;
  details: BillingDetails;
}

const BillingDetailsForm = ({ onSubmit, isUpgrade = false, details }: IBillingDetailsForm) => {
  const { t } = useI18n("admin-org");
  const { countries } = useI18nCountries();
  const { organisation } = useContext(AccountContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUpgradeForm>();

  const submitBillingForm = (fields: IUpgradeForm) => {
    onSubmit(fields);
  };

  const requiredMsg = (field: string) => t("form_validation.required", { field });
  return (
    <Form
      width="100%"
      onSubmit={handleSubmit(submitBillingForm)}
      data-testid="billing-details-form"
    >
      <FormGroup>
        <legend>{t("admin-org:organisation.billing.details")}</legend>

        <FormRow>
          <Label htmlFor="firstName">{t("admin-org:organisation.billing.first_name")}</Label>
          <Input
            {...register("firstName", {
              required: requiredMsg(t("admin-org:organisation.billing.first_name")),
            })}
            data-testid="firstName"
            data-cy="firstName"
            id="firstName"
            defaultValue={details?.firstName || ""}
            error={errors.firstName?.message}
          />
        </FormRow>

        <FormRow>
          <Label htmlFor="lastName">{t("admin-org:organisation.billing.last_name")}</Label>
          <Input
            {...register("lastName", {
              required: requiredMsg(t("admin-org:organisation.billing.last_name")),
            })}
            data-testid="lastName"
            data-cy="lastName"
            id="lastName"
            defaultValue={details?.lastName || ""}
            error={errors.lastName?.message}
          />
        </FormRow>

        <FormRow>
          <Label htmlFor="addressLine1">{t("admin-org:organisation.billing.address_1")}</Label>
          <Input
            {...register("addressLine1", {
              required: requiredMsg(t("admin-org:organisation.billing.address_1")),
            })}
            data-testid="addressLine1"
            data-cy="addressLine1"
            id="addressLine1"
            defaultValue={details?.addressLine1 || ""}
            error={errors.addressLine1?.message}
          />
        </FormRow>

        <FormRow>
          <Label htmlFor="addressLine2">{t("admin-org:organisation.billing.address_2")}</Label>
          <Input
            data-testid="addressLine2"
            data-cy="addressLine2"
            id="addressLine2"
            defaultValue={details.addressLine2 ?? ""}
            {...register("addressLine2")}
          />
        </FormRow>

        <FormRow>
          <Label htmlFor="addressLine3">{t("admin-org:organisation.billing.address_3")}</Label>
          <Input
            data-testid="addressLine3"
            data-cy="addressLine3"
            id="addressLine3"
            defaultValue={details.addressLine3 ?? ""}
            {...register("addressLine3")}
          />
        </FormRow>

        <FormRow>
          <Label htmlFor="postalCode">{t("admin-org:organisation.billing.post_code")}</Label>
          <Input
            {...register("postalCode", {
              required: requiredMsg(t("admin-org:organisation.billing.post_code")),
              validate: (value: string) =>
                postcodeValidator(value, details.countryCode || organisation.countryCode) ||
                t("admin-org:organisation.billing.invalid_post_code"),
            })}
            data-cy="postalCode"
            data-testid="postalCode"
            id="postalCode"
            defaultValue={details?.postalCode || ""}
            error={errors.postalCode?.message}
          />
        </FormRow>

        <FormRow>
          <Label htmlFor="city">{t("admin-org:organisation.billing.city")}</Label>
          <Input
            {...register("city", {
              required: requiredMsg(t("admin-org:organisation.billing.city")),
            })}
            data-testid="city"
            data-cy="city"
            id="city"
            defaultValue={details?.city || ""}
            error={errors.city?.message}
          />
        </FormRow>

        <FormRow>
          <Label htmlFor="state">{t("admin-org:organisation.billing.state_suburb")}</Label>
          <Input
            data-testid="state"
            data-cy="state"
            id="state"
            defaultValue={details.state ?? ""}
            {...register("state")}
          />
        </FormRow>

        <FormRow>
          <Label htmlFor="countryCode">{t("country")}</Label>
          <Input
            name="countryCode"
            defaultValue={countries[details.countryCode || organisation.countryCode]}
            disabled={true}
            data-testid="countryCode"
            data-cy="countryCode"
          />
        </FormRow>
      </FormGroup>

      <FormRow variant="submit">
        <Button
          type="submit"
          variant="primary"
          data-testid="billing-details-submit"
          data-cy="billing-details-submit"
        >
          {isUpgrade
            ? t("admin-org:organisation.billing.submit")
            : t("admin-org:organisation.billing.update")}
        </Button>
        {!isUpgrade && (
          <Button
            data-testid="billing-details-discard"
            data-cy="billing-details-discard"
            onClick={() => reset()}
          >
            {t("admin-org:organisation.billing.discard_changes")}
          </Button>
        )}
      </FormRow>
    </Form>
  );
};

export default BillingDetailsForm;
