import { ComponentProps, forwardRef } from "react";

import { Box } from "../components/primitives";
import { childrenCount } from "../functions/children-count";

const FormRow = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof Box> & { variant?: "vertical" | "horizontal" | "submit" }
>(({ variant = "vertical", ...props }, ref) => {
  const childLayout =
    variant === "vertical"
      ? { width: "100%" }
      : {
          gridTemplateColumns: `repeat(${childrenCount(props.children)}, auto)`,
          width: "max-content",
          mt: variant === "submit" ? 4 : undefined,
        };

  const boxProps = {
    display: "grid",
    gridGap: "2",
    alignItems: "center",
    ...childLayout,
    ...props,
  };

  return <Box {...boxProps} ref={ref} />;
});

FormRow.displayName = "FormRow";

export default FormRow;
