let globalToken: undefined | string;

const TOKEN_KEY = "ngStorage-token";

export const clearToken = () => setToken("");

export const setToken = (token?: string) => {
  globalToken = token;
  if (!token) {
    localStorage.removeItem(TOKEN_KEY);
  } else {
    localStorage.setItem(TOKEN_KEY, `"${token}"`);
  }
};

export const getToken = () => {
  globalToken = (localStorage.getItem(TOKEN_KEY) ?? globalToken ?? '""').replace(/"/g, "");
  return globalToken;
};
