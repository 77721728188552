import { useMemo, useState } from "react";
import { useDebounce } from "use-debounce";

import { config } from "../../administration/users/config";

export const useEmailState = () => {
  const [email, setEmail] = useState("");
  const [debouncedEmail] = useDebounce(email, 500);
  const badPattern = debouncedEmail && !config.email.pattern.exec(debouncedEmail);
  const errors = useMemo(
    () =>
      badPattern
        ? {
            ...(badPattern ? { badPattern } : {}),
          }
        : undefined,
    [badPattern]
  );

  return useMemo(
    () => ({
      email,
      setEmail,
      errors,
    }),
    [errors, email]
  );
};

export default useEmailState;
