import styled, { theme } from "../../theme";
import Flex from "../primitives/flex";

/**
 * @deprecated Use Label from `/shared/ui` instead.
 */
export const Label = styled(Flex)`
  color: ${theme.colors.neutral.darkest};
  box-sizing: border-box;
  font-family: inherit;
  cursor: pointer;
  &:first-letter {
    text-transform: uppercase;
  }
`.withComponent("label");

Label.displayName = "Label";

Label.defaultProps = {
  mb: 2,
  lineHeight: 1.3,
  width: "100%",
  fontWeight: 600,
};

export default Label;
