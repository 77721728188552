export const WHANAU_URL_BASE = "whanau";

export const WHANAU_URL = `/n/${WHANAU_URL_BASE}`;

export const NEW_WHANAU_URL_BASE = `${WHANAU_URL_BASE}/new`;
export const NEW_WHANAU_URL = `/n/${NEW_WHANAU_URL_BASE}`;

export const WHANAU_EDIT_URL = "edit";
export const WHANAU_SESSIONS_URL = "sessions";

export const CLIENT_URL = "/n/clients";
