import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconChevronRight = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={3.5}
      d="m9 4 6.657 7.072c.457.486.457 1.37 0 1.856L9 20"
    />
  </NcIcon>
);
export { NcIconChevronRight };
