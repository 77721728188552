import { forwardRef } from "react";

import { useI18n } from "~/hooks/use-i18n";

import MessageBox from "../shared/components/message-box/message-box";
import { Box, Text } from "../shared/components/primitives";

export const PasswordHint = forwardRef<HTMLDivElement, Parameters<typeof MessageBox>[0]>(
  (props, ref) => {
    const { t } = useI18n("account");
    return (
      <MessageBox variant="info" ref={ref} {...props}>
        <Text as="h2" fontSize="3" mb="2">
          {t("account:password_hint.title")}
        </Text>
        <Dl>
          <Dt>{t("account:password_hint.unique")}</Dt>
          <Dd mb="4">{t("account:password_hint.unique_desc")}</Dd>
          <Dt>{t("account:password_hint.random")}</Dt>
          <Dd mb="4">{t("account:password_hint.random_desc")}</Dd>
          <Dt>{t("account:password_hint.memorable")}</Dt>
          <Dd>{t("account:password_hint.memorable_desc")}</Dd>
        </Dl>
      </MessageBox>
    );
  }
);
PasswordHint.displayName = "PasswordHint";

export default PasswordHint;

const Dl = Box.withComponent("dl");
const Dt = Box.withComponent("dt");
const Dd = Box.withComponent("dd");

Dt.defaultProps = {
  fontWeight: "bold",
};
