import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";

import { useI18n } from "~/hooks/use-i18n";

import { AccountContext } from "../../account/account.context";
import { restPoster } from "../../graphql-hooks/custom-fetcher";
import Button from "../../shared/components/forms/button";
import MessageBox from "../../shared/components/message-box/message-box";
import { Box, Text } from "../../shared/components/primitives";

const Unverified = () => {
  const { t } = useI18n("admin-org");
  const { organisation, account } = useContext(AccountContext);
  const [didSendVerificationEmail, setDidSendVerificationEmail] = useState(false);
  const { mutate: sendVerificationEmail } = useMutation({
    mutationFn: (username: string) => restPoster("/v1/signup/resendWelcomeEmail", { username }),
    onSuccess: () => {
      setDidSendVerificationEmail(true);
    },
  });
  const onVerifyClicked = () => {
    sendVerificationEmail(account.username);
  };
  return (
    <Box maxWidth="60rem">
      <MessageBox mt="4" data-testid="unverified-info-box">
        <Text fontWeight={500} fontSize="4" mt="0" mb="2" as={"h3"}>
          {t("admin-org:subscriptions.upgrade.verify_account_message")}
        </Text>
        <Text mb="4" fontSize="2">
          {t("admin-org:subscriptions.upgrade.verify_account_description")}
        </Text>
        <Button mb="4" data-testid="resend-verification-button" onClick={onVerifyClicked}>
          {t("admin-org:subscriptions.upgrade.verify_button")}
        </Button>
        {didSendVerificationEmail && (
          <Text mb="4" fontSize="2" data-testid="did-send-verification-email">
            {t("admin-org:subscriptions.upgrade.verify_account_email_sent", {
              email: organisation.email,
            })}
          </Text>
        )}
      </MessageBox>
    </Box>
  );
};

export default Unverified;
