export const getSearchNormalizedString = (str: string) =>
  `${str}`
    .toLowerCase()
    .normalize("NFD")
    .replace(
      //Unicode 0300-036F = Combining Diacritical Marks
      /[\u0300-\u036f]/g,
      ""
    )
    .replace(/[.\\'’+*?[^\]$(){}=!<>|:-]/g, "");
