import { css, keyframes } from "@emotion/core";
import type { ReactNode } from "react";
import type { PopoverProps as ReactAriaPopoverProps } from "react-aria-components";
import {
  OverlayArrow,
  DialogTrigger as ReactAriaDialogTrigger,
  Popover as ReactAriaPopover,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";

const animations = {
  slide: keyframes`
      from {
        transform: var(--origin);
        opacity: 0;
      }
    
      to {
        transform: translateY(0);
        opacity: 1;
      }
    `,
};

export const popoverStyles = {
  popover: css`
    padding-inline: ${ncTheme.spacing(4)};
    padding-block: ${ncTheme.spacing(2)};
    border-radius: ${ncTheme.borderRadius.small};
    background-color: ${ncTheme.colors.light};
    border: ${ncTheme.border()};
    box-shadow: ${ncTheme.shadows.large};
    max-width: 18rem;

    &[data-entering] {
      animation: ${animations.slide} 200ms;
    }

    &[data-exiting] {
      animation: ${animations.slide} 200ms reverse ease-in;
    }

    &[data-placement="top"] {
      --origin: translateY(4px);
    }

    &[data-placement="bottom"] {
      --origin: translateY(-4px);
    }

    &[data-placement="right"] {
      --origin: translateX(-4px);
    }

    &[data-placement="left"] {
      --origin: translateX(4px);
    }
  `,
  popoverArrow: css`
    svg {
      display: block;
      fill: ${ncTheme.colors.light};
      stroke: ${ncTheme.colors.ui};
    }

    &[data-placement="right"] {
      svg {
        rotate: 90deg;
      }
    }

    &[data-placement="bottom"] {
      svg {
        rotate: 180deg;
      }
    }

    &[data-placement="left"] {
      svg {
        rotate: -90deg;
      }
    }
  `,
};

interface PopoverProps extends ReactAriaPopoverProps {
  children: ReactNode;
}

const NcPopover = ({ children, placement, ...props }: PopoverProps) => {
  return (
    <ReactAriaPopover
      data-nc="NcPopover"
      css={popoverStyles.popover}
      placement={placement}
      {...props}
    >
      <OverlayArrow css={popoverStyles.popoverArrow}>
        <svg width={8} height={8} viewBox="0 0 8 8">
          <path d="M0 0 L4 4 L8 0" />
        </svg>
      </OverlayArrow>
      {children}
    </ReactAriaPopover>
  );
};

interface PopoverTriggerProps {
  children: ReactNode;
}

const PopoverTrigger = ({ ...props }: PopoverTriggerProps) => {
  return <ReactAriaDialogTrigger data-nc="NcPopover.Trigger" {...props} />;
};

NcPopover.Trigger = PopoverTrigger;

export { NcPopover };
