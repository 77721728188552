import { ComponentProps } from "react";

import styled from "../../theme";
import Flex from "../primitives/flex";

export const Page = styled(Flex)<ComponentProps<typeof Flex>>``;

Page.displayName = "Page";
Page.defaultProps = {
  width: "100%",
  height: "100%",
  overflow: "auto",
  flex: 1,
  zIndex: 0,
  justifyContent: "center",
};

export default Page;
