import styled, { theme } from "../../theme";
import Box from "../primitives/box";

export const TableHeaderRow = styled(Box)`
  border-color: ${theme.colors.neutral.medium};
`.withComponent("tr");

TableHeaderRow.displayName = "TableHeaderRow";

TableHeaderRow.defaultProps = {
  alignItems: "center",
};

export default TableHeaderRow;
