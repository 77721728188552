import { ComponentProps } from "react";

import styled from "../../theme";
import Text from "../primitives/text";

export const TableCell = styled(Text)<ComponentProps<typeof Text>>`
  vertical-align: middle;
`.withComponent("td");

TableCell.displayName = "TableCell";

TableCell.defaultProps = {
  p: 3,
};

export default TableCell;
