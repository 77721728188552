import { NcButton, NcModal } from "@noted/noted-components";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { AccountContext } from "~/account/account.context";
import {
  ClientInviteResponse,
  ClientPayload,
  DuplicateClient,
  SendInvitePayload,
  useDuplicateClientsQuery,
  useInviteClientMutation,
  useSendInviteMutation,
} from "~/dashboard/clients/client-invite-queries";
import DuplicateClientsForm from "~/dashboard/clients/duplicate-clients-form";
import InviteClientForm from "~/dashboard/clients/invite-client-form";
import { usePendingInvitesQuery } from "~/dashboard/dashboard-queries";
import { useI18n } from "~/hooks/use-i18n";
import { useToast } from "~/shared/components/alerts/toast-provider";

interface InviteClientProps {
  isInviteModalOpen: boolean;
  setIsInviteModalOpen: Dispatch<SetStateAction<boolean>>;
}

export type InviteClientFormData = {
  firstName: string;
  lastName: string;
  email: string;
  inviteSubject: string;
  inviteMessage: string;
  inviteHeading: string;
  inviteButton: string;
};

const getClientDataInput = ({ firstName, lastName, email }: InviteClientFormData) =>
  ({ firstName, lastName, email }) as ClientPayload;

const getInviteFormInput = ({
  inviteSubject,
  inviteMessage,
  inviteHeading,
  inviteButton,
}: InviteClientFormData) =>
  ({
    inviteSubject,
    inviteMessage,
    inviteHeading,
    inviteButton,
  }) as unknown as SendInvitePayload;

const getObjectsFromFormInput = (values: InviteClientFormData) => ({
  clientData: getClientDataInput(values),
  inviteData: getInviteFormInput(values),
});

const InviteClient = ({ setIsInviteModalOpen, isInviteModalOpen }: InviteClientProps) => {
  const { t } = useI18n(["org", "admin-client-settings"]);
  const [selectedDuplicate, setSelectedDuplicate] = useState<DuplicateClient | undefined | null>(
    null
  );
  const queryClient = useQueryClient();
  const { enqueueError, enqueueSuccess } = useToast();
  const { organisation } = useContext(AccountContext);
  const settings = JSON.parse(organisation.settingsJson || "{}") as {
    clientInviteDefaults?: { [key: string]: string };
  };

  const defaultValues = {
    ...(t("admin-client-settings:clientInvite.defaults", { returnObjects: true }) as unknown as {
      [key: string]: string;
    }),
    ...settings.clientInviteDefaults,
  };

  const [resolvingDuplicates, setResolvingDuplicates] = useState(false);

  const inviteFormId = "invite-client-form";
  const form = useForm<InviteClientFormData>({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, getValues, reset } = form;

  const { clientData, inviteData } = getObjectsFromFormInput(getValues());

  const { data, isLoading: isLoadingDuplicates } = useDuplicateClientsQuery(
    resolvingDuplicates,
    clientData
  );

  useEffect(() => {
    if (data?.length === 0) {
      setResolvingDuplicates(false);
      createNewClient();
    }
  }, [data]);

  const duplicateClients = data || [];

  const { mutate: doSendInvite, isPending: isSendingInvite } = useSendInviteMutation();

  const { mutate: doCreateClient, isPending: isCreatingClient } = useInviteClientMutation();

  const isLoading = isLoadingDuplicates || isSendingInvite || isCreatingClient;

  const onError = () => {
    enqueueError(t("org:dashboard.clients.invite.error"));
  };

  const sendInvite = (clientId: number) => {
    doSendInvite(
      { clientId, variables: inviteData },
      {
        onSuccess() {
          enqueueSuccess(t("org:dashboard.clients.invite.success", { email: clientData.email }));
          onClose();
        },
        onError,
      }
    );
  };

  const useExistingClient = (client: DuplicateClient) => {
    sendInvite(client.id);
  };

  const createNewClient = () => {
    doCreateClient(
      { variables: clientData },
      {
        onSuccess(client: ClientInviteResponse) {
          if (client.id) {
            return sendInvite(client.id);
          }
        },
        onError,
      }
    );
  };

  const handleSubmitDuplicate = () => {
    if (selectedDuplicate === undefined) {
      return createNewClient();
    }
    if (selectedDuplicate) {
      return useExistingClient(selectedDuplicate);
    }
  };

  const onClose = () => {
    reset();
    setResolvingDuplicates(false);
    setSelectedDuplicate(null);
    // invalidate to ensure the check is not cached
    queryClient.invalidateQueries({ queryKey: useDuplicateClientsQuery.getKey() });
    // invalidate to ensure the new invite is shown
    queryClient.invalidateQueries({ queryKey: usePendingInvitesQuery.getKey() });
    setIsInviteModalOpen(false);
  };

  const onSubmitInvited = handleSubmit(() => {
    setResolvingDuplicates(true);
  });

  return (
    <NcModal isOpen={isInviteModalOpen} onOpenChange={setIsInviteModalOpen} className="max-w-2xl">
      <NcModal.Header>
        <NcModal.Heading>{t("org:dashboard.clients.invite.title")}</NcModal.Heading>
      </NcModal.Header>
      <NcModal.Body isLoading={isLoading}>
        {resolvingDuplicates ? (
          <DuplicateClientsForm
            duplicateClients={duplicateClients}
            setSelectedDuplicate={setSelectedDuplicate}
          />
        ) : (
          <InviteClientForm form={form} onSubmit={onSubmitInvited} formId={inviteFormId} />
        )}
      </NcModal.Body>
      <NcModal.Footer>
        <NcButton onPress={() => onClose()}>{t("org:dashboard.clients.invite.cancel")}</NcButton>
        {resolvingDuplicates ? (
          <NcButton
            variant="primary"
            onPress={handleSubmitDuplicate}
            isDisabled={selectedDuplicate === null}
          >
            {t("org:dashboard.clients.invite.select")}
          </NcButton>
        ) : (
          <NcButton variant="primary" type="submit" form={inviteFormId}>
            {t("org:dashboard.clients.invite.send")}
          </NcButton>
        )}
      </NcModal.Footer>
    </NcModal>
  );
};
export default InviteClient;
