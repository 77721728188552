import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconPlus = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="M12 3v18M21 12H3"
    />
  </NcIcon>
);
export { NcIconPlus };
