import * as Sentry from "@sentry/react";
import { FallbackRender } from "@sentry/react/types/errorboundary";
import { ReactElement, ReactNode } from "react";

const ErrorBoundary = ({
  children,
  fallback,
}: {
  children: ReactNode;
  fallback: ReactElement | FallbackRender;
}) => {
  return <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>;
};

export default ErrorBoundary;
