import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";

import { useI18n } from "~/hooks/use-i18n";

import { config } from "../administration/users/config";
import { restPoster } from "../graphql-hooks/custom-fetcher";
import { useToast } from "../shared/components/alerts/alerts";
import { LinkAsButton } from "../shared/components/buttons";
import { Button } from "../shared/components/forms";
import { PagePublic, Shell } from "../shared/components/layout";
import {
  OverlayMessageBox,
  OverlayMessageBoxContent,
  OverlayMessageBoxHighlight,
} from "../shared/components/overlay-message-box";
import { PageTitle, Text } from "../shared/components/primitives";
import { useIsMounted } from "../shared/hooks/use-is-mounted";
import { ExclamationCircleOutline, LogoIcon } from "../shared/icons";
import { theme } from "../shared/theme";
import { Feedback, Form, FormRow, Input, Label } from "../shared/ui";
import PasswordHint from "./password-hint";

const resetPasswordRequest = z.object({
  newPassword: z
    .string()
    .min(config.password.minLength, "account:password.min_length")
    .max(config.password.maxLength, "account:password.max_length"),
  token: z.string(),
});

const resetPasswordForm = resetPasswordRequest
  .extend({
    confirmPassword: z.string().min(1, "account:password.confirmation_required"),
  })
  .refine(values => values.newPassword === values.confirmPassword, {
    path: ["confirmPassword"],
    message: "account:password.mismatch",
  });

type ResetPasswordRequest = z.infer<typeof resetPasswordRequest>;
type ResetPasswordForm = z.infer<typeof resetPasswordForm>;

const ResetPassword = () => {
  const { t } = useI18n("account");
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ResetPasswordForm>({ resolver: zodResolver(resetPasswordForm), mode: "onChange" });

  const {
    isPending: saving,
    error,
    mutate: resetPassword,
  } = useMutation({
    mutationFn: (body: ResetPasswordRequest) => {
      resetPasswordRequest.parse(body);
      return restPoster("/v1/auth/tokenResetPassword", body);
    },
    onSuccess() {
      enqueueSuccess(t("account:reset_password.update_success"));
      if (isMounted()) {
        navigate("/");
      }
    },
    onError() {
      enqueueError(t("account:reset_password.update_error"));
    },
  });
  const { enqueueError, enqueueSuccess } = useToast();

  const location = useLocation();
  const isMounted = useIsMounted();

  const params = new URLSearchParams(location.search);
  const tokenParam = params.get("token");
  const onSubmit = handleSubmit(async values => {
    const { token, newPassword } = values;
    resetPassword({ token, newPassword });
  });

  return (
    <Shell>
      <PagePublic>
        {!tokenParam || error ? (
          <OverlayMessageBox>
            <OverlayMessageBoxHighlight bg={theme.colors.warning.mediumDark}>
              <ExclamationCircleOutline />
            </OverlayMessageBoxHighlight>

            {error && error.message === "Token doesn't exist or has expired" && (
              <OverlayMessageBoxContent>
                <PageTitle>{t("account:reset_password.token_expired")}</PageTitle>
                <Text mb="4">{t("account:reset_password.token_expired_description")}</Text>
                <LinkAsButton to="/login" mb="2">
                  {t("account:reset_password.back_to_login")}
                </LinkAsButton>
              </OverlayMessageBoxContent>
            )}

            {!tokenParam && (
              <OverlayMessageBoxContent>
                <PageTitle>{t("account:reset_password.token_missing")}</PageTitle>
                <Text mb="4">{t("account:reset_password.token_missing_description")}</Text>
                <LinkAsButton to="/login" mb="2">
                  {t("account:reset_password.back_to_login")}
                </LinkAsButton>
              </OverlayMessageBoxContent>
            )}
          </OverlayMessageBox>
        ) : (
          <OverlayMessageBox>
            <OverlayMessageBoxHighlight>
              <LogoIcon />
            </OverlayMessageBoxHighlight>
            <OverlayMessageBoxContent>
              <PageTitle>{t("account:reset_password.title")}</PageTitle>
              <Form onSubmit={onSubmit}>
                <Input
                  type="hidden"
                  id="token"
                  value={tokenParam}
                  {...register("token", { required: true })}
                />
                <FormRow>
                  <Label htmlFor="newPassword">{t("account:reset_password.new_password")}</Label>
                  <Input
                    autoComplete="new-password"
                    required
                    id="newPassword"
                    type="password"
                    {...register("newPassword")}
                  />
                  {errors?.newPassword?.message && (
                    <Feedback>{t(errors.newPassword.message, config.password)}</Feedback>
                  )}
                </FormRow>

                <FormRow>
                  <Label htmlFor="confirmPassword">
                    {t("account:reset_password.confirm_password")}
                  </Label>
                  <Input
                    id="confirmPassword"
                    type="password"
                    required
                    {...register("confirmPassword")}
                  />
                  {errors?.confirmPassword?.message && (
                    <Feedback>{t(errors.confirmPassword.message)}</Feedback>
                  )}
                </FormRow>
                <PasswordHint my="4" />

                <Button type="submit" variant="primary" disabled={saving} mb="2">
                  {t("account:reset_password.submit_button")}
                </Button>
              </Form>
            </OverlayMessageBoxContent>
          </OverlayMessageBox>
        )}
      </PagePublic>
    </Shell>
  );
};
export default ResetPassword;
