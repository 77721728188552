import styled, { theme } from "../../theme";
import Flex from "../primitives/flex";

export const PageHeader = styled(Flex)`
  color: ${theme.colors.neutral.dark};
  background-color: ${theme.colors.neutral.lightest};
  border-bottom: solid 1px ${theme.colors.neutral.medium};
`;

PageHeader.displayName = "PageHeader";

PageHeader.defaultProps = {
  position: "sticky",
  top: 0,
  zIndex: 5,
  pt: [3, 6],
  pb: [3, 4],
  mx: [5, 6],
};

export default PageHeader;
