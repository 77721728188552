import { Team } from "~/graphql-hooks/types";
import { ClientTeam } from "~/org/clients/teams-client-lists/types";

export const separateTeams = <Type>(teams: Array<Type>, separator: (t: Type) => boolean) => {
  // partition teams by function
  const [defaultTeam, remainingTeams] = teams.reduce(
    (acc, team) => {
      if (separator(team)) {
        return [[...acc[0], team], acc[1]];
      }
      return [acc[0], [...acc[1], team]];
    },
    [[], []] as [Array<Type>, Array<Type>]
  );

  return [defaultTeam, remainingTeams];
};

export const mergePartitionedTeams = (
  defaultTeam: Array<Team | ClientTeam>,
  remaining: Array<Team | ClientTeam>,
  descriptionOverride?: string
) => {
  const description = descriptionOverride ? { description: descriptionOverride } : {};
  return defaultTeam.length === 0
    ? remaining
    : [{ ...defaultTeam[0], ...description }, ...remaining];
};
