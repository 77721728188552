import { useContext } from "react";

import { useI18n } from "~/hooks/use-i18n";

import { AccountContext } from "../../account/account.context";
import { useFeatures } from "../../account/features.context";
import Button from "../../shared/components/forms/button";
import MessageBox from "../../shared/components/message-box/message-box";
import { Box, Text } from "../../shared/components/primitives";

const PaymentConfirmed = () => {
  const { invalidate } = useContext(AccountContext);
  const { t } = useI18n("admin-org");
  const { refetchFeatures } = useFeatures();

  const onClick = () => {
    invalidate().then(() => {
      refetchFeatures();
    });
  };
  return (
    <Box>
      <MessageBox>
        <Text as="h2" fontSize="5">
          {t("admin-org:subscriptions.upgrade.thank_you")}
        </Text>
        <Text as="p" fontSize="4" mb="3">
          {t("admin-org:subscriptions.upgrade.payment_confirmed_title")}
        </Text>
        <Text as="p" mb="2">
          {t("admin-org:subscriptions.upgrade.payment_confirmed_description")}
        </Text>
        <Text as="p">{t("admin-org:subscriptions.upgrade.payment_confirmed_signoff")}</Text>
        <Button mt="3" py="3" fontSize="3" onClick={onClick} variant="primary">
          {t("admin-org:subscriptions.upgrade.payment_confirmed_button")}
        </Button>
      </MessageBox>
    </Box>
  );
};

export default PaymentConfirmed;
