import { Key } from "react";

import { ButtonGroup } from "../buttons";
import Button from "./button";

interface ICheckboxButtonGroupProps<TKey> {
  options: Array<{ key: TKey; value: string }>;
  selectedOptions: TKey[];
  onButtonClick: (value: TKey) => void;
}

export function CheckboxButtonGroup<T extends Key>({
  options,
  selectedOptions,
  onButtonClick,
}: ICheckboxButtonGroupProps<T>) {
  return (
    <ButtonGroup width="100%">
      {options.map(option => (
        <li key={option.key} aria-current={selectedOptions?.find(v => v === option.key) && true}>
          <Button onClick={() => onButtonClick?.(option.key)}>{option.value}</Button>
        </li>
      ))}
    </ButtonGroup>
  );
}

export default CheckboxButtonGroup;
