import { useMemo, useState } from "react";
import { useDebounce } from "use-debounce";

import { config } from "../../administration/users/config";
import { usePublicUsernameFormValidation } from "../../hooks/use-username-validation";

export const useUsernameState = () => {
  const [username, setUsername] = useState("");
  const [debouncedUsername] = useDebounce(username, 500);
  const tooLong = debouncedUsername.length > config.username.maxLength;
  const tooShort = debouncedUsername && debouncedUsername.length < config.username.minLength;
  const badPattern = !config.username.pattern.exec(debouncedUsername);
  const { isFetching, isError: notUnique } = usePublicUsernameFormValidation(debouncedUsername, {
    enabled: Boolean(debouncedUsername) && !(tooLong || tooShort || badPattern),
    retry: false,
  });
  const errors = useMemo(
    () =>
      notUnique || tooLong || tooShort || badPattern
        ? {
            ...(notUnique ? { notUnique } : {}),
            ...(tooLong ? { tooLong } : {}),
            ...(tooShort ? { tooShort } : {}),
            ...(badPattern ? { badPattern } : {}),
          }
        : undefined,
    [badPattern, notUnique, tooLong, tooShort]
  );
  return useMemo(
    () => ({
      username,
      setUsername,
      validating: isFetching || debouncedUsername !== username,
      errors,
    }),
    [debouncedUsername, errors, isFetching, username]
  );
};

export default useUsernameState;
