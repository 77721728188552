import styled, { theme } from "../../theme";
import Box from "../primitives/box";

export const TableRow = styled(Box)`
  border-color: ${theme.colors.neutral.medium};
  &:last-of-type {
    border-color: transparent;
  }
`.withComponent("tr");

TableRow.displayName = "TableRow";

TableRow.defaultProps = {
  alignItems: "center",
  borderBottom: "solid 1px",
};

export default TableRow;
