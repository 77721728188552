import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconExclamationCircle = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11m-9 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-.5-11a1.5 1.5 0 0 0-3 0v5a1.5 1.5 0 0 0 3 0z"
      clipRule="evenodd"
    />
  </NcIcon>
);
export { NcIconExclamationCircle };
