import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconSpanner = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m18.32 3.549.527.534.739-.729-.924-.473zm-3.594 3.543-.527-.534-.255.251.035.357zm-2.207-2.658.527.534zm-1.155 5.143.425.618.481-.33-.202-.548zm-.316.26.526.535zm-7.37 7.265-.526-.534zm0 3.23.527-.535zm3.276 0-.527-.535zm7.37-7.266-.527-.534zm.357-.455.201-.723-.55-.152-.294.49zm4.864-1.25.526.533zm1-5.516.679-.317-.45-.962-.756.745zm-3.542 3.492-.072.746.349.034.25-.246zm-2.075-.2-.746.074.06.614.614.059zm2.866-6.122-3.595 3.543 1.053 1.068 3.595-3.543zm-4.748 1.953a4.27 4.27 0 0 1 4.933-.752l.683-1.335c-2.15-1.1-4.861-.763-6.67 1.02zm-.978 4.349a4.1 4.1 0 0 1 .978-4.349L11.993 3.9a5.6 5.6 0 0 0-1.332 5.936zm-.494 1.055q.102-.1.215-.177l-.85-1.237a3 3 0 0 0-.418.346zm-7.37 7.265 7.37-7.265-1.053-1.068-7.37 7.264zm0 2.16a1.51 1.51 0 0 1 0-2.16l-1.052-1.069a3.01 3.01 0 0 0 0 4.297zm2.223 0a1.59 1.59 0 0 1-2.222 0l-1.053 1.068a3.09 3.09 0 0 0 4.328 0zm7.37-7.265-7.37 7.265 1.053 1.068 7.37-7.264zm.241-.306a1.5 1.5 0 0 1-.241.306l1.053 1.069q.28-.279.475-.605zm4.98-1.4a4.27 4.27 0 0 1-4.136 1.062l-.401 1.446a5.77 5.77 0 0 0 5.59-1.44zm.847-4.663c.716 1.53.434 3.4-.846 4.663l1.053 1.068a5.6 5.6 0 0 0 1.152-6.366zM17.53 9.871 21.07 6.38 20.018 5.31l-3.542 3.492zm-2.674.013 2.075.2.144-1.494L15 8.39zm-.876-2.718.202 2.045 1.492-.147-.202-2.046z"
    />
  </NcIcon>
);
export { NcIconSpanner };
