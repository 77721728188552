import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconInformationCircle = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11M10 5.875a1.875 1.875 0 1 1 3.75 0 1.875 1.875 0 0 1-3.75 0M12.25 10h-1.625a.625.625 0 1 0 0 1.25h.625v6.25h-.625a.625.625 0 1 0 0 1.25h3.75a.625.625 0 1 0 0-1.25h-.625V11a1 1 0 0 0-.022-.21.625.625 0 0 0-.603-.79h-.875"
      clipRule="evenodd"
    />
  </NcIcon>
);
export { NcIconInformationCircle };
