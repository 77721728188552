import emotionStyled, { CreateStyled } from "@emotion/styled";

import { colors, IColors } from "./colors";

// TODO: Delete export
export interface ITheme {
  colors: IColors;
  fontSizes: string[];
  space: string[];
  lineHeight: number;
  breakpoints: string[];
  container: { standard: string; wide: string };
  shadows: { panel: string; popOver: string };
  border: (color?: string, variant?: "standard" | "bold") => string;
  borderRadius: { small: string; standard: string; rounded: string };
}

const shadowColor = "0deg 0% 0%";

export const theme: ITheme = {
  colors,
  fontSizes: ["0.75rem", "0.875rem", "1rem", "1.25rem", "1.5rem", "2rem", "3rem", "4rem", "5rem"],
  space: [
    "0rem",
    "0.25rem",
    "0.5rem",
    "0.75rem",
    "1rem",
    "1.5rem",
    "2rem",
    "4rem",
    "8rem",
    "16rem",
  ],
  lineHeight: 1.5,
  breakpoints: ["40em", "52em", "64em", "76em"],
  container: {
    standard: "80rem",
    wide: "110rem",
  },
  shadows: {
    panel: `0px 0.6px 1px hsl(${shadowColor} / 0.1),
0px 1px 1.2px -0.5px hsl(${shadowColor} / 0.1), 0px 2.3px 2.8px -1px hsl(${shadowColor} / 0.12)`,
    popOver: `2px 2px 2px hsl(${shadowColor} / 0.1)`,
  },
  border: (color = colors.neutral.medium, variant = "standard") => {
    if (variant === "bold") {
      return `0.2rem solid ${color}`;
    }
    return `1px solid ${color}`;
  },
  borderRadius: {
    small: "0.25rem",
    standard: "0.5rem",
    rounded: "1.5em",
  },
};

const widthBreakpoints = {
  small: {
    maximum: "639px",
  },
  medium: {
    minimum: "640px",
    maximum: "879px",
  },
  mediumLarge: {
    minimum: "880px",
    maximum: "1023px",
  },
  large: {
    minimum: "1024px",
    maximum: "1299px",
  },
  xLarge: {
    minimum: "1300px",
    maximum: "1599px",
  },
  xxLarge: {
    minimum: "1600px",
  },
};

export const visuallyHiddenStyles = `
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  word-wrap: normal;
`;

export const BREAKPOINT_XXLARGE_QUERY = `screen and (min-width: ${widthBreakpoints.xxLarge.minimum})`;
export const BREAKPOINT_XLARGE_MAX_QUERY = `screen and (max-width: ${widthBreakpoints.xLarge.maximum})`;
export const BREAKPOINT_XLARGE_QUERY = `screen and (min-width: ${widthBreakpoints.xLarge.minimum})`;
export const BREAKPOINT_LARGE_MAX_QUERY = `(max-width: ${widthBreakpoints.large.maximum})`;
export const BREAKPOINT_LARGE_QUERY = `screen and (min-width: ${widthBreakpoints.large.minimum})`;
export const BREAKPOINT_LARGE_ONLY_QUERY = `screen and (min-width: ${widthBreakpoints.large.minimum}) and (max-width: ${widthBreakpoints.large.maximum})`;
export const BREAKPOINT_MID_LARGE_MAX_QUERY = `screen and (max-width: ${widthBreakpoints.mediumLarge.maximum})`;
export const BREAKPOINT_MID_LARGE_QUERY = `screen and (min-width: ${widthBreakpoints.mediumLarge.minimum})`;
export const BREAKPOINT_MID_MAX_QUERY = `screen and (max-width: ${widthBreakpoints.medium.maximum})`;
export const BREAKPOINT_MID_QUERY = `screen and (min-width: ${widthBreakpoints.medium.minimum})`;
export const BREAKPOINT_SMALL_MAX_QUERY = `screen and (max-width: ${widthBreakpoints.small.maximum})`;

export const styled = emotionStyled as CreateStyled<ITheme>;
export default styled;
