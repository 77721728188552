import styled, { theme } from "../../theme";
import Flex from "../primitives/flex";

export const PageContentWrapper = styled(Flex)<{ withToolbar?: boolean }>`
  background-color: ${theme.colors.neutral.lightest};
  max-width: 100%;
  margin-bottom: ${props => (props?.withToolbar ? "4rem" : "unset")};
`;

PageContentWrapper.displayName = "PageContentWrapper";

PageContentWrapper.defaultProps = {
  flexDirection: "column",
  flexBasis: theme.container.standard,
  withToolbar: false,
};

export default PageContentWrapper;
