import styled from "../../theme";
import Box from "./box";

export const Flex = styled(Box)`
  display: flex;
`;

Flex.displayName = "Flex";

export default Flex;
