import { DateTime, Interval } from "luxon";
import {
  createContext,
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { getBottomFromTopAndDuration, getTopFromStartTime } from "../item-computed-functions";
import { IResource } from "../resources-context";

export const BlockedTimeContext = createContext<IBlockedTimeForm>({} as IBlockedTimeForm);

export const BlockedTimeState = ({ children }: { children: ReactNode }) => {
  const value = useLocalBlockedTime();
  return <BlockedTimeContext.Provider value={value}>{children}</BlockedTimeContext.Provider>;
};

export const useBlockedTime = () => useContext(BlockedTimeContext);

const useLocalBlockedTime = (): IBlockedTimeForm => {
  const [blockedTimeId, setBlockedTimeId] = useState<number>();
  const [resource, setResource] = useState<IResource>();
  const [from, setFrom] = useState<DateTime>();
  const [duration, setDuration] = useState<number>(30);
  // const [to, setTo] = useState<DateTime>();
  const notes = useRef<HTMLTextAreaElement>(null);

  const memoizedClear = useCallback(() => {
    setBlockedTimeId(undefined);
    setResource(undefined);
    setFrom(undefined);
    setDuration(30);
    if (notes.current) {
      notes.current.value = "";
    }
  }, []);

  const potentialBlockedTime = useMemo(() => {
    if (resource && from && duration) {
      return {
        resource,
        from,
        to: from.plus({ minutes: duration }),
      };
    }
    return undefined;
  }, [from, resource, duration]);

  const getPotentialBlockedTime = useCallback(
    (columnDay: DateTime, r: IResource) => {
      if (!potentialBlockedTime) {
        return;
      }
      if (potentialBlockedTime.resource.type !== r.type) {
        return;
      }
      if (potentialBlockedTime.resource.id !== r.id) {
        return;
      }

      const blockedTimeInterval = Interval.fromDateTimes(
        potentialBlockedTime.from,
        potentialBlockedTime.to
      );
      const dayInterval = Interval.fromDateTimes(columnDay.startOf("day"), columnDay.endOf("day"));

      if (!blockedTimeInterval.intersection(dayInterval)) {
        return;
      }

      return {
        top: `${getTopFromStartTime(columnDay, potentialBlockedTime.from)}%`,
        bottom: `${getBottomFromTopAndDuration(
          getTopFromStartTime(columnDay, potentialBlockedTime.from),
          blockedTimeInterval.toDuration().as("minutes")
        )}%`,
      };
    },
    [potentialBlockedTime]
  );

  return useMemo(
    () => ({
      resource,
      from,
      to: from?.plus({ minutes: duration }),
      setResource,
      setFrom,
      setDuration,
      clear: memoizedClear,
      notes,
      getPotentialBlockedTime,
      blockedTimeId,
      setBlockedTimeId,
    }),
    [blockedTimeId, from, getPotentialBlockedTime, memoizedClear, resource, duration]
  );
};

interface IBlockedTimeForm {
  resource?: IResource;
  from?: DateTime;
  to?: DateTime;
  notes: RefObject<HTMLTextAreaElement>;
  setResource: Dispatch<SetStateAction<IResource | undefined>>;
  setFrom: Dispatch<SetStateAction<DateTime | undefined>>;
  setDuration: Dispatch<SetStateAction<number>>;
  clear: () => void;
  getPotentialBlockedTime: (
    columnDay: DateTime,
    resource: IResource
  ) => { top: string; bottom: string } | undefined;
  blockedTimeId?: number;
  setBlockedTimeId: Dispatch<SetStateAction<number | undefined>>;
}
