import { css, keyframes } from "@emotion/core";
import type { ReactNode } from "react";

import { ncTheme } from "../nc-theme";
import { NcLoadingIndicator, type NcLoadingIndicatorProps } from "./nc-loading-indicator";

const animations = {
  fadeIn: keyframes`
      to {
          opacity: 1;
      }
    `,
};

const loadingOverlayStyles = {
  wrapper: css`
    position: relative;
    min-height: 1.5em;
  `,
  loading: css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    place-items: center;
    opacity: 0;
    background-color: ${ncTheme.colors.light}cc;
    animation: ${animations.fadeIn} 750ms forwards ease-in-out;
  `,
  loadingIcon: css`
    opacity: 0;
    animation: ${animations.fadeIn} 1000ms 500ms forwards ease-in-out;
  `,
};

type NcLoadingOverlayProps = NcLoadingIndicatorProps & {
  children?: ReactNode;
  isLoading?: boolean;
};

export const NcLoadingOverlay = ({
  variant,
  isLoading,
  children,
  ...props
}: NcLoadingOverlayProps) => (
  <div
    data-nc="NcLoadingOverlay"
    css={loadingOverlayStyles.wrapper}
    aria-live="polite"
    aria-busy={isLoading}
    {...props}
  >
    {children}
    {isLoading && (
      <div css={loadingOverlayStyles.loading}>
        <NcLoadingIndicator css={loadingOverlayStyles.loadingIcon} variant={variant} />
      </div>
    )}
  </div>
);
