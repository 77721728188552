import {
  useMutation,
  useQueryClient,
  UseQueryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";

import { useMeQuery } from "~/account/use-me-query";
import {
  GetUserDocument,
  GetUserQuery,
  GetUserQueryVariables,
  useEditableFeaturesQuery,
  useGetUserQuery,
  UserPrivilegesQuery,
  useSecurityRolesQuery,
  useUserPrivilegesQuery,
} from "~/administration/users/api";
import { customFetcher, restPoster, restPutter } from "~/graphql-hooks/custom-fetcher";
import { IUser } from "~/shared/types";

export * from "../../users/api/users.generated";

/**
 * TODO: Suspense version of generated get user graphql query should be
 * either changed to using the user REST endpoint or avoid using suspense
 */
export const useGetUserSuspenseQuery = <TData = GetUserQuery, TError = unknown>(
  variables: GetUserQueryVariables,
  options?: Omit<UseQueryOptions<GetUserQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetUserQuery, TError, TData>["queryKey"];
  }
) => {
  return useSuspenseQuery<GetUserQuery, TError, TData>({
    queryKey: ["getUser", variables],
    queryFn: customFetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables),
    ...options,
  });
};

useGetUserQuery.getKey = (variables: GetUserQueryVariables) => ["getUser", variables];

export const useUserDetails = (id: number) => {
  const { data } = useGetUserSuspenseQuery({ id });
  // TODO: Removing the IUser type here would be ideal
  return data?.user as IUser;
};
useUserDetails.getKey = (id: number) => ["getUser", { id }];

export const useUserPrivileges = () => {
  const { data } = useUserPrivilegesQuery(undefined, { staleTime: Infinity });
  return data?.userPrivileges ?? ([] as UserPrivilegesQuery["userPrivileges"]);
};

type CreateNotedIdUser = {
  title?: string;
  firstName: string;
  lastName?: string;
  email: string;
  phone?: string;
  registrationNumber?: string;
  shortCode?: string;
  organisationId: number;
  securityRoles: { key: string }[];
  addToDefaultTeam?: boolean;
  privileges: { key: string }[];
};

export type NotedIdUser = {
  id: number;
  securityRoles: {
    id: number;
    key: string;
    name: string;
    system: boolean;
  }[];
} & Omit<CreateNotedIdUser, "securityRoles">;

export function useCreateNotedIdUserMutation() {
  return useMutation({
    mutationFn: (values: CreateNotedIdUser) =>
      restPoster<CreateNotedIdUser, NotedIdUser>("/v2/users", values),
  });
}

type UpdateNotedIdUser = {
  id: number;
  title?: string;
  firstName: string;
  lastName?: string;
  email: string;
  phone?: string;
  registrationNumber?: string;
  shortCode?: string;
  organisationId: number;
  securityRoles: { key: string }[];
  privileges: { key: string }[];
  isSuspended?: boolean;
};

export function useUpdateNotedIdUserMutation() {
  const queryClient = useQueryClient();
  const { data: { user: me } = {} } = useMeQuery();

  return useMutation({
    mutationFn: (values: UpdateNotedIdUser) =>
      restPutter<UpdateNotedIdUser, NotedIdUser>(`/v2/users/${values.id}`, values),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: useUserDetails.getKey(data.id) });
      if (me?.id === data.id) {
        queryClient.invalidateQueries({ queryKey: useMeQuery.getKey() });
      }
    },
  });
}

export const useEditableFeatures = () => {
  const { data, ...rest } = useEditableFeaturesQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.editableFeatures.features,
    ...rest,
  };
};

export const useSecurityRoles = () => {
  const { data, ...rest } = useSecurityRolesQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.userSecurityRoles,
    ...rest,
  };
};
