import { css } from "@emotion/core";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import type { ButtonProps as ReactAriaButtonProps } from "react-aria-components";
import { Button as ReactAriaButton } from "react-aria-components";

import { ncTheme } from "../nc-theme";
import { NcLoadingIndicator } from "./nc-loading-indicator";

const iconButtonStyles = css`
  position: relative;
  align-items: center;
  background: transparent;
  display: flex;
  justify-content: center;
  color: ${ncTheme.colors.dark};

  &:after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    margin: -${ncTheme.spacing(2)};
  }

  &[aria-disabled="true"],
  &[data-disabled="true"],
  &:disabled {
    color: ${ncTheme.colors.disabled};
  }

  &:not([aria-disabled="true"], :disabled, [data-disabled="true"]) {
    &:hover {
      color: ${ncTheme.colors.main};
    }

    &:active,
    &[data-pressed="true"] {
      color: ${ncTheme.colors.active};
    }
  }
`;

export const linkStyles = css`
  text-decoration: underline;
  color: ${ncTheme.colors.main};
  font-weight: ${ncTheme.fontWeight.standard};

  &[aria-disabled="true"],
  &:disabled,
  &[data-disabled="true"] {
    color: ${ncTheme.colors.disabled};
    background-color: unset;
    border: unset;
  }

  &:not([aria-disabled="true"], :disabled, [data-disabled="true"]) {
    &:active,
    &[data-pressed="true"] {
      text-decoration-color: ${ncTheme.colors.active};
      color: ${ncTheme.colors.active};
    }

    &:hover {
      text-decoration-color: ${ncTheme.colors.active};
      text-decoration-thickness: ${ncTheme.borderWidth.medium};
      color: ${ncTheme.colors.active};
    }
  }
`;

export const buttonStyles = {
  base: css`
    position: relative;
    display: inline-flex;
    gap: ${ncTheme.spacing(2)};
    border-radius: ${ncTheme.borderRadius.medium};
    cursor: pointer;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition:
      color ${ncTheme.transitionSpeed.medium},
      background-color ${ncTheme.transitionSpeed.medium},
      border ${ncTheme.transitionSpeed.medium},
      text-decoration ${ncTheme.transitionSpeed.medium};
    font-weight: ${ncTheme.fontWeight.bold};
    overflow: hidden;
    color: currentColor;
    outline: none;

    &[data-focused="true"][data-focus-visible="true"] {
      ${ncTheme.utilities.outlineStyles};
    }

    &:disabled,
    &[aria-disabled="true"],
    &[data-disabled="true"] {
      background-color: ${ncTheme.colors.disabledLight};
      color: ${ncTheme.colors.disabled};
      border: ${ncTheme.border(ncTheme.colors.disabledLight, "medium")};
      cursor: not-allowed;
    }

    [aria-current]:not([aria-current="false"]) > & {
      &:not([data-disabled="true"], [aria-disabled="true"], :disabled) {
        background-color: ${ncTheme.colors.active};
        border-color: ${ncTheme.colors.active};
        color: ${ncTheme.colors.light};
      }
    }
  `,
  link: linkStyles,
  variant: {
    default: css`
      padding-inline: ${ncTheme.spacing(4)};
      padding-block: ${ncTheme.spacing(2)};
      background-color: ${ncTheme.colors.light};
      color: ${ncTheme.colors.main};

      &:not([data-disabled="true"], [aria-disabled="true"], :disabled) {
        border: ${ncTheme.border(ncTheme.colors.ui, "medium")};

        &:hover {
          color: ${ncTheme.colors.light};
          background-color: ${ncTheme.colors.main};
          border-color: ${ncTheme.colors.main};
        }

        &:active,
        &[data-pressed="true"] {
          color: ${ncTheme.colors.light};
          background-color: ${ncTheme.colors.active};
          border-color: ${ncTheme.colors.active};
        }
      }
    `,
    primary: css`
      padding-inline: ${ncTheme.spacing(4)};
      padding-block: ${ncTheme.spacing(2)};
      background-color: ${ncTheme.colors.success};
      color: ${ncTheme.colors.light};
      border: ${ncTheme.border(ncTheme.colors.successActive, "medium")};

      &:not([data-disabled="true"], [aria-disabled="true"], :disabled) {
        &:hover {
          background-color: ${ncTheme.colors.successActive};
          border-color: ${ncTheme.colors.successActive};
          color: ${ncTheme.colors.light};
        }

        &:active,
        &[data-pressed="true"] {
          background-color: ${ncTheme.colors.success};
          border-color: ${ncTheme.colors.success};
        }

        [aria-current]:not([aria-current="false"]) > & {
          background-color: ${ncTheme.colors.successActive};
          border-color: ${ncTheme.colors.successActive};
          color: ${ncTheme.colors.light};
        }
      }
    `,
    danger: css`
      padding-inline: ${ncTheme.spacing(4)};
      padding-block: ${ncTheme.spacing(2)};
      background-color: ${ncTheme.colors.light};
      color: ${ncTheme.colors.danger};

      &:not([data-disabled="true"], [aria-disabled="true"], :disabled) {
        border: ${ncTheme.border(ncTheme.colors.dangerActive, "medium")};

        &:hover {
          color: ${ncTheme.colors.light};
          background-color: ${ncTheme.colors.danger};
          border-color: ${ncTheme.colors.danger};
        }

        &:active,
        &[data-pressed="true"] {
          color: ${ncTheme.colors.light};
          background-color: ${ncTheme.colors.dangerActive};
          border-color: ${ncTheme.colors.dangerActive};
        }

        [aria-current]:not([aria-current="false"]) > & {
          background-color: ${ncTheme.colors.dangerActive};
          border-color: ${ncTheme.colors.dangerActive};
          color: ${ncTheme.colors.light};
        }
      }
    `,
    link: linkStyles,
    icon: iconButtonStyles,
    iconDanger: css`
      ${iconButtonStyles}
      &,
        &:visited {
        color: ${ncTheme.colors.danger};
        &:not([aria-disabled="true"], :disabled, [data-disabled="true"]) {
          &:hover {
            color: ${ncTheme.colors.dangerActive};
          }

          &:active,
          &[data-pressed="true"] {
            color: ${ncTheme.colors.danger};
          }
        }
      }
    `,
    trigger: css`
      font-weight: inherit;
    `,
  },
  isLoading: css`
    position: absolute;
    inset: 0;
    justify-content: center;
    background-color: inherit;
  `,
};

export interface NcButtonProps extends ReactAriaButtonProps {
  variant?: keyof typeof buttonStyles.variant;
  isLoading?: boolean;
  children: ReactNode;
}

export const NcButton = forwardRef<HTMLButtonElement, NcButtonProps>(
  ({ type = "button", variant = "default", isLoading, isDisabled, children, ...props }, ref) => {
    return (
      <ReactAriaButton
        data-nc="NcButton"
        ref={ref}
        type={type}
        css={[
          buttonStyles.base,
          buttonStyles.variant[variant as keyof typeof buttonStyles.variant],
        ]}
        isDisabled={isLoading || isDisabled}
        data-loading={isLoading}
        {...props}
      >
        {children}
        {isLoading && <NcLoadingIndicator css={buttonStyles.isLoading} />}
      </ReactAriaButton>
    );
  }
);
