import { NcComboBoxProps, NcFieldComboBox } from "@noted/noted-components";

import { useI18n } from "~/hooks/use-i18n";
import { PrimhdCode } from "~/primhdv2/api";

function convertCodesToComboBoxItems(codes: PrimhdCode[]) {
  return codes.map(({ code, description }: PrimhdCode) => {
    return {
      id: code,
      label: `${code} - ${description}`,
    };
  });
}

interface PrimhdCodeComboBoxProps
  extends Omit<NcComboBoxProps, "renderEmptyState" | "placeholder" | "defaultItems"> {
  codes: PrimhdCode[];
}

const PrimhdCodeComboBox = ({ codes, label, ...props }: PrimhdCodeComboBoxProps) => {
  const { t } = useI18n("primhd");
  const items = convertCodesToComboBoxItems(codes);

  return (
    <NcFieldComboBox
      renderEmptyState={() => t("primhd:records.edit.codes_combo_box.empty")}
      label={label}
      placeholder={t("primhd:records.edit.codes_combo_box.placeholder")}
      defaultItems={items}
      {...props}
    />
  );
};

export default PrimhdCodeComboBox;
