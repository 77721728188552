import { ReactNode } from "react";

import { useI18n } from "~/hooks/use-i18n";
import { PageHeader } from "~/shared/components/layout";
import { BackToParent } from "~/shared/components/navigation/back-to-parent";
import { Box, Flex, PageTitle } from "~/shared/components/primitives";

export function UserPageHeader({ children, title }: { children?: ReactNode; title: string }) {
  const { t } = useI18n("admin-users");

  return (
    <PageHeader flexDirection="column">
      <BackToParent to={`/admin/users/`} text={t("admin-users:back_to_users")} />
      <Flex width="100%">
        <PageTitle>{title}</PageTitle>
        <Box mr="auto" />
        {children}
      </Flex>
    </PageHeader>
  );
}
