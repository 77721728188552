import styled from "../../theme";
import Flex from "../primitives/flex";

const PageActions = styled(Flex)`
  flex: 1;
  gap: 4px;
  justify-content: end;
`;

export default PageActions;
