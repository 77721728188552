import styled, { theme } from "../theme";

// This is inspired by the Group component (src/shared/components/forms/group.tsx)
// but with a couple key differences:
//   1. It is a fieldset HTML element instead of a set of nested divs.
//   2. It is designed to work "out of the box" with Form, FormRow and Input components in
//      this directory.  The key things is it is a Grid with the same grid-gap as Form.  This
//      means you can add this into a Form and add FormRow/Input components and get the same
//      spacing as what you would have gotten from Form.
const FormGroup = styled.fieldset`
  background-color: ${theme.colors.neutral.light};
  border: 1px solid ${theme.colors.neutral.medium};
  border-radius: 4px;
  box-sizing: border-box;
  display: grid;
  grid-gap: ${theme.space[3]};
  margin-bottom: ${theme.space[5]};
  padding-bottom: ${theme.space[4]};
  padding-top: ${theme.space[4]};
  padding-right: ${theme.space[4]};
  padding-left: ${theme.space[4]};

  & legend {
    background-color: ${theme.colors.info.medium};
    border-radius: 3px;
    color: ${theme.colors.neutral.lightest};
    font-size: ${theme.fontSizes[1]};
    text-transform: uppercase;
    padding: ${theme.space[2]} ${theme.space[3]};
  }
`;

export default FormGroup;
