import { forwardRef, Fragment } from "react";

import { useI18n, useI18nCountries } from "~/hooks/use-i18n";

import { Select } from "../../shared/ui";

export const CountriesSelect = forwardRef<
  HTMLSelectElement,
  Omit<Parameters<typeof Select>[0], "children">
>((props, ref) => {
  const { countries } = useI18nCountries();
  const { t } = useI18n("account");

  return (
    <Select {...props} ref={ref}>
      <option disabled={true} value="">
        {t("account:signup.countrycode_select_placeholder")}
      </option>
      {Object.keys(countries).map((c: string, index: number) => {
        if (index === 4) {
          return (
            <Fragment key={c}>
              <option disabled={true} role="separator">
                {"---"}
              </option>
              <option value={c}>{countries[c]}</option>
            </Fragment>
          );
        } else {
          return (
            <option key={c} value={c}>
              {countries[c]}
            </option>
          );
        }
      })}
    </Select>
  );
});
CountriesSelect.displayName = "CountriesSelect";

export default CountriesSelect;
