import { css } from "@emotion/core";
import type { ReactNode } from "react";

import { ncTheme } from "../nc-theme";

export function NcVisuallyHidden({ children }: { children: ReactNode }) {
  return (
    <span
      data-nc="NcVisuallyHidden"
      css={css`
        ${ncTheme.utilities.visuallyHiddenStyles}
      `}
    >
      {children}
    </span>
  );
}
