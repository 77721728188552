import styled, { theme } from "../../theme";
import Flex from "../primitives/flex";

export const Header = styled(Flex)`
  color: ${theme.colors.neutral.dark};
  border-bottom: solid 1px ${theme.colors.neutral.medium};
  background-color: ${theme.colors.neutral.lightest};
  box-shadow: 0px 1px 5px rgba(78, 88, 100, 0.2);
  z-index: 2;
`.withComponent("header");

Header.displayName = "Header";

Header.defaultProps = {
  alignItems: "center",
};

export default Header;
