import { useQuery } from "@tanstack/react-query";
import { memo, useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { AccountContext, useAccount } from "~/account/account.context";
import { useFeatures } from "~/account/features.context";
import { restFetcher } from "~/graphql-hooks/custom-fetcher";
import { useI18n } from "~/hooks/use-i18n";
import { Modal, ModalBody, ModalHeader } from "~/shared/components/modal";
import { IOrganisation } from "~/shared/types";

import Upgrade from "./upgrade";

const UpgradeModal = () => {
  const { t } = useI18n("admin-org");

  const navigate = useNavigate();
  const location = useLocation();

  const { organisation, isUpgradable, setOrganisation, invalidate } = useContext(AccountContext);
  const { refetchFeatures } = useFeatures();
  const params = new URLSearchParams(location.search);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  useEffect(() => {
    if (!isUpgradable) {
      return setShouldRedirect(true);
    }
  }, [isUpgradable, setShouldRedirect]);

  if (!params.get("upgrade")) {
    return null;
  }
  if (shouldRedirect) {
    return <Navigate to={{ pathname: location.pathname, search: "" }} />;
  }
  const onClose = () => {
    // If we close the modal, we need to refetch the organisation,
    // as we may have update org info.
    invalidate().then(() => {
      refetchFeatures();
      navigate({ pathname: location.pathname, search: "" });
    });
  };
  const onEmailVerified = () => setOrganisation({ ...organisation, emailVerified: true });
  return (
    <>
      <Modal handleExit={onClose}>
        <ModalHeader>{t("admin-org:subscriptions.upgrade.modal_title")}</ModalHeader>
        <ModalBody minWidth={["initial", "initial", "60rem"]}>
          <Upgrade />
        </ModalBody>
      </Modal>
      {!organisation.emailVerified && <PollForVerification emailVerified={onEmailVerified} />}
    </>
  );
};

const PollForVerification = memo(({ emailVerified }: { emailVerified: () => void }) => {
  const { organisation } = useAccount();

  const refetchInterval = organisation ? !organisation.emailVerified && 5000 : undefined;
  const enabled = Boolean(refetchInterval);

  const { data } = useQuery({
    queryKey: ["organisations-me"],
    queryFn: () => restFetcher<IOrganisation>("/v1/organisations/me"),
    enabled,
    refetchInterval,
  });

  useEffect(() => {
    if (data?.emailVerified) {
      emailVerified();
    }
  }, [data]);

  return <></>;
});

PollForVerification.displayName = "Poll for verification";

export default UpgradeModal;
