import { DateTime, Interval } from "luxon";
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";

import { useTimeslotsQuery } from "../../api";
import { IResource } from "../resources-context";
import {
  filterBySelection,
  IColumnisedTimeslots,
  ISelection,
  ITimeslot,
  ITimeslotsResponse,
  mergeColumnisedTimeslots,
} from "../timeslots-state-utils";
import { useAppointmentForm } from "./appointment-form-context";

interface IFetch {
  serviceId: number;
  from: DateTime;
  to: DateTime;
  workerIds: number[];
  spaceIds: number[];
  clientId?: number;
}

export const TimeslotsContext = createContext<ITimeslotState>({
  fetch: () => undefined,
  refetch: () => undefined,
  intervalsByColumn: {},
  intervals: {},
  timeslotByMilliseconds: {},
  loading: false,
  timeslotsByResourceAndDate: () => [],
});

export const TimeslotsState = ({ children }: { children: ReactNode }) => {
  const { workerId, spaceId } = useAppointmentForm();
  const availability = useTimeslotAvailability({ workerId, spaceId });
  return <TimeslotsContext.Provider value={availability}>{children}</TimeslotsContext.Provider>;
};

export const useTimeslots = () => useContext(TimeslotsContext);

interface ITimeslotState {
  fetch: (query: IFetch) => void;
  refetch: () => void;
  intervalsByColumn: IColumnisedTimeslots;
  intervals: { [day: string]: { intervals: Interval[]; workerIds: number[]; spaceIds: number[] } };
  timeslotByMilliseconds: { [key: string]: ITimeslot };
  loading: boolean;
  error?: boolean;
  rawTimeslots?: ITimeslot[];
  timeslotsByResourceAndDate: (day: DateTime, resource: IResource) => Interval[];
}

const useTimeslotAvailability = (resourceSelection: ISelection): ITimeslotState => {
  const { workerId, spaceId } = resourceSelection; // This is coming from the appointment form.
  const [query, setQuery] = useState<IFetch>();

  const {
    data,
    isError: error,
    isLoading: loading,
    refetch,
  } = useTimeslotsQuery(
    {
      input: {
        clientId: query?.clientId,
        from: query?.from.toMillis(),
        serviceId: query?.serviceId,
        spaceIds: query?.spaceIds,
        to: query?.to.toMillis(),
        workerIds: query?.workerIds,
      },
    },
    { enabled: Boolean(query) }
  );

  const fetch = useCallback((params: IFetch) => setQuery(params), [setQuery]);

  const memoizedColumns = useMemo(
    () => recalculateColumns(data, workerId, spaceId),
    [data, workerId, spaceId]
  );

  const timeslotByMilliseconds = useMemo(
    () =>
      ((data && data.timeslots) || []).reduce<{
        [key: string]: ITimeslot;
      }>((tsByMillis, ts) => ({ ...tsByMillis, [ts.from]: ts }), {}),
    [data]
  );

  const timeslotsByResourceAndDate = useCallback(
    (day: DateTime, resource: IResource) => {
      const byDateKey = memoizedColumns.intervalsByColumn[day.toFormat("yyyyLLdd")];
      return byDateKey
        ? byDateKey[resource.type === "worker" ? "workers" : "spaces"][resource.id] || []
        : [];
    },
    [memoizedColumns]
  );

  return useMemo(
    () => ({
      ...memoizedColumns,
      timeslotsByResourceAndDate,
      timeslotByMilliseconds,
      rawTimeslots: data && data.timeslots,
      fetch,
      loading,
      error: Boolean(error),
      refetch,
    }),
    [
      memoizedColumns,
      timeslotsByResourceAndDate,
      timeslotByMilliseconds,
      data,
      fetch,
      loading,
      error,
      refetch,
    ]
  );
};

const recalculateColumns = (data?: ITimeslotsResponse, workerId?: number, spaceId?: number) => {
  const intervalsByColumn = filterBySelection({ workerId, spaceId }, data);
  const intervals = mergeColumnisedTimeslots(intervalsByColumn);
  return { intervalsByColumn, intervals };
};
