import { css } from "@emotion/core";
import type { HTMLProps, ReactNode } from "react";
import type { FormProps as RAFormProps } from "react-aria-components";
import { Form as RAForm } from "react-aria-components";
import type { FieldValues, UseFormReturn } from "react-hook-form";
import { FormProvider, useFormContext } from "react-hook-form";

import { ncTheme } from "../../nc-theme";
import type { NcButtonProps } from "../nc-button";
import { NcButton } from "../nc-button";
import type { NcGroupProps } from "../nc-group";
import { NcGroup } from "../nc-group";

const styles = {
  form: css`
    display: grid;
    gap: ${ncTheme.spacing(6)};
    width: 100%;
  `,
  actions: css`
    width: 100%;
    display: flex;
    gap: ${ncTheme.spacing(2.5)};
  `,
  row: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${ncTheme.spacing(8)} ${ncTheme.spacing(4)};
  `,
};

export interface FormProps<T extends FieldValues> extends RAFormProps {
  form: UseFormReturn<T, unknown>;
  children: ReactNode;
}

export function NcForm<T extends FieldValues>({
  children,
  form,
  autoComplete = "off",
  onSubmit = e => e.preventDefault(),
  ...props
}: FormProps<T>) {
  return (
    <FormProvider data-nc="NcForm" {...form}>
      <RAForm css={styles.form} autoComplete={autoComplete} onSubmit={onSubmit} {...props}>
        {children}
      </RAForm>
    </FormProvider>
  );
}

interface NcFormActionProps extends HTMLProps<HTMLDivElement> {}

const Actions = ({ children, ...props }: NcFormActionProps) => {
  return (
    <div data-nc="NcForm.Actions" css={styles.actions} {...props}>
      {children}
    </div>
  );
};
NcForm.Actions = Actions;

const ResetButton = ({ children, ...props }: NcButtonProps) => {
  const { reset } = useFormContext();

  const handleReset = () => {
    reset();
  };

  return (
    <NcButton data-nc="NcForm.ResetButton" type="reset" onPress={handleReset} {...props}>
      {children}
    </NcButton>
  );
};
NcForm.ResetButton = ResetButton;

const SubmitButton = ({ children, ...props }: NcButtonProps) => (
  <NcButton nc-data="NcForm.SubmitButton" type="submit" variant="primary" {...props}>
    {children}
  </NcButton>
);
NcForm.SubmitButton = SubmitButton;

interface NcFormRowProps extends NcGroupProps {
  "aria-label"?: string;
  "aria-labelledby"?: string;
}

const FormRow = ({ children, ...props }: NcFormRowProps) => {
  return (
    <NcGroup data-nc="NcForm.FormRow" css={styles.row} {...props}>
      {children}
    </NcGroup>
  );
};
NcForm.Row = FormRow;
