import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconResetRight = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2v2a8 8 0 1 1-4.5 1.385V8h2V2h-6v2H6a9.99 9.99 0 0 0-4 8"
    />
  </NcIcon>
);
export { NcIconResetRight };
