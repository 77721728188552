import { BaseSyntheticEvent } from "react";

import { Box } from "../components/primitives";

export const stopEventPropagation =
  (fn: (e: BaseSyntheticEvent) => void) => (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    fn(event);
  };

const Form = Box.withComponent("form");

Form.displayName = "Form";

Form.defaultProps = {
  width: "100%",
  noValidate: true,
  autoComplete: "off",
  display: "grid",
  gridGap: "3",
};

export default Form;
