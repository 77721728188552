import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconTick = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M20.414 5.586a2 2 0 0 1 0 2.828l-10.014 10a2.004 2.004 0 0 1-2.832 0l-4.006-4a1.998 1.998 0 0 1 1.423-3.39 2 2 0 0 1 1.41.562l2.589 2.586 8.598-8.586a2.004 2.004 0 0 1 2.832 0"
    />
  </NcIcon>
);
export { NcIconTick };
