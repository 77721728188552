import { css } from "@emotion/core";
import type { ReactNode } from "react";
import type {
  ColumnProps as ReactAriaColumnProps,
  TableProps as ReactAriaTableProps,
} from "react-aria-components";
import {
  Cell as ReactAriaCell,
  Column as ReactAriaColumn,
  Row as ReactAriaRow,
  Table as ReactAriaTable,
  TableBody as ReactAriaTableBody,
  TableHeader as ReactAriaTableHeader,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";
import { NcButton } from "./nc-button";
import { NcIconSortAscending, NcIconSortDescending, NcIconSortNone } from "./nc-icons";

const styles = {
  table: css`
    width: 100%;
    border: ${ncTheme.border(ncTheme.colors.ui)};
  `,
  headerCell: css`
    padding-inline: ${ncTheme.spacing(2)};
    padding-block: ${ncTheme.spacing(2)};
    text-align: left;
    font-weight: ${ncTheme.fontWeight.bold};
    background-color: ${ncTheme.colors.uiLight};
    border: ${ncTheme.border(ncTheme.colors.ui)};
    border-top: none;

    &[data-focused] {
      outline-style: none;
    }

    &[data-focus-visible] {
      ${ncTheme.utilities.outlineStyles};
    }

    &:first-of-type {
      border-left: none;
    }

    &:last-of-type {
      border-right: none;
    }
  `,
  sortAction: css`
    width: 100%;
    justify-content: left;
  `,
  sortIcon: css`
    margin-left: auto;
  `,
  body: css`
    tr:first-of-type {
      td,
      th {
        border-top: none;
      }
    }

    &:last-of-type {
      td,
      th {
        border-bottom: none;
      }
    }

    &[data-empty="true"] {
      td {
        text-align: center;
        padding: ${ncTheme.spacing(4)};
        font-style: italic;
      }
    }
  `,
  rowHeaders: css`
    td[role="rowheader"] {
      background-color: ${ncTheme.colors.uiLight};
    }
  `,
  row: css`
    &[data-href] {
      cursor: pointer;

      &[data-hovered="true"],
      &[data-focused="true"] {
        background-color: ${ncTheme.colors.focused};
      }
    }

    &[data-focused] {
      outline-style: none;
    }

    &[data-focus-visible] {
      ${ncTheme.utilities.outlineStyles};
    }
  `,
  cell: css`
    padding-inline: ${ncTheme.spacing(2)};
    padding-block: ${ncTheme.spacing(2)};
    border: ${ncTheme.border(ncTheme.colors.ui)};

    &:first-of-type {
      border-left: none;
    }

    &:last-of-type {
      border-right: none;
    }

    &[data-focus-visible="true"] {
      ${ncTheme.utilities.outlineStyles};
    }
  `,
};

interface NcTableProps extends ReactAriaTableProps {
  styleRowHeaders?: boolean;
}

const NcTable = ({ styleRowHeaders, ...props }: NcTableProps) => (
  <ReactAriaTable
    data-nc="NcTable"
    css={[styles.table, styleRowHeaders && styles.rowHeaders]}
    {...props}
  />
);

const TableHeader = ({ ...props }) => (
  <ReactAriaTableHeader data-nc="NcTable.TableHeader" {...props} />
);

interface ColumnProps extends ReactAriaColumnProps {
  children: ReactNode;
}

const SortIcon = ({ sortDirection, ...props }: { sortDirection?: "ascending" | "descending" }) => {
  switch (sortDirection) {
    case "ascending":
      return <NcIconSortAscending {...props} />;
    case "descending":
      return <NcIconSortDescending {...props} />;
    default:
      return <NcIconSortNone {...props} />;
  }
};

const Column = ({ children, ...props }: ColumnProps) => (
  <ReactAriaColumn data-nc="NcTable.Column" css={styles.headerCell} {...props}>
    {({ sort, allowsSorting, sortDirection }) => (
      <>
        {allowsSorting ? (
          <>
            <NcButton
              css={styles.sortAction}
              onPress={() => {
                if (!sortDirection) {
                  sort("ascending");
                  return;
                }
                sort(sortDirection === "ascending" ? "descending" : "ascending");
              }}
            >
              {children}
              <SortIcon sortDirection={sortDirection} css={styles.sortIcon} />
            </NcButton>
          </>
        ) : (
          <>{children}</>
        )}
      </>
    )}
  </ReactAriaColumn>
);

const TableBody = ({ ...props }) => (
  <ReactAriaTableBody data-nc="NcTable.TableBody" css={styles.body} {...props} />
);

const Row = ({ ...props }) => <ReactAriaRow data-nc="NcTable.Row" css={styles.row} {...props} />;

const Cell = ({ ...props }) => (
  <ReactAriaCell data-nc="NcTable.Cell" css={styles.cell} {...props} />
);

NcTable.TableHeader = TableHeader;
NcTable.Column = Column;
NcTable.TableBody = TableBody;
NcTable.Row = Row;
NcTable.Cell = Cell;

export { NcTable };
