import { useQueryClient } from "@tanstack/react-query";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";

import { FeaturePermission } from "~/graphql-hooks/types";

import { AccountContext } from "../../account/account.context";
import { useFeatures } from "../../account/features.context";
import { useSchedulingStateQuery } from "../api";

interface IKeyNamePair {
  id?: number;
  name?: string | null;
}

export type OnboardingStateType =
  | "idle"
  | "loading"
  | "ready"
  | "incomplete"
  | "error"
  | "rechecking";

interface IOnboardingActionsRequired {
  hasServices: boolean;
  hasWorkingHours: boolean;
  hasTimezone: boolean;
}

interface IAgendaOnboarding {
  checkOnboarding: () => void;
  state: OnboardingStateType;
  errors: IOnboardingActionsRequired;
  workersRequiringWorkingHours: IKeyNamePair[];
  servicesCount: number;
}

const initialErrors = {
  hasServices: false,
  hasWorkingHours: false,
  hasTimezone: false,
};

const useOnboarding = (): IAgendaOnboarding => {
  const [state, setState] = useState<OnboardingStateType>("idle");
  const [errors, setErrors] = useState<IOnboardingActionsRequired>(initialErrors);
  const { organisation, account } = useContext(AccountContext);
  const timezone = organisation ? organisation.timezone : "";
  const userId = account ? account.id : 0;
  const [loadedOnce, setLoadedOnce] = useState(false);
  const { featureEnabled } = useFeatures();

  const queryClient = useQueryClient();

  const {
    data,
    isLoading: loading,
    isError,
  } = useSchedulingStateQuery(undefined, {
    enabled: featureEnabled(FeaturePermission.BookingSystem),
  });

  useEffect(() => {
    setState("idle");
  }, [userId]);

  useEffect(() => {
    setState("error");
  }, [isError]);

  useEffect(() => {
    if (loading) {
      setState(s => {
        switch (s) {
          case "idle":
          case "ready":
            return "loading";
          default:
            return "rechecking";
        }
      });
      return;
    }
  }, [loading]);

  useEffect(() => {
    if (loadedOnce) {
      setState(Object.values(errors).some(v => !v) ? "incomplete" : "ready");
    }
  }, [errors, loadedOnce]);

  useEffect(() => {
    if (data) {
      const {
        schedulingState: {
          totalServices,
          servicesMissingResources,
          servicesRequiringWorkingHours,
          totalBookableServices,
        },
      } = data;
      const hasServices =
        !!totalBookableServices || totalServices > servicesMissingResources.length;
      const hasWorkingHours = !!totalBookableServices || !servicesRequiringWorkingHours.length;
      setErrors(e => ({ ...e, hasServices, hasWorkingHours }));
      setLoadedOnce(true);
    }
  }, [data]);

  useEffect(() => {
    setErrors(e => ({ ...e, hasTimezone: !!timezone }));
  }, [timezone]);

  return useMemo(
    () => ({
      checkOnboarding: () =>
        queryClient.invalidateQueries({ queryKey: useSchedulingStateQuery.getKey() }),
      state,
      errors,
      workersRequiringWorkingHours: data?.schedulingState.workersRequiringWorkingHours ?? [],
      servicesCount: data?.schedulingState.totalServices || 0,
    }),
    [errors, state, data]
  );
};

const OnboardingContext = createContext<IAgendaOnboarding>({
  checkOnboarding: () => {
    /* no-op */
  },
  state: "idle",
  errors: initialErrors,
  servicesCount: 0,
  workersRequiringWorkingHours: [],
});

export const OnboardingState = ({ children }: { children: ReactNode }) => {
  const value = useOnboarding();
  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};

export const useAgendaOnboarding = () => useContext(OnboardingContext);
