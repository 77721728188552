import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { config } from "@noted/configuration";
import { ComponentProps, ReactNode, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { clearToken, getToken } from "~/global-token";
import { restPoster } from "~/graphql-hooks/custom-fetcher";

import { useMeQuery } from "./account/use-me-query";

function getContextSession() {
  const rawValue = localStorage.getItem("ngStorage-session") ?? "";
  return rawValue.replace(/"/g, "").trim();
}

function clearContextSession() {
  localStorage.removeItem("ngStorage-session");
}

function LegacyAuthProvider({ children }: ComponentProps<typeof AuthProvider>) {
  return <>{children}</>;
}

function useLegacyAuth() {
  const navigate = useNavigate();
  const location = useLocation();

  const logout = useCallback(async () => {
    try {
      if (getToken()) {
        await restPoster("/v1/auth/logout");
      }
    } finally {
      clearContextSession();
      clearToken();
      if (location.pathname !== "/login") {
        navigate("/login");
      }
    }
  }, [getToken, restPoster, clearContextSession, clearToken, navigate, location.pathname]);

  return {
    isLegacy: true,
    isAuthenticated: true,
    isLoading: false,
    getToken: () => Promise.resolve(getToken()),
    hasPassedExpiryThreshold: () => Promise.resolve(false),
    logout,
  };
}

function Auth0AuthProvider({ children }: ComponentProps<typeof AuthProvider>) {
  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      cookieDomain={config.auth0.cookieDomain}
      authorizationParams={{
        audience: config.auth0.audience,
        redirect_uri: config.landingUrl,
      }}
    >
      {children}
    </Auth0Provider>
  );
}

function useAuth0Auth() {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently: getToken,
    getIdTokenClaims,
    logout: auth0Logout,
  } = useAuth0();

  const { data } = useMeQuery();

  const logout = useCallback(async () => {
    try {
      if (getContextSession()) {
        await restPoster("/v1/auth/logout");
      }
    } finally {
      clearContextSession();
      clearToken();
      auth0Logout({
        logoutParams: {
          returnTo: config.landingUrl,
        },
      });
    }
  }, [restPoster, clearContextSession, clearToken, auth0Logout, config.landingUrl]);

  const hasPassedExpiryThreshold = async (threshold: number) => {
    const sessionTimeout = data?.organisation?.sessionTimeout ?? 120;
    const sessionDuration = sessionTimeout * 60 * 1000;
    const idToken = await getIdTokenClaims();
    if (!idToken || !idToken.exp || !idToken.iat) {
      return false;
    }
    const issuedAt = idToken.iat * 1000;
    const expiresAt = issuedAt + sessionDuration;
    const expiresIn = expiresAt - Date.now();
    return expiresIn < sessionDuration * (threshold / 100);
  };

  return {
    isLegacy: false,
    isAuthenticated,
    isLoading,
    getToken,
    logout,
    hasPassedExpiryThreshold,
  };
}

function AuthProvider({ children }: { children: ReactNode }) {
  const session = getContextSession();
  const Provider = session ? Auth0AuthProvider : LegacyAuthProvider;

  return <Provider>{children}</Provider>;
}

export default AuthProvider;

type UseAuthType = {
  isLegacy: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  getToken: (options?: object) => Promise<string>;
  hasPassedExpiryThreshold: (threshold: number) => Promise<boolean>;
  session: string;
  logout: () => Promise<void>;
};

export function useAuth(): UseAuthType {
  const session = useRef(getContextSession());
  const useAuthHook = session.current ? useAuth0Auth : useLegacyAuth;

  const details = useAuthHook();

  return {
    ...details,
    session: session.current,
  };
}
