import {
  NcBadge,
  NcFormattedMessage,
  NcGridList,
  NcGridListSelection,
  NcHeading,
} from "@noted/noted-components";
import { DateTime } from "luxon";

import { DuplicateClient } from "~/dashboard/clients/client-invite-queries";
import { useI18n } from "~/hooks/use-i18n";

type DuplicateClientItem = {
  label: string;
  id: number;
  isActive?: boolean;
  email?: string;
  dateOfBirth?: string;
  medicalServiceId?: string;
};

interface DuplicateClientsFormProps {
  duplicateClients: DuplicateClient[];
  setSelectedDuplicate: (duplicate: DuplicateClient | undefined) => void;
}

const DuplicateClientsForm = ({
  duplicateClients,
  setSelectedDuplicate,
}: DuplicateClientsFormProps) => {
  const { t } = useI18n("org");
  const newClientSelection = {
    id: 0,
    label: t("org:dashboard.clients.invite.duplicates.new_client"),
  };

  const listItems: DuplicateClientItem[] = [
    ...duplicateClients.map((client: DuplicateClient) => {
      const { id, firstName, lastName, email, dateOfBirth, medicalServiceId, currentStatus } =
        client;
      const isActive = !currentStatus.active === false;
      return {
        id,
        label: `${firstName} ${lastName}`,
        medicalServiceId,
        dateOfBirth,
        email,
        isActive,
      };
    }),
    newClientSelection,
  ];

  return (
    <div className="grid gap-3">
      <div>
        <NcHeading level={3} styleAs={4}>
          {t("org:dashboard.clients.invite.duplicates.heading")}
        </NcHeading>
        <NcFormattedMessage>
          {t("org:dashboard.clients.invite.duplicates.instruction")}
        </NcFormattedMessage>
      </div>
      <NcGridList
        items={listItems}
        selectionMode="single"
        aria-label={t("org:dashboard.clients.invite.duplicates.list")}
        disallowEmptySelection={true}
        onSelectionChange={(keys: NcGridListSelection) => {
          if (keys === "all") return;
          const selectedClient = duplicateClients.find(
            client => client.id === [...keys.values()][0]
          );
          setSelectedDuplicate(selectedClient);
        }}
      >
        {item => (
          <NcGridList.Item textValue={item.label}>
            {item.id === 0 ? (
              <NcFormattedMessage variant="secondary" className="pl-2">
                {item.label}
              </NcFormattedMessage>
            ) : (
              <DuplicateClientItem item={item} />
            )}
          </NcGridList.Item>
        )}
      </NcGridList>
    </div>
  );
};

export default DuplicateClientsForm;

const DuplicateClientItem = ({ item }: { item: DuplicateClientItem }) => {
  const { t } = useI18n("org");
  return (
    <div className="grid w-full items-center justify-between gap-2 pl-2 sm:grid-cols-[1fr,9rem,4rem]">
      <div className="grid content-start gap-x-1">
        <div className="flex items-start gap-2">
          <div className="font-bold">{item.label}</div>
        </div>

        {item.email && <div>{item.email}</div>}
      </div>

      <div className="grid">
        <div className="flex items-center gap-1">
          <span className="w-10 text-sm">{t("org:dashboard.clients.invite.duplicates.nhi")}</span>

          {item.medicalServiceId ? <span className="font-bold">{item.medicalServiceId}</span> : "-"}
        </div>

        <div className="flex items-center gap-1">
          <span className="w-10 text-sm">{t("org:dashboard.clients.invite.duplicates.dob")}</span>

          {item.dateOfBirth ? (
            <span className="font-bold">
              {DateTime.fromISO(item.dateOfBirth).toLocaleString(DateTime.DATE_SHORT)}
            </span>
          ) : (
            "-"
          )}
        </div>
      </div>

      {"isActive" in item &&
        (item.isActive ? (
          <NcBadge variant="default">{t("org:dashboard.clients.invite.duplicates.active")}</NcBadge>
        ) : (
          <NcBadge variant="neutralLight">
            {t("org:dashboard.clients.invite.duplicates.inactive")}
          </NcBadge>
        ))}
    </div>
  );
};
