import { ElementType, forwardRef, useEffect } from "react";
import * as ReactIs from "react-is";

import { isDevelopment } from "./is-development";

const warn = (msg: string) =>
  console.warn(
    `%c${msg}`,
    "color: #AC5500; background: #FFEBD7; padding: 4px 10px; border-radius: 4px; font-family: courier;"
  );

function wrapperFn<TParam, TResult>(
  fn: (...params: TParam[]) => TResult,
  message?: string
): (...params: TParam[]) => TResult {
  return (...params) => {
    warn(message ?? `WARNING! ${fn.name ?? "Function"} is deprecated.`);
    return fn(...params);
  };
}

const wrappedComponent = (Component: ElementType, message?: string) => {
  // eslint-disable-next-line react/display-name
  return forwardRef((props, ref) => {
    useEffect(() => {
      // eslint-disable-next-line
      warn(message ?? `WARNING! ${(Component as any).displayName ?? "Component"} is deprecated.`);
    }, []);
    return <Component {...props} ref={ref} />;
  });
};

export const deprecated = <T,>(fn: T, message?: string): T => {
  if (!isDevelopment() || !fn) {
    return fn;
  }

  if (typeof fn === "function") {
    // eslint-disable-next-line
    return wrapperFn(fn as any) as unknown as T;
  }

  if (ReactIs.isValidElementType(fn)) {
    return wrappedComponent(fn, message) as T;
  }

  if (message) {
    warn(message);
  }
  return fn;
};
