import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcFormattedMessage } from "./nc-formatted-message";
import { NcHeading, type NcHeadingProps } from "./nc-heading";
import { NcLoadingIndicator } from "./nc-loading-indicator";

const styles = {
  panel: css`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    border-radius: ${ncTheme.borderRadius.medium};
    border: ${ncTheme.border(ncTheme.colors.ui)};
    background-color: ${ncTheme.colors.uiLight};
    overflow-y: auto;
  `,
  header: css`
    display: flex;
    flex-basis: ${ncTheme.spacing(6)};
    flex-shrink: 0;
    align-items: center;
    border-top-left-radius: ${ncTheme.borderRadius.medium};
    border-top-right-radius: ${ncTheme.borderRadius.medium};
    padding: ${ncTheme.spacing(3)};
  `,
  body: css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;
    background-color: ${ncTheme.colors.light};
    padding: ${ncTheme.spacing(3)};
    :not(:only-child) {
      border-top: ${ncTheme.border(ncTheme.colors.ui)};
    }
  `,
  loading: css`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${ncTheme.colors.brand};
  `,
  footer: css`
    display: flex;
    flex-basis: ${ncTheme.spacing(6)};
    flex-shrink: 0;
    align-items: center;
    border-bottom-left-radius: ${ncTheme.borderRadius.medium};
    border-bottom-right-radius: ${ncTheme.borderRadius.medium};
    padding: ${ncTheme.spacing(3)};
    background-color: ${ncTheme.colors.light};
    border-top: ${ncTheme.border(ncTheme.colors.ui)};
  `,
};

interface NcPanelProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const NcPanel = ({ children, ...props }: NcPanelProps) => {
  return (
    <div data-nc="NcPanel" css={css(styles.panel)} {...props}>
      {children}
    </div>
  );
};

interface NcPanelHeaderProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const NcPanelHeader = ({ children, ...props }: NcPanelHeaderProps) => {
  return (
    <div data-nc="NcPanel.Header" css={styles.header} {...props}>
      {children}
    </div>
  );
};
NcPanel.Header = NcPanelHeader;

const NcPanelHeading = ({ children, ...props }: Omit<NcHeadingProps, "styleAs">) => (
  <NcHeading styleAs={4} {...props}>
    {children}
  </NcHeading>
);
NcPanel.Heading = NcPanelHeading;

interface NcPanelBodyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  error?: boolean | string;
  empty?: boolean | string;
  isLoading?: boolean;
}

type NcPanelBodyState = "LOADING" | "LOADED" | "EMPTY" | "ERROR";

function getPanelState({
  isLoading,
  empty,
  error,
}: Omit<NcPanelBodyProps, "children">): NcPanelBodyState {
  if (isLoading) return "LOADING";
  if (empty) return "EMPTY";
  if (error) return "ERROR";
  return "LOADED";
}

const NcPanelBody = ({ children, isLoading, empty, error, ...props }: NcPanelBodyProps) => {
  const state = getPanelState({ isLoading, empty, error });
  if (state === "LOADED") {
    return (
      <div data-nc="NcPanel.Body" css={styles.body} {...props}>
        {children}
      </div>
    );
  }
  return (
    <div data-nc="NcPanel.Body" css={styles.body}>
      {state === "LOADING" && (
        <div css={styles.loading}>
          <NcLoadingIndicator />
        </div>
      )}
      {state === "ERROR" && <NcPanelBodyError error={error} />}
      {state === "EMPTY" && <NcPanelBodyEmpty empty={empty} />}
    </div>
  );
};
NcPanel.Body = NcPanelBody;

const NcPanelBodyError = ({ error }: { error: NcPanelBodyProps["error"] }) => {
  const { t } = useI18n();
  const errorText = typeof error === "string" ? error : t("error_default");
  return <NcFormattedMessage variant="danger">{errorText}</NcFormattedMessage>;
};

const NcPanelBodyEmpty = ({ empty }: { empty: NcPanelBodyProps["empty"] }) => {
  const { t } = useI18n();
  const emptyText = typeof empty === "string" ? empty : t("empty_default");
  return <NcFormattedMessage variant="secondary">{emptyText}</NcFormattedMessage>;
};

interface NcPanelFooterProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

const NcPanelFooter = ({ children, ...props }: NcPanelFooterProps) => {
  return (
    <div data-nc="NcPanel.Footer" css={styles.footer} {...props}>
      {children}
    </div>
  );
};

NcPanel.Footer = NcPanelFooter;
