import { css } from "@emotion/core";
import { fromDate, toCalendarDateTime } from "@internationalized/date";
import type { RangeValue } from "@react-types/shared";
import { useCallback } from "react";
import type {
  DateValue,
  DateRangePickerProps as ReactAriaDateRangePickerProps,
} from "react-aria-components";
import {
  CalendarCell,
  CalendarGrid,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Popover,
  DateInput as ReactAriaDateInput,
  DateRangePicker as ReactAriaDateRangePicker,
  RangeCalendar as ReactAriaRangeCalendar,
} from "react-aria-components";
import { useFormContext } from "react-hook-form";

import { ncTheme } from "../../nc-theme";
import { useI18n } from "../../use-i18n";
import { NcButton } from "../nc-button";
import { NcIconCalendar, NcIconChevronLeft, NcIconChevronRight } from "../nc-icons";
import type { FieldProps } from "./nc-field";
import { NcField } from "./nc-field";
import { castDatePropTypes, fieldDateStyles } from "./nc-field-date";
import { inputStyles } from "./nc-input";
import type { DateRange } from "./types";
import { useValidation } from "./use-validation";

interface DateTypeProps {
  minValue?: Date | undefined;
  maxValue?: Date | undefined;
  value?: DateRange | undefined;
  defaultValue?: DateRange | undefined;
  isDateUnavailable?: (date: Date) => boolean;
  validate?: (value: DateRange | undefined) => string | string[] | true | null | undefined;
}

interface NcFieldDateRangeProps
  extends FieldProps,
    Omit<
      ReactAriaDateRangePickerProps<DateValue>,
      | "name"
      | "defaultValue"
      | "value"
      | "onChange"
      | "minValue"
      | "maxValue"
      | "validate"
      | "placeholderValue"
      | "isDateUnavailable"
      | "autoFocus"
    >,
    DateTypeProps {
  includeTime?: boolean;
  picker?: boolean;
  toLabel?: string;
  onChange?: (value: DateRange | undefined) => void;
}

const styles = {
  group: css`
    align-items: center;
    display: flex;
    gap: ${ncTheme.spacing(2)};
    flex-wrap: wrap;
    width: 100%;
  `,
  groupSegment: css`
    display: flex;
    gap: ${ncTheme.spacing(2)};
    align-items: center;
    flex-wrap: wrap;
  `,
  inputs: css`
    ${inputStyles};
    ${fieldDateStyles.inputs};
    ${fieldDateStyles.group}
    width: 15rem;
    max-width: 15rem;
  `,
  segment: fieldDateStyles.segment,
  pickerButton: css`
    ${fieldDateStyles.pickerButton};
    padding: ${ncTheme.spacing(1)} 0;

    & > svg {
      width: 1.35rem;
      height: 1.35rem;
    }
  `,
  calendar: fieldDateStyles.calendar,
};

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

const InputSegment = ({ slot, ...props }: { slot: "start" | "end" }) => (
  <ReactAriaDateInput slot={slot} {...props} css={styles.inputs}>
    {segment => <DateSegment css={styles.segment} segment={segment} />}
  </ReactAriaDateInput>
);

const castRangeValue = (range: DateRange) =>
  ({
    start: toCalendarDateTime(fromDate(range.start, tz)),
    end: toCalendarDateTime(fromDate(range.end, tz)),
  }) as unknown as RangeValue<DateValue>;

const castDateRange = (range: RangeValue<DateValue>) =>
  ({
    start: range.start ? range.start.toDate(tz) : undefined,
    end: range.end ? range.end.toDate(tz) : undefined,
  }) as DateRange;

const castRangeValueProps = ({ defaultValue, value, ...remaining }: DateTypeProps) => {
  const defaultValueProps = defaultValue ? { defaultValue: castRangeValue(defaultValue) } : {};
  const valueProps = value ? { value: castRangeValue(value) } : {};
  return {
    ...(castDatePropTypes(remaining) as { [p: string]: DateValue }),
    ...defaultValueProps,
    ...valueProps,
  };
};

const RangeCalendar = () => {
  return (
    <Popover css={styles.calendar.popover}>
      <Dialog>
        <ReactAriaRangeCalendar>
          <header css={styles.calendar.header}>
            <NcButton slot="previous" variant="icon">
              <NcIconChevronLeft />
            </NcButton>
            <Heading />
            <NcButton slot="next" variant="icon">
              <NcIconChevronRight />
            </NcButton>
          </header>
          <CalendarGrid css={styles.calendar.calendar}>
            {date => <CalendarCell date={date} css={styles.calendar.cell} />}
          </CalendarGrid>
        </ReactAriaRangeCalendar>
      </Dialog>
    </Popover>
  );
};

export const NcFieldDateRange = ({
  name,
  label,
  labelNode,
  description,
  picker,
  includeTime,
  minValue,
  maxValue,
  defaultValue,
  value,
  isDateUnavailable,
  toLabel = "to",
  variant,
  shouldForceLeadingZeros = true,
  hourCycle = 24,
  onChange,
  ...props
}: NcFieldDateRangeProps) => {
  const { t } = useI18n();
  const {
    register,
    formState: { defaultValues },
    setValue,
  } = useFormContext();

  const { ref } = register(name);

  const { validationHandler } = useValidation({
    label: label,
    rules: {
      required: props?.isRequired ? {} : undefined,
      minDate: minValue
        ? {
            value: includeTime ? minValue.toLocaleString() : minValue.toLocaleDateString(),
            valueDate: minValue,
          }
        : undefined,
      maxDate: maxValue
        ? {
            value: includeTime ? maxValue.toLocaleString() : maxValue.toLocaleDateString(),
            valueDate: maxValue,
          }
        : undefined,
      isDateUnavailable: isDateUnavailable
        ? {
            check: isDateUnavailable,
          }
        : undefined,
    },
  });

  const dateValidationHandler = useCallback(
    (value: RangeValue<DateValue>) =>
      validationHandler(value?.start ? castDateRange(value) : undefined),
    [validationHandler]
  );

  const handleChange = (value: RangeValue<DateValue>) => {
    setValue(`${name}.start`, value.start ? value.start.toDate(tz) : undefined, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue(`${name}.end`, value.end ? value.end.toDate(tz) : undefined, {
      shouldDirty: true,
      shouldValidate: true,
    });
    onChange?.(value.start && value.end ? castDateRange(value) : undefined);
  };

  return (
    <ReactAriaDateRangePicker
      data-nc="NcFieldDateRange"
      {...{
        ...props,
        ...castRangeValueProps({
          minValue,
          maxValue,
          defaultValue,
          value,
          ...(defaultValues?.[name] ? { defaultValue: defaultValues?.[name] } : {}),
          isDateUnavailable,
        }),
        validate: dateValidationHandler,
        shouldForceLeadingZeros,
        hourCycle,
        granularity: includeTime ? "minute" : "day",
        onChange: handleChange,
        ref,
        startName: `${name}.start`,
        endName: `${name}.end`,
      }}
    >
      <NcField
        {...{
          label: labelNode || label,
          description,
          variant,
          isRequired: props.isRequired,
        }}
      >
        <Group css={[styles.group]}>
          <div css={styles.groupSegment}>
            <InputSegment slot="start" />
            <span aria-hidden="true">{t(toLabel)}</span>
          </div>
          <div css={styles.groupSegment}>
            <InputSegment slot="end" />
            {picker && (
              <NcButton variant="icon" css={styles.pickerButton}>
                <NcIconCalendar />
              </NcButton>
            )}
          </div>
        </Group>
        {picker && <RangeCalendar />}
      </NcField>
    </ReactAriaDateRangePicker>
  );
};
