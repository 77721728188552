import { ComponentProps } from "react";

import styled from "../../theme";
import Flex from "../primitives/flex";

export const PageSubNavigation = styled(Flex)<ComponentProps<typeof Flex>>``;

PageSubNavigation.displayName = "PageSubNavigation";
PageSubNavigation.defaultProps = {
  width: "100%",
  height: "100%",
  overflow: "hidden",
  flex: 1,
  zIndex: 0,
  flexDirection: ["column", "row", "row"],
};

export default PageSubNavigation;
