import styled, { theme } from "../../theme";
import Box, { BoxProps } from "../primitives/box";

export type FooterProps = BoxProps;

export const ModalFooter = styled(Box)<FooterProps>`
  border-top-color: ${theme.colors.neutral.light};
`;

ModalFooter.displayName = "ModalFooter";

ModalFooter.defaultProps = {
  m: 0,
  padding: 3,
  borderTop: "solid 1px",
};
export default ModalFooter;
