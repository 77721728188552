import { cloneDeep } from "lodash-es";
import { DateTime, Interval } from "luxon";

import { type Appointment, type Client, type Service } from "~/graphql-hooks/types";
import { getFreeIntervals } from "~/shared/functions/date-helper";

import { GetCalendarItemsQuery, ResourceAdjustmentPeriodFragment } from "../api";
import { Mode } from "./agenda-view";
import { CalendarItemType, IBlockedTime, ICalendarItem } from "./calendar-item-state";
import { IResource } from "./resources-context";
import { ITimeslot } from "./timeslots-state-utils";
import { getWorkingHoursForDay } from "./unavailable-time";

const parseDateTime = (date: DateTime) => {
  // not really sure why we do this ... the input is already a DateTime
  return DateTime.fromMillis(date.toMillis());
};

const dayLongInterval = (date: DateTime) => {
  const d = parseDateTime(date);
  return Interval.fromDateTimes(d.startOf("day"), d.endOf("day"));
};

const parseInterval = (int: Interval) => {
  const start = parseDateTime(int.start);
  const end = parseDateTime(int.end);
  return Interval.fromDateTimes(start, end);
};

// Receives ALL appointments/blocked times
// Filters out the ones for the day (the potentially clashing ones)
// Provides the items for the column
export function calendarItemsForDateAndResource(
  allAppointments: Appointment[],
  allBlockedTimes: IBlockedTime[],
  date: DateTime,
  id: number,
  mode: Mode
) {
  date = parseDateTime(date);

  const linkedAppointments = appointmentsForDate(allAppointments, date);
  const linkedBlockedTimes = blockedTimesForDate(allBlockedTimes, date);

  const dayInterval = dayLongInterval(date);

  const filteredAppointments = linkedAppointments
    .filter(appointment =>
      mode === "workers"
        ? appointment.workers?.some(w => w?.id === id)
        : appointment.spaces?.some(w => w?.id === id)
    )
    .filter(createAppointmentsOnDateFilter(dayInterval));

  const filteredBlockedTimes = linkedBlockedTimes
    .filter(time =>
      mode === "workers" ? time.worker && time.worker.id === id : time.space && time.space.id === id
    )
    .filter(createBlockedTimesOnDateFilter(dayInterval));

  const calendarItems = createPositionedCalendarItemsWithDate(
    filteredAppointments,
    filteredBlockedTimes,
    date
  );

  const appointments = calendarItems
    .filter(c => c.type === CalendarItemType.APPOINTMENT)
    .map(item => ({
      item,
      appointment: filteredAppointments.find(a => a.id === item.itemTypeId)!,
    }));
  const blockedTimes = calendarItems
    .filter(c => c.type === CalendarItemType.BLOCKED_TIME)
    .map(item => ({
      item,
      blockedTime: filteredBlockedTimes.find(bt => bt.id === item.itemTypeId)!,
    }));

  const columnItems = {
    appointments,
    blockedTimes,
  };

  return {
    columnItems,
    linkedAppointments,
    linkedBlockedTimes,
  };
}

export function appointmentsForDate(appointments: Appointment[], date: DateTime) {
  date = parseDateTime(date);
  const dayInterval = dayLongInterval(date);
  return appointments.filter(createAppointmentsOnDateFilter(dayInterval));
}

export function appointmentsForResource(appointments: Appointment[], resource: IResource) {
  return appointments.filter(a =>
    resource.type === "space"
      ? a.spaces?.some(s => s?.id === resource.id)
      : a.workers?.some(w => w?.id === resource.id)
  );
}

export function appointmentsForClient(appointments: Appointment[], client: Client) {
  return appointments.filter(a => a.client.id === client.id);
}

function blockedTimesForDate(blockedTimes: IBlockedTime[], date: DateTime) {
  date = parseDateTime(date);
  const dayInterval = dayLongInterval(date);
  return blockedTimes.filter(createBlockedTimesOnDateFilter(dayInterval));
}

export function getKnownFreeTimesForResourceAndDate(
  appointments: Appointment[],
  blockedTimes: IBlockedTime[],
  resource: IResource,
  type: Mode,
  date: DateTime,
  skip: Array<{ id: number; type: CalendarItemType }>
) {
  date = parseDateTime(date);

  const interval = Interval.fromDateTimes(
    date.minus({ days: 1 }).startOf("day"),
    date.plus({ days: 1 }).endOf("day")
  );

  // For all those days, get me the free times
  return interval
    .splitBy({ days: 1 }) // Break the interval into days
    .map(i => i.start.startOf("day")) // For every day, get me the free times
    .reduce<Interval[]>(
      (res, d) => [...res, ...getFreeTimes(appointments, blockedTimes, resource, type, d, skip)],
      []
    );
}

function getFreeTimes(
  appointments: Appointment[],
  blockedTimes: IBlockedTime[],
  resource: IResource,
  type: Mode,
  date: DateTime,
  skip?: Array<{ id: number; type: CalendarItemType }>
) {
  const { columnItems } = calendarItemsForDateAndResource(
    appointments,
    blockedTimes,
    date,
    resource.id,
    type
  );
  const items = [
    ...columnItems.appointments.map(a => a.item),
    ...columnItems.blockedTimes.map(a => a.item),
  ];
  const busyIntervals = items.reduce<Interval[]>((res, item) => {
    if (skip && skip.find(s => s.id === item.itemTypeId && s.type === item.type)) {
      return res;
    }
    switch (item.type) {
      case CalendarItemType.APPOINTMENT: {
        const appointment = appointments.find(a => a.id === item.itemTypeId);
        return appointment
          ? Interval.merge([
              ...res,
              Interval.after(DateTime.fromMillis(appointment.from), {
                minutes: appointment.duration,
              }),
            ])
          : res;
      }
      case CalendarItemType.BLOCKED_TIME: {
        const blockedTime = blockedTimes.find(bt => bt.id === item.itemTypeId);
        return blockedTime
          ? Interval.merge([
              ...res,
              Interval.fromDateTimes(
                DateTime.fromMillis(blockedTime.from),
                DateTime.fromMillis(blockedTime.to)
              ),
            ])
          : res;
      }
      default:
        return res;
    }
  }, []);

  return getFreeIntervals(getWorkingHoursForDay(resource, date), busyIntervals);
}

function createAppointmentsOnDateFilter(interval: Interval) {
  return function (appointment: Appointment) {
    const fromDT = DateTime.fromMillis(appointment.from);
    const toDT = fromDT.plus({ minutes: appointment.duration });
    return Interval.fromDateTimes(fromDT, toDT).overlaps(interval);
  };
}

function createBlockedTimesOnDateFilter(interval: Interval) {
  return function (blockedTime: IBlockedTime) {
    const fromDT = DateTime.fromMillis(blockedTime.from);
    const toDT = DateTime.fromMillis(blockedTime.to);
    return Interval.fromDateTimes(fromDT, toDT).overlaps(interval);
  };
}

function createPositionedCalendarItemsWithDate(
  appointments: Appointment[],
  blockedTimes: IBlockedTime[],
  date: DateTime
) {
  const appItems = appointments.map(a => {
    return createItem(
      `${CalendarItemType.APPOINTMENT}-${a.id}`,
      a.id,
      date,
      DateTime.fromMillis(a.from),
      a.duration,
      CalendarItemType.APPOINTMENT
    );
  });
  const blockedItems = blockedTimes.map(bt => {
    const start = DateTime.fromMillis(bt.from);
    const itemInterval = Interval.fromDateTimes(start, DateTime.fromMillis(bt.to));
    return createItem(
      `${CalendarItemType.BLOCKED_TIME}-${bt.id}`,
      bt.id,
      date,
      start,
      itemInterval.toDuration("minutes").minutes,
      CalendarItemType.BLOCKED_TIME
    );
  });
  return applyLeftAndWidthValues([...appItems, ...blockedItems]);
}

export function createItem(
  id: string,
  itemTypeId: number,
  date: DateTime,
  startTime: DateTime,
  duration: number,
  type: CalendarItemType
) {
  const top = getTopFromStartTime(date, startTime);
  return {
    top,
    bottom: getBottomFromTopAndDuration(top, duration),
    // temp values for left and width
    left: 0,
    width: 0,
    itemTypeId,
    type,
    id,
  };
}

export function getTopFromStartTime(day: DateTime, startTime: DateTime) {
  day = parseDateTime(day);
  startTime = parseDateTime(startTime);
  const daysOffset = Math.ceil(day.startOf("day").diff(startTime).as("days"));
  return (startTime.hour + startTime.minute / 60) / 0.24 - daysOffset * 100;
}

export function getBottomFromTopAndDuration(top: number, durationInMins: number) {
  return 100 - (top + (durationInMins / 1440) * 100);
}

// Mutates items instead of returning new array
export function applyLeftAndWidthValues(items: ICalendarItem[]) {
  const itemsWithLeftAndWidthApplied: ICalendarItem[] = [];
  const itemsSortedByTopAscending = [...items].sort((x, y) => x.top - y.top);

  const columns: Array<Array<ICalendarItem & { endTop: number }>> = [];

  const itemsWithEndTops = itemsSortedByTopAscending.map(item => ({
    ...item,
    endTop: 100 - item.bottom,
  }));

  itemsWithEndTops.forEach(item => {
    let isColumnFound = false;
    for (const column of columns) {
      if (!column.some(colItem => isOverlap(item.top, item.endTop, colItem.top, colItem.endTop))) {
        column.push(item);
        isColumnFound = true;
        break;
      }
    }
    if (!isColumnFound) {
      columns.push([item]);
    }
  });

  columns.forEach((columnItems, columnIndex) => {
    // Calculate right collisions
    columnItems.forEach(columnItem => {
      let rightCollisionCount = 0;
      for (let i = columnIndex + 1; i < columns.length; ++i) {
        if (
          columns[i].some(({ top, endTop }) =>
            isOverlap(top, endTop, columnItem.top, columnItem.endTop)
          )
        ) {
          ++rightCollisionCount;
        }
      }

      // Calculate left value for items
      let left = 0;
      if (columnIndex > 0) {
        const prevColumn = columns[columnIndex - 1]
          .filter(prevColumnItem =>
            isOverlap(prevColumnItem.top, prevColumnItem.endTop, columnItem.top, columnItem.endTop)
          )
          .map(a => a.left + a.width)
          .sort((x, y) => y - x);

        if (prevColumn.length) {
          left = prevColumn[0];
        }
      }

      columnItem.width = (100 - left) / (rightCollisionCount + 1);
      columnItem.left = left;

      // Remove endTop before pushing to result array
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { endTop: _, ...otherItemProps } = columnItem;
      itemsWithLeftAndWidthApplied.push({ ...otherItemProps });
    });
  });

  return itemsWithLeftAndWidthApplied;
}

function isOverlap(s1: number, e1: number, s2: number, e2: number) {
  const { start1, end1, start2, end2 } = roundNumberKeys({
    start1: s1,
    end1: e1,
    start2: s2,
    end2: e2,
  });
  return (start1 >= start2 && start1 < end2) || (start2 >= start1 && start2 < end1);
}

const MAX_ROUND = 100;
const round2decimals = (val: number) => Math.round(val * MAX_ROUND) / MAX_ROUND;

export function roundNumberKeys<T extends Record<string, unknown>>(obj: T) {
  if (typeof obj === "object") {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (typeof value === "number") {
        (obj[key] as number) = round2decimals(value);
      }
    });
  }
  return obj;
}

function isColliding(
  appointment: Appointment,
  allAppointments: Appointment[],
  allBlockedTimes: IBlockedTime[]
) {
  return (
    resourcesAppointmentCollisions(appointment, allAppointments) ||
    resourcesBlockedTimeCollisions(appointment, allBlockedTimes)
  );
}

function appointmentOverlaps(a1: Appointment, a2: Appointment) {
  const interval1 = Interval.after(DateTime.fromMillis(a1.from), { minutes: a1.duration });
  const interval2 = Interval.after(DateTime.fromMillis(a2.from), { minutes: a2.duration });
  return interval1.overlaps(interval2);
}

function blockedTimeOverlaps(bt: IBlockedTime, a: Appointment) {
  const interval1 = Interval.after(DateTime.fromMillis(a.from), { minutes: a.duration });
  const interval2 = Interval.fromDateTimes(
    DateTime.fromMillis(bt.from),
    DateTime.fromMillis(bt.to)
  );
  return interval1.overlaps(interval2);
}

function resourcesAppointmentCollisions(appointment: Appointment, appointments: Appointment[]) {
  return (
    resourcesAppointmentCollisionsByType(
      appointment,
      appointments,
      appointment.workers?.map(w => w?.id) ?? [],
      "worker"
    ) ||
    resourcesAppointmentCollisionsByType(
      appointment,
      appointments,
      appointment.spaces?.map(s => s?.id) ?? [],
      "space"
    )
  );
}

function resourcesAppointmentCollisionsByType(
  appointment: Appointment,
  appointments: Appointment[],
  resources: number[],
  type: "worker" | "space"
) {
  return resources.some(resourceId =>
    appointments.some(
      a =>
        !a.cancelled &&
        a.id !== appointment.id &&
        ((type === "worker" ? a.workers : a.spaces) ?? []).some(r => resourceId === r?.id) &&
        appointmentOverlaps(appointment, a)
    )
  );
}

function resourcesBlockedTimeCollisions(appointment: Appointment, blockedTimes: IBlockedTime[]) {
  return (
    resourcesBlockedTimeCollisionsByType(
      appointment,
      blockedTimes,
      appointment.workers?.map(w => ({ id: w?.id })) ?? [],
      "worker"
    ) ||
    resourcesBlockedTimeCollisionsByType(
      appointment,
      blockedTimes,
      appointment.spaces?.map(s => ({ id: s?.id })) ?? [],
      "space"
    )
  );
}

function resourcesBlockedTimeCollisionsByType(
  appointment: Appointment,
  blockedTimes: IBlockedTime[],
  resources: Array<{ id: number }>,
  type: "worker" | "space"
) {
  return resources.some(({ id: resourceId }) =>
    blockedTimes.some(
      b =>
        (type === "worker" ? b.worker && b.worker.id : b.space && b.space.id) === resourceId &&
        blockedTimeOverlaps(b, appointment)
    )
  );
}
interface ICollision {
  worker?: { id: number; name: string };
  space?: { id: number; name: string };
  anyResource: boolean;
}
export function appointmentFormConflicts(
  workers: IResource[],
  spaces: IResource[],
  appointments: Appointment[],
  blockedTimes: IBlockedTime[],
  timeslotByMilliseconds: { [key: string]: ITimeslot },
  intervalsProp: {
    [day: string]: { intervals: Interval[]; workerIds: number[]; spaceIds: number[] };
  },
  options: {
    isServiceSelectable: boolean;
    appointmentId?: number;
    date?: DateTime;
    workerId?: number;
    spaceId?: number;
    service?: Service;
  }
) {
  const {
    isServiceSelectable,
    appointmentId,
    date: dateProp,
    workerId,
    spaceId,
    service,
  } = options;

  /// Parsing start ///
  const intervals: {
    [day: string]: { intervals: Interval[]; workerIds: number[]; spaceIds: number[] };
  } = {};
  Object.keys(intervalsProp).forEach(key => {
    intervals[key] = {
      intervals: intervalsProp[key].intervals.map(i => parseInterval(i)),
      workerIds: intervalsProp[key].workerIds,
      spaceIds: intervalsProp[key].spaceIds,
    };
  });
  const date = dateProp && parseDateTime(dateProp);
  /// Parsing end ///

  function getResourceFreeTimesFromStore(r: IResource, d: DateTime) {
    return getKnownFreeTimesForResourceAndDate(
      appointments,
      blockedTimes,
      r,
      r.type === "worker" ? "workers" : "spaces",
      d,
      appointmentId ? [{ id: appointmentId, type: CalendarItemType.APPOINTMENT }] : []
    );
  }

  function collisionsFromStore() {
    const collisions: ICollision = { anyResource: false };
    if (!date) {
      return;
    }
    const worker = workerId ? workers.find(w => w.id === workerId) : undefined;
    const space = spaceId ? spaces.find(s => s.id === spaceId) : undefined;

    const appointmentInterval = Interval.after(date, { minutes: service ? service.duration : 0 });

    if (worker) {
      const freeTimes = getResourceFreeTimesFromStore(worker, date);
      collisions.worker = !freeTimes.some(i => i.engulfs(appointmentInterval)) ? worker : undefined;
    }

    if (space) {
      const freeTimes = getResourceFreeTimesFromStore(space, date);
      collisions.space = !freeTimes.some(i => i.engulfs(appointmentInterval)) ? space : undefined;
    }

    if (collisions.worker || collisions.space) {
      return collisions;
    }
  }

  function collisionsFromTimeslots() {
    const collisions: ICollision = { anyResource: false };
    if (date) {
      const ts = timeslotByMilliseconds[date.toMillis()];
      if (!ts) {
        return { anyResource: true };
      } else {
        const space = spaces.find(s => s.id === spaceId);
        const worker = workers.find(w => w.id === workerId);
        if (space && ts.spaceIds && !ts.spaceIds.includes(space.id)) {
          collisions.space = space;
        }
        if (worker && ts.workerIds && !ts.workerIds.includes(worker.id)) {
          collisions.worker = worker;
        }
        if (collisions.worker || collisions.space) {
          return collisions;
        }
      }
    }
    return;
  }

  function availableIntervalsFromTimeslots() {
    return Object.keys(intervals).reduce<Interval[]>(
      (res, day) => [...res, ...intervals[day].intervals],
      []
    );
  }

  function availableIntervalsFromStore() {
    if (!service) {
      return;
    }

    const worker = workerId ? workers.find(w => w.id === workerId) : undefined;
    const space = spaceId ? spaces.find(s => s.id === spaceId) : undefined;

    if (!worker && !spaces) {
      return;
    }

    const workerFreeTimes =
      worker && date ? getResourceFreeTimesFromStore(worker, date) : undefined;
    const spaceFreeTimes = space && date ? getResourceFreeTimesFromStore(space, date) : undefined;

    let intersection: Interval[] | undefined;

    if (workerFreeTimes && spaceFreeTimes) {
      intersection = workerFreeTimes.reduce<Interval[]>(
        (res, wo) =>
          Interval.merge([
            ...res,
            ...(spaceFreeTimes
              .map(sp => sp.intersection(wo))
              .filter(i => i !== null) as Interval[]),
          ]),
        []
      );
    }
    if (workerFreeTimes && !spaceFreeTimes) {
      intersection = workerFreeTimes;
    }
    if (!workerFreeTimes && spaceFreeTimes) {
      intersection = spaceFreeTimes;
    }
    if (intersection) {
      return intersection
        .map(
          i =>
            Interval.fromDateTimes(i.start, i.end.minus({ minutes: service.duration })) as Interval
        )
        .filter(i => i.isValid);
    }
  }

  // isServiceSelectable determines whether it's using the timeslots coming from the search,
  // or has been triggered from the calendar and uses the store.
  const knownCollisions = isServiceSelectable ? collisionsFromStore() : collisionsFromTimeslots();
  const availableIntervals = isServiceSelectable
    ? availableIntervalsFromStore()
    : availableIntervalsFromTimeslots();

  return { knownCollisions, availableIntervals };
}

function getAdjustmentsByType(adjustmentPeriods?: ResourceAdjustmentPeriodFragment[] | null) {
  return (adjustmentPeriods || []).reduce<{
    available: Array<ResourceAdjustmentPeriodFragment & { interval: Interval }>;
    unavailable: Array<ResourceAdjustmentPeriodFragment & { interval: Interval }>;
    blocked: Array<ResourceAdjustmentPeriodFragment & { interval: Interval }>;
  }>(
    (res, period) => {
      const { type, from, to } = period;
      switch (type) {
        case "ALLOCATED": {
          return {
            ...res,
            available: [...res.available, { ...period, interval: intervalFromMillis(from, to) }],
          };
        }
        case "DEALLOCATED": {
          return {
            ...res,
            unavailable: [
              ...res.unavailable,
              { ...period, interval: intervalFromMillisAllDay(from, to) },
            ],
          };
        }
        case "BLOCKED":
          return {
            ...res,
            blocked: [...res.blocked, { ...period, interval: intervalFromMillis(from, to) }],
          };
        default:
          return res;
      }
    },
    { blocked: [], available: [], unavailable: [] }
  );
}

function intervalFromMillis(from: number, to: number) {
  return Interval.fromDateTimes(DateTime.fromMillis(from), DateTime.fromMillis(to));
}
function intervalFromMillisAllDay(from: number, to: number) {
  return Interval.fromDateTimes(
    DateTime.fromMillis(from).startOf("day"),
    DateTime.fromMillis(to).endOf("day")
  );
}

function isPositionInUnavailableArea(
  u: { top: number; height: number },
  p: { top: number; bottom: number }
) {
  const unavailablePeriod = roundNumberKeys(u);
  const position = roundNumberKeys(p);
  const one =
    unavailablePeriod.top >= position.top && unavailablePeriod.top < 100 - position.bottom;
  const two =
    position.top >= unavailablePeriod.top &&
    position.top < unavailablePeriod.top + unavailablePeriod.height;

  return one || two;
}

function periodsByResource(adjustmentPeriods: ResourceAdjustmentPeriodFragment[]) {
  return adjustmentPeriods.reduce<{
    periodsById: {
      [key: string]: ResourceAdjustmentPeriodFragment;
    };
    workers: { [resource: string]: ResourceAdjustmentPeriodFragment[] };
    spaces: { [resource: string]: ResourceAdjustmentPeriodFragment[] };
  }>(
    (res, period) => {
      const resourceType = period.worker ? "worker" : period.space ? "space" : "other";
      const periodsById = { ...res.periodsById, [period.id]: period };
      const partialResult = { ...res, periodsById };
      switch (resourceType) {
        case "worker": {
          const { workers } = res;
          const id = period.worker!.id;
          workers[id] = [...(workers[id] ? workers[id] : []), period];
          return { ...partialResult, workers };
        }
        case "space": {
          const { spaces } = res;
          const id = period.space!.id;
          spaces[id] = [...(spaces[id] ? spaces[id] : []), period];
          return { ...partialResult, spaces };
        }
        default:
          return partialResult;
      }
    },
    { periodsById: {}, workers: {}, spaces: {} }
  );
}

function unavailablePosition(day: DateTime, unavailableInterval: Interval) {
  day = parseDateTime(day);
  unavailableInterval = parseInterval(unavailableInterval);
  const { start, end } = unavailableInterval;
  const top = (start.diff(day, "hours").toObject().hours || 0) / 0.24;
  const height = (end.diff(start, "hours").toObject().hours || 0) / 0.24;
  return { top: Math.max(0, top), height: Math.min(100, height) };
}

function getWorkingTime(
  resource: IResource,
  date: DateTime,
  adjustmentPeriods: ResourceAdjustmentPeriodFragment[]
): { workingTime?: Interval; period?: ResourceAdjustmentPeriodFragment } {
  date = parseDateTime(date);

  const { available, unavailable } = getAdjustmentsByType(adjustmentPeriods);

  date = date.startOf("day");
  const todayInterval = Interval.fromDateTimes(date, date.endOf("day"));

  const leave = unavailable.find(({ interval }) => interval.overlaps(todayInterval));

  if (leave) {
    return { workingTime: undefined, period: leave };
  }

  const specialHours = (() => {
    const availableBlock = available
      .filter(({ interval }) => interval.overlaps(todayInterval))
      .sort((a, b) => b.id - a.id)[0];
    if (availableBlock) {
      const from = availableBlock.interval.start.set({
        year: date.year,
        month: date.month,
        day: date.day,
      });
      const to = availableBlock.interval.end.set({
        year: date.year,
        month: date.month,
        day: date.day,
      });
      availableBlock.interval = Interval.fromDateTimes(from, to);
      if (availableBlock.interval.isValid) {
        return { workingTime: availableBlock.interval, period: availableBlock };
      }
    }
  })();
  if (specialHours) {
    const { workingTime, period } = specialHours;
    return { workingTime, period };
  }

  return { workingTime: getWorkingHoursForDay(resource, date)[0], period: undefined };
}

function unavailableIntervals(
  resource: IResource,
  date: DateTime,
  adjustmentPeriods: ResourceAdjustmentPeriodFragment[]
): {
  top: number;
  height: number;
}[] {
  date = parseDateTime(date).startOf("day");
  const todayInterval = Interval.fromDateTimes(date, date.endOf("day"));
  const { workingTime } = getWorkingTime(resource, date, adjustmentPeriods);
  if (workingTime) {
    return todayInterval
      .difference(workingTime)
      .map((nw: Interval) => unavailablePosition(date, nw));
  } else {
    return [{ top: 0, height: 100 }];
  }
}

export function mapAgendaItemsQuery(interval: Interval, query: GetCalendarItemsQuery) {
  interval = parseInterval(interval);

  const workers = query?.workers?.results ?? [];
  const spaces = query?.spaces ?? [];
  const appointments = (query?.appointmentsForPeriod ?? []) as Appointment[];
  const adjustments =
    (query?.resourceAdjustmentPeriodsBetween as ResourceAdjustmentPeriodFragment[]) ?? [];
  const { blocked } = getAdjustmentsByType(adjustments);

  const clashingAppointments: number[] = [];

  const firstDay = interval.start.startOf("day");
  const days = Math.ceil(interval.toDuration("days").days);

  const periods = periodsByResource(adjustments);

  function getAdjustmentsByResource(r: IResource) {
    switch (r.type) {
      case "worker":
        return periods.workers[r.id] || [];
      case "space":
        return periods.spaces[r.id] || [];
      default:
        return [];
    }
  }

  function getUnavailableTimeByResouce(resource: IResource, d: DateTime) {
    const resourceAdjustmentsPeriods = getAdjustmentsByResource(resource);
    return unavailableIntervals(resource, d, resourceAdjustmentsPeriods);
  }

  const graph: { [key: string]: ColumState } = {};

  function isClashingLocal(
    a: { appointment: Appointment; item: ICalendarItem },
    columnDay: DateTime,
    linkedApp: Appointment[],
    linkedBts: IBlockedTime[]
  ) {
    if (!a.appointment.id || a.appointment.cancelled) {
      return false;
    }
    const isInUnavailableArea =
      a.appointment.workers?.some(appointmentWorker => {
        const workerFound = workers.find(localWorker => localWorker?.id === appointmentWorker?.id);
        return (
          !!workerFound &&
          getUnavailableTimeByResouce(
            { ...workerFound, type: "worker" } as IResource,
            columnDay
          ).some(area => isPositionInUnavailableArea(area, a.item))
        );
      }) ||
      a.appointment.spaces?.some(appointmentSpace => {
        const spaceFound = spaces.find(s => s?.id === appointmentSpace?.id);
        return (
          !!spaceFound &&
          getUnavailableTimeByResouce(
            { ...spaceFound, type: "space" } as IResource,
            columnDay
          ).some(area => isPositionInUnavailableArea(area, a.item))
        );
      });
    if (isInUnavailableArea) {
      return true;
    } else {
      return isColliding(a.appointment, linkedApp, linkedBts);
    }
  }

  [...Array(days)].forEach((_, i) => {
    const day = firstDay.plus({ days: i });
    const dayKey = day.toISODate();
    workers.forEach(w => {
      const { linkedAppointments, linkedBlockedTimes, ...itemsForWorker } =
        calendarItemsForDateAndResource(
          appointments,
          blocked as IBlockedTime[],
          day,
          w?.id,
          "workers"
        );
      const result = {
        ...itemsForWorker,
        id: w?.id as number,
        name: w?.name ?? "",
        background: backgroundString(
          getUnavailableTimeByResouce({ ...w, type: "worker" } as IResource, day)
        ),
      };

      itemsForWorker.columnItems.appointments = itemsForWorker.columnItems.appointments.map(a => {
        let isClashing: boolean;
        if (clashingAppointments.includes(a.appointment.id)) {
          isClashing = true;
        } else {
          isClashing = isClashingLocal(a, day, linkedAppointments, linkedBlockedTimes);
          if (isClashing) {
            clashingAppointments.push(a.appointment.id);
          }
        }
        return {
          ...a,
          item: { ...a.item, isClashing },
        };
      });

      graph[`${dayKey}_worker_${w?.id}`] = result;
    });
    spaces.forEach(s => {
      const { linkedAppointments, linkedBlockedTimes, ...itemsForSpace } =
        calendarItemsForDateAndResource(
          appointments,
          blocked as IBlockedTime[],
          day,
          s?.id,
          "spaces"
        );
      const result = {
        ...itemsForSpace,
        id: s?.id as number,
        name: s?.name ?? "",
        background: backgroundString(
          getUnavailableTimeByResouce({ ...s, type: "space" } as IResource, day)
        ),
      };

      itemsForSpace.columnItems.appointments = itemsForSpace.columnItems.appointments.map(a => {
        let isClashing: boolean;
        if (clashingAppointments.includes(a.appointment.id)) {
          isClashing = true;
        } else {
          isClashing = isClashingLocal(a, day, linkedAppointments, linkedBlockedTimes);
          if (isClashing) {
            clashingAppointments.push(a.appointment.id);
          }
        }
        return {
          ...a,
          item: { ...a.item, isClashing },
        };
      });

      graph[`${dayKey}_space_${s?.id}`] = result;
    });
  });

  const blockedTimes = blocked as IBlockedTime[];
  return {
    graph,
    adjustments,
    appointments,
    blockedTimes,
    appointmentsAsObject: reduceAppointments(appointments),
    blockedTimesAsObject: reduceBlockedTimes(blockedTimes),
  };
}

function reduceAppointments(appointments: Appointment[]) {
  return appointments.reduce<{ [id: number]: Appointment }>(
    (res, app) => ({ ...res, [app.id]: app }),
    {}
  );
}

function reduceBlockedTimes(blockedTimes: IBlockedTime[]) {
  return blockedTimes.reduce<{ [id: number]: IBlockedTime }>(
    (res, bt) => ({ ...res, [bt.id]: bt }),
    {}
  );
}

type ColumState = {
  id: number;
  name: string;
  columnItems: {
    appointments: { item: ICalendarItem; appointment: Appointment }[];
    blockedTimes: { item: ICalendarItem; blockedTime: IBlockedTime }[];
  };
  background: string;
};

export function backgroundString(
  areas: {
    top: number;
    height: number;
  }[]
) {
  const greys = areas.map(({ top, height }) => [top, top + height]);
  const whites: Array<{ from: number; to: number }> = [{ from: 0, to: 0 }];
  greys.forEach(([from, to], i) => {
    whites[i].to = from;
    whites.push({ from: to, to: 100 });
  });
  const results: string[] = [];
  whites.forEach((white, i) => {
    results.push(`#ffffff00 ${white.from}%`);
    results.push(`#ffffff00 ${white.to}%`);
    if (i < whites.length - 1) {
      results.push(`${"#B8C4CE40"} ${greys[i][0]}%`);
      results.push(`${"#B8C4CE40"} ${greys[i][1]}%`);
    }
  });
  return `linear-gradient(to bottom, ${results.join(", ")})`;
}

export type ColumnMap = ReturnType<typeof mapAgendaItemsQuery>;

export function mergeAgendaItems(prev: ColumnMap, newer: ColumnMap) {
  const older = cloneDeep(prev);
  const newerGraphKeys = Object.keys(newer.graph);
  newerGraphKeys.forEach(key => {
    older.graph[key] = newer.graph[key];
  });
  older.appointments = [
    ...older.appointments.filter(a => !newer.appointments.find(n => n.id === a.id)),
    ...newer.appointments,
  ];
  older.blockedTimes = [
    ...older.blockedTimes.filter(b => !newer.blockedTimes.find(n => n.id === b.id)),
    ...newer.blockedTimes,
  ];
  older.adjustments = [
    ...older.adjustments.filter(b => !newer.adjustments.find(n => n.id === b.id)),
    ...newer.adjustments,
  ];
  older.appointmentsAsObject = reduceAppointments(older.appointments);
  older.blockedTimesAsObject = reduceBlockedTimes(older.blockedTimes);

  return older;
}
