import { NcPanel } from "@noted/noted-components";
import { ReactNode } from "react";

import { useI18n } from "~/hooks/use-i18n";

const RecordDetailsPanel = ({ children }: { children: ReactNode }) => {
  const { t } = useI18n("primhd");
  return (
    <NcPanel>
      <NcPanel.Header>
        <NcPanel.Heading level={4}>{t("primhd:records.edit.primhd_details")}</NcPanel.Heading>
      </NcPanel.Header>
      <NcPanel.Body>{children}</NcPanel.Body>
    </NcPanel>
  );
};

export default RecordDetailsPanel;
