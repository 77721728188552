import { FlatNamespace, KeyPrefix, Namespace, TFunction, TOptions } from "i18next";
import { DateTime, Settings } from "luxon";
import { useMemo } from "react";
import { useTranslation, UseTranslationOptions } from "react-i18next";

export type UnescapedTFunction = TFunction<string | readonly string[], KeyPrefix<Namespace>>;

export enum LocaleId {
  enNz = "en-NZ",
  mi = "mi",
}

const addCommonNamespace = (ns: FlatNamespace | FlatNamespace[]) =>
  ["common", ...(Array.isArray(ns) ? ns : [ns])] as unknown as FlatNamespace;

export const useI18n = (
  ns: FlatNamespace | FlatNamespace[] = [],
  options?: UseTranslationOptions<KeyPrefix<Namespace>>
) => {
  const { t, i18n } = useTranslation(addCommonNamespace(ns), options);
  const enhancedT = useMemo(
    () =>
      ((key: string, options?: TOptions) =>
        key.length === 0 ? "" : t(key, options)) as UnescapedTFunction,
    [t]
  );
  const locale = i18n.language as LocaleId;

  return {
    locale,
    t: enhancedT,
    formatCurrency: (amount: number, currencyCode?: string | null) =>
      (amount / 1.0).toLocaleString(locale, {
        minimumFractionDigits: 2,
        currency: currencyCode ?? "NZD",
        currencyDisplay: "symbol",
        style: "currency",
      }),
    formatDate: (date: Date, formatOption: Intl.DateTimeFormatOptions) =>
      DateTime.fromJSDate(date).setLocale(locale).toLocaleString(formatOption),
    changeLocale: async (localeId: LocaleId) => {
      await i18n.changeLanguage(localeId);
      Settings.defaultLocale = localeId;
    },
  };
};

export const useI18nCountries = () => {
  const { t } = useTranslation(["countries"]);
  const countries = t("countries", { returnObjects: true });
  return { countries } as { countries: { [key: string]: string } };
};
