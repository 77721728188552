import { css, keyframes } from "@emotion/core";
import type { ReactNode } from "react";
import type {
  TooltipProps as ReactAriaTooltipProps,
  TooltipTriggerComponentProps as ReactAriaTooltipTriggerProps,
} from "react-aria-components";
import {
  OverlayArrow,
  Tooltip as ReactAriaTooltip,
  TooltipTrigger as ReactAriaTooltipTrigger,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";

const tooltipOffset = "8px";

const animations = {
  slide: keyframes`
      from {
        transform: var(--origin);
        opacity: 0;
      }
    
      to {
        transform: translateY(0);
        opacity: 1;
      }
    `,
};

const styles = {
  tooltip: css`
    padding-inline: ${ncTheme.spacing(4)};
    padding-block: ${ncTheme.spacing(2)};
    border-radius: ${ncTheme.borderRadius.small};
    background-color: ${ncTheme.colors.dark};
    color: ${ncTheme.colors.light};
    box-shadow: ${ncTheme.shadows.large};

    &[data-entering] {
      animation: ${animations.slide} 200ms;
    }

    &[data-exiting] {
      animation: ${animations.slide} 200ms reverse ease-in;
    }

    &[data-placement="top"] {
      margin-bottom: ${tooltipOffset};
      --origin: translateY(4px);
    }

    &[data-placement="bottom"] {
      margin-top: ${tooltipOffset};
      --origin: translateY(-4px);
    }

    &[data-placement="right"] {
      margin-left: ${tooltipOffset};
      --origin: translateX(-4px);
    }

    &[data-placement="left"] {
      margin-right: ${tooltipOffset};
      --origin: translateX(4px);
    }
  `,
  tooltipArrow: css`
    svg {
      display: block;
    }

    &[data-placement="right"] {
      svg {
        rotate: 90deg;
      }
    }

    &[data-placement="bottom"] {
      svg {
        rotate: 180deg;
      }
    }

    &[data-placement="left"] {
      svg {
        rotate: -90deg;
      }
    }
  `,
};

interface TooltipProps extends ReactAriaTooltipProps {
  children: ReactNode;
}

const NcTooltip = ({ children, placement, ...props }: TooltipProps) => {
  return (
    <ReactAriaTooltip data-nc="NcTooltip" css={styles.tooltip} placement={placement} {...props}>
      <OverlayArrow css={styles.tooltipArrow}>
        <svg width={8} height={8} viewBox="0 0 8 8">
          <path d="M0 0 L4 4 L8 0" />
        </svg>
      </OverlayArrow>
      {children}
    </ReactAriaTooltip>
  );
};

const TooltipTrigger = ({ delay = 250, ...props }: ReactAriaTooltipTriggerProps) => {
  return <ReactAriaTooltipTrigger data-nc="NcTooltip.Trigger" delay={delay} {...props} />;
};

NcTooltip.Trigger = TooltipTrigger;

export { NcTooltip };
