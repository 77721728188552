import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconCross = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4}
      d="m5 5 14 14M19 5 5 19"
    />
  </NcIcon>
);
export { NcIconCross };
