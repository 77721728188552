import { config } from "@noted/configuration";
import { FormEvent, useState } from "react";
import {
  CardElement,
  Elements,
  injectStripe,
  ReactStripeElements,
  StripeProvider,
} from "react-stripe-elements";

import { useI18n } from "~/hooks/use-i18n";

import { Form } from "../../shared/components/forms";
import Button from "../../shared/components/forms/button";
import InputError from "../../shared/components/forms/input-error";
import Box from "../../shared/components/primitives/box";

interface IPaymentFormProps extends ReactStripeElements.InjectedStripeProps {
  onCreateToken: (response: string) => void;
  isUpgrade: boolean;
}
const PaymentForm = ({ onCreateToken, stripe, isUpgrade }: IPaymentFormProps) => {
  const [cardError, setCardError] = useState("");
  const { t } = useI18n("admin-org");

  const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    stripe!.createToken().then(response => {
      if (response.error) {
        setCardError(response.error.message ? response.error.message : "Something went wrong");
      } else {
        onCreateToken(response.token!.id);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} data-testid="payment-form">
      <Box
        width="100%"
        maxWidth="60rem"
        style={{
          backgroundColor: "white",
          padding: "8px 12px",
          borderRadius: "4px",
          border: "1px solid transparent",
          boxShadow: "0 1px 3px 0 #e6ebf1",
          transition: "box-shadow 150ms ease",
        }}
      >
        <CardElement
          style={{
            base: {
              color: "#32325d",
              fontFamily: '"Lato", "Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#a94442",
              iconColor: "#a94442",
            },
          }}
        />
      </Box>
      {cardError && <InputError>{cardError}</InputError>}
      <Button type="submit" mt="3" py="3" fontSize="3" variant="primary">
        {isUpgrade
          ? t("admin-org:organisation.paymentMethod.subscribe_now")
          : t("admin-org:organisation.paymentMethod.update_details")}
      </Button>
    </Form>
  );
};

const WrappedPaymentForm = injectStripe(PaymentForm);

interface IExportedPaymentForm {
  isUpgrade?: boolean;
  onCreateToken: (response: string) => void;
  locale: string;
}
const ExportedPaymentForm = ({
  onCreateToken,
  locale,
  isUpgrade = false,
}: IExportedPaymentForm) => {
  return (
    <StripeProvider apiKey={config.stripeKey}>
      <Elements key={String(locale).substring(0, 2)} locale={String(locale).substring(0, 2)}>
        <WrappedPaymentForm
          onCreateToken={onCreateToken}
          isUpgrade={isUpgrade}
          stripe={null}
          elements={null}
        />
      </Elements>
    </StripeProvider>
  );
};

export default ExportedPaymentForm;
