import { HTMLAttributes, KeyboardEventHandler } from "react";

const focusOn = (element: HTMLElement) => (element?.firstChild as HTMLElement).focus();

const isAlphaKey = (key: string) => /[a-z]/.test(key.toLowerCase());

const findByTextContent = (startWith: string, listItems: Array<HTMLElement>) =>
  listItems.find((item: HTMLElement) => item.innerText?.toLowerCase().startsWith(startWith));

const focusOnTextMatch = (startWith: string, listItems: Array<HTMLElement>) => {
  const matchingElement = findByTextContent(startWith, listItems);
  return matchingElement ? focusOn(matchingElement) : undefined;
};

const focusOnFirstSibling = (element: HTMLElement) =>
  focusOn(element?.parentElement?.firstElementChild as HTMLElement);

const focusOnNextSibling = (element: HTMLElement) => {
  const next = element.nextElementSibling as HTMLElement;

  return next ? focusOn(next) : focusOnFirstSibling(element);
};

const focusOnLastSibling = (element: HTMLElement) =>
  focusOn(element?.parentElement?.lastElementChild as HTMLElement);

const focusOnPrevSibling = (element: HTMLElement) => {
  const next = element.previousElementSibling as HTMLElement;

  return next ? focusOn(next) : focusOnLastSibling(element);
};

const getAllSiblings = (element: HTMLElement) =>
  element?.parentElement?.children
    ? (Array.from(element?.parentElement.children) as Array<HTMLElement>)
    : [];

/**
 * Headless Component for menu/list keyboard navigation
 * ------------------------------------------------------
 * Requires that the first child of list items are focusable and
 * should be enclosed in a <ol> or <ul> element
 *
 * Based on https://www.w3.org/WAI/ARIA/apg/patterns/menubar/#keyboardinteraction
 *
 * @component
 * @example
 *
 * return (
 *   <ul>
 *     <NavigableListItem>
 *       <a>Link 1</a>
 *     </NavigableListItem>
 *     <NavigableListItem>
 *       <button>Link 2</button>
 *     </NavigableListItem>
 *   </ul>
 * )
 */
export const NavigableListItem = ({ children, ...props }: HTMLAttributes<HTMLLIElement>) => {
  const handleKeyboardNav = (event: KeyboardEvent) => {
    event.preventDefault();
    event.stopPropagation();

    // get the current list item which is the parent of the focusable first child
    const targetListItem = (event.target as HTMLElement)?.parentElement;

    if (!targetListItem) {
      return;
    }

    if (isAlphaKey(event.key)) {
      focusOnTextMatch(event.key, getAllSiblings(targetListItem));
    }

    switch (event.key) {
      case "ArrowDown":
        return focusOnNextSibling(targetListItem);

      case "ArrowUp":
        return focusOnPrevSibling(targetListItem);

      case "Home":
        return focusOnFirstSibling(targetListItem);

      case "End":
        return focusOnLastSibling(targetListItem);
    }
  };

  return (
    <li {...props} onKeyUp={handleKeyboardNav as unknown as KeyboardEventHandler<HTMLLIElement>}>
      {children}
    </li>
  );
};
