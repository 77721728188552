import { useI18n } from "~/hooks/use-i18n";

import SomethingWentWrong from "../shared/components/errors/something-went-wrong";
import { Button } from "../shared/components/forms";
import { Box, Flex } from "../shared/components/primitives";

const AnonymousErrorPage = () => {
  const { t } = useI18n("account");
  const onClick = () => {
    // use the window location so we force a refresh
    window.location.assign("/");
  };
  return (
    <Flex flexDirection="column" alignItems="center">
      <SomethingWentWrong>
        <Box mt="4" alignSelf="flex-start">
          <Button onClick={onClick}>{t("account:reset_password.back_to_login")}</Button>
        </Box>
      </SomethingWentWrong>
    </Flex>
  );
};

export default AnonymousErrorPage;
