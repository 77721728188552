import {
  NcIconExclamationCircle,
  NcIconExclamationTriangle,
  NcIconResetRight,
  NcIconSpanner,
  NcIconTickCircle,
} from "@noted/noted-components";
import { NcIconProps } from "@noted/noted-components/src/components/nc-icons/nc-icon";

import { type PrimhdSubmitStatus } from "./types";

interface PrimhdStatusIconProps extends NcIconProps {
  status: PrimhdSubmitStatus;
}

export function PrimhdStatusIcon({ status, ...props }: PrimhdStatusIconProps) {
  switch (status) {
    case "REJECTED":
      return <NcIconExclamationCircle {...props} />;
    case "WARNING":
      return <NcIconExclamationTriangle {...props} />;
    case "UPDATED":
      return <NcIconResetRight {...props} />;
    case "FIXED":
      return <NcIconSpanner {...props} />;
    default:
      return <NcIconTickCircle {...props} />;
  }
}

function getStatusClassNames(status: string) {
  switch (status) {
    case "REJECTED":
      return "flex items-center gap-1 text-danger";
    case "WARNING":
      return "flex items-center gap-1 text-danger";
    case "UPDATED":
      return "flex items-center gap-1 text-main";
    default:
      return "flex items-center gap-1 text-success";
  }
}

export function PrimhdStatusLabel({ status, ...props }: { status: PrimhdSubmitStatus }) {
  return (
    <span className="capitalize" {...props}>
      <span>{status.toLocaleLowerCase()}</span>
    </span>
  );
}

export const PrimhdStatus = ({
  status,
  className,
  ...props
}: {
  status: PrimhdSubmitStatus;
  className?: string;
}) => (
  <span className={`${getStatusClassNames(status)} ${className}`} {...props}>
    <PrimhdStatusIcon status={status} />
    <PrimhdStatusLabel status={status} />
  </span>
);
