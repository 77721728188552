import { DateTime, Interval } from "luxon";

export type MinutesInterval = 1 | 5 | 10 | 15 | 30;

export const roundDateTime = (interval: MinutesInterval, date: DateTime) => {
  const { minute } = date;
  let offset = interval - (minute % interval);
  offset = offset === interval ? 0 : offset;
  let newMinute = minute + offset;
  if (offset > interval / 2) {
    newMinute -= interval;
  }
  return date.set({ minute: newMinute }).startOf("minute");
};

export const getFreeIntervals = (allowedTimes: Interval[], blockedTimes: Interval[]) => {
  const freeTimes = allowedTimes
    .map(s => s.difference(...blockedTimes))
    .reduce<Interval[]>((res, wFreeTimes) => Interval.merge([...res, ...wFreeTimes]), []);
  return freeTimes;
};

export const startOfWeekDays = (date: DateTime) =>
  Interval.after(date.startOf("week"), { weeks: 1 })
    .splitBy({ days: 1 })
    .map(d => d.start.startOf("day"));
