import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconTickCircle = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11m5.208-14.111a1.5 1.5 0 0 0-2.416-1.778l-4.887 6.644-1.883-1.752A1.5 1.5 0 0 0 5.978 14.2l3.116 2.898a1.5 1.5 0 0 0 2.23-.21z"
      clipRule="evenodd"
    />
  </NcIcon>
);
export { NcIconTickCircle };
