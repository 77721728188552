import "@reach/dialog/styles.css";

import { css } from "@emotion/core";
import {
  DialogContent as ReachDialogContent,
  DialogOverlay as ReachDialogOverlay,
} from "@reach/dialog";
import { positionDefault } from "@reach/popover";
import { useRect } from "@reach/rect";
import { DateTime, Interval } from "luxon";
import { ComponentProps, forwardRef, RefObject, useEffect, useRef, useState } from "react";

import { useI18n } from "~/hooks/use-i18n";

import { DayPicker } from "../components/date/day-picker";
import { TimePicker } from "../components/date/time-picker";
import { Button } from "../components/forms";
import { Box, Flex, Text } from "../components/primitives";
import { MinutesInterval, roundDateTime } from "../functions/date-helper";
import { ExitDialog, Pencil, Scheduling } from "../icons";
import styled, { theme } from "../theme";
import { Input } from "./input";
import VisuallyHidden from "./visually-hidden";

const InputButton = Input.withComponent("button");

const DateButtonInput = styled(InputButton)<
  ComponentProps<typeof InputButton> & { hasDate: boolean; isOpen: boolean }
>`
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  padding: ${({ theme: { space } }) => `${space[2]}`};
  padding-right: ${({ theme: { space } }) => `${space[6]}`};
  width: 100%;
  border-radius: 4px;
  ${props => (!props.hasDate ? `color: ${theme.colors.neutral.mediumDark};` : "")}
  ${props =>
    props.isOpen
      ? `
    outline-style: auto;
    outline-width: thick;
  `
      : ""}
`;

const DateValue = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof DateButtonInput> & {
    hasDate: boolean;
    isOpen: boolean;
    valueType: "input" | "text";
    inputValue: string;
  }
>((props, ref) => {
  const { valueType, inputValue, hasDate, isOpen, ...buttonProps } = props;

  if (valueType === "input") {
    return (
      <DateButtonInput {...{ hasDate, isOpen, ...buttonProps }} type="button" ref={ref}>
        {inputValue}
        <SchedulingIcon />
      </DateButtonInput>
    );
  } else {
    return (
      <DateButtonText {...{ hasDate, isOpen, ...buttonProps }} type="button" ref={ref}>
        {<Text mr="2">{inputValue}</Text>}
        <Text>
          <Pencil
            cursor={buttonProps.disabled ? "not-allowed" : "pointer"}
            style={{ color: theme.colors.primary.dark }}
          />
        </Text>
      </DateButtonText>
    );
  }
});
DateValue.displayName = "DateValue";

interface IDatePickerProps
  extends Omit<
    ComponentProps<typeof DateValue>,
    "children" | "onClick" | "hasDate" | "isOpen" | "valueType" | "inputValue"
  > {
  date?: Date;
  placeholderDate?: Date;
  onDateSelect?: (date?: Date) => void;
  showTime?: boolean;
  interval?: MinutesInterval;
  allowedIntervals?: Interval[];
  overlayAriaLabel?: string;
  buttonOnly?: boolean;
  noRounding?: boolean;
  valueType?: "input" | "text";
}

const DatePicker = forwardRef<HTMLInputElement | HTMLButtonElement, IDatePickerProps>(
  (props, ref) => {
    const {
      date: dateProp,
      showTime = true,
      onDateSelect,
      interval = 5,
      noRounding = false,
      allowedIntervals,
      overlayAriaLabel,
      valueType = "input",
      placeholderDate,
      buttonOnly = false,
      ...buttonProps
    } = props;

    const [date, setDate] = useState(initialDateValue(interval, dateProp || placeholderDate));
    const [uncontrolledValue, setUncontrolledValue] = useState(!!dateProp);

    useEffect(() => {
      setDate(
        dateProp && noRounding
          ? DateTime.fromJSDate(dateProp)
          : initialDateValue(interval, dateProp || placeholderDate)
      );
    }, [noRounding, dateProp, interval, placeholderDate]);

    const { t } = useI18n();

    const [showDialog, setShowDialog] = useState(false);
    const open = () => setShowDialog(true);
    const close = () => setShowDialog(false);
    const toggle = () => setShowDialog(!showDialog);

    const localRef = useRef<HTMLButtonElement>(null);
    const datePickerRef = (ref || localRef) as RefObject<HTMLButtonElement>;

    const onSubmit = (dateValue?: DateTime) => {
      const newValue = dateValue?.toJSDate();
      if (dateValue) {
        setDate(dateValue);
      }
      if (onDateSelect) {
        onDateSelect(newValue);
      }
      setUncontrolledValue(!!dateValue);
      close();
    };

    const placeholder = showTime
      ? t("datePicker.placeholder")
      : t("datePicker.placeholder_no_time");

    const inputValue = dateProp
      ? DateTime.fromJSDate(dateProp).toLocaleString(
          showTime ? DateTime.DATETIME_SHORT : DateTime.DATE_MED
        )
      : uncontrolledValue && date
      ? date.toLocaleString(showTime ? DateTime.DATETIME_SHORT : DateTime.DATE_MED)
      : placeholder;

    const initialRef = useRef<HTMLButtonElement>(null);

    const focusOnLoad = uncontrolledValue && showTime ? "submit" : "day";

    const datePickerTriggerRect = useRect(datePickerRef);

    return (
      <div className="datepicker" css={style}>
        {buttonOnly ? (
          <Button
            css={css`
              border-width: 1px;
            `}
            className="datepicker__toggle"
            onClick={toggle}
            disabled={buttonProps.disabled}
          >
            <VisuallyHidden>{t("date_input.pick_date")}</VisuallyHidden>
            <Scheduling />
          </Button>
        ) : (
          <DateValue
            {...buttonProps}
            ref={datePickerRef}
            hasDate={!!dateProp || !!uncontrolledValue}
            isOpen={showDialog}
            onClick={open}
            valueType={valueType}
            inputValue={inputValue}
          />
        )}
        {showDialog && (
          <DialogOverlay
            isOpen={showDialog}
            onDismiss={close}
            style={{ zIndex: 99999 }}
            initialFocusRef={initialRef}
          >
            <DialogContent
              datePickerTriggerRect={datePickerTriggerRect}
              aria-label={overlayAriaLabel || placeholder}
            >
              {uncontrolledValue && (
                <Flex justifyContent={"space-between"}>
                  <TopButton data-testid="clear-selection" onClick={() => onSubmit(undefined)}>
                    {t("datePicker.clear_selection")}
                  </TopButton>
                  <TopButton onClick={close}>
                    <ExitDialog style={{ display: "flex" }} />
                  </TopButton>
                </Flex>
              )}
              <Box>
                <DayPicker
                  onChange={({ year, month, day }) => {
                    if (!showTime) {
                      onSubmit(date.set({ year, month, day }));
                    } else {
                      setDate(d => d.set({ year, month, day }));
                    }
                  }}
                  selectedDay={date}
                  focusDayOnLoad={focusOnLoad === "day"}
                  onConfirm={() => onSubmit(date)}
                />
                {showTime && (
                  <Flex
                    justifyContent={showTime ? "space-between" : "flex-end"}
                    p="2"
                    minWidth="14rem"
                    mt="4"
                  >
                    <Flex flexDirection="column">
                      <CalendarText pr="2" alignSelf="start">
                        {t("datePicker.time")}
                      </CalendarText>
                      <TimePicker
                        mb="0"
                        interval={interval}
                        selectedDay={date}
                        onChange={({ hour, minute }) => setDate(d => d.set({ hour, minute }))}
                        allowedIntervals={allowedIntervals}
                        onConfirm={() => onSubmit(date)}
                      />
                    </Flex>
                    <Box alignSelf="flex-end">
                      <Button
                        data-testid="select-date"
                        variant="primary"
                        onClick={() => onSubmit(date)}
                        ref={focusOnLoad === "submit" ? initialRef : undefined}
                      >
                        {t("datePicker.select")}
                      </Button>
                    </Box>
                  </Flex>
                )}
              </Box>
            </DialogContent>
          </DialogOverlay>
        )}
      </div>
    );
  }
);
DatePicker.displayName = "DatePicker";

const style = css`
  &.datepicker {
    position: relative;
  }

  .datepicker {
    &__toggle {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: ${theme.space[2]};

      svg {
        height: 1.2rem;
        width: 1.2rem;
        fill: ${theme.colors.neutral.dark};
      }
    }
  }
`;

const DateButtonText = styled(Text)<{ isOpen: boolean; hasDate: boolean }>(
  ({ theme, isOpen, hasDate }) => `
  display: flex;
  align-items: center;
  margin-top: ${theme.space[2]};
  color: ${theme.colors.neutral.dark};
  outline-color: ${theme.colors.info.mediumLight};
  ${!hasDate ? `color: ${theme.colors.neutral.mediumDark};` : ""}
  ${
    isOpen
      ? `
    outline-style: auto;
    outline-width: thick;
  `
      : ""
  }
  &:disabled {
    cursor: not-allowed;
  }
`
).withComponent("button");

const SchedulingIcon = styled(Scheduling)`
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 0 8px;
  background: transparent;
  height: 1.2rem;
  width: 1.2rem;
  fill: ${theme.colors.neutral.dark};
`;

const CalendarText = styled(Text)`
  user-select: none;
  font-weight: bold;
`;

const DialogOverlay = styled(ReachDialogOverlay)`
  background: #f9fafb00 !important;
`;

const StyledDialogContent = styled(ReachDialogContent)`
  box-shadow: 2px 2px 2px rgba(39, 50, 63, 0.14);
  border-radius: 4px;
  border: solid 1px ${theme.colors.neutral.light};
  width: 260px !important;
  margin: 0 !important;
  padding: 2rem 1.5rem !important;
  position: absolute;
`;

const DialogContent = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof StyledDialogContent> & { datePickerTriggerRect: DOMRect | null }
>((props, ref) => {
  const { datePickerTriggerRect, ...dialogProps } = props;
  const dialogRef = useRef<HTMLDivElement>(null);
  const popOverRect = useRect((ref || dialogRef) as RefObject<HTMLDivElement>);
  const [opacity, setOpacity] = useState(0);
  const [transform, setTransform] = useState("translateY(-10px)");
  useEffect(() => {
    let alive = true;
    const timeout = setTimeout(() => {
      if (alive) {
        setOpacity(1);
        setTransform("translateY(0px)");
      }
    }, 150);
    return () => {
      alive = false;
      clearTimeout(timeout);
    };
  });

  // Increase size of triggering element so that it pops up with margin;
  const marginY = 10;
  const triggerInputWithWithMargin = (() => {
    if (!datePickerTriggerRect) {
      return null;
    }
    const { top: prevTop, bottom: prevBottom, height: prevHeight } = datePickerTriggerRect;
    const { left, right, width } = datePickerTriggerRect;
    const top = Math.max(prevTop - marginY, 0);
    const bottom = Math.max(prevBottom - marginY, 0);
    const height = prevHeight + Math.abs(top - prevTop) + Math.abs(bottom - prevBottom);
    return { left, right, width, top, bottom, height };
  })();

  const position = positionDefault(triggerInputWithWithMargin, popOverRect);

  return (
    <StyledDialogContent
      ref={ref || dialogRef}
      {...dialogProps}
      style={{
        ...position,
        opacity,
        transform,
        transition: "transform 0.3s, opacity 0.2s",
      }}
    />
  );
});
DialogContent.displayName = "DialogContent";

const TopButton = styled(Button)`
  background: none;
  color: ${theme.colors.neutral.dark};
  border: none;
  padding: 0.5rem;
  &:focus,
  &:hover,
  &:active {
    border: none;
  }
  cursor: pointer;
`;

const initialDateValue = (interval: MinutesInterval, date: Date = new Date()) =>
  roundDateTime(interval, DateTime.fromJSDate(date));

export default DatePicker;
