import css from "@emotion/css";
import { ReactNode } from "react";

import { visuallyHiddenStyles } from "~/shared/theme";

export default function VisuallyHidden({ children }: { children: ReactNode }) {
  return (
    <span
      css={css`
        ${visuallyHiddenStyles}
      `}
    >
      {children}
    </span>
  );
}
