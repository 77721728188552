import { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import { useI18n } from "~/hooks/use-i18n";

import Container from "./container";
import ExitButton from "./exit-button";
import Overlay from "./overlay";

interface IModalProps {
  children: ReactNode;
  root?: string;
  id?: string;
  handleExit: () => void;
  style?: object;
}

export const Modal = ({
  children,
  id = "modal-root",
  root = "root",
  handleExit,
  style,
  ...otherProps
}: IModalProps) => {
  const { t } = useI18n();
  const exitButton = useRef<HTMLButtonElement>(null);
  const modal = useRef<HTMLDivElement>(document.createElement("div"));
  modal.current.id = id;
  const currentModal = modal.current;

  useEffect(() => {
    document.body.append(currentModal);
    return () => {
      document.body.removeChild(currentModal);
    };
  }, [currentModal]);

  useEffect(() => {
    const rootContainer = document.querySelector(`#${root}`);
    const modalContainer = document.querySelector(`#${id}`);

    const capturePosition = () => {
      const cachedPosition = window.pageYOffset;
      return {
        freeze: () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          document.body.style = `position: fixed; top: ${cachedPosition * -1}px; width: 100%;`;
        },
        unfreeze: () => {
          document.body.removeAttribute("style");
          window.scrollTo({
            top: cachedPosition,
          });
        },
      };
    };

    const toggleTabIndex = (type: "on" | "off", container: Element) => {
      const focusableElements = container.querySelectorAll("button, a, input, textarea, select");
      focusableElements.forEach((element: Element) => {
        if (type === "on") {
          element.removeAttribute("tabindex");
        } else {
          element.setAttribute("tabindex", "-1");
        }
      });
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleExit();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    const { freeze, unfreeze } = capturePosition();

    if (modalContainer) {
      toggleTabIndex("on", modalContainer);
    }
    if (rootContainer) {
      toggleTabIndex("off", rootContainer);
    }
    freeze();

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      unfreeze();
      if (modalContainer) {
        toggleTabIndex("off", modalContainer);
      }
      if (rootContainer) {
        toggleTabIndex("on", rootContainer);
      }
    };
  }, [handleExit, id, root]);

  return createPortal(
    <Overlay active={true} onClick={() => handleExit()}>
      <Container
        onClick={event => {
          //Prevent click events inside the modal from bubbling up to the overlay and closing the modal
          event.stopPropagation();
        }}
        active={true}
        style={style ? style : {}}
        {...otherProps}
      >
        <ExitButton active={true} ref={exitButton} onClick={() => handleExit()}>
          {t("exit")}
          <svg width="16" height="16" viewBox="0 0 20 20">
            <g fill="#5F6B7A" fillRule="nonzero" stroke="none">
              <path d="M18.6753571 2.08152486l-.6487597-.67476795c-.3578377-.37260221-.9393896-.37260221-1.2979286 0L9.98071429 8.33578453 3.21534416 1.30137569c-.35848052-.37254144-.93974026-.37254144-1.29792858 0l-.64875974.6746464c-.3581883.37272377-.3581883.97644752 0 1.34923205l8.055 8.37634806c.35818832.3729061.93898052.3729061 1.29757793 0l8.05412333-8.27102761c.359065-.37254144.359065-.97650829 0-1.34904973z" />
              <path d="M18.6753571 17.91847514l-.6487597.67476795c-.3578377.37260221-.9393896.37260221-1.2979286 0l-6.74795451-6.92902762-6.76537013 7.03440884c-.35848052.37254144-.93974026.37254144-1.29792858 0l-.64875974-.6746464c-.3581883-.37272377-.3581883-.97644752 0-1.34923205l8.055-8.37634806c.35818832-.3729061.93898052-.3729061 1.29757793 0l8.05412333 8.27102761c.359065.37254144.359065.97650829 0 1.34904973z" />
            </g>
          </svg>
        </ExitButton>
        {children}
      </Container>
    </Overlay>,
    modal.current
  );
};

export default Modal;
