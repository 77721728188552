import css from "@emotion/css";
import { useEffect } from "react";

import { useAuth } from "~/auth-provider";
import { LoadingLogo } from "~/shared/components/loading/loading-logo";

const styles = {
  self: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  `,
};

function Logout() {
  const { logout } = useAuth();

  useEffect(() => {
    logout().catch(() => console.error);
  }, [logout]);

  return (
    <div css={styles.self}>
      <LoadingLogo width="60" />
    </div>
  );
}

export default Logout;
