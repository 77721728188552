import { useQuery } from "@tanstack/react-query";
import { ReactNode } from "react";

import { primhdCodeQueries, PrimhdCodeQueryResponse } from "~/primhdv2/api";
import { CodeType, PrimhdRecordType } from "~/primhdv2/types";

function RecordCodeLoader({
  recordType,
  children,
}: {
  recordType: PrimhdRecordType;
  children: ({
    isLoading,
    codes,
  }: {
    isLoading: boolean;
    codes?: PrimhdCodeQueryResponse;
  }) => ReactNode;
}) {
  const mapTypeToCodeTypes: Record<PrimhdRecordType, CodeType[]> = {
    ACTIVITY: ["ACTIVITY_TYPE", "ACTIVITY_SETTING"],
    CLASSIFICATION: ["CLASSIFICATION_CODING", "CLASSIFICATION_VALUE", "CLASSIFICATION_DIAGNOSIS"],
    CONSUMER: [
      "CONSUMER_WELLNESS",
      "CONSUMER_ACCOMMODATION",
      "CONSUMER_EDUCATION",
      "CONSUMER_EMPLOYMENT",
    ],
    COLLECTION: [
      "COLLECTION_REASON",
      "COLLECTION_PROTOCOL",
      "COLLECTION_FOCUS",
      "COLLECTION_TOOL",
      "COLLECTION_ADMIN",
      "COLLECTION_STATUS",
    ],
  };

  const { isLoading, data } = useQuery(
    primhdCodeQueries.list(`type=${mapTypeToCodeTypes[recordType].join(",")}`)
  );

  return children({
    isLoading,
    codes: data,
  });
}

export default RecordCodeLoader;
