import type { ReactNode } from "react";
import { useContext } from "react";
import { RouterProvider as ReactAriaRouterProvider } from "react-aria-components";

import { NcGlobalContext } from "./nc-global-context";

type NcRouterProviderProps = {
  children: ReactNode;
};

export const NcRouterProvider = ({ children, ...props }: NcRouterProviderProps) => {
  const { navigate } = useContext(NcGlobalContext);
  return (
    <ReactAriaRouterProvider navigate={navigate} {...props}>
      {children}
    </ReactAriaRouterProvider>
  );
};
