import { css } from "@emotion/core";
import type { ReactNode } from "react";

import { ncTheme } from "../nc-theme";

export const staticListStyles = {
  item: css`
    padding: ${ncTheme.spacing(3)};
    &:not(:last-of-type) {
      border-bottom: ${ncTheme.border()};
    }
  `,
};

type NcListProps = {
  children: ReactNode;
};

const NcStaticList = ({ children, ...props }: NcListProps) => {
  return (
    <ul data-nc="NcStaticList" {...props}>
      {children}
    </ul>
  );
};

const Item = ({ ...props }) => {
  return <li data-nc="NcStaticList.Item" css={staticListStyles.item} {...props} />;
};

NcStaticList.Item = Item;

export { NcStaticList };
