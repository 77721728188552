import styled, { theme } from "../../theme";
import Text from "../primitives/text";

export const NavLinkText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  color: ${theme.colors.primary.lightest};
`;

NavLinkText.displayName = "Title";

NavLinkText.defaultProps = {
  pl: 3,
};

export default NavLinkText;
