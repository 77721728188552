import { DateTime, Interval } from "luxon";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  IAdjustmentFormData,
  useAdjustmentFormData,
} from "../availability/adjustments/adjustment-hooks";
import { Mode } from "./agenda-view";
import {
  IResourceAdjustmentPeriodState,
  useResourceAdjustmentPeriods,
} from "./resource-adjustment-periods-state";

interface IAvailabilityContext {
  mode: Mode;
  setMode: Dispatch<SetStateAction<Mode>>;
  viewDate: DateTime;
  setViewDate: Dispatch<SetStateAction<DateTime>>;
  isResourceSidebarVisible: boolean;
  setIsResourceSidebarVisible: Dispatch<SetStateAction<boolean>>;
  resourceAdjustmentPeriods: IResourceAdjustmentPeriodState;
  formState: IAdjustmentFormData;
}

const AvailabilityContext = createContext<IAvailabilityContext | null>(null);

export const useAvailability = () => {
  const value = useContext(AvailabilityContext);
  if (value === null) {
    throw new Error("useAvailability must be used with a AvailabilityState");
  }

  return value;
};

export const AvailabilityState = ({ children }: { children: ReactNode }) => {
  const [viewDate, setViewDate] = useState<DateTime>(DateTime.local().startOf("week"));
  const [mode, setMode] = useState<Mode>("workers");
  const [isResourceSidebarVisible, setIsResourceSidebarVisible] = useState<boolean>(false);
  const resourceAdjustmentPeriods = useResourceAdjustmentPeriods(
    Interval.after(viewDate.startOf("day"), { days: 7 })
  );

  const formState = useAdjustmentFormData();

  const value = useMemo(
    () => ({
      mode,
      setMode,
      viewDate,
      setViewDate,
      isResourceSidebarVisible,
      setIsResourceSidebarVisible,
      resourceAdjustmentPeriods,
      formState,
    }),
    [isResourceSidebarVisible, mode, resourceAdjustmentPeriods, viewDate, formState]
  );
  return <AvailabilityContext.Provider value={value}>{children}</AvailabilityContext.Provider>;
};
