import { ComponentProps } from "react";

import styled, { theme } from "../../theme";
import { Box, Flex } from "../primitives";

export const Group = ({
  title,
  children,
  ...otherProps
}: {
  title: string;
} & Omit<ComponentProps<typeof Box>, "ref">) => {
  return (
    <GroupContainer {...otherProps}>
      <GroupWrapper>
        <Title title={title} />
        <Content>{children}</Content>
      </GroupWrapper>
    </GroupContainer>
  );
};

const GroupContainer = styled(Box)``;

GroupContainer.defaultProps = {
  pt: 5,
  pb: 5,
};

const GroupWrapper = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  background-color: ${theme.colors.neutral.light};
  border: 1px solid;
  border-color: ${theme.colors.neutral.medium};
  border-radius: 4px;

  &:focus-within {
    border-color: ${theme.colors.info.mediumLight};
  }
`;

const Title = ({ title }: { title: string }) => {
  return (
    <TitleWrapper>
      <TitleText>
        <TitleOverflow>{title}</TitleOverflow>
      </TitleText>
    </TitleWrapper>
  );
};

const TitleWrapper = styled(Box)`
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  margin-top: -1rem;
`;

const TitleText = styled(Box)`
  display: inline-block;
  padding: 0.5rem 0.75rem;
  color: ${theme.colors.neutral.lightest};
  background-color: ${theme.colors.info.medium};
  border-radius: 3px;
  font-size: ${theme.fontSizes[1]};
  text-transform: uppercase;
`;

const TitleOverflow = styled(Box)`
  max-height: 2.25rem;
  overflow: hidden;
  @supports (-webkit-line-clamp: 2) {
    max-height: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const Content = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  padding: 0.5rem 1rem 1rem;
`;
