import { z } from "zod";

const accessLevel = z.union([z.literal("FULL"), z.literal("LIMITED"), z.literal("RESTRICTED")]);

export type AccessLevel = z.infer<typeof accessLevel>;

export const appointmentSchema = z.object({
  id: z.number(),
  notes: z.string(),
  client: z.object({
    id: z.number(),
    name: z.string(),
    accessLevel,
  }),
  service: z.object({
    name: z.string(),
  }),
  from: z.number(),
  duration: z.number(),
  confirmed: z.boolean(),
  arrived: z.boolean(),
  cancelled: z.boolean(),
  completed: z.boolean(),
  paid: z.boolean(),
  telehealth: z.boolean(),
  telehealthRoom: z
    .object({
      token: z.string(),
    })
    .optional(),
});

export type Appointment = z.infer<typeof appointmentSchema>;
