import { isDevelopment } from "../shared/functions/is-development";

export const config = {
  angularOrigin: window.location.origin,
  telehealthAppRoute: isDevelopment() ? "http://localhost:3000/call" : "/call",
  privateRoutes: [
    "/admin",
    "/scheduling",
    "/primhd",
    "/auditing",
    "/raw",
    "/update-password",
    "/extract",
    "/user-settings",
  ],
};

export default config;
