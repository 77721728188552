import {
  getName,
  Name,
  NcBadge,
  NcButton,
  NcCollapse,
  NcFormattedMessage,
  NcFormattedName,
  NcGridList,
  NcIconEllipsis,
  NcLink,
  NcMenu,
  NcPanel,
} from "@noted/noted-components";
import { groupBy, orderBy } from "lodash-es";
import { DateTime } from "luxon";
import { ReactNode, useState } from "react";
import { useId } from "react-aria";

import { canDeleteClientRecords, useAccount } from "~/account/account.context";
import { useI18n } from "~/hooks/use-i18n";
import { ConfirmActionModal } from "~/shared/components/actions-modal/confirm-actions-modal";

import {
  useAllRemindersQuery,
  useCompleteReminderMutation,
  useDeleteReminderMutation,
  useUpdateReminderMutation,
} from "../dashboard-queries";
import { NoRemindersIcon } from "../icons";
import RemindersEditModal from "./reminders-edit-modal";
import { Reminder } from "./types";

const PanelEmpty = () => {
  const { t } = useI18n("org");
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-2 p-8">
      <NoRemindersIcon opacity="0.5" width="8rem" />
      <NcFormattedMessage variant="secondary">
        {t("org:dashboard.reminders.empty")}
      </NcFormattedMessage>
    </div>
  );
};

function getMonthNameAbbreviation(dueDate: string) {
  return DateTime.fromISO(dueDate).toFormat("MMM").toLocaleUpperCase();
}

function getDateDay(dueDate: string) {
  return DateTime.fromISO(dueDate).toFormat("dd");
}

const ReminderItem = ({
  reminder,
  status,
  setEditingReminder,
  handleReminderDelete,
}: {
  reminder: Reminder;
  status: ReactNode;
  setEditingReminder: (r: Reminder) => void;
  handleReminderDelete: (r: Reminder) => void;
}) => {
  const { t } = useI18n("org");
  const { account, organisation } = useAccount();

  const { mutate: completeReminder } = useCompleteReminderMutation();

  const handleMenuAction = (reminder: Reminder) => (type: string) => {
    if (type === "edit") {
      setEditingReminder(reminder);
    }
    if (type === "complete") {
      completeReminder({ id: reminder.id });
    }
    if (type === "delete") {
      handleReminderDelete(reminder);
    }
  };

  return (
    <NcGridList.Item
      className="group grid grid-cols-[4.5rem_1fr_auto] items-start gap-3"
      textValue={`${getName(reminder.patient as Name, "full")} ${reminder.title}`}
    >
      <div className="grid gap-1">
        {status}
        <div className="text-center text-sm">
          {getDateDay(reminder.dueDate)} {getMonthNameAbbreviation(reminder.dueDate)}
        </div>
      </div>

      <div className="grid gap-2">
        <NcLink
          className="group-hover:text-current group-focus:text-current"
          href={`/n/clients/${reminder.patientId}`}
        >
          <NcFormattedName name={reminder.patient as Name} variant="full" />
        </NcLink>
        <NcCollapse>
          <NcCollapse.Summary>{reminder.title}</NcCollapse.Summary>
          {reminder.description ? (
            reminder.description
          ) : (
            <NcFormattedMessage variant="secondary">
              {t("org:dashboard.reminders.no_description")}
            </NcFormattedMessage>
          )}
        </NcCollapse>
      </div>
      <NcMenu.Trigger>
        <NcButton aria-label={t("org:dashboard.reminders.actions.trigger")}>
          <NcIconEllipsis />
        </NcButton>
        <NcMenu
          aria-label={t("org:dashboard.reminders.actions.label")}
          onAction={type => handleMenuAction(reminder)(`${type}`)}
          placement="bottom end"
        >
          {!organisation.readOnlyMode && (
            <NcMenu.Item id="complete" variant="success">
              {t("org:dashboard.reminders.actions.complete.label")}
            </NcMenu.Item>
          )}
          <NcMenu.Item id="open-client" href={`/n/clients/${reminder.patientId}`}>
            {t("org:dashboard.reminders.actions.open_client")}
          </NcMenu.Item>
          {!organisation.readOnlyMode && (
            <>
              <NcMenu.Item id="edit">{t("org:dashboard.reminders.actions.edit")}</NcMenu.Item>

              {canDeleteClientRecords(account) && (
                <>
                  <NcMenu.Separator />
                  <NcMenu.Item id="delete" variant="danger">
                    {t("org:dashboard.reminders.actions.delete")}
                  </NcMenu.Item>
                </>
              )}
            </>
          )}
        </NcMenu>
      </NcMenu.Trigger>
    </NcGridList.Item>
  );
};

const RemindersPanel = () => {
  const { t } = useI18n("org");
  const panelId = useId();
  const [editingReminder, setEditingReminder] = useState<Reminder>();
  const [deletingReminder, setDeletingReminder] = useState<Reminder>();

  const { mutate: saveReminder } = useUpdateReminderMutation();
  const { mutate: deleteReminder, isPending: isDeleting } = useDeleteReminderMutation();
  const { data: reminders, isLoading, error } = useAllRemindersQuery();

  const today = DateTime.now().endOf("day");

  function groupReminders(reminders: Reminder[]) {
    return groupBy(reminders, (r: Reminder) => {
      if (isOverdue(r.dueDate)) {
        return "overdue";
      } else if (isToday(r.dueDate)) {
        return "today";
      } else {
        return "future";
      }
    });
  }

  function sortReminders(reminders: Reminder[]) {
    return orderBy(reminders, "dueDate");
  }

  const groupedReminders = groupReminders(sortReminders(reminders || []));

  function getDueDateDay(dueDate: string) {
    return DateTime.fromISO(dueDate).endOf("day");
  }

  function isOverdue(dueDate: string) {
    const dueDay = getDueDateDay(dueDate);
    return dueDay < today;
  }

  function isToday(dueDate: string) {
    const dueDay = getDueDateDay(dueDate);
    return dueDay.equals(today);
  }

  const handleReminderSave = (reminder: Reminder) => {
    setEditingReminder(undefined);
    saveReminder(reminder);
  };

  const handleReminderDelete = (reminder: Reminder) => {
    setDeletingReminder(reminder);
  };

  return (
    <NcPanel>
      <NcPanel.Header>
        <NcPanel.Heading id={panelId} level={2}>
          {t("org:dashboard.reminders.heading")}
        </NcPanel.Heading>
        <NcBadge variant="defaultLight" className="ml-auto">
          {reminders?.length}
        </NcBadge>
      </NcPanel.Header>
      <NcPanel.Body
        className="p-0"
        isLoading={isLoading}
        error={
          error
            ? t("common:error_loading_refresh", {
                component: t("org:dashboard.reminders.name"),
              })
            : undefined
        }
      >
        {!reminders?.length && <PanelEmpty />}
        {!!reminders?.length && (
          <NcGridList aria-labelledby={panelId}>
            {groupedReminders.overdue?.length &&
              groupedReminders.overdue.map(reminder => (
                <ReminderItem
                  key={reminder.id}
                  reminder={reminder}
                  status={
                    <NcBadge variant="dangerLight">{t("org:dashboard.reminders.overdue")}</NcBadge>
                  }
                  {...{
                    setEditingReminder,
                    handleReminderDelete,
                  }}
                />
              ))}
            {groupedReminders.today?.length &&
              groupedReminders.today.map(reminder => (
                <ReminderItem
                  key={reminder.id}
                  reminder={reminder}
                  status={
                    <NcBadge variant="defaultLight">{t("org:dashboard.reminders.today")}</NcBadge>
                  }
                  {...{
                    setEditingReminder,
                    handleReminderDelete,
                  }}
                />
              ))}
            {groupedReminders.future?.length &&
              groupedReminders.future.map(reminder => (
                <ReminderItem
                  key={reminder.id}
                  reminder={reminder}
                  status={
                    <NcBadge variant="neutralLight">{t("org:dashboard.reminders.future")}</NcBadge>
                  }
                  {...{
                    setEditingReminder,
                    handleReminderDelete,
                  }}
                />
              ))}
          </NcGridList>
        )}
        {editingReminder && (
          <RemindersEditModal
            reminder={editingReminder}
            handleExit={() => setEditingReminder(undefined)}
            onSaveReminder={handleReminderSave}
          />
        )}
        {deletingReminder && (
          <ConfirmActionModal
            title={t("org:dashboard.reminders.delete.heading")}
            message={t("org:dashboard.reminders.delete.message")}
            continueLabel={t("org:dashboard.reminders.actions.delete")}
            inProgress={isDeleting}
            onCancel={() => setDeletingReminder(undefined)}
            onContinue={() => {
              deleteReminder({ id: deletingReminder.id });
              setDeletingReminder(undefined);
            }}
            variant="danger"
          />
        )}
      </NcPanel.Body>
    </NcPanel>
  );
};

export default RemindersPanel;
