import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconSortDescending = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12.784 22.64a1.034 1.034 0 0 1-1.568 0l-5.97-6.96C4.68 15.016 5.155 14 6.03 14h11.94c.876 0 1.35 1.017.783 1.68z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="m6 8 5.304-5.706a.97.97 0 0 1 1.392 0L18 8"
    />
  </NcIcon>
);
export { NcIconSortDescending };
