import { PropsWithChildren, ReactNode, useState } from "react";

import { useReadOnly } from "../../../account/account.context";
import Tag, { TagText } from "../forms/tag";
import { Box } from "../primitives";
import { Choice, ChoiceInput, ChoiceItem, ChoiceLabel, ChoiceMenu, IChoice } from "./choice";

export interface ISearchableMultiSelectProps<T> {
  options: T[];
  selectedOptions: T[];
  onSelectOption: (option: T) => void;
  onDeselectOption: (option: T) => void;
  getOptionId: (option: T) => number | string;
  getOptionLabel: (option: T) => string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  highlightLastSelectedOption?: boolean;
  showSelectedOptionsInDropdown?: boolean;
  maxLength?: number;
  id?: string;
  staticValues?: ReactNode;
}

export const SearchableMultiSelect = <T,>(
  props: PropsWithChildren<ISearchableMultiSelectProps<T> & Omit<IChoice, "children">>
) => {
  const {
    options,
    selectedOptions,
    onSelectOption,
    onDeselectOption,
    getOptionId,
    getOptionLabel,
    label,
    placeholder,
    disabled,
    highlightLastSelectedOption,
    showSelectedOptionsInDropdown,
    maxLength,
    id: inputId,
    staticValues,
    ...choiceProps
  } = props;

  const readOnlyMode = useReadOnly();
  const [lastTouchedOptionId, setLastTouchedOptionId] = useState<number | string>();

  return (
    <>
      <Choice
        selectedItem={{}}
        onChange={o => {
          if (o) {
            setLastTouchedOptionId(getOptionId(o));
            onSelectOption(o);
          }
        }}
        itemToString={o => getOptionLabel(o) || ""}
        {...choiceProps}
      >
        {label && <ChoiceLabel htmlFor={inputId}>{label}</ChoiceLabel>}
        <ChoiceInput
          showDropdownIcon={true}
          placeholder={placeholder}
          disabled={disabled || readOnlyMode}
          maxLength={maxLength}
          id={inputId}
          mb={0}
        />

        <ChoiceMenu>
          {staticValues}
          {(showSelectedOptionsInDropdown
            ? options
            : options.filter(o => {
                const oId = getOptionId(o);
                return !selectedOptions.some(s => getOptionId(s) === oId);
              })
          ).map(o => {
            const id = getOptionId(o);
            const l = getOptionLabel(o);
            return (
              <ChoiceItem key={id} options={{ key: l, item: o }}>
                {l}
              </ChoiceItem>
            );
          })}
        </ChoiceMenu>
      </Choice>
      <Box mt="4" mb="2" px="0">
        <Box style={{ display: "inline" }}>
          {selectedOptions.map(o => {
            const id = getOptionId(o);
            return (
              <Tag
                key={id}
                closable={!disabled && !readOnlyMode}
                onClose={() => onDeselectOption(o)}
                mr="1"
                mt="0"
                mb="1"
                ml="0"
                variant={
                  highlightLastSelectedOption && lastTouchedOptionId === id ? "primary" : "default"
                }
                onClick={() => setLastTouchedOptionId(id)}
              >
                <TagText>{getOptionLabel(o)}</TagText>
              </Tag>
            );
          })}
        </Box>
      </Box>
    </>
  );
};
