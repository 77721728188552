import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useShouldReactRefresh = () => {
  const currentVersion = import.meta.env.VITE_COMMIT_SHA;
  const [latestVersion, setLatestVersion] = useState("");

  useEffect(() => {
    let alive = true;
    const checkVersion = () => {
      fetch(`${window.location.origin}/latest.sha?sentAt=${new Date().getTime()}`)
        .then(res => {
          if (!res.ok) {
            throw new Error("Error");
          }
          return res.text();
        })
        .then(version => {
          if (alive) {
            setLatestVersion(version);
          }
        })
        .catch(() => {
          // Empty catch so that Sentry doesn't pick it up
        });
    };
    window.addEventListener("focus", checkVersion);
    return () => {
      alive = false;
      window.removeEventListener("focus", checkVersion);
    };
  }, []);

  return Boolean(currentVersion && latestVersion && currentVersion !== latestVersion);
};

export const VersionUpdateController = () => {
  const location = useLocation();
  const shouldReactRefresh = useShouldReactRefresh();

  useEffect(() => {
    if (shouldReactRefresh) {
      window.location.reload();
    }
  }, [location, shouldReactRefresh]);

  return null;
};
