import { useI18n } from "~/hooks/use-i18n";
import { Page, PageContent, PageContentWrapper, PageHeader } from "~/shared/components/layout";
import { PageTitle } from "~/shared/components/primitives";
import { IAccount } from "~/shared/types";

import { AccProviderSettings } from "../edit/acc-provider-settings";

export const AccSettingsPage = ({ user }: { user: IAccount }) => {
  const { t } = useI18n("user-settings");
  // ACC provider settings expects a graphQL user object with name property
  const accUser = { ...user, name: `${user.firstName} ${user.lastName}` };
  return (
    <Page data-testid="acc-provider-page">
      <PageContentWrapper>
        <PageHeader>
          <PageTitle>{t("user-settings:accSettings.title")}</PageTitle>
        </PageHeader>
        <PageContent data-testid="acc-provider-content">
          <AccProviderSettings user={accUser} />
        </PageContent>
      </PageContentWrapper>
    </Page>
  );
};
