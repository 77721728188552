import { IResourceAdjustmentPeriod } from "../../shared/types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBlockedTime extends IResourceAdjustmentPeriod {}

export enum CalendarItemType {
  "APPOINTMENT" = "APPOINTMENT",
  "BLOCKED_TIME" = "BLOCKED_TIME",
}

export interface ICalendarItem {
  id: string;
  type: CalendarItemType;
  itemTypeId: number;
  top: number;
  bottom: number;
  left: number;
  width: number;
  isClashing?: boolean;
}
