import { useQueryClient } from "@tanstack/react-query";
import { createContext, memo, ReactNode, useContext, useMemo } from "react";

import { type Service } from "~/graphql-hooks/types";

import { useIsTelehealthEnabled } from "../../account/features.context";
import { useGetServicesQuery as useGetServicesRemoteQuery } from "../api";

type ServicesContextType = { services: Service[]; loading: boolean; refetch: () => void };

const ServicesContext = createContext<ServicesContextType>({
  loading: false,
  data: undefined,
  error: undefined,
  services: [],
} as unknown as ServicesContextType);

export const useGetServicesQuery = () => {
  const telehealthEnabled = useIsTelehealthEnabled();
  const queryClient = useQueryClient();

  const services = useGetServicesRemoteQuery(
    { telehealthEnabled },
    { refetchOnWindowFocus: false }
  );

  return useMemo(
    () =>
      ({
        loading: services.isLoading,
        services: services.data?.services ?? [],
        refetch: () =>
          queryClient.invalidateQueries({
            queryKey: useGetServicesRemoteQuery.getKey({ telehealthEnabled }),
          }),
      }) as ServicesContextType,
    [telehealthEnabled, services]
  );
};

export const useServices = () => useContext(ServicesContext);

export const ServicesState = memo(({ children }: { children: ReactNode }) => {
  const value = useGetServicesQuery();
  return <ServicesContext.Provider value={value}>{children}</ServicesContext.Provider>;
});
ServicesState.displayName = "ServicesState";
