import { css } from "@emotion/core";
import { ReactNode } from "react";

import { BREAKPOINT_MID_QUERY, theme } from "../../theme";

export const SubNavigation = ({
  alternate,
  children,
}: {
  alternate?: boolean;
  children: ReactNode;
}) => {
  const styles = css`
    background-color: ${alternate ? theme.colors.neutral.lightest : theme.colors.info.dark};
    display: flex;
    flex-basis: auto;
    flex-direction: row;
    flex-shrink: 0;
    overflow: auto;
    padding: 0.75rem;
    z-index: 1;

    @media ${BREAKPOINT_MID_QUERY} {
      flex-basis: 13rem;
      flex-direction: column;
    }
  `;
  return <nav css={styles}>{children}</nav>;
};

export default SubNavigation;
