import { css } from "@emotion/core";
import type { HTMLAttributes } from "react";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcBadge } from "./nc-badge";
import { NcFlexLayout } from "./nc-flex-layout";

interface NcFormattedAddressProps extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  address: {
    street1?: string;
    street2?: string;
    suburb?: string;
    city?: string;
    region?: string;
    postcode?: string;
    country?: string;
    isPrimaryMailing?: boolean;
  };
}

const styles = {
  address: css`
    display: flex;
    flex-direction: column;
    gap: ${ncTheme.spacing(0.5)};
  `,
  addressLabel: css`
    margin-bottom: ${ncTheme.spacing(0.5)};
  `,
  primaryMailing: css`
    margin-left: auto;
  `,
};

export const NcFormattedAddress = ({ address, label, ...props }: NcFormattedAddressProps) => {
  const { t } = useI18n();
  return (
    <address data-nc="NcFormattedAddress" css={styles.address} {...props}>
      <NcFlexLayout>
        {label && <strong css={styles.addressLabel}>{label}</strong>}
        {address.isPrimaryMailing && (
          <NcBadge variant="defaultLight" css={styles.primaryMailing}>
            {t("Primary mailing")}
          </NcBadge>
        )}
      </NcFlexLayout>
      {address.street1 && <div>{address.street1}</div>}
      {address.street2 && <div>{address.street2}</div>}
      {address.suburb && <div>{address.suburb}</div>}
      {(address.city || address.postcode) && (
        <div>
          <span>{address.city}</span>
          &nbsp;
          <span>{address.postcode}</span>
        </div>
      )}
      {address.country && <div>{address.country}</div>}
    </address>
  );
};
