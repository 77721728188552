import { css } from "@emotion/core";
import { isFunction } from "lodash-es";
import { ChangeEvent, FocusEvent, forwardRef, SelectHTMLAttributes, useRef } from "react";

import { useI18n } from "~/hooks/use-i18n";

import { theme } from "../theme";
import { Feedback, Label } from "./index";
import Select from "./select";

const safeProps = (value?: number) => {
  if (value === undefined) {
    return 0;
  }
  if (value < 0) {
    return 0;
  }
  return value % (24 * 60);
};

export const minutesToObject = (value?: number) => {
  const safeValue = safeProps(value);
  const hours = Math.floor(safeValue / 60);
  const minutes = safeValue - hours * 60;
  return { hours, minutes };
};

type DurationProps = SelectHTMLAttributes<HTMLSelectElement> & {
  error?: string;
  minutesInterval?: 1 | 5 | 10 | 15 | 20 | 30 | 60;
};

/**
 * Duration component designed to work as an uncontrolled component.
 */
const Duration = forwardRef<HTMLSelectElement, DurationProps>(
  ({ defaultValue, disabled, error, id, minutesInterval = 5, name, onBlur, onChange }, ref) => {
    const { t } = useI18n();

    const dummyField = (defaultValue ? { value: defaultValue } : {}) as HTMLSelectElement;
    if (isFunction(ref)) {
      ref(dummyField);
    }

    const initialValues = minutesToObject(Number(dummyField.value || 0));
    const currentHours = useRef(initialValues.hours);
    const currentMinutes = useRef(initialValues.minutes);

    return (
      <>
        <div
          css={css`
            align-items: center;
            display: grid;
            gap: ${theme.space[1]} ${theme.space[2]};
            grid-template-columns: repeat(4, 4.6875rem);
          `}
        >
          <Select
            aria-label={t("hours")}
            data-cy="duration-hours"
            data-testid="duration-hours"
            defaultValue={currentHours.current}
            disabled={disabled}
            id={id}
            onBlur={event => {
              const e = event as FocusEvent<HTMLSelectElement>;
              if (onBlur) onBlur(e);
            }}
            onChange={event => {
              const e = event as ChangeEvent<HTMLSelectElement>;
              currentHours.current = Number(e.currentTarget.value);

              if (!onChange) return;
              onChange({
                target: {
                  name,
                  value: Number(e.currentTarget.value) * 60 + currentMinutes.current,
                },
              } as unknown as ChangeEvent<HTMLSelectElement>);
            }}
          >
            {[...Array(24)].map((_, i) => (
              <option value={i} key={i}>
                {i < 10 ? `0${i}` : i}
              </option>
            ))}
          </Select>
          <Label>{t("hours")}</Label>

          <Select
            aria-label={t("minutes")}
            data-cy="duration-minutes"
            data-testid="duration-minutes"
            defaultValue={currentMinutes.current}
            disabled={disabled}
            onChange={event => {
              const e = event as ChangeEvent<HTMLSelectElement>;
              currentMinutes.current = Number(e.currentTarget.value);

              if (!onChange) return;
              onChange({
                target: {
                  name,
                  value: currentHours.current * 60 + Number(e.currentTarget.value),
                },
              } as unknown as ChangeEvent<HTMLSelectElement>);
            }}
          >
            {[...Array(60 / minutesInterval)]
              .map((_, i) => i * minutesInterval)
              .map(m => (
                <option value={m} key={m}>
                  {m < 10 ? `0${m}` : m}
                </option>
              ))}
          </Select>
          <Label>{t("minutes")}</Label>
        </div>
        {error && <Feedback>{t(error)}</Feedback>}
      </>
    );
  }
);
Duration.displayName = "Duration";

export default Duration;
