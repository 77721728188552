import styled from "../../theme";
import { Flex } from "../primitives";

export const ButtonGroup = styled(Flex)`
  list-style: none;
  li {
    button {
      margin-left: -0.15rem;
    }
    &:not(:first-of-type):not(:last-of-type) {
      button {
        border-radius: 0;
      }
    }
    &:first-of-type button {
      margin-left: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-of-type button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:focus,
    &:active {
      z-index: 1;
    }
  }
`.withComponent("ul");

export default ButtonGroup;
