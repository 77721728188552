import { css } from "@emotion/core";
import { forwardRef, type ReactNode } from "react";
import type { CheckboxProps as ReactAriaCheckboxProps } from "react-aria-components";
import { Checkbox as ReactAriaCheckbox } from "react-aria-components";

import { ncTheme } from "../../nc-theme";
import { svgToDataUri } from "../../utils";
import { labelStyles } from "../nc-label";
import { inputStyles } from "./nc-input";

const svgs = {
  checked: svgToDataUri(
    `<svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"/></svg>`
  ),
  indeterminate: svgToDataUri(
    `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h8"/></svg>`
  ),
};

interface NcCheckboxProps extends ReactAriaCheckboxProps {
  children: ReactNode;
}

export const checkboxStyles = {
  wrap: css`
    align-items: center;
    display: flex;
    gap: ${ncTheme.spacing(2)};
    transition: transform ${ncTheme.transition("color text-decoration border-color")};

    &[data-disabled] {
      cursor: not-allowed;
      color: ${ncTheme.colors.disabled};
    }

    &[data-hovered],
    &[data-focus-visible] {
      color: ${ncTheme.colors.main};
      text-decoration: underline;
      border-radius: ${ncTheme.borderRadius.medium};
    }

    &[data-focus-visible] {
      outline: solid;
      ${ncTheme.utilities.outlineStyles}
      border-radius: ${ncTheme.borderRadius.medium};
    }
  `,
  checkbox: css`
    ${inputStyles};

    min-width: unset;
    width: 1.5em;
    height: 1.5em;
    padding: 0;

    [data-disabled] & {
      background-color: ${ncTheme.colors.disabledLight};
    }

    [data-selected] & {
      background-color: ${ncTheme.colors.active};
      background-image: url("${svgs.checked}");
    }

    [data-disabled][data-selected] & {
      background-color: ${ncTheme.colors.disabled};
    }

    [data-indeterminate] & {
      background-color: ${ncTheme.colors.active};
      background-image: url("${svgs.indeterminate}");
    }

    :focus &,
    :active &,
    :hover &,
    :focus-within & {
      background-color: ${ncTheme.colors.active};
    }
  `,
  label: css`
    ${labelStyles};
    line-height: 1.5;
  `,
};

export const NcCheckbox = forwardRef<HTMLLabelElement, NcCheckboxProps>(
  ({ children, ...props }, ref) => {
    return (
      <ReactAriaCheckbox data-nc="NcCheckbox" ref={ref} css={checkboxStyles.wrap} {...props}>
        <div css={checkboxStyles.checkbox}></div>
        <div css={checkboxStyles.label}>{children}</div>
      </ReactAriaCheckbox>
    );
  }
);
