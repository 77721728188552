import HCaptcha from "@hcaptcha/react-hcaptcha";
import { ComponentProps } from "react";

import { isDevelopment } from "../functions/is-development";

type Props = Omit<ComponentProps<typeof HCaptcha>, "sitekey">;

/**
 * To test captcha in localhost, uncomment BE lines that allow token 10000000-aaaa-bbbb-cccc-000000000001
 */
export const Captcha = (props: Props) => {
  return (
    <HCaptcha
      sitekey={
        isDevelopment()
          ? "10000000-ffff-ffff-ffff-000000000001"
          : "86f7682a-5efd-4b6b-aada-c03851ab3e12"
      }
      {...props}
    />
  );
};
