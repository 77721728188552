import { css, Global } from "@emotion/core";
import type { ReactNode } from "react";
import { createContext } from "react";
import type { NavigateFunction } from "react-router-dom";

import { NcRouterProvider } from "./nc-router-provider";
import { ncTheme } from "./nc-theme";

const defaultValue = {
  t: (value: string) => value,
  locale: "en-NZ",
  navigate: () => {},
};

type NcGlobalContextValue = {
  t: (value: string, params?: { [key: string]: string }) => string;
  locale: string;
  navigate: NavigateFunction;
};

const globalThemeStyles = css`
  /* App defaults */
  html {
    color: ${ncTheme.colors.dark};
    background-color: ${ncTheme.colors.light};
    outline-color: ${ncTheme.colors.brandActive};

    *:focus {
      outline: none;
    }

    *[data-focused="true"][data-focus-visible="true"],
    *:focus-visible {
      ${ncTheme.utilities.outlineStyles};
    }
  }
`;

export const NcGlobalContext = createContext<NcGlobalContextValue>(defaultValue);

export const NcGlobalProvider = ({
  value,
  children,
}: {
  value: NcGlobalContextValue;
  children: ReactNode;
}) => (
  <NcGlobalContext.Provider value={value || defaultValue}>
    <Global styles={globalThemeStyles} />
    <NcRouterProvider>{children}</NcRouterProvider>
  </NcGlobalContext.Provider>
);
