import styled, { theme } from "../../theme";
import Text from "../primitives/text";

export const ModalHeader = styled(Text)`
  border-bottom-color: ${theme.colors.neutral.light};
`.withComponent("h1");

ModalHeader.displayName = "ModalHeader";

ModalHeader.defaultProps = {
  m: 0,
  padding: 3,
  fontWeight: 400,
  fontSize: 3,
  borderBottom: "solid 1px",
};
export default ModalHeader;
