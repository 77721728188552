import { ComponentProps } from "react";

import styled, { theme } from "../../theme";
import Flex from "../primitives/flex";

export type ContainerProps = ComponentProps<typeof Flex> & { active: boolean };

export const ModalContainer = styled(Flex)<ContainerProps>`
  background: ${theme.colors.neutral.lightest};
  overflow: hidden;
  box-shadow:
    0px 17px 20px 9px rgba(0, 0, 0, 0.12),
    0px 4px 11px rgba(0, 0, 0, 0.25);
  transform: ${props => (props.active ? "translateY(0%)" : "translateY(-25%)")};
  opacity: ${props => (props.active ? 1 : 0)};
  transition:
    transform 250ms ease-out,
    opacity 200ms ease-out;
`;

ModalContainer.displayName = "ModalContainer";

ModalContainer.defaultProps = {
  borderRadius: 5,
  flexDirection: "column",
  margin: 6,
};

export default ModalContainer;
