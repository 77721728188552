import styled, { theme } from "../../theme";
import Flex from "../primitives/flex";

/**
 * @deprecated Use TextArea from `/shared/ui` instead.
 */
export const TextArea = styled(Flex)`
  border-color: ${theme.colors.neutral.medium};
  border-width: 1px;
  border-style: solid;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  color: ${theme.colors.neutral.darkest};
  outline-color: ${theme.colors.info.mediumLight};
  resize: vertical;
  &:hover {
    border-color: ${theme.colors.neutral.mediumDark};
  }
  &:active,
  &:focus {
    border-color: ${theme.colors.neutral.mediumDark};
    z-index: 1;
  }
  &:disabled {
    color: ${theme.colors.neutral.mediumDark};
    cursor: not-allowed;
  }
  ::placeholder {
    color: ${theme.colors.neutral.mediumDark};
  }
`.withComponent("textarea");

TextArea.displayName = "TextArea";

TextArea.defaultProps = {
  maxLength: 255,
  p: 2,
  mb: 4,
  borderRadius: 4,
  width: "100%",
  height: "8rem",
};

export default TextArea;
