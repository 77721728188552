import { useEffect, useState } from "react";

import { appInsideIframe } from "~/app-inside-iframe";
import { useAuth } from "~/auth-provider";

import { AuthTimeoutModal } from "./auth-timeout-modal";

export const AuthTimeoutChecker = () => {
  const { hasPassedExpiryThreshold, session } = useAuth();
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const timeoutWarningThreshold = 10; // percent

  useEffect(() => {
    if (session && !appInsideIframe) {
      const getExpiry = async () => {
        const isExpiring = await hasPassedExpiryThreshold(timeoutWarningThreshold);
        if (isExpiring !== isWarningModalOpen) setIsWarningModalOpen(isExpiring);
      };
      const interval = setInterval(() => {
        getExpiry().catch(console.error);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [hasPassedExpiryThreshold]);

  return (
    <>
      {isWarningModalOpen && <AuthTimeoutModal handleExit={() => setIsWarningModalOpen(false)} />}
    </>
  );
};
