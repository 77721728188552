import type { NcIconProps } from "./nc-icon";
import { NcIcon } from "./nc-icon";

const NcIconSortNone = (props: NcIconProps) => (
  <NcIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="m6 8 5.304-5.706a.97.97 0 0 1 1.392 0L18 8M18 16l-5.304 5.706a.97.97 0 0 1-1.392 0L6 16"
    />
  </NcIcon>
);
export { NcIconSortNone };
