import { useQueries, useQuery, UseQueryOptions, useSuspenseQuery } from "@tanstack/react-query";

import { customFetcher, restFetcher } from "~/graphql-hooks/custom-fetcher";
import { IUser } from "~/shared/types";

import {
  GetUserDocument,
  GetUserQuery,
  GetUserQueryVariables,
  useEditableFeaturesQuery,
  useGetUserQuery,
  useSecurityRolesQuery,
  useUserPrivilegesQuery,
  type UserPrivilegesQuery,
} from "./users.generated";

export * from "./users.generated";

/**
 * TODO: Suspense version of generated get user graphql query should be
 * either changed to using the user REST endpoint or avoid using suspense
 */
export const useGetUserSuspenseQuery = <TData = GetUserQuery, TError = unknown>(
  variables: GetUserQueryVariables,
  options?: Omit<UseQueryOptions<GetUserQuery, TError, TData>, "queryKey"> & {
    queryKey?: UseQueryOptions<GetUserQuery, TError, TData>["queryKey"];
  }
) => {
  return useSuspenseQuery<GetUserQuery, TError, TData>({
    queryKey: ["getUser", variables],
    queryFn: customFetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables),
    ...options,
  });
};

useGetUserQuery.getKey = (variables: GetUserQueryVariables) => ["getUser", variables];

export const useUserDetails = (id: number) => {
  const { data } = useGetUserSuspenseQuery({ id });
  // TODO: Removing the IUser type here would be ideal
  return data?.user as IUser;
};
useUserDetails.getKey = (id: number) => ["getUser", { id }];

export const useUserPrivileges = () => {
  const { data } = useUserPrivilegesQuery(undefined, { staleTime: Infinity });
  return data?.userPrivileges ?? ([] as UserPrivilegesQuery["userPrivileges"]);
};

export const useSecurityRoles = () => {
  const { data, ...rest } = useSecurityRolesQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.userSecurityRoles,
    ...rest,
  };
};

export const useEditableFeatures = () => {
  const { data, ...rest } = useEditableFeaturesQuery(undefined, {
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.editableFeatures.features,
    ...rest,
  };
};

const MINS_15 = 15 * 60 * 1000;

const getUserQueryFunction = (userId: number) => {
  return restFetcher<IUser>(`/v1/users/${userId}`);
};

export const useGetUserRestQuery = (userId: number) => {
  return useQuery({
    queryKey: ["user", userId],
    queryFn: () => getUserQueryFunction(userId),
    staleTime: MINS_15,
  });
};

export const useGetUsersByIdsQuery = (userIds: number[]) => {
  const queries = userIds.map(userId => ({
    queryKey: ["user", userId],
    queryFn: () => getUserQueryFunction(userId),
    staleTime: MINS_15,
  }));

  const results = useQueries({ queries });
  const isLoading = results.some(result => result.isLoading);
  const isError = results.some(result => result.isError);
  const data = results.map(result => result.data).filter(u => u !== null && u !== undefined);

  return { data, isLoading, isError };
};
