import { ThemeProvider as StyleThemeProvider } from "emotion-theming";
import { ReactElement } from "react";

import { theme } from "./index";

const ThemeProvider = ({ children }: { children: ReactElement }) => (
  <StyleThemeProvider theme={theme}>{children}</StyleThemeProvider>
);

export default ThemeProvider;
