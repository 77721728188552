import {
  NcFieldDate,
  NcFieldNumber,
  NcFieldSelect,
  NcFieldset,
  NcForm,
  NcLoadingIndicator,
} from "@noted/noted-components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { restFetcher, restPutter } from "~/graphql-hooks/custom-fetcher";
import { useI18n } from "~/hooks/use-i18n";
import { PrimhdCodeQueryResponse } from "~/primhdv2/api";
import { useToast } from "~/shared/components/alerts/alerts";

import PrimhdCodeComboBox from "../../primhd-code-combo-box";
import { PrimhdCollectionOutcome, PrimhdCollectionRecord } from "../../types";

type UpdateApi = Pick<
  PrimhdCollectionRecord,
  | "id"
  | "referralId"
  | "reasonId"
  | "focusOfCareId"
  | "toolId"
  | "protocolId"
  | "statusId"
  | "adminModeId"
  | "collectionDate"
  | "completionDate"
> & { outcomes: PrimhdCollectionOutcome[] };

type FormOutcomes = Record<string, string>;

type CollectionFormFields = {
  reasonId: string;
  focusOfCareId: string;
  toolId: string;
  protocolId: string;
  statusId: string;
  adminModeId: string;
  collectionDate: Date;
  completionDate: Date;
};

interface ICollectionFormProps {
  record: PrimhdCollectionRecord;
  onRecordUpdate: () => void;
  formId: string;
  codes: PrimhdCodeQueryResponse;
}

export const CollectionForm = ({ record, onRecordUpdate, formId, codes }: ICollectionFormProps) => {
  const { t } = useI18n("primhd");
  const { enqueueError } = useToast();

  const { mutate: updateRecord } = useMutation({
    mutationFn: (body: UpdateApi) => restPutter(`/v1/primhd/records/collection/${record.id}`, body),
    onSuccess() {
      onRecordUpdate();
    },
    onError(error) {
      const errorMessage =
        error.status === 400 && error.message
          ? error.message
          : t("primhd:records.edit.update_error");
      enqueueError(errorMessage);
    },
  });

  const form = useForm<CollectionFormFields>({
    defaultValues: {
      reasonId: record.reasonId,
      focusOfCareId: record.focusOfCareId,
      toolId: record.toolId,
      protocolId: record.protocolId,
      statusId: record.statusId,
      adminModeId: record.adminModeId,
      collectionDate: new Date(record.collectionDate),
      completionDate: new Date(record.completionDate),
      ...getOutcomesFormValues(record.outcomes),
    },
  });

  const { watch, handleSubmit, setValue } = form;
  const formValues = watch();
  const toolId = watch("toolId");

  const { isLoading, data: outcomeQuestions } = useQuery({
    queryKey: ["primhd-codes-outcomes", toolId],
    queryFn: () => restFetcher<PrimhdCollectionOutcome[]>(`/v1/primhd/codes/outcomes/${toolId}`),
    enabled: Boolean(toolId),
    staleTime: Infinity,
  });

  const onSubmit = handleSubmit(data => {
    if (!data || !outcomeQuestions) return;
    const {
      reasonId,
      focusOfCareId,
      toolId,
      protocolId,
      statusId,
      adminModeId,
      collectionDate,
      completionDate,
      ...outcomes
    } = data;

    updateRecord({
      id: record.id,
      referralId: record.referralId,
      reasonId,
      focusOfCareId,
      toolId,
      protocolId,
      statusId,
      adminModeId,
      collectionDate: collectionDate.toISOString(),
      completionDate: completionDate.toISOString(),
      outcomes: getUpdateRequestOutcomes(outcomes, outcomeQuestions),
    });
  });

  return (
    <NcForm form={form} onSubmit={onSubmit} id={formId}>
      <div className="grid items-end gap-4 sm:grid-cols-2">
        <NcFieldDate
          inputWidth="full"
          label={t("primhd:records.edit.collection_record.collection_date_label")}
          name="collectionDate"
          picker
          includeTime
          isRequired
        />
        <NcFieldDate
          inputWidth="full"
          label={t("primhd:records.edit.collection_record.completion_date_label")}
          name="completionDate"
          picker
          includeTime
          isRequired
        />
        <PrimhdCodeComboBox
          codes={codes["COLLECTION_REASON"]}
          inputWidth="full"
          label={t("primhd:records.edit.collection_record.reason_id_label")}
          name="reasonId"
          isRequired
        />
        <PrimhdCodeComboBox
          codes={codes["COLLECTION_FOCUS"]}
          inputWidth="full"
          label={t("primhd:records.edit.collection_record.focus_of_care_id_label")}
          name="focusOfCareId"
          isRequired
        />
        <PrimhdCodeComboBox
          codes={codes["COLLECTION_PROTOCOL"]}
          inputWidth="full"
          label={t("primhd:records.edit.collection_record.protocol_id_label")}
          name="protocolId"
          isRequired
        />
        <PrimhdCodeComboBox
          codes={codes["COLLECTION_STATUS"]}
          inputWidth="full"
          label={t("primhd:records.edit.collection_record.status_id_label")}
          name="statusId"
          isRequired
        />
        <PrimhdCodeComboBox
          codes={codes["COLLECTION_ADMIN"]}
          inputWidth="full"
          label={t("primhd:records.edit.collection_record.admin_mode_id_label")}
          name="adminModeId"
          isRequired
        />
        <PrimhdCodeComboBox
          codes={codes["COLLECTION_TOOL"]}
          inputWidth="full"
          label={t("primhd:records.edit.collection_record.tool_id_label")}
          name="toolId"
          onSelectionChange={id => {
            if (id !== toolId) {
              Object.keys(formValues).map(outcomeKey => {
                if (outcomeKey.startsWith("outcome-"))
                  setValue(outcomeKey as keyof CollectionFormFields, "");
              });
            }
          }}
          isRequired
        />
      </div>
      {toolId && (
        <NcFieldset label={t("primhd:records.edit.collection_record.outcomes")} name="outcomes">
          {isLoading ? (
            <NcLoadingIndicator />
          ) : (
            <div className="grid items-end gap-4 sm:grid-cols-2">
              {outcomeQuestions &&
                outcomeQuestions.map(outcome => (
                  <div key={outcome.id}>
                    {outcome.inputType === "NUMERIC" ? (
                      <NcFieldNumber
                        inputWidth="full"
                        label={outcome.description}
                        name={`outcome-${outcome.itemCode}`}
                        minValue={0}
                        maxValue={99}
                      />
                    ) : outcome.inputType === "SELECTION" ? (
                      <NcFieldSelect
                        inputWidth="full"
                        label={outcome.description}
                        name={`outcome-${outcome.itemCode}`}
                        items={outcome.selectableValues.map(val => ({
                          label: val.description,
                          id: val.code,
                        }))}
                      />
                    ) : null}
                  </div>
                ))}
            </div>
          )}
        </NcFieldset>
      )}
    </NcForm>
  );
};

function getOutcomesFormValues(outcomes: PrimhdCollectionOutcome[]) {
  const returnedOutcomes: FormOutcomes = {};
  outcomes.forEach(outcome => {
    if (!outcome.itemValue) {
      return;
    }
    if (outcome.inputType === "NUMERIC") {
      returnedOutcomes[outcome.itemCode] = outcome.itemValue;
    } else if (outcome.inputType === "SELECTION") {
      const selectedValue = outcome.selectableValues.find(
        selectableValue => selectableValue.code === outcome.itemValue
      );
      if (selectedValue) {
        returnedOutcomes[`outcome-${outcome.itemCode}`] = selectedValue.code;
      }
    }
  });
  return returnedOutcomes;
}

function getUpdateRequestOutcomes(
  formOutcomes: FormOutcomes,
  recordOutcomes: PrimhdCollectionOutcome[]
) {
  return recordOutcomes.map(({ itemValue: _, ...outcome }) => {
    const formValue = formOutcomes[`outcome-${outcome.itemCode}`] ?? null;
    return {
      ...outcome,
      itemValue: formValue,
    };
  });
}
