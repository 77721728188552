import css from "@emotion/css";
import { forwardRef } from "react";

import { theme } from "../../theme";

const checkmark = `
<svg width="15" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.8327 1.42185L12.7457 0.198026C12.5162 -0.0584655 12.1448 -0.065793 11.9085 0.176042L5.52813 6.7056C5.29857 6.9401 4.94072 6.9401 4.71116 6.7056L2.09149 4.06739C1.85518 3.82555 1.48384 3.83288 1.25428 4.08937L0.16725 5.31319C-0.0623084 5.56969 -0.0555575 5.98741 0.187505 6.23657L4.71116 10.8241C4.94072 11.0586 5.29857 11.0586 5.52813 10.8241L13.8125 2.34522C14.0556 2.09606 14.0623 1.68567 13.8327 1.42185Z" fill="white"/>
</svg>
`;

const styles = css`
  appearance: none;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid ${theme.colors.neutral.medium};
  background: ${theme.colors.neutral.lightest};
  border-radius: 3px;
  transition: 0.3s ease-out background-color;

  &:hover {
    background: ${theme.colors.neutral.light};
  }

  &:focus {
    box-shadow: 0 0 0 3px ${theme.colors.neutral.medium};
    border-color: ${theme.colors.info.medium};
  }

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    background-color: ${theme.colors.disabledLight};
  }

  &:disabled:checked {
    background-color: ${theme.colors.disabled};
  }

  &:checked {
    background-color: ${theme.colors.info.mediumLight};
    background-image: url("data:image/svg+xml,${encodeURI(checkmark).replace(/#/g, "%23")}");
    background-repeat: no-repeat;
    background-position: center;
  }

  &.checkbox--small {
    width: 1.15rem;
    height: 1.15rem;

    &:checked {
      background-size: 0.8rem;
    }
  }
`;

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  small?: boolean;
}

// a wrapper component for input checkbox
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ small, className, ...props }: CheckboxProps, ref) => (
    <input
      className={`checkbox ${small ? "checkbox--small" : ""} ${className}`}
      type="checkbox"
      css={styles}
      ref={ref}
      {...props}
    />
  )
);

Checkbox.displayName = "Checkbox";
