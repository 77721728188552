import { css } from "@emotion/core";
import type { LabelHTMLAttributes } from "react";
import { forwardRef } from "react";
import { Label as ReactAriaLabel } from "react-aria-components";

import { ncTheme } from "../nc-theme";

export const labelStyles = css`
  display: inline-flex;
  align-items: center;
  gap: ${ncTheme.spacing(1.5)};
  font-weight: ${ncTheme.fontWeight.bold};
  line-height: 1.2;
  cursor: pointer;
`;

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {}

export const NcLabel = forwardRef<HTMLLabelElement, LabelProps>(({ ...props }, ref) => {
  return <ReactAriaLabel data-nc="NcLabel" ref={ref} css={labelStyles} {...props} />;
});
