import { Box } from "../components/primitives";
import styled, { theme } from "../theme";

export const Label = styled(Box)`
  color: ${theme.colors.neutral.darkest};
  box-sizing: border-box;
  font-family: inherit;
  cursor: pointer;
  &:first-letter {
    text-transform: uppercase;
  }
  input,
  textarea,
  select {
    font-weight: normal;
  }
`.withComponent("label");

Label.displayName = "Label";

Label.defaultProps = {
  mt: 0,
  mb: 0,
  lineHeight: 1.3,
  width: "100%",
  fontWeight: 600,
};

export default Label;
