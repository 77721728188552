/*
 * Helpers to handle zod form validation where native string input
 * needs to be transformed before validation
 * */
import { z, ZodDate, ZodNumber } from "zod";

export const zDateAsString = (validator: ZodDate = z.date()) =>
  z.preprocess(arg => {
    if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
  }, validator);

export const zNumberAsString = (validator: ZodNumber = z.number()) =>
  z.preprocess(arg => parseInt(`${arg}`, 10), validator);
