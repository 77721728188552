import { css } from "@emotion/core";
import type { HTMLAttributes, ReactNode } from "react";

import { ncTheme } from "../nc-theme";

const styles = css`
  display: grid;
  gap: ${ncTheme.spacing(4)};
  width: 100%;
`;

interface NcGridLayoutProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

export const NcGridLayout = ({ children, ...props }: NcGridLayoutProps) => {
  return (
    <div data-nc="NcGridLayout" css={styles} {...props}>
      {children}
    </div>
  );
};
