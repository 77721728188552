import "@reach/dialog/styles.css";

import { css } from "@emotion/core";
import { DateTime } from "luxon";
import { ChangeEvent, forwardRef, InputHTMLAttributes, useEffect, useRef, useState } from "react";

import { useI18n } from "~/hooks/use-i18n";

import { theme } from "../theme";
import DatePicker from "./date-picker";

const FORMAT_DATE = "dd/MM/yyyy";

const style = css`
  &.date-input {
    align-items: stretch;
    display: flex;
    max-width: 100%;

    .datepicker {
      display: flex;
      align-items: stretch;

      &__toggle {
        border-radius: 0 ${theme.space[1]} ${theme.space[1]} 0;
      }
    }

    &:focus-within {
      border-radius: ${theme.space[1]};
      outline: 0.1rem solid ${theme.colors.neutral.mediumDark};

      .datepicker__toggle {
        border-color: ${theme.colors.neutral.mediumDark};
      }
    }
  }

  .date-input {
    &__input {
      border: 1px solid ${theme.colors.neutral.medium};
      border-right: 0;
      border-radius: ${theme.space[1]} 0 0 ${theme.space[1]};
      padding: ${theme.space[2]};
      margin-right: -0.2rem;
      flex-grow: 1;
      max-width: calc(100% - 3.2rem);

      &:focus,
      &:active {
        outline: none;
        border-color: ${theme.colors.neutral.mediumDark};
      }
    }
  }
`;

type DatePickerProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  "id" | "className" | "name" | "onBlur" | "onChange" | "disabled"
>;

/**
 * A date picker that allows the user to select a date, but no time value.  It provides an text input
 * that the user can type directly into or use a "Calendar" component to select via a UI.
 *
 * This is very similar to "date-input.tsx" but have the following differences:
 *   1. The text input is an uncontrolled component.  This makes it suited for use with react-hook-form.
 *   2. It's input/output are Strings as apposed to Dates used by "date-input.tsx".
 *   3. It performs no validation on the user input.  This allows the validation to be handled by an
 *      external validation framework like zod.
 */
const UncontrolledDayInput = forwardRef<HTMLInputElement, DatePickerProps>(
  ({ className = "", name, onBlur, onChange, disabled, ...rest }, ref) => {
    const { t } = useI18n();
    const myRef = useRef<HTMLInputElement | null>(null);

    // is there a better way of doing this?
    // the initial render of the DatePicker component will have the incorrect date because the
    // input ref is not attached to the DOM
    // tracking the mount state and force a re-render once we are mounted
    const [, setReady] = useState<boolean>(false);
    useEffect(() => {
      setReady(true);
    }, []);

    const asDate = (value: string | undefined) => {
      if (!value) return undefined;
      // FIXME: handle formats
      const d = DateTime.fromFormat(value, FORMAT_DATE);
      return d.isValid ? d.toJSDate() : undefined;
    };

    const asString = (value: Date | undefined) =>
      value
        ? DateTime.fromJSDate(value).toLocaleString({
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        : "";

    return (
      <div css={style} className={`date-input ${className}`}>
        <input
          className="date-input__input"
          {...rest}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          ref={element => {
            if (!ref) return;

            myRef.current = element;

            if (typeof ref === "function") {
              ref(element);
              return;
            }

            ref.current = element;
          }}
          placeholder={t("date_input.placeholder")}
          maxLength={FORMAT_DATE.length}
          disabled={disabled}
        />
        <DatePicker
          ref={myRef}
          buttonOnly
          date={asDate(myRef.current?.value)}
          showTime={false}
          onDateSelect={d => {
            const value = asString(d);
            myRef.current && (myRef.current.value = value);

            onChange?.({
              currentTarget: { name: name ?? "", value },
              target: { name: name ?? "", value },
            } as ChangeEvent<HTMLInputElement>);
          }}
          interval={1}
          noRounding
          disabled={disabled}
        />
      </div>
    );
  }
);

UncontrolledDayInput.displayName = "UncontrolledDayInput";

export default UncontrolledDayInput;
