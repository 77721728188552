import styled from "../../theme";
import Box from "./box";

export const Absolute = styled(Box)`
  position: absolute;
`;

Absolute.displayName = "Absolute";

export default Absolute;
