import styled, { theme } from "../../theme";
import { Box } from "../primitives";

export const OverlayMessageBoxHighlight = styled(Box)``;

OverlayMessageBoxHighlight.defaultProps = {
  bg: theme.colors.neutral.darkest,
  px: [5, 4],
  py: 4,
  color: theme.colors.neutral.lightest,
};

export default OverlayMessageBoxHighlight;
