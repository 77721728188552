import {
  NcAlert,
  NcButton,
  NcDataList,
  NcFormattedMessage,
  NcHeading,
  NcIconExclamationTriangle,
  NcModal,
} from "@noted/noted-components";
import { useIsMutating, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ReactNode, Suspense } from "react";

import { restPutter } from "~/graphql-hooks/custom-fetcher";
import { useI18n } from "~/hooks/use-i18n";
import { useToast } from "~/shared/components/alerts/toast-provider";

import { PrimhdCodeQueryResponse } from "../api";
import type {
  PrimhdActivityRecord,
  PrimhdClassificationRecord,
  PrimhdCollectionRecord,
  PrimhdConsumerRecord,
  PrimhdRecord,
  PrimhdRecordSummary,
  PrimhdRecordType,
} from "../types";
import { recordQueries } from "./api";
import { ActivityForm, ClassificationForm, CollectionForm, ConsumerForm } from "./record-forms";
import RecordCodeLoader from "./record-forms/record-code-loader";

type PrimhdRecordModalProps = {
  recordSummary: PrimhdRecordSummary;
  onOpenChange: (isOpen: boolean) => void;
};

export const PrimhdRecordModal = ({ recordSummary, onOpenChange }: PrimhdRecordModalProps) => {
  const { t } = useI18n("primhd");
  const { enqueueSuccess, enqueueError } = useToast();
  const queryClient = useQueryClient();
  const mutationCount = useIsMutating();
  const isMutating = mutationCount > 0;

  const { data, isLoading, isError } = useQuery(recordQueries.detail(recordSummary));

  const { mutate: fixRecord } = useMutation({
    mutationFn: () => restPutter(`/v1/primhd/records/fixed/${recordSummary.id}`),
    onSuccess: () => {
      enqueueSuccess(t("primhd:records.edit.update_success"));
      queryClient.invalidateQueries({
        queryKey: recordQueries.all(),
      });
      onOpenChange(false);
    },
    onError: () => {
      enqueueError("Failed to mark record as fixed");
    },
  });

  const formId = "edit-primhd-record"; // Used to submit the form

  const handleUpdate = () => {
    fixRecord();
  };
  return (
    <NcModal
      isOpen={Boolean(recordSummary)}
      onOpenChange={onOpenChange}
      isDismissable={false}
      className="max-w-4xl"
    >
      <NcModal.Header>
        <NcModal.Heading>Edit PRIMHD record details</NcModal.Heading>
      </NcModal.Header>
      <RecordCodeLoader recordType={recordSummary.type}>
        {({ isLoading: isLoadingCodes, codes }) => (
          <NcModal.Body isLoading={isLoading || isLoadingCodes} error={isError}>
            {codes && (
              <RecordForms
                record={data as PrimhdRecord}
                recordType={recordSummary.type}
                codes={codes}
                onUpdate={handleUpdate}
                onClose={() => onOpenChange(false)}
                formId={formId}
              >
                <RecordSummary recordSummary={recordSummary} />
              </RecordForms>
            )}
          </NcModal.Body>
        )}
      </RecordCodeLoader>
      <NcModal.Footer>
        <NcButton onPress={() => onOpenChange(false)} isDisabled={isMutating}>
          Close
        </NcButton>
        <NcButton variant="primary" type="submit" form={formId} isLoading={isLoading || isMutating}>
          Mark as fixed
        </NcButton>
      </NcModal.Footer>
    </NcModal>
  );
};

const RecordSummary = ({ recordSummary }: { recordSummary: PrimhdRecordSummary }) => {
  return (
    <>
      <RecordDetails record={recordSummary} />
      <RecordSubmitMessages recordSummary={recordSummary} />
    </>
  );
};

const RecordForms = ({
  record,
  recordType,
  codes,
  onUpdate,
  formId,
  children,
}: {
  record: PrimhdRecord;
  recordType: PrimhdRecordType;
  codes: PrimhdCodeQueryResponse;
  onUpdate: () => void;
  onClose: () => void;
  formId: string;
  children: ReactNode;
}) => {
  if (!record) return null;
  return (
    <div className="grid gap-5">
      {children}
      <Suspense>
        {recordType === "ACTIVITY" ? (
          <ActivityForm
            codes={codes}
            formId={formId}
            record={record as PrimhdActivityRecord}
            onRecordUpdate={onUpdate}
          />
        ) : recordType === "CLASSIFICATION" ? (
          <ClassificationForm
            codes={codes}
            formId={formId}
            record={record as PrimhdClassificationRecord}
            onRecordUpdate={onUpdate}
          />
        ) : recordType === "COLLECTION" ? (
          <CollectionForm
            codes={codes}
            formId={formId}
            record={record as PrimhdCollectionRecord}
            onRecordUpdate={onUpdate}
          />
        ) : recordType === "CONSUMER" ? (
          <ConsumerForm
            codes={codes}
            formId={formId}
            record={record as PrimhdConsumerRecord}
            onRecordUpdate={onUpdate}
          />
        ) : null}
      </Suspense>
    </div>
  );
};

const RecordDetails = ({ record }: { record: PrimhdRecordSummary }) => {
  return (
    <div className="grid gap-3">
      <NcHeading level={3}>{record.title}</NcHeading>
      <NcDataList>
        <NcDataList.Item label="Date">
          {DateTime.fromISO(record.clientCreated).toLocaleString(DateTime.DATETIME_MED)}
        </NcDataList.Item>
        <NcDataList.Item label="Worker">{record.worker.name}</NcDataList.Item>
        <NcDataList.Item label="Client">{record.client.name}</NcDataList.Item>
        <NcDataList.Item label="Referral ID">{record.referral?.referralId}</NcDataList.Item>
      </NcDataList>
    </div>
  );
};

const RecordSubmitMessages = ({ recordSummary }: { recordSummary: PrimhdRecordSummary }) => {
  const { t } = useI18n("primhd");
  return (
    <div>
      {recordSummary.submitMessages.length ? (
        <div className="grid gap-2">
          <NcHeading level={4}>{t("primhd:records.edit.messages.title")}</NcHeading>
          {recordSummary.submitMessages.map(msg => (
            <NcAlert
              variant="danger"
              key={msg.ref}
              icon={msg.type === "WARNING" ? <NcIconExclamationTriangle /> : null}
            >
              <NcHeading level={4}>{msg.title}</NcHeading>
              <NcFormattedMessage>{msg.message}</NcFormattedMessage>
            </NcAlert>
          ))}
        </div>
      ) : (
        <NcFormattedMessage variant="secondary">
          {t("primhd:records.edit.messages.no_messages")}
        </NcFormattedMessage>
      )}
    </div>
  );
};
