import { ReactNode } from "react";

import { Info } from "../../icons";
import { Box } from "../primitives";
import MessageBox from "./message-box";

interface AlertMessageBoxProps {
  children: ReactNode;
  variant?: "info" | "warning" | "error";
}

const AlertMessageBox = ({ children, variant = "info" }: AlertMessageBoxProps) => {
  return (
    <MessageBox
      display="flex"
      flexDirection="row"
      gridGap={12}
      variant={variant === "error" ? "danger" : variant}
      width="100%"
    >
      <Info fill="currentColor" height={20} width={20} />
      <Box>{children}</Box>
    </MessageBox>
  );
};

export default AlertMessageBox;
