import { FunctionComponent, Suspense, useEffect } from "react";

import { useI18n } from "~/hooks/use-i18n";

import { LoadingLogo } from "../shared/components/loading/loading-logo";
import { Flex } from "../shared/components/primitives";

export function WaitingComponent(Comp: FunctionComponent) {
  // eslint-disable-next-line react/display-name
  return (props: Record<string, unknown> | null) => (
    <Suspense fallback={<LoadingPage />}>
      <Comp {...props} />
    </Suspense>
  );
}

export const LoadingPage = () => {
  const { t } = useI18n();
  useEffect(() => {
    if (t) {
      document.title = t("loading");
    }
  }, [t]);
  return (
    <Flex width="100%" height="100vh" alignItems="center" justifyContent="center">
      <LoadingLogo width="60" />
    </Flex>
  );
};
