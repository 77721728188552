import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { restPoster } from "~/graphql-hooks/custom-fetcher";

export const usePublicUsernameFormValidation = (
  username?: string,
  options?: Omit<UseQueryOptions, "queryKey" | "queryFn">
) => {
  return useQuery({
    queryKey: ["username-validator", username],
    queryFn: () => restPoster("/v1/signup/validateUsername", { username }),
    ...options,
  });
};
