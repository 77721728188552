import { css } from "@emotion/core";
import type { FieldsetHTMLAttributes, ForwardedRef, HTMLAttributes, ReactNode } from "react";
import { forwardRef } from "react";
import { useField } from "react-aria";

import { ncTheme } from "../../nc-theme";
import { NcGroup } from "../nc-group";
import type { FieldProps } from "./nc-field";
import { errorMessageStyles, NcField } from "./nc-field";

export interface FieldsetProps
  extends FieldProps,
    Omit<FieldsetHTMLAttributes<HTMLFieldSetElement>, "name"> {
  children: ReactNode;
  errorMessage?: ReactNode | string;
}

const styles = {
  outer: css`
    border: ${ncTheme.border()};
    border-radius: ${ncTheme.borderRadius.medium};
    padding: ${ncTheme.spacing(2)} ${ncTheme.spacing(4)} ${ncTheme.spacing(4)};
    background-color: ${ncTheme.colors.uiLight};

    &[disabled] {
      background-color: ${ncTheme.colors.disabledLight};
      color: ${ncTheme.colors.disabled};

      *:not {
        color: ${ncTheme.colors.disabled};
      }

      legend {
        background-color: ${ncTheme.colors.disabled};
        color: ${ncTheme.colors.disabledLight};
      }
    }

    &[aria-invalid="true"] {
      border-color: ${ncTheme.colors.danger};
    }

    [data-focus-within="true"] > &,
    &:focus-within {
      border-color: ${ncTheme.colors.active};
    }
  `,
  legend: css`
    padding-block: ${ncTheme.spacing(1)};
    padding-inline: ${ncTheme.spacing(2)};
    margin-bottom: ${ncTheme.spacing(1)};
    background-color: ${ncTheme.colors.main};
    border-radius: ${ncTheme.borderRadius.small};
    color: ${ncTheme.colors.light};
    font-weight: bold;

    [aria-invalid="true"] > & {
      background-color: ${ncTheme.colors.danger};
    }
  `,
  messages: css`
    :not(:empty) {
      margin-bottom: ${ncTheme.spacing(3)};
    }
  `,
  nested: css`
    display: grid;
    gap: ${ncTheme.spacing(2)};
  `,
};

export const NcFieldset = forwardRef(
  (
    { errorMessage, children, labelNode, ...props }: FieldsetProps,
    ref: ForwardedRef<HTMLFieldSetElement>
  ) => {
    const { labelProps, fieldProps, errorMessageProps, descriptionProps } = useField(props);
    return (
      <NcGroup data-nc="NcFieldset">
        <fieldset
          {...fieldProps}
          {...props}
          css={styles.outer}
          ref={ref}
          aria-invalid={!!errorMessage}
        >
          <legend {...(labelProps as HTMLAttributes<HTMLLegendElement>)} css={styles.legend}>
            {labelNode || props.label}
          </legend>
          <div {...descriptionProps} css={styles.messages}>
            <NcField.Description {...descriptionProps}>{props.description}</NcField.Description>
            {errorMessage && (
              <div {...errorMessageProps} css={errorMessageStyles}>
                {errorMessage}
              </div>
            )}
          </div>
          <div css={styles.nested}>{children}</div>
        </fieldset>
      </NcGroup>
    );
  }
);
