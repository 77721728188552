import styled from "../../theme";
import Text from "../primitives/text";

export const SubLinkText = styled(Text)`
  white-space: nowrap;
`;

SubLinkText.displayName = "Title";

SubLinkText.defaultProps = {
  fontSize: 1,
};

export default SubLinkText;
