import { css } from "@emotion/core";

import { ncTheme } from "../nc-theme";
import { useI18n } from "../use-i18n";
import { NcButton } from "./nc-button";
import { NcLoadingIndicator } from "./nc-loading-indicator";

const styles = {
  list: css`
    display: flex;
    li {
      &:not(:first-of-type) {
        margin-left: -${ncTheme.borderWidth.medium};
      }
      &:first-of-type button {
        border-top-left-radius: ${ncTheme.borderRadius.medium};
        border-bottom-left-radius: ${ncTheme.borderRadius.medium};
      }

      &:last-of-type button {
        border-top-right-radius: ${ncTheme.borderRadius.medium};
        border-bottom-right-radius: ${ncTheme.borderRadius.medium};
      }
    }
  `,
  loading: css`
    padding-block: ${ncTheme.spacing(2)};
  `,
  responsivelyHidden: css`
    display: none;

    ${ncTheme.mediaQueries.width.medium} {
      display: initial;
    }
  `,
  pageAction: css`
    position: relative;
    border-radius: 0;
    min-width: 2.25em;

    [aria-current="page"] &,
    &[data-hovered],
    &[data-focus-visible] {
      z-index: 1;
    }

    [aria-current="page"] & {
      &,
      &[data-hovered],
      &[data-pressed="true"] {
        cursor: default;
        border-color: ${ncTheme.colors.main};
        background-color: ${ncTheme.colors.main};
        color: ${ncTheme.colors.light};
      }
    }
  `,
};

interface NcPaginatorProps {
  currentPage: number;
  totalPages: number;
  maxCurrentAdjacentPages?: number;
  zeroBasedPagination?: boolean;
  onPageChange: (page: number) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const NcPaginator = ({
  currentPage,
  totalPages,
  maxCurrentAdjacentPages = 2,
  zeroBasedPagination,
  onPageChange,
  isLoading,
  isDisabled,
}: NcPaginatorProps) => {
  const { t } = useI18n();
  const firstPage = zeroBasedPagination ? 0 : 1;
  const lastPage = zeroBasedPagination ? totalPages - 1 : totalPages;
  const range = (num: number) => [...Array(num)].map((_, i) => i + firstPage);
  const FilterForMaxItems = (n: number) =>
    currentPage + maxCurrentAdjacentPages >= n && currentPage - maxCurrentAdjacentPages <= n;

  const pages = range(totalPages).filter(FilterForMaxItems);

  function getPageAriaLabel(page: number, pageLabel: number) {
    const isCurrentPage = page === currentPage;
    return isCurrentPage ? `${t("current page")}, ${pageLabel}` : `${t("go to page")} ${pageLabel}`;
  }

  return (
    <nav
      data-nc="NcPaginator"
      data-testid="pagination"
      role="navigation"
      aria-label={t("Pagination navigation")}
    >
      <ul css={styles.list}>
        {isLoading ? (
          <li css={styles.loading}>
            <NcLoadingIndicator />
          </li>
        ) : (
          <>
            <li css={styles.responsivelyHidden}>
              <NcButton
                css={styles.pageAction}
                isDisabled={currentPage <= firstPage || isDisabled}
                onPress={() => onPageChange(firstPage)}
              >
                {t("First")}
              </NcButton>
            </li>

            <li>
              <NcButton
                css={styles.pageAction}
                isDisabled={currentPage <= firstPage || isDisabled}
                onPress={() => onPageChange(currentPage - 1)}
              >
                {t("Previous")}
              </NcButton>
            </li>

            {pages.map(page => {
              const pageLabel = zeroBasedPagination ? page + 1 : page;
              const pageAriaLabel = getPageAriaLabel(page, pageLabel);
              return (
                <li
                  key={page}
                  css={styles.responsivelyHidden}
                  aria-current={page === currentPage ? "page" : undefined}
                >
                  <NcButton
                    css={styles.pageAction}
                    onPress={() => onPageChange(page)}
                    aria-label={pageAriaLabel}
                    isDisabled={isDisabled}
                  >
                    {pageLabel}
                  </NcButton>
                </li>
              );
            })}

            <li>
              <NcButton
                css={styles.pageAction}
                isDisabled={currentPage >= lastPage || isDisabled}
                onPress={() => onPageChange(currentPage + 1)}
              >
                {t("Next")}
              </NcButton>
            </li>

            <li css={styles.responsivelyHidden}>
              <NcButton
                css={styles.pageAction}
                isDisabled={currentPage >= lastPage || isDisabled}
                onPress={() => onPageChange(lastPage)}
              >
                {t("Last")}
              </NcButton>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export { NcPaginator };
