import styled from "../theme";
import { Input } from "./input";

const encodeSvg = (svg: string) =>
  `url("data:image/svg+xml,${encodeURI(svg).replace(/#/g, "%23")}")`;

const svg = (colorHandle: string, colorHandleOutline: string, colorText: string) =>
  `${encodeSvg(`<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="-2 -2 28 28">
    <circle class="handle" cx="12" cy="12" r="11" fill="${colorHandle}" stroke="${colorHandleOutline}"/>
  </svg>`)},
   ${encodeSvg(`<svg viewBox="0 0 16 16" fill="${colorText}" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"/>
  </svg>`)},
  ${encodeSvg(` <svg viewBox="0 0 17 16" height="16" width="16" xmlns="http://www.w3.org/2000/svg" fill="${colorText}">
    <path d="M11.7384 4.26161C12.0872 4.61041 12.0872 5.17594 11.7384 5.52475L9.26314 7.99999L11.7384 10.4753C12.0872 10.8241 12.0872 11.3896 11.7384 11.7384C11.3896 12.0872 10.8241 12.0872 10.4753 11.7384L8 9.26314L5.52474 11.7384C5.17594 12.0872 4.61041 12.0872 4.2616 11.7384C3.9128 11.3896 3.9128 10.8241 4.2616 10.4753L6.73686 7.99999L4.26162 5.52475C3.91281 5.17594 3.91281 4.61041 4.26162 4.26161C4.61042 3.9128 5.17595 3.9128 5.52476 4.26161L8 6.73685L10.4752 4.26161C10.824 3.9128 11.3896 3.9128 11.7384 4.26161Z" />
  </svg>`)}
  `;

export const Toggle = styled(Input)(({ theme: { colors } }) => {
  return {
    appearance: "none",
    padding: 0,
    printColorAdjust: "exact",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    flexShrink: 0,
    height: `1.75rem`,
    aspectRatio: "2/1",
    borderRadius: 13,
    color: `${colors.success.medium}`,
    border: `1px solid ${colors.neutral.medium}`,
    transitionProperty: "box-shadow, background, border-color",
    transitionDuration: "150ms",
    transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,

    backgroundColor: colors.info.medium,
    backgroundOrigin: "border-box",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: `left, -200%, right`,
    backgroundImage: svg(colors.neutral.light, colors.neutral.medium, colors.neutral.lightest),
    cursor: "pointer",

    "&:hover, &:focus": {
      borderColor: colors.neutral.medium,
    },

    "&:checked": {
      backgroundPosition: "right, left, 200%",
      backgroundColor: colors.success?.medium,
    },

    "&:disabled": {
      color: colors.disabledLight,
      backgroundColor: colors.disabled,
      backgroundImage: svg(colors.disabledLight, colors.disabled, colors.disabledLight),
      cursor: "not-allowed",
    },
  };
});

Toggle.displayName = "Toggle";

Toggle.defaultProps = {
  type: "checkbox",
};

export default Toggle;
