import isPropValid from "@emotion/is-prop-valid";
import { Link } from "react-router-dom";

import styled from "../../theme";
import Button from "../forms/button";

export const LinkAsButton = styled(Button.withComponent(Link), {
  shouldForwardProp: prop => isPropValid(prop),
})``;

LinkAsButton.displayName = "LinkAsButton";

LinkAsButton.defaultProps = {
  type: "link",
  variant: "default",
  py: 2,
  px: 4,
  fontWeight: 600,
  borderRadius: 4,
};

export default LinkAsButton;
