import Rect, { useRect } from "@reach/rect";
import { useCombobox, UseComboboxStateChangeOptions } from "downshift";
import {
  ComponentProps,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  SVGProps,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

import { getSearchNormalizedString } from "~/shared/functions/searchHelpers";

import styled from "../../theme";
import { Input } from "../../ui";
import { Box, Text } from "../primitives";

interface DropdownSearchCommonProps<T> {
  id: string;
  items: T[];
  getItemId: (item: T) => number | string;
  getItemText: (item: T) => string;
  getDisplayText?: (item: T | undefined) => string;
  onItemChange: (item?: T) => void;
  showToggleButton?: boolean;
  additionalContent?: ReactNode;
  renderItem?: (item: T) => ReactNode;
}

export interface DropdownSearchProps<T> extends DropdownSearchCommonProps<T> {
  item?: T | undefined;
  itemId?: number | string;
  isItemMatch?: (item: T, input: string) => void;
  noMatchString?: string;
}

export const DropdownSearch = <T,>({
  item,
  items,
  itemId,
  getItemId,
  getItemText,
  isItemMatch,
  noMatchString,
  ...props
}: DropdownSearchProps<T> & Omit<ComponentProps<typeof Input>, "id" | "onSelect">) => {
  const currentItem = item ?? items.find(i => getItemId(i) === itemId);

  const isMatch = useCallback(
    (itm: T, inputValue: string) =>
      isItemMatch
        ? isItemMatch(itm, inputValue)
        : new RegExp(getSearchNormalizedString(inputValue), "i").test(
            getSearchNormalizedString(getItemText(itm))
          ),
    [isItemMatch, getItemText]
  );

  const [searchTerm, setSearchTerm] = useState(currentItem ? getItemText(currentItem) : "");
  const filteredItems = useMemo(
    () => (searchTerm ? items.filter(i => isMatch(i, searchTerm)) : items),
    [items, searchTerm, isMatch]
  );

  const onInputChange = (inputValue: string) => {
    setSearchTerm(inputValue);
  };

  return (
    <DropdownSearchBase
      input={searchTerm}
      item={currentItem}
      items={filteredItems}
      getItemId={getItemId}
      getItemText={getItemText}
      onInputChange={onInputChange}
      {...props}
      additionalContent={
        noMatchString && filteredItems.length === 0 && <Text>{noMatchString}</Text>
      }
    />
  );
};

/*
 * The DropdownSearchBase component is designed to be consumed by a
 * custom 'dropdown-search'-like component.
 *
 * The consuming component needs to:
 *   - handle item filtering (pass filtered items to the 'items' prop)
 *   - handle textbox state (pass the input value to 'input'
 *     and listen for changes from 'onInputChange')
 *   - handle selected item state (pass the current item to 'item'
 *     and listen for changes from 'onItemChange')
 *
 * Why have a dropdown-search-base component? Re-use!
 *
 * We can use this to create a generic dropdown search component that filters
 * a local array of items, but we can also use this to create a custom dropdown search
 * that fetches items from the server.
 *
 * Props:
 *   - id - id of component, required to connect external label to input with 'htmlFor'
 *   - input - the string value displayed in the textbox
 *   - item - the current selected item
 *   - items - the items to render in the dropdown menu
 *   - getItemId - function that tells the component how to retrieve an id from an item
 *   - getItemText - function that tells the component how to retrieve a text
 *     representation of an item
 *   - onInputChange - function that is called when input value changes
 *   - onItemChange - function that is called when an item is selected
 *   - showToggleButton - OPTIONAL boolean to show or hide the menu toggle button
 *   - additionalContent - OPTIONAL ReactNode that renders inside the menu below the item list
 *     of the array of items. Use this for 'loading', 'error', or 'no items' messages.
 *   - renderItem - OPTIONAL function that tells the component how to render an item. If
 *     undefined, items are rendered as strings using 'getItemText'
 */

interface DropdownSearchBaseProps<T> extends DropdownSearchCommonProps<T> {
  item: T | undefined;
  input: string;
  onInputChange: (value: string) => void;
  inputRef?: ForwardedRef<HTMLInputElement>;
}

export const DropdownSearchBase = <T,>({
  id,
  input,
  item,
  items,
  getItemId,
  getItemText,
  getDisplayText,
  onInputChange,
  onItemChange,
  showToggleButton = true,
  additionalContent,
  renderItem,
  inputRef,
  ...props
}: DropdownSearchBaseProps<T> & Omit<ComponentProps<typeof Input>, "id" | "onSelect">) => {
  // "?? null" maintains the 'controlled component' status when 'item' prop is undefined
  const selectedItem = useMemo(() => item ?? null, [item]);
  const itemToString = useCallback(
    (i: T | null) => {
      const getText = getDisplayText ?? getItemText;
      return i ? getText(i) : "";
    },
    [getItemText, getDisplayText]
  );
  const onInputValueChange = useCallback(
    ({ inputValue: v }: { inputValue?: string }) => onInputChange(v ?? ""),
    [onInputChange]
  );
  const onSelectedItemChange = useCallback(
    ({ selectedItem }: { selectedItem?: T | null }) => onItemChange(selectedItem ?? undefined),
    [onItemChange]
  );

  // This customises how downshift handles state changes
  const stateReducer = useCallback(
    (_: unknown, { type, changes }: UseComboboxStateChangeOptions<T>) => {
      if (type === useCombobox.stateChangeTypes.InputBlur) {
        return {
          ...changes,
          // Setting selectedItem to current selectedItem prevents selecting of highlighted item
          // on input blur so highlighted item is not accidentally selected when user clicks away
          selectedItem,
          // Makes sure input has the selected item's full text on blur,
          // as the text may have been changed by user after selecting the item
          inputValue: selectedItem ? getItemText(selectedItem) : "",
        };
      }
      return changes;
    },
    [getItemText, selectedItem]
  );

  const {
    isOpen,
    highlightedIndex,
    getMenuProps,
    getInputProps,
    getToggleButtonProps,
    getComboboxProps,
    getItemProps,
  } = useCombobox({
    id,
    items,
    selectedItem,
    inputValue: input,
    onInputValueChange,
    onSelectedItemChange,
    itemToString,
    stateReducer,
  });
  return (
    <Box width="100%" {...getComboboxProps()}>
      <Input
        {...props}
        // 'aria-labelledby' set to undefined as label is rendered outside component
        {...getInputProps({ id, "aria-labelledby": undefined, ref: inputRef })}
      />
      {!!showToggleButton && (
        <button
          {...getToggleButtonProps()}
          style={{
            position: "absolute",
            right: "6px",
            top: "6px",
            cursor: "pointer",
            zIndex: "3",
            background: "transparent",
          }}
          type="button"
        >
          <ToggleButtonIcon />
        </button>
      )}
      {/* 'aria-labelledby' set to undefined as label is rendered outside component */}
      <Menu isOpen={isOpen && !props.disabled} {...getMenuProps({ "aria-labelledby": undefined })}>
        {items.map((itm, index) => (
          <Item
            isHighlighted={highlightedIndex === index}
            key={`${getItemId(itm)}`}
            {...getItemProps({ item: itm, index })}
          >
            {renderItem ? renderItem(itm) : getItemText(itm)}
          </Item>
        ))}
        {additionalContent && (
          <AdditionalContentWrapper>{additionalContent}</AdditionalContentWrapper>
        )}
      </Menu>
    </Box>
  );
};

interface IMenuProps {
  isOpen: boolean;
  id?: string;
}

const Menu = forwardRef<HTMLUListElement, PropsWithChildren<IMenuProps>>(
  ({ id, isOpen, children, ...props }, ref) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const menuRect = useRect(menuRef);

    return (
      <Rect>
        {({ rect, ref: rectRef }) => (
          <div ref={rectRef}>
            {createPortal(
              <StyledMenu
                id={id}
                data-testid={id}
                {...props}
                ref={ref}
                style={{
                  top: ((isOpen && rect?.top) || 0) + 4,
                  left: (isOpen && rect?.left) || 0,
                  width: rect?.width || "100%",
                  visibility: isOpen ? "visible" : "hidden",
                  minHeight: Math.min(menuRect?.height ?? 0, 300),
                  maxHeight: Math.min(menuRect?.height ?? 0, 300),
                  boxSizing: "initial",
                }}
              >
                <Box ref={menuRef}>{isOpen && children}</Box>
              </StyledMenu>,
              document.body
            )}
          </div>
        )}
      </Rect>
    );
  }
);
Menu.displayName = "Menu";

const StyledMenu = styled.ul(({ theme }) => ({
  background: theme.colors.neutral.lightest,
  padding: `${theme.space[2]} 0`,
  boxShadow: `2px 2px 2px rgba(39, 50, 63, 0.14)`,
  borderLeft: `1px solid ${theme.colors.neutral.light}`,
  borderTop: `1px solid ${theme.colors.neutral.light}`,
  borderRadius: "4px",
  overflow: "auto",
  position: "absolute",
  zIndex: 3,
}));

interface IItemProps {
  isHighlighted: boolean;
}

const Item = forwardRef<HTMLLIElement, PropsWithChildren<IItemProps>>(
  ({ isHighlighted, ...props }, ref) => {
    return <StyledItem isHighlighted={isHighlighted} ref={ref} {...props} />;
  }
);
Item.displayName = "Item";

const AdditionalContentWrapper = styled.div(({ theme }) => ({
  padding: `0 ${theme.space[2]}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledItem = styled.li<{ isHighlighted?: boolean }>(({ isHighlighted, theme }) => ({
  padding: theme.space[2],
  cursor: "pointer",
  ...(isHighlighted
    ? {
        backgroundColor: theme.colors.primary.dark,
        color: theme.colors.neutral.lightest,
      }
    : {}),
}));

export const ToggleButtonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" {...props}>
    <path
      fill="currentColor"
      d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
    />
  </svg>
);
