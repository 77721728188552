import {
  createContext,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from "react";

const CalendarRefContext = createContext<{
  calendarRef: RefObject<HTMLDivElement>;
  center: (el: HTMLElement) => void;
}>({ calendarRef: { current: null }, center: () => undefined });

export const CalendarRefState = ({ children }: { children: ReactNode }) => {
  const calendarRef = useRef<HTMLDivElement>(null);
  const center = useCallback(
    (el: HTMLElement) => {
      if (calendarRef.current) {
        const boundaries = calendarRef.current.getBoundingClientRect();
        const elementBoundaries = el.getBoundingClientRect();

        const centerScroll = {
          top:
            elementBoundaries.top -
            boundaries.top +
            calendarRef.current.scrollTop -
            boundaries.height / 2 +
            elementBoundaries.height / 2,
          left:
            elementBoundaries.left -
            boundaries.left +
            calendarRef.current.scrollLeft -
            boundaries.width / 2 +
            elementBoundaries.width / 2,
        };

        if (calendarRef.current.scrollTo) {
          calendarRef.current.scrollTo({ ...centerScroll, behavior: "smooth" });
        } else {
          calendarRef.current.scrollTop = centerScroll.top;
          calendarRef.current.scrollLeft = centerScroll.left;
        }
      }
    },
    [calendarRef]
  );

  const value = useMemo(() => ({ calendarRef, center }), [calendarRef, center]);

  return <CalendarRefContext.Provider value={value}>{children}</CalendarRefContext.Provider>;
};

export const useBookingCalendarRef = () => useContext(CalendarRefContext);
