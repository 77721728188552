import { css } from "@emotion/core";
import type { ReactNode } from "react";
import {
  Breadcrumb as ReactAriaBreadcrumb,
  Breadcrumbs as ReactAriaBreadcrumbs,
  type BreadcrumbProps as ReactAriaBreadcrumbProps,
} from "react-aria-components";

import { ncTheme } from "../nc-theme";

const styles = {
  layout: css`
    background-color: ${ncTheme.colors.uiLight};
  `,
  breadcrumbs: css`
    display: flex;
    margin-inline: auto;
    padding-inline: ${ncTheme.spacing(8)};
    padding-block: ${ncTheme.spacing(2)};
    max-width: ${ncTheme.containers.page};
  `,
  breadcrumb: css`
    &:not(:last-child)::after {
      content: "/";
      content: "/" / "";
      alt: " ";
      padding: 0 5px;
    }
  `,
};

interface NcBreadcrumbsProps {
  children: ReactNode;
  ariaLabel: string;
}

interface BreadcrumbsProps {
  children: ReactNode;
}

const NcBreadcrumbs = ({ children, ariaLabel, ...props }: NcBreadcrumbsProps) => {
  return (
    <nav data-nc="NcBreadcrumbs" css={styles.layout} aria-label={ariaLabel} {...props}>
      {children}
    </nav>
  );
};

const Breadcrumbs = ({ children, ...props }: BreadcrumbsProps) => {
  return (
    <ReactAriaBreadcrumbs data-nc="NcBreadcrumbs.Breadcrumbs" css={styles.breadcrumbs} {...props}>
      {children}
    </ReactAriaBreadcrumbs>
  );
};

interface BreadcrumbProps extends ReactAriaBreadcrumbProps {
  children: ReactNode;
}

const Breadcrumb = ({ children, ...props }: BreadcrumbProps) => {
  return (
    <ReactAriaBreadcrumb data-nc="NcBreadcrumbs.Breadcrumb" css={styles.breadcrumb} {...props}>
      {children}
    </ReactAriaBreadcrumb>
  );
};

NcBreadcrumbs.Breadcrumbs = Breadcrumbs;
NcBreadcrumbs.Breadcrumb = Breadcrumb;

export { NcBreadcrumbs };
