import { uniq } from "lodash-es";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { type Service } from "~/graphql-hooks/types";

import { AccountContext } from "../../../account/account.context";
import {
  getFilteredResources,
  getSortedResources,
} from "../../shared/resource-filter/resource-filter-helpers";
import { Mode } from "../agenda-view";
import { useAvailability } from "../availability-context";
import { useWeekViewFlag, useWeekViewResource } from "../context/booking-view-context";
import { IResource, useResources } from "../resources-context";
import { useServices } from "../services-context";
import { useBookingView } from "./booking-view-context";

export interface IResourceFilters {
  serviceIds: number[];
}

interface IResourceData {
  filters: IResourceFilters;
  unselectedWorkerIds: number[];
  unselectedSpaceIds: number[];
  sortedWorkerIds: number[];
  sortedSpaceIds: number[];
}

export interface IResourceFilterContext {
  sortedWorkerIds: number[];
  setSortedWorkerIds: Dispatch<SetStateAction<number[]>>;
  sortedSpaceIds: number[];
  setSortedSpaceIds: Dispatch<SetStateAction<number[]>>;
  selectedWorkerIds: number[];
  setSelectedWorkerIds: Dispatch<SetStateAction<number[]>>;
  selectedSpaceIds: number[];
  setSelectedSpaceIds: Dispatch<SetStateAction<number[]>>;
  resourceFilters: IResourceFilters;
  setResourceFilters: Dispatch<SetStateAction<IResourceFilters>>;
  ensureResourcesAreSelected: (resoureIds: { workerIds: number[]; spaceIds: number[] }) => void;
}

const localStorageKey = "resourceFilterData";

const isUnkownAValidNumber = (v: unknown) => typeof v === "number" && !isNaN(v);

const getObjectFromString = (str: string | null): object => {
  if (!str) {
    return {};
  }

  const data = JSON.parse(str);

  if (typeof data !== "object" || data === null) {
    return {};
  }

  return data;
};

const setPersistedResourceData = (
  userId: number,
  sortedWorkerIds: number[],
  sortedSpaceIds: number[],
  selectedWorkerIds: number[],
  selectedSpaceIds: number[],
  filters: IResourceFilters
) => {
  const unselectedWorkerIds = sortedWorkerIds.filter(w => !selectedWorkerIds.some(sw => sw === w));
  const unselectedSpaceIds = sortedSpaceIds.filter(s => !selectedSpaceIds.some(ss => ss === s));

  const newPersistedDataForUser: IResourceData = {
    filters,
    sortedWorkerIds,
    sortedSpaceIds,
    unselectedWorkerIds,
    unselectedSpaceIds,
  };

  const previousPersistedData = getObjectFromString(localStorage.getItem(localStorageKey));

  localStorage.setItem(
    localStorageKey,
    JSON.stringify({ ...previousPersistedData, [userId]: newPersistedDataForUser })
  );
};

const getPersistedResourceData = (userId: number): IResourceData => {
  const emptyData: IResourceData = {
    filters: {
      serviceIds: [],
    },
    unselectedWorkerIds: [],
    unselectedSpaceIds: [],
    sortedWorkerIds: [],
    sortedSpaceIds: [],
  };

  try {
    const value = localStorage.getItem(localStorageKey);

    if (typeof value !== "string") {
      return emptyData;
    }

    const data = JSON.parse(value);

    if (typeof data !== "object" || !data[userId]) {
      return emptyData;
    }

    const userData = data[userId];

    if (
      !Array.isArray(userData.filters.serviceIds) ||
      !Array.isArray(userData.unselectedWorkerIds) ||
      !Array.isArray(userData.unselectedSpaceIds) ||
      !Array.isArray(userData.sortedWorkerIds) ||
      !Array.isArray(userData.sortedSpaceIds)
    ) {
      return emptyData;
    }

    return {
      filters: {
        serviceIds: userData.filters.serviceIds.filter(isUnkownAValidNumber),
      },
      unselectedWorkerIds: userData.unselectedWorkerIds.filter(isUnkownAValidNumber),
      unselectedSpaceIds: userData.unselectedSpaceIds.filter(isUnkownAValidNumber),
      sortedWorkerIds: userData.sortedWorkerIds.filter(isUnkownAValidNumber),
      sortedSpaceIds: userData.sortedSpaceIds.filter(isUnkownAValidNumber),
    };
  } catch {
    return emptyData;
  }
};

const getSortedResourceIds = (resources: IResource[], previousSortedIds: number[]) => {
  return resources
    .map(r => r.id)
    .sort((a, b) => {
      const indexA = previousSortedIds.indexOf(a);
      const indexB = previousSortedIds.indexOf(b);
      return indexA > -1 ? (indexB > -1 ? indexA - indexB : -1) : 1;
    });
};

const getSelectedResources = (resources: IResource[], unselectedIds: number[]) => {
  return resources.filter(r => !unselectedIds.some(u => u === r.id)).map(r => r.id);
};

const getValidResourceFilters = (
  previousFilters: IResourceFilters,
  services: Service[]
): IResourceFilters => {
  return {
    serviceIds: previousFilters.serviceIds.filter(sId => services.some(s => s.id === sId)),
  };
};

export const ResourceFilterContext = createContext<IResourceFilterContext>(
  {} as IResourceFilterContext
);
export const useResourceFilter = () => useContext(ResourceFilterContext);

export const ResourcesFilterProvider = ({ children }: { children: ReactNode }) => {
  const { workers, spaces } = useResources();
  const { services } = useServices();

  const {
    account: { id: userId },
  } = useContext(AccountContext);

  const [sortedWorkerIds, setSortedWorkerIds] = useState<number[]>([]);
  const [sortedSpaceIds, setSortedSpaceIds] = useState<number[]>([]);
  const [selectedWorkerIds, setSelectedWorkerIds] = useState<number[]>([]);
  const [selectedSpaceIds, setSelectedSpaceIds] = useState<number[]>([]);
  const [resourceFilters, setResourceFilters] = useState<IResourceFilters>({ serviceIds: [] });

  const ensureResourcesAreSelected = useCallback(
    ({ workerIds, spaceIds }: { workerIds: number[]; spaceIds: number[] }) => {
      setSelectedWorkerIds(savedIds =>
        workerIds.every(id => savedIds.includes(id)) ? savedIds : uniq([...savedIds, ...workerIds])
      );
      setSelectedSpaceIds(savedIds =>
        spaceIds.every(id => savedIds.includes(id)) ? savedIds : uniq([...savedIds, ...spaceIds])
      );
    },
    []
  );

  useEffect(() => {
    const localData = getPersistedResourceData(userId);
    setSortedWorkerIds(getSortedResourceIds(workers, localData.sortedWorkerIds));
    setSortedSpaceIds(getSortedResourceIds(spaces, localData.sortedSpaceIds));
    setSelectedWorkerIds(getSelectedResources(workers, localData.unselectedWorkerIds));
    setSelectedSpaceIds(getSelectedResources(spaces, localData.unselectedSpaceIds));
    setResourceFilters(getValidResourceFilters(localData.filters, services as Service[]));
  }, [
    userId,
    workers,
    spaces,
    services,
    setSortedWorkerIds,
    setSortedSpaceIds,
    setSelectedWorkerIds,
    setSelectedSpaceIds,
    setResourceFilters,
  ]);

  useEffect(() => {
    // Only set once resources have loaded, i.e. there are resources and we have their sort order
    if (sortedWorkerIds.length || sortedSpaceIds.length) {
      setPersistedResourceData(
        userId,
        sortedWorkerIds,
        sortedSpaceIds,
        selectedWorkerIds,
        selectedSpaceIds,
        resourceFilters
      );
    }
  }, [
    userId,
    sortedWorkerIds,
    sortedSpaceIds,
    selectedWorkerIds,
    selectedSpaceIds,
    resourceFilters,
  ]);

  const value = useMemo(
    () => ({
      sortedWorkerIds,
      setSortedWorkerIds,
      sortedSpaceIds,
      setSortedSpaceIds,
      selectedWorkerIds,
      setSelectedWorkerIds,
      selectedSpaceIds,
      setSelectedSpaceIds,
      resourceFilters,
      setResourceFilters,
      ensureResourcesAreSelected,
    }),
    [
      resourceFilters,
      selectedSpaceIds,
      selectedWorkerIds,
      sortedSpaceIds,
      sortedWorkerIds,
      ensureResourcesAreSelected,
    ]
  );

  return <ResourceFilterContext.Provider value={value}>{children}</ResourceFilterContext.Provider>;
};

const useSelectedResources = (mode: Mode) => {
  const { workers, spaces } = useResources();
  const { selectedWorkerIds, selectedSpaceIds, sortedWorkerIds, sortedSpaceIds, resourceFilters } =
    useResourceFilter();

  const filteredResources = useMemo(
    () =>
      mode === "workers"
        ? getSortedResources(getFilteredResources(workers, resourceFilters), sortedWorkerIds)
        : getSortedResources(getFilteredResources(spaces, resourceFilters), sortedSpaceIds),
    [mode, resourceFilters, sortedSpaceIds, sortedWorkerIds, spaces, workers]
  );

  const selectedResourceIds = useMemo(
    () =>
      (mode === "workers" ? selectedWorkerIds : selectedSpaceIds).filter(rId =>
        filteredResources.some(r => r.id === rId)
      ),
    [filteredResources, mode, selectedSpaceIds, selectedWorkerIds]
  );

  return useMemo(
    () => filteredResources.filter(r => selectedResourceIds.includes(r.id)),
    [filteredResources, selectedResourceIds]
  );
};

export const useDayViewSelectedResources = () => {
  const { weekView } = useWeekViewFlag();
  const { mode } = useBookingView();
  const resources = useSelectedResources(mode);
  return useMemo(() => (weekView ? [] : resources), [resources, weekView]);
};

export const useAvailabilityViewSelectedResources = () => {
  const { mode } = useAvailability();
  return useSelectedResources(mode);
};

export const useBookingHasColumns = () => {
  const { weekView } = useWeekViewFlag();
  const resources = useDayViewSelectedResources();
  const resource = useWeekViewResource();

  return useMemo(
    () => (weekView ? !!resource : !!resources.length),
    [resource, resources.length, weekView]
  );
};
